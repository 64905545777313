import React, { useState, useEffect, useRef  } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid, Drawer, Divider, IconButton , CssBaseline, Button } from "@mui/material"
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PublicIcon from '@mui/icons-material/Public';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import LogoutIcon from '@mui/icons-material/Logout';
import AppsIcon from '@mui/icons-material/Apps';
// import { getAvailableCenters } from "../../utils/Server_api";
import Logo from './img/logo ENVIRO blanco sin fondo.png'
const drawerWidth = '15vw';
const drawerHeight = '90vh';

const DrawerHeader = styled("div")(({ theme })  => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));



const severityMap = {
    "C": "rgb(211, 47, 47)",
    "A": "rgb(253, 183, 70)",
    "B": "rgb(0, 128, 0)"
  };


export default function PersistentDrawerLeft({ 
    handleDrawerClose, 
    open,
    setOpen,
    updateClientCredentials,
    translate,
    capitalize,
    SWVersion,
    handleLogoMouseEnter, handleLogoMouseLeave,
    consolidado_estados,
    selectedRegion, setSelectedRegion,
    selectedArea, setSelectedArea, setStatusCenter
    
 }) {
    const theme = useTheme();

    //* ----------------------------- Estado del componente -----------------------------
    // const [centersData, setCentersData ] = React.useState( [] )
    const [showRegions, setShowRegions] = useState(true);
    const [showAreas, setShowAreas] = useState(true);
    const [posicionArea, setPosicionArea] = useState(null)
    const drawerRef = useRef(null);
    const navigate = useNavigate();
    const [showOptions, setShowOptions] = useState(false);

    //*--------------------- Obtener las regiones, áreas y centros del cliente actual 'regionsData' ------------------------------
    //? Descripción:
    // Utiliza la información de los centros (obtenida de la función getAvailableCenters) para mapear
    // y estructurar los datos de las regiones, áreas y centros. Crea un array de objetos donde cada objeto
    // representa una región, con sus respectivas áreas y centros asociados.
    // Cada región contiene un identificador único ('clientId'), el nombre de la región ('name'), y un array de áreas.
    // Cada área tiene un nombre ('name') y un array de centros asociados.
    // Los centros están determinados por la primera área de cada región.
    // El resultado se almacena en la variable 'regionsData'.
    // Además, se inicializan estados para la región, área y centro seleccionados.

      // Recuperar el objeto desde localStorage
    const rac = [JSON.parse(localStorage.getItem('Regiones'))];


    const regionsData = rac.map((clientData, index) => {
        const regions = Object.keys(clientData);
        
        return regions.map(region => {
            const areas = Object.keys(clientData[region]);
            
            // Mapea las áreas y asigna los centros específicos de cada área
            const mappedAreas = areas.map(area => {
                const centers = clientData[region][area];
                return {
                    name: area,
                    centers: centers,
                };
            });
    
            return {
                clientId: index.toString(),
                name: region,
                areas: mappedAreas,
            };
        });
    }).flat();

    // console.log('regionsData',rac)
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };
    

    // const regionsData = centersData.map((clientData, index) => {
    //     const regions = Object.keys(clientData);
        
    //     return regions.map(region => {
    //         const areas = Object.keys(clientData[region]);
    //         const centers = clientData[region][areas[0]];
        
    //         return {
    //         clientId: index.toString(),  // Utiliza el índice como clientId
    //         name: region,
    //         areas: areas.map(area => ({ name: area, centers })),
    //         };
    //     });
    //     }).flat();
    
    // console.log('centersData',centersData)
    // console.log('regionsData',regionsData)


    //* ----------------------------- Estado del Componente para la Selección de Regiones, Áreas y Centros -----------------------------
    //? Descripción:
    // Se definen estados para manejar la selección de región, área y centro.
    // - selectedRegion: Almacena la región actualmente seleccionada.
    // - selectedArea: Almacena el área actualmente seleccionada dentro de la región.
    // - selectedCentro: Almacena el centro actualmente seleccionado dentro del área.
    // const [selectedRegion, setSelectedRegion] = useState(null);
    // const [selectedArea, setSelectedArea] = useState(null);
    const [selectedCentro, setSelectedCentro] = useState(null);
    // const [centro, setCentro] = useState([]);

    // console.log('selectedRegion, selectedArea, selectedCentro : ', selectedRegion)


    //* ----------------------------- Función para Actualizar los Centros: updateCenters -----------------------------
    // !Deprecado
    //? Descripción:
    // Utiliza la función asíncrona 'getAvailableCenters' para obtener los centros disponibles,
    // actualiza el estado 'centersData' con la nueva información obtenida.
    // const updateCenters = async () => { 
    //     const available_centers = await getAvailableCenters( loggedIn ); 
    //     setCentersData( available_centers )
    //     // console.log( 'available_centers: ', available_centers )
    // }

    //* ----------------------------- Actualización de centros mediante useEffect -----------------------------
    // !Deprecado
    //? Descripción:
    // Utiliza useEffect de React para ejecutar la función 'updateCenters' al montar el componente,
    // lo que garantiza la obtención inicial de datos de centros al cargar el componente.

    // React.useEffect(() => {
    //     updateCenters()
    // }, [] );

    // *  -----------------------------  Función: handleRegionClick ------------------------------------------------
    //? Descripción: 
    //? Función utilizada para poder seleccionar Regiones
    //? Parámetros:
    // - region : objeto con atributo 'clientId' que corresponde al indice del cliente 'name' que corresponde a la region seleccionada
    //            y un atributo 'area' que es un array de objetos de las areas de la region seleccionada, objeto que corresponde al que se desbribe en los parametros de handleAreaClick 
    const handleRegionClick = (region) => {
        // console.log('region',region)
        setSelectedRegion(region);
    };

    // *  -----------------------------  Función: handleAreaClick ------------------------------------------------
    //? Descripción: 
    //? Función utilizada para poder seleccionar areas
    //? Parámetros:
    // - area : objeto con atributo 'name' que corresponde al area seleccionada y un atributo 'centers' que es un array de los centros de esa area
    const handleAreaClick = (area) => {
        setSelectedArea(area.name);
        // setCentro(area.centers)
        // console.log('area center seleccionada',area.centers)
        // console.log('areas seleccionada',area)
    };
    // *  -----------------------------  Función: handleCentroClick ------------------------------------------------
    //? Descripción: 
    //? Función utilizada para poder seleccionar centro
    //? Parámetros:
    // - selectedRegion?.name,  : string de la region seleccionada
    // - area                   : string del area seleccionado
    // - centro                 : string del centro seleccionado
    //?  updateClientCredentials: función de serverApi
    // recibe: selectedRegion?.name, area y centro que corresponden a la region seleccionada, area seleccionada y centro seleccionado 
    const handleCentroClick = (area, centro, status) => {
        setStatusCenter(status)
        setSelectedCentro(centro);
        // console.log('centro', status)
        updateClientCredentials(
          selectedRegion?.name,
          area,
          centro
        );
      };

          //* ------------------------ Función: handleHome ------------------------------------------------------
        //? Descripción: Redirige a la página de inicio al hacer clic en el menú de usuario.
        const handleHome = () => {
            window.location.href = "/home";
        };

           //* ------------------------ Función: handleExit ------------------------------------------------
    //? Descripción: Maneja el cierre del menú de usuario y realiza redirección.
    const handleExit = () => {
        window.location.href = "/"; // Cambia "/Añadir" por la ruta que deseas redirigir
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("empresa");
    };

//  * --------------------------------------- useEffect: para cerrar el drawer al presionar fuera del cajon
    useEffect(() => {
    // Función para cerrar el Drawer cuando se hace clic fuera de él
    const handleOutsideClick = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setOpen(false);
        }
    };

    // Agregar el event listener cuando el Drawer está abierto
    if (open) {
        document.addEventListener('mousedown', handleOutsideClick);
    } else {
        // Remover el event listener cuando el Drawer está cerrado
        document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
    };
    }, [open]);


    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized && consolidado_estados.length > 0) {
            const firstRegion = consolidado_estados[0];
            setSelectedRegion(firstRegion);
            localStorage.setItem('REGION', firstRegion.name);

            if (firstRegion.areas.length > 0) {
                const firstArea = firstRegion.areas[0];
                setSelectedArea(firstArea.name);
                localStorage.setItem('AREA', firstArea.name);
            }
            setInitialized(true);
        }
    }, [consolidado_estados, initialized]);
    

    //* Componente personalizado para el pie de página del Drawer
    const DrawerFooter = () => {
        return (
        <Grid container alignContent='center' style={{height:'10vh', marginTop:'80vh', position: 'absolute', padding: '1vh', borderTop: '1px solid #2f2f2f', }}>
            {/* Contenido del pie de página */}
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}> {translate('email_label')} informaciones@itgchile.com</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}> {translate('phone_label')} +56 (65) 22 52 881</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}> {translate('phone_label')} +56 (65) 22 50 080</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}>ITG Chile</p>
            </Grid>
            <Grid container justifyContent='center' alignContent='center' style={{ height:'1.5vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175, height:'1.3vh', color:'rgba(255,255,255)'}}>{SWVersion}</p>
            </Grid>
        </Grid>
        );
    };
      
    const styleFont='1vw'
    const styleFontNombre='0.75vw'
    const stylep175='0.6vw'
    const heightCelda ='5.6vh'

    return (
        <Grid container justifyContent="center" item xs={1} sx={{ display: "flex" }} style={{ cursor: 'default !important', userSelect: 'none', }} 
        ref={drawerRef} 
        onMouseEnter={() => handleLogoMouseEnter()}
        onMouseLeave={() => handleLogoMouseLeave()}  
        >
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    // height:drawerHeight,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        height:drawerHeight,
                        marginTop:'6.7vh',
                        marginLeft:'1vw',
                        borderRadius:'2vh',
                        border: 0,
                        background:'linear-gradient(#393939, #1a1a1a)',
                        display: 'flex',
                        // backgroundColor:'#404048'
                        // boxSizing: "border-box",
                        // border:'solid 0.01vh '

                    },
                    // border:'solid 0.1vh'
                    borderRadius:'2vh',
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                {/* Logo Itg y boton para cerrar el cajon */}
                <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between', minHeight:heightCelda, maxHeight:heightCelda,backgroundColor:'#252525', '&:hover': {
                                                                backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                                            }, }}>
                    <Button onClick={toggleOptions} fullWidth color='inherit'>
                        <Grid container justifyContent={'center'} alignContent={'center'}>
                            <Grid container justifyContent={'flex-start'} alignContent={'center'} item xs={6}>
                                <img
                                    src={Logo}
                                    style={{ paddingLeft: '0.5vw', width: '12.5vh', aspectRatio: '17/6', paddingTop: '1vh', marginTop:'-1vh' }}
                                    alt={''}
                                />
                            </Grid>
                            <Grid container justifyContent={'flex-end'} alignContent={'center'} item xs={6}>
                                {showOptions=== false ? (
                                    <KeyboardArrowDownIcon style={{fontSize:styleFont}} />
                                ) : (
                                    <KeyboardArrowUpIcon style={{fontSize:styleFont}} />
                                )}
                            </Grid>
                        </Grid>
                    </Button>
                </DrawerHeader>
                <Divider />
                {/* Regiones ,areas y centros */}
                {/* <Swiper
                    direction={'vertical'}
                    slidesPerView={'auto'}
                    freeMode={true}
                    scrollbar={{ draggable: true }}
                    mousewheel={true}
                    modules={[FreeMode, Scrollbar, Mousewheel]}
                    style={{ maxHeight:'74vh',  }} // Limitar la altura del Swiper a 80vh

                >
                    <SwiperSlide style={{overflowY:'hidden', }} > */}
                        <Grid container style={{ overflowY:'auto',maxHeight:'74vh',}} >
                        {showOptions && (
                                <Grid container style={{backgroundColor:'#252525'}}>
                                    <Grid onClick={handleHome} container style={{backgroundColor:'#252525',marginTop:'1vh'}}>
                                            <Grid container justifyContent='center'  >
                                                    {/* boton para selecicionar ciclos */}
                                                    <Grid container justifyContent='center' item xs={11}
                                                        sx={{
                                                            height: '5vh',
                                                            width:'12vw',
                                                            borderRadius:'1vh',
                                                            justifyContent: 'initial',
                                                            px: 1,
                                                            fontSize: "1.5vh", 
                                                            '&:hover': {
                                                                backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                                            },
                                                        }}
                                                    >
                                                        {/* icono de las regiones */}
                                                        <Grid  container justifyContent='center' item xs={2} alignContent='center'>
                                                            <AppsIcon style={{fontSize:styleFont}} />
                                                        </Grid>
                                                        {/* gestionar ciclos */} 
                                                        <Grid  item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                            <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}>  Home</p>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid onClick={handleExit} container justifyContent='center' alignContent='center' style={{  borderBottom: '0.1vh solid #3f3f3f',height: '6vh',backgroundColor:'#252525',}}>
                                            <Grid container justifyContent='center'  >
                                                    {/* boton cerrar sesion */}
                                                    <Grid container justifyContent='center' item xs={11}
                                                        sx={{
                                                            height: '5vh',
                                                            width:'12vw',
                                                            borderRadius:'1vh',
                                                            justifyContent: 'initial',
                                                            px: 1,
                                                            fontSize: "1.5vh", 
                                                            '&:hover': {
                                                                backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                                            },
                                                        }}
                                                    >
                                                        {/* icono de las regiones */}
                                                        <Grid container justifyContent='center' item xs={2} alignContent='center'>
                                                            <LogoutIcon style={{fontSize:styleFont}} />
                                                        </Grid>
                                                        {/* nombre de las regiones */} 
                                                        <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                            <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}>  {translate('cerrar_sesion')}     </p>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {/* Texto para las Regiones y boton para ocultar las regiones */}
                            <Grid container >
                                {/* <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}></p> */}
                                <p style={{ color: 'white', fontSize: styleFont, marginLeft: '0.4vw' }}>
                                    {translate('regions')}
                                    <IconButton
                                        onClick={() => setShowRegions(!showRegions)}
                                        disabled={selectedRegion===null}
                                        style={{ color: 'white' }}
                                    >
                                        {showRegions ? <ExpandLessIcon  style={{fontSize:styleFont}}  /> : <ExpandMoreIcon  style={{fontSize:styleFont}} />}
                                    </IconButton>
                                </p>
                            </Grid>
                            {/* Region seleccionada al ocultar las demas regiones */}
                            {showRegions === false &&
                            <Grid container justifyContent='center'  >
                                {/* boton para selecicionar regiones */}
                                <Grid container justifyContent='center' item xs={11}
                                    sx={{
                                        height: '5vh',
                                        width:'12vw',
                                        borderRadius:'1vh',
                                        justifyContent: 'initial',
                                        px: 1,
                                        fontSize: "1.5vh", // Ajuste del tamaño del texto para centros
                                        backgroundColor:  'rgb(232, 78, 14,0.6)',
                                    }}
                                >
                                    {/* icono de las regiones */}
                                    <Grid container justifyContent='center' item xs={2} alignContent='center' style={{backgroundColor: severityMap[selectedRegion.statusRegions], height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh' }}>
                                        <PublicIcon style={{fontSize:styleFont}} />
                                    </Grid>
                                    {/* nombre de las regiones */} 
                                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                        <p sx={{ opacity: open ? 1 : 0, fontSize: '1.2vh', }}>{selectedRegion && capitalize(selectedRegion.name)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                    
                }
                            {/* mapeo de Regiones */}
                            {showRegions &&
                                consolidado_estados.map((region, index) => (
                                    <Grid container key={index} style={{ marginTop:'1vh'}} >
                                            <Grid container justifyContent='center'  >
                                                    {/* boton para selecicionar regiones */}
                                                    {/* <Badge badgeContent={ <PublicIcon style={{fontSize:styleFont}} />}
                                                     anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                      }}
                                                      sx={{marginLeft:'0vw','& .MuiBadge-badge': { backgroundColor: severityMap[region.statusRegions], height:'3vh', width:'1.5vw', marginTop:'0.8vh', marginRight:'0.1vw', fontSize:'1.8vh' }  }}
                                                      > */}
                                                        <Grid container justifyContent='center'   item xs={12}
                                                            sx={{
                                                                height: '5vh',
                                                                width:'12vw',
                                                                borderRadius:'1vh',
                                                                justifyContent: 'initial',
                                                                px: 1,
                                                                fontSize: "1.5vh", // Ajuste del tamaño del texto para centros
                                                                '&:hover': {
                                                                    backgroundColor: 'rgb(255, 120, 60)', // puedes cambiar el color y grosor del borde a tu gusto
                                                                },
                                                                backgroundColor:  (selectedRegion && selectedRegion.clientId ===  region.clientId) && (selectedRegion && selectedRegion.name ===  region.name) ? 'rgb(232, 78, 14,0.6)' : '#3f3f3f',
                                                            }}
                                                            // onClick={() => handleRegionClick(region)}
                                                            onClick={() => {
                                                                handleRegionClick(region);
                                                                navigate(`/Enviro/${region.name}`);
                                                                localStorage.setItem('REGION', region.name);
                                                            }}
                                                        >
                                                            {/* icono de las regiones */}
                                                            <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[region.statusRegions] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                                                                <PublicIcon style={{fontSize:styleFont}} />
                                                            </Grid>
                                                            {/* nombre de las regiones */} 
                                                            <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                                <p sx={{ opacity: open ? 1 : 0, color: selectedRegion === region && 'white', fontSize: '1.2vh', }}>{capitalize(region.name)}</p>
                                                            </Grid>
                                                        </Grid>
                                                    {/* </Badge> */}
                                            </Grid>
                                    </Grid>
                                ))
                            }
                            {/* Texto para las áreas y boton para ocultar las areas */}
                            <Grid container  style={{overflowY:'hidden', }} >
                                {/* <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}></p> */}
                                <p style={{ color: 'white', fontSize: styleFont, marginLeft: '0.4vw' }}>
                                    {translate('areas')}
                                    <IconButton
                                        onClick={() => setShowAreas(!showAreas)}
                                        disabled={selectedArea===null}
                                        style={{ color: 'white' }}
                                    >
                                        {showAreas ? <ExpandLessIcon  style={{fontSize:styleFont}}  /> : < ExpandMoreIcon  style={{fontSize:styleFont}} />}
                                    </IconButton>
                                </p>
                            </Grid>
                            {/* Area seleccionada al ocultar las demas areas */}
                            {showAreas === false &&
                                <Grid container justifyContent='center'>
                                {/* boton para seleccionar areas */}
                                <Grid container justifyContent='center' item xs={11}
                                    sx={{
                                        height: '5vh',
                                        width:'12vw',
                                        borderRadius:'1vh',
                                        justifyContent: 'initial',
                                        px: 1,
                                        fontSize: "1.5vh",
                                        backgroundColor: 'rgb(255, 120, 60, 0.6)',
                                        marginTop:'0.5vh',
                                    }}
                                >
                                    {/* icono de la areas */}
                                    
                                               
                                    <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[selectedRegion?.areas?.[posicionArea]?.statusArea] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>       
                                        <TravelExploreIcon  style={{fontSize:styleFont}}/>
                                    </Grid>
                                    {/* nombre de la area seleccionada */}
                                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                        <p sx={{ opacity: open ? 1 : 0, fontSize: '1.2vh', }}>{capitalize(selectedArea)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            }
                            {/* mapeo de Areas */}
                            <Grid container   style={{overflowY:'hidden', }}>
                                { showAreas && selectedRegion && selectedRegion.areas.map((area, index) => (
                                    <Grid  key={index} container justifyContent='center' style={{marginTop:'1vh'}}>
                                    {/* boton para seleccionar areas */}
                                    {/* <Badge badgeContent={ <TravelExploreIcon  style={{fontSize:styleFont}}/>} 
                                         anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          sx={{marginLeft:'0vw', '& .MuiBadge-badge': 
                                          { backgroundColor: severityMap[area.statusArea], height:'3vh', width:'1.5vw', 
                                          marginTop:'0.8vh', marginRight:'0.1vw', fontSize:'1.8vh' }  }}> */}
                                        <Grid container justifyContent='center' item xs={12}
                                            sx={{
                                                height: '5vh',
                                                width:'12vw',
                                                borderRadius:'1vh',
                                                justifyContent: 'initial',
                                                px: 1,
                                                fontSize: "1.5vh",
                                                '&:hover': {
                                                    backgroundColor: 'rgb(247, 130, 78)', // puedes cambiar el color y grosor del borde a tu gusto
                                                },
                                                backgroundColor: selectedArea === area.name ? 'rgb(255, 120, 60, 0.6)' : '#3f3f3f',
                                                // marginTop:'1vh',
                                            }}
                                            // onClick={() => handleAreaClick(area)}
                                            onClick={() => {handleAreaClick(area);
                                                localStorage.setItem('AREA', area.name)
                                                navigate(`/Enviro/${selectedRegion.name}/${area.name}`);
                                                setPosicionArea(index)
                                                }}
                                        >
                                        
                                        <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[area.statusArea] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                                            <TravelExploreIcon  style={{fontSize:styleFont}}/>
                                        </Grid>
                                        <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                            <p sx={{ opacity: open ? 1 : 0, color: selectedArea === area.name && 'white', fontSize: '1.2vh',  }}>{capitalize(area.name)}</p>
                                        </Grid>
                                        </Grid>
                                    {/* </Badge> */}
                                {/* <Divider style={{ color: "white", backgroundColor: "#2f2f2f", height: "0.01vh", width:'14.9vw' }} /> */}
                            </Grid>
                                ))}
                            </Grid>
                            {/* Texto para los centros */}
                            <Grid container >
                                <p style={{ color: 'white', fontSize: styleFont, marginLeft: '0.2vw' }}>{translate('centers')}</p>
                            </Grid>
                            <Divider style={{ color: "white", backgroundColor: "#2f2f2f", height: "0.01vh"}} />
                            {/* mapeo de Centros */}
                            <Grid container  style={{overflowY:'hidden', }} >
                            { selectedRegion && selectedArea && selectedRegion.areas.map((area, index) => (
                                selectedArea === area.name && (
                                    area.centers.map((centro, index) => (
                                    <Grid  key={index} container justifyContent='center' style={{marginTop:'1vh'}}>
                                                {/* boton para seleccionar areas */}
                                                {/* <Badge badgeContent={ <TravelExploreIcon  style={{fontSize:styleFont}}/>} 
                                                     anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                      }}
                                                      sx={{marginLeft:'0vw', '& .MuiBadge-badge': 
                                                      { backgroundColor: severityMap[area.statusArea], height:'3vh', width:'1.5vw', 
                                                      marginTop:'0.8vh', marginRight:'0.1vw', fontSize:'1.8vh' }  }}> */}
                                                    <Grid container justifyContent='center' item xs={12}
                                                        sx={{
                                                            height: '5vh',
                                                            width:'12vw',
                                                            borderRadius:'1vh',
                                                            justifyContent: 'initial',
                                                            px: 1,
                                                            fontSize: "1.5vh",
                                                            '&:hover': {
                                                                backgroundColor: 'rgb(247, 130, 78)', // puedes cambiar el color y grosor del borde a tu gusto
                                                            },
                                                            backgroundColor: selectedCentro === centro ? 'rgb(255, 120, 60, 0.6)' : '#3f3f3f',
                                                            // marginTop:'1vh',
                                                        }}
                                                        // onClick={() => handleAreaClick(area)}
                                                        onClick={() => {
                                                            handleCentroClick(area.name, centro.name, centro.statusCenter);
                                                            navigate(`/Enviro/${selectedRegion.name}/${area.name}/${centro.name}`);
                                                            localStorage.setItem('CENTRO',centro.name)
                                                        }}
                                                    >
                                                    
                                                    <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[centro.statusCenter] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                                                        <FmdGoodIcon  style={{fontSize:styleFont}}/>
                                                    </Grid>
                                                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                        <p sx={{ opacity: open ? 1 : 0, color: selectedCentro === centro.name && 'white', fontSize: '1.2vh',  }}>{capitalize(centro.name)}</p>
                                                    </Grid>
                                                    </Grid>
                                                {/* </Badge> */}
                                            {/* <Divider style={{ color: "white", backgroundColor: "#2f2f2f", height: "0.01vh", width:'14.9vw' }} /> */}
                                        </Grid>
                                )))
                            ))}

                            </Grid>
                        </Grid>
                    {/* </SwiperSlide>
                </Swiper> */}
                <DrawerFooter /> {/*pie de pagina */}
            </Drawer>
        </Grid>
    );
}
