
// ? Definición de la URL de la API
const apiUrl = 'http://192.168.20.93/zabbix/api_jsonrpc.php';



// *  -------------------------------  Función: handleLogin  -----------------------------------------------

//? Descripción: 
//? Función asincrónica (async) que maneja la autenticación del usuario mediante una solicitud POST a zabbix.
//? 
//? ¿Como se guardan los datos en string, username, password, etc, detalle
//? Parámetros:
  // - setLoading: Función de estado que actualiza el estado de carga.
  // - setError: Función de estado que actualiza el estado de error.
  // - axios: Objeto de axios utilizado para realizar la solicitud HTTP.
  // - username: Nombre de usuario proporcionado por el usuario.
  // - password: Contraseña proporcionada por el usuario.
export const handleLogin = async ( user, password) => {
    console.log('logeado')
//? Acción:
  // - Establece el estado de carga como true.
  // - Borra cualquier error existente.
  // - Realiza una solicitud POST a la API Zabbix para autenticar al usuario.
  // - Si la autenticación es exitosa, obtiene el token de autenticación y lo almacena en el almacenamiento local.
  // - En caso de error durante la autenticación, imprime un mensaje de error en la consola y actualiza el estado de error.
  // - Independientemente del resultado, establece el estado de carga como falso al finalizar.


  try {
    // Realiza una solicitud POST a la API Zabbix para autenticar al usuario.
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'user.login',
        params: {
          user: user,
          password: password,
        },
        id: 1,
      }),
    });

    // Verifica si la solicitud fue exitosa.
    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    // Parsea la respuesta a JSON.
    const data = await response.json();

    // Obtiene el token de autenticación y lo almacena en el almacenamiento local.
    const token = data.result;
    localStorage.setItem('token', token);
    console.log('Autenticado con éxito. Token:', token);

    // Redirige a la página de inicio
    // window.location.href = "/home";
  } catch (error) {
    // En caso de error durante la autenticación, imprime un mensaje de error en la consola y actualiza el estado de error.
    console.error('Error al autenticar:', error);
  } finally {
  }
};

// *  ------------------------------------------------------------------------------


// *  -----------------------------  Función: handleGetProblems ------------------------------------------------

//? Descripción: 
//? Función asincrónica (async) que realiza una solicitud POST a la API de Zabbix para obtener datos de problemas (problems) no resueltos.
//? Esta función recupera un arreglo de objetos, donde cada objeto representa un problema o evento. 
//? Cada evento está caracterizado por diversas propiedades, siendo algunas de las más destacadas:
//?   - clock: Marca de tiempo del evento en formato de tiempo UNIX.
//?   - severity: Nivel de gravedad del evento.
//?   - tags: Lista de etiquetas asociadas al evento, cada una con un nombre de etiqueta y un valor.
//? 
//? Los datos recuperados se almacenan en el estado mediante la función `setProblems`, permitiendo que sean utilizados en la interfaz de usuario.
//
//? Parámetros:
//?   - setProblems: Función de estado que actualiza el estado de los problemas con los datos recuperados.
//?   - tag (opcional): Entero que define el tipo de problemas a solicitar. 
//?     Valores posibles:
//?       1 - (predeterminado) Problemas recientes.
//?       2 - Historial de problemas.
//?       3 - Problemas actuales.
export const handleGetProblems = async (setProblems, tag = 3) => {
    //? Acción: 
    // - Obtiene el token de autenticación del almacenamiento local.
    // - Define los parámetros de la solicitud en función del valor de `tag`.
    // - Realiza una solicitud POST a la API con los parámetros definidos.
    // - Si la solicitud es exitosa, actualiza el estado de los problemas con los datos obtenidos.
    // - Si hay un error durante la solicitud, imprime un mensaje de error en la consola.
  
    let authToken = localStorage.getItem('token');
    // Obtiene el token de autenticación almacenado localmente.
  
    // Define los parámetros de la solicitud basados en la opción seleccionada.
    let params = {
      output: 'extend', // Devuelve todas las propiedades del objeto.
      selectTags: 'extend',  // Array de objetos [{tag:'Centro', value: 'Cementerio'}, {...}]
      sortfield: ['eventid'],
      sortorder: 'DESC',
    };
  
    // Modifica los parámetros en función del valor de tag.
    switch (tag) {
      case 2: // Historial
        params = {
          ...params,
          recent: 'false', // Obtiene problemas históricos.
        };
        break;
      case 3: // Problemas actuales
        params = {
          ...params,
          recent: 'true', // Obtiene solo problemas actuales.
          acknowledged: 0, // Filtra solo problemas no reconocidos.
        };
        break;
      case 1: // Problemas recientes (predeterminado)
      default:
        params = {
          ...params,
          recent: 'true', // Obtiene problemas recientes.
        };
        break;
    }
  
    try {
      // Realiza una solicitud POST a la API Zabbix para obtener datos de problemas.
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          method: 'problem.get', // Método de Zabbix que permite recuperar problemas de acuerdo con los parámetros dados.
          params: params,
          auth: authToken,
          id: 2,
        }),
      });
  
      // Verifica si la solicitud fue exitosa.
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }
  
      // Parsea la respuesta a JSON.
      const data = await response.json();
  
      // Imprime en la consola los datos de problemas obtenidos.
      console.log('Datos de problemas:', data.result);
  
      // Actualiza el estado de los problemas con los datos obtenidos.
      setProblems(data.result);
    } catch (error) {
      // En caso de error durante la solicitud, imprime un mensaje de error en la consola.
      console.error('Error al obtener datos de problemas:', error);
    }
  };
  
// *  ------------------------------------------------------------------------------


// *  -----------------------------  Función: handleGetResolvedProblems ------------------------------------------------
//? Descripción: 
//? Función asincrónica (async) que realiza una solicitud POST a API zabbix para obtener datos de problems resueltos.
//? Se trata de un arreglo de objetos, donde cada objeto representa un evento. Cada evento está caracterizado por diversas propiedades, siendo algunas de las más destacadas:
  // clock: Marca de tiempo del evento en formato de tiempo UNIX.
  // severity: Nivel de gravedad del evento.
  // tags: Lista de etiquetas asociadas al evento, cada una con un nombre de etiqueta y un valor.
//? Parámetros:
  // - axios      : Objeto de axios utilizado para realizar la solicitud HTTP.
export const handleGetResolvedProblems = async (axios) => {
  //? Acción: 
    // - Obtiene el token de autenticación del almacenamiento local.
    // - Realiza una solicitud POST a la API con una serie de parámetros específicos para obtener datos de problemas resueltos.
    // - Si la solicitud es exitosa, actualiza el estado de los problemas rersueltos con los datos obtenidos.
    // - Si hay un error durante la solicitud, imprime un mensaje de error en la consola.

  let authToken = localStorage.getItem('token');
  // Obtiene el token de autenticación almacenado localmente.

  try {
    // Realiza una solicitud POST a la API Zabbix para obtener eventos resueltos.
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'event.get',
      params: {
        output: 'extend',  // Tipo: query, Propiedades del objeto que se van a devolver.
        select_acknowledges: 'extend',
        selectTags: "extend",
        selectSuppressionData: "extend",
        value: 0,  // 0 para eventos resueltos, 1 para eventos activos
        sortfield: ['clock'],
        sortorder: 'DESC',
        limit: 10,  // Ajusta según tus necesidades
      },
      auth: authToken,
      id: 6,
    });

    // Imprime en la consola la respuesta completa de eventos resueltos.
    console.log('Respuesta completa de eventos resueltos:', response.data);
    // Verifica si se encontraron eventos resueltos en la respuesta.
    if (response.data.result && response.data.result.length > 0) {
      // Imprime en la consola los eventos resueltos encontrados.
      console.log('Eventos resueltos:', response.data.result);
    } else {
      //! Imprime un mensaje si no se encontraron eventos resueltos.
      console.log('No se encontraron eventos resueltos.');
    }
  } catch (error) {
    //! En caso de error durante la solicitud, imprime un mensaje de error en la consola.
    console.error('Error al obtener eventos resueltos:', error.response ? error.response.data : error.message);
  }
};

// *  ------------------------------------------------------------------------------

// ? Dashboard

export const handleGetHostAvailability = async (axios) => {
  let authToken = localStorage.getItem('token');

  try {
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'host.get',
      params: {
        output: ['hostid', 'host', 'status'],
        selectInterfaces: ['interfaceid', 'ip'],
        selectGroups: ['groupid', 'name'],
        selectItems: ['itemid', 'key_', 'lastvalue', 'lastclock'],
      },
      auth: authToken,
      id: 8,
    });

    console.log('Disponibilidad de Hosts:', response.data.result);
  } catch (error) {
    console.error('Error al obtener la disponibilidad de Hosts:', error);
  }
};

// ? Recuperación de eventos por período de tiempo
export const handleGetResolvedProblemsByTime = async (axios, startDate, endDate) => {
  let authToken = localStorage.getItem('token');
  
  try {
    const startTime = startDate ? new Date(startDate).toISOString() : null;
    const endTime = endDate ? new Date(endDate).toISOString() : null;
    console.log("startTime",startTime)
    console.log("endTime",endTime)

    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'event.get',
      params: {
        // output: 'extend',
        // time_from: startTime,
        // time_till: endTime,
        output: "extend",
        time_from: startTime,
        time_till: endTime,
        sortfield: ["eventid"],
        sortorder: "desc"
      },
      auth: authToken,
      id: 7,
    });

    console.log('Respuesta completa de eventos resueltos por tiempo:', response.data);

    if (response.data.result && response.data.result.length > 0) {
      console.log('Eventos resueltos por tiempo:', response.data.result);
    } else {
      console.log('No se encontraron eventos resueltos en el período especificado.');
    }
  } catch (error) {
    console.error('Error al obtener eventos resueltos por tiempo:', error.response ? error.response.data : error.message);
  }
};

// ? Recuperación de equipos 
export const handleGetEquipos = async (axios) => {
  let authToken = localStorage.getItem('token');

  try {
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'host.get',
      params: {
        output: 'extend',
      },
      auth: authToken,
      id: 3,
    });
    console.log('Datos de equipos:', response.data);
  } catch (error) {
    console.error('Error al obtener datos de equipos:', error);
  }
};

export const handleGetSLA = async (axios) => {
  let authToken = localStorage.getItem('token');

  try {
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'sla.get',
      params: {
        output: "extend",
        selectSchedule: ["period_from", "period_to"],
        selectExcludedDowntimes: ["name", "period_from", "period_to"],
        selectServiceTags: ["tag", "operator", "value"],
        preservekeys: true
      },
      auth: authToken,
      id: 4,
    });
    console.log('Datos de SLA:', response.data);
  } catch (error) {
    console.error('Error al obtener datos de SLA:', error);
  }
};

export const handleGetSLAsli = async (axios, selectedDate) => {
  let authToken = localStorage.getItem('token');

  try {
    const formattedDate = Math.floor(new Date(selectedDate).getTime() / 1000); // Convierte la fecha a formato Unix
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'sla.getsli',
      params: {
        output: ['maintenanceid', 'name'],
        selectTimeperiods: 'extend',
        slaid: "1",
        // serviceids: [50, 60, 70],
        // periods: 3,
        period_from: formattedDate.toString(),
      },
      auth: authToken,
      id: 4,
    });

    console.log('Datos de SLA:', response.data);
  } catch (error) {
    console.error('Error al obtener datos de SLA:', error.response ? error.response.data : error.message);
  }
};

// ? Reportes
export const handleGetReport = async (axios) => {
  let authToken = localStorage.getItem('token');
 
  try {
    // Reemplaza 'nombre_del_informe' con el nombre del informe que deseas obtener
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'report.get',
      params: {
        output: "extend",
        selectUsers: "extend",
        selectUserGroups: "extend",
        reportids: ["1", "2"]
        // Otros parámetros específicos del informe, si es necesario
      },
      auth: authToken,
      id: 1, // Utiliza un ID único para la solicitud de informe
    });

    const reportData = response.data.result;

  if (reportData && reportData.length > 0) {
    console.log('Datos del informe:', reportData);
    // Aquí puedes procesar los datos del informe y mostrarlos en la interfaz de usuario
  } else {
    console.log('El informe está vacío o no se pudo obtener.');
  }
} catch (error) {
  console.error('Error al obtener informe:', error.message);
}
};


export const handleCreateReport = async (axios, startDate, endDate) => {
  let authToken = localStorage.getItem('token');

  try {
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'report.create',
      params: {
        name: 'NuevoInforme', // Reemplaza con el nombre deseado para el informe
        type: 0, // Tipo de informe (puede variar según tus necesidades)
        source: {
          // Detalles específicos del informe, por ejemplo:
          // table: 'items',
          // columns: ['itemid', 'name', 'lastvalue'],
          // filter: { hostid: '12345' },
        },
        period: {
          // Período de tiempo del informe
          from: startDate, // Reemplaza con tu lógica para obtener la fecha de inicio
          to: endDate, // Reemplaza con tu lógica para obtener la fecha de fin
        },
      },
      auth: authToken,
      id: 1, // Utiliza un ID único para la solicitud de creación del informe
    });

    const reportId = response.data.result.reportid;
    console.log('Informe creado con éxito. ID del informe:', reportId);

    // Puedes almacenar el ID del informe creado y usarlo según tus necesidades
  } catch (error) {
    console.error('Error al crear informe:', error.message);
  }
};


//? datos historcos

export const handleGetHistoricalProblems = async (axios, ) => {
  let authToken = localStorage.getItem('token');

  try {
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'history.get',
      params: {
        output: 'extend',
        history: 0, // 0: Problemas
        // Agrega otros parámetros según tus necesidades
        // itemids: [12345], // ID del elemento si es necesario
        // time_from: startTime,
        // time_till: endTime,
        sortfield: ['clock'],
        sortorder: 'DESC',
        limit: 50,  // Ajusta según tus necesidades
      },
      auth: authToken,
      id: 9,
    });
    console.log('Datos históricos de problemas:', response.data.result);
  } catch (error) {
    console.error('Error al obtener datos históricos de problemas:', error);
  }
};


//? Platntillas
export const handleGetTemplates = async (axios, ) => {
  let authToken = localStorage.getItem('token');

  try {
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'template.get',
      params: {
        output: ['templateid', 'host'],
        selectGroups: ['groupid', 'name'],
        selectHosts: ['hostid', 'host'],
        selectItems: ['itemid', 'key_', 'lastvalue', 'lastclock'],
      },
      auth: authToken,
      id: 8,
    });

    console.log('Información de Plantillas:', response.data.result);
  } catch (error) {
    console.error('Error al obtener información de Plantillas:', error);
  }
};

// ? SLA REPORT
export const handleGetSLAReport = async (axios,  setLoading, setError, selectedDate) => {
  let authToken = localStorage.getItem('token');
  
  setLoading(true);
  setError(null);

  try {
    if (!authToken) {
      throw new Error('Token de autenticación no disponible. Asegúrate de iniciar sesión.');
    }

    if (!selectedDate) {
      throw new Error('Selecciona una fecha para obtener el SLA Report.');
    }

    const formattedDate = Math.floor(new Date(selectedDate).getTime() / 1000);

    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'sla.get',
      params: {
        output: 'extend',
        selectUptime: 'extend',
        selectDowntime: 'extend',
        selectTimeperiods: 'extend',
        selectServiceTags: 'extend',
        excludeMaintenance: true,
        sortfield: ['period_from'],
        sortorder: 'DESC',
        time_from: formattedDate,
        time_till: formattedDate + 86400, // Agregando un día completo (86400 segundos)
      },
      auth: authToken,
      id: 10,
    });

    if (response.data.result) {
      const slaReportData = response.data.result;
      console.log('Datos del SLA Report:', slaReportData);
      // Procesar y mostrar datos en la interfaz de usuario
    } else {
      throw new Error('La respuesta de la API no contiene la propiedad result.');
    }
  } catch (error) {
    console.error('Error al obtener datos del SLA Report:', error.message);
    setError('Error al obtener datos del SLA Report.');
  } finally {
    setLoading(false);
  }
};

// ? Función para obtener los IDs de los disparadores
export const handleGetTriggerIds = async (axios) => {
  let authToken = localStorage.getItem('token');

  try {
    const response = await axios.post(apiUrl, {
      jsonrpc: '2.0',
      method: 'trigger.get',
      params: {
        output: ['triggerid'],
        // Aquí puedes agregar los parámetros específicos según tus necesidades
      },
      auth: authToken,
      id: 5,  // Puedes usar un ID diferente si es necesario
    });
    console.log('TriggerIds:', response.data.result.map(trigger => trigger.triggerid));
  } catch (error) {
    console.error('Error al obtener TriggerIds:', error);
  }
};

