import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Checkbox, FormGroup, FormControlLabel, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import 'chartjs-adapter-moment';
import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
// import { AntSwitch } from './utils/funciones_grafico'
import { format } from 'date-fns';

ChartJS.register(zoomPlugin);
ChartJS.register(...registerables);


const AmbientalChart = (props) => {
    const { data1, data2, data3, data4, width, height, title, titleSize, axisFontSize, legendFontSize,
        showOxigeno, showSaturacion, showSalinidad, showTemperatura,
        toggleOxigeno, toggleSaturacion, toggleSalinidad, toggleTemperatura, pauseRequest, setPauseRequest,
        profundidadesPerPage, currentView, translate} = props

    const canvasRef = useRef(null);
    const chartRef = useRef(null);
    const [lineType, setLineType] = useState('line');   // 'area' || 'line'

    const [data1Len, setData1Len] = React.useState(data1 ? data1.length : 0);
    const o2 = { colorData: "#0269be", label: translate('oxigeno'), data1 };
    const so2 = { colorData: "#02a26c", label:  translate('saturacion'), data2 };
    const sal = { colorData: "#a97716", label:  translate('salinidad'), data3 };
    const temp = { colorData: "#cc1106", label:  translate('temperatura'), data4 };
    // const linea = { colorData: lineType !== 'area' ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.2)', label: 'Línea' }
    // const area = { colorData: lineType === 'area' ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.2)', label: 'Área' }

    /* Estilo botones Línea/Área */
    const { buttWidth, buttHeight, buttMTop, buttMLeft, buttFontSz, buttTextMTop } = calculateToogleButtonStyles(profundidadesPerPage, currentView);

    const styleSwitch = {
        fontSize: buttFontSz,
        height: buttHeight,
        width: buttWidth,
    }

    // ? Función para ocultar la leyenda
    const handleLegendClick = (datasetIndex) => {
        if (datasetIndex < 4) {
            const toggleFunction = [
                toggleOxigeno,
                toggleSaturacion,
                toggleSalinidad,
                toggleTemperatura,
            ][datasetIndex];

            // Call the corresponding toggle function
            toggleFunction && toggleFunction();

            const chart = chartRef.current;
            if (chart) {
                chart.data.datasets[datasetIndex].hidden = ![
                    showOxigeno,
                    showSaturacion,
                    showSalinidad,
                    showTemperatura,
                ][datasetIndex];

                chart.update();
            }
        }
        else { // Línea / Área
            setLineType(datasetIndex === 4 ? 'line' : 'area')
        }
    };

    // ? Función para cambiar el tipo de línea
    /* const handleButtonClick = () => {
        // Cambiar el estado entre 'primary' y 'secondary'
        setLineType((prevLineType) => (prevLineType === 'area' ? 'line' : 'area'));
    }; */

    const handleAlignment = (event, newAlignment) => {
        // Ignora el cambio si newAlignment es null para prevenir que el ToggleButton se des-seleccione completamente
        if (newAlignment !== null) {
            setLineType(newAlignment);
        }
    };

    // Esta función se encarga de actualizar los datos del gráfico
    const updateChartData = (chart, newData, datasetIndex) => {

        // ! Original - deprecado
        chart.data.datasets[datasetIndex].data = newData.map(d => ({ x: d.time, y: d.value }));
        chart.update();
    };

    // Función para actualizar la configuración del eje Y
    const updateYAxisConfig = (chart, axisId, newLimits) => {
        if (chart.options.scales[axisId]) {
            chart.options.scales[axisId].min = newLimits.min;
            chart.options.scales[axisId].max = newLimits.max;
        }
    };

    // Funcionalidad del botón home
    const handleHomeClick = () => {
        const chart = chartRef.current;
        if (chart) {
            chart.resetZoom();
            if (pauseRequest) { setPauseRequest(false) }
        }
    };

    /* // -- Creación del gráfico al montar componente -- // */
    useEffect(() => {

        if (!chartRef.current && canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');

            // Calcular los nuevos límites para cada eje
            const minMaxOxigeno = getMinMax(data1, 0.3, 0);
            const minMaxSaturacion = getMinMax(data2, 0.3, 5);
            const minMaxSalinidad = getMinMax(data3, 0.2, 0);
            const minMaxTemperatura = getMinMax(data4, 0.2, 0.5);

            // Configuración inicial del gráfico

            const chartConfig = createChartConfig(data1, data2, data3, data4,translate,
                minMaxOxigeno, minMaxSaturacion, minMaxSalinidad, minMaxTemperatura,
                title, titleSize, axisFontSize, legendFontSize, lineType);

            // Asignar el yAxisID a cada dataset
            const yaxis_labels = ['yOxigeno', 'ySaturacion', 'ySalinidad', 'yTemperatura']
            chartConfig.data.datasets.forEach((dataset, index) => {
                dataset.yAxisID = yaxis_labels[index] //`y${dataset.label}`;
            });


            // Crear el gráfico
            chartRef.current = new ChartJS(ctx, chartConfig);

            // Asegúrate de asignar el yAxisID correspondiente a cada dataset
            chartConfig.data.datasets[0].yAxisID = 'yOxigeno';
            chartConfig.data.datasets[1].yAxisID = 'ySaturacion';
            chartConfig.data.datasets[2].yAxisID = 'ySalinidad';
            chartConfig.data.datasets[3].yAxisID = 'yTemperatura';

        }

        return () => {
            // Vacío, no es necesario hacer nada cuando los datos cambian
        };
    }, []);


    // Actualizar visibilidad de curvas
    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            chart.data.datasets.forEach((dataset, index) => {
                dataset.hidden = ![
                    showOxigeno,
                    showSaturacion,
                    showSalinidad,
                    showTemperatura,
                ][index];
            });

            chart.update();
        }
    }, [showOxigeno, showSaturacion, showSalinidad, showTemperatura]);

    // Actualizar tipo de gráfico - línea/área
    useEffect(() => {
        // Esta función se ejecutará cada vez que lineType cambie
        if (chartRef.current) {
            // Actualizar el tipo de línea en cada dataset del gráfico
            chartRef.current.data.datasets.forEach((dataset) => {
                dataset.fill = lineType === 'area';
            });
            chartRef.current.update();
        }
    }, [lineType]);


    // Manejo de la actualización de los datos del gráfico
    useEffect(() => {
        if (chartRef.current && data1Len !== data1.length) {
            setData1Len(data1.length)
            // console.log('gráfico data1 actualizado a largo:', data1.length)
            // Actualizar los datos del gráfico
            updateChartData(chartRef.current, data1, 0);
            updateChartData(chartRef.current, data2, 1);
            updateChartData(chartRef.current, data3, 2);
            updateChartData(chartRef.current, data4, 3);

            // Calcular los nuevos límites para cada eje
            const newMinMaxOxigeno = getMinMax(data1, 0.3, 0);
            const newMinMaxSaturacion = getMinMax(data2, 0.3, 5);
            const newMinMaxSalinidad = getMinMax(data3, 0.2, 0);
            const newMinMaxTemperatura = getMinMax(data4, 0.2, 0.5);

            // Actualizar la configuración de los ejes Y
            updateYAxisConfig(chartRef.current, 'yOxigeno', newMinMaxOxigeno);
            updateYAxisConfig(chartRef.current, 'ySaturacion', newMinMaxSaturacion);
            updateYAxisConfig(chartRef.current, 'ySalinidad', newMinMaxSalinidad);
            updateYAxisConfig(chartRef.current, 'yTemperatura', newMinMaxTemperatura);

            // Actualizar el gráfico para reflejar los cambios
            chartRef.current.update();
        }
    }, [props]);




    return (
        <Grid style={{ cursor: 'default !important', userSelect: 'none', }} >
            <Box display='flex' >

                <Grid container justifyContent="center" >
                    {/* Otros botones e interacciones pueden ir aquí */}
                    <Grid container justifyContent="center" style={{ width, height }}>
                        <canvas ref={canvasRef} />
                    </Grid>
                </Grid>

            </Box>
            <Grid container justifyContent="center" style={{ cursor: 'pointer', marginTop: "0vh" }}>

                {/* Leyenda */}
                <Grid container justifyContent="center" item xs={12}>

                    {/* Botón Home */}
                    <IconButton onClick={handleHomeClick} style={{ marginRight: "1vw", marginTop: '-0.2vh' }}>
                        <HomeIcon style={{ fontSize: legendFontSize }} />
                    </IconButton>

                    {/* Leyenda Personalizada */}
                    {[o2, so2, sal, temp,/*  linea, area */].map((data, index) => (
                        <FormGroup key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // key={index}
                                        // checked={selectedLegends[index]}
                                        checked={
                                            index === 0 ? showOxigeno :
                                                index === 1 ? showSaturacion :
                                                    index === 2 ? showSalinidad :
                                                        index === 3 ? showTemperatura :
                                                            index === 4 ? lineType === 'line' : lineType === 'area'
                                        }
                                        onChange={() => handleLegendClick(index)}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: legendFontSize } }}
                                        style={{
                                            width: `${100 / 4}%`,
                                            padding: '5px',
                                            color: `${data.colorData}`,
                                            fontSize: "1vh",
                                            textDecoration: index === 0 ? (showOxigeno ? 'underline' : 'none') :
                                                index === 1 ? (showSaturacion ? 'underline' : 'none') :
                                                    index === 2 ? (showSalinidad ? 'underline' : 'none') : (showTemperatura ? 'underline' : 'none'),
                                            marginLeft: index === 4 ? '3vw' : null
                                        }}
                                    //     textDecoration: selectedLegends[index] ? 'underline' : 'none',
                                    // }}
                                    />
                                }
                                label={data.label}
                                style={{
                                    color: `${data.colorData}`,
                                    // fontSize:legendFontSize,
                                    // color:'#5d5d5d'
                                }}
                                sx={{ '& .MuiTypography-root': { fontSize: legendFontSize } }}
                            />
                        </FormGroup>
                    ))}

                    {/* Botones Línea/Área */}
                    <Grid marginTop={buttMTop} height={buttHeight} marginLeft={buttMLeft} >
                        <ToggleButtonGroup
                            size="small"
                            value={lineType}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="line" aria-label="left aligned"  >
                                <Typography style={styleSwitch} color={lineType !== 'area' ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.2)'} marginTop={buttTextMTop} >{translate('linea')}</Typography>
                            </ToggleButton>
                            <ToggleButton value="area" aria-label="centered">
                                <Typography style={styleSwitch} color={lineType === 'area' ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.2)'} marginTop={buttTextMTop} >{translate('area_')}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                </Grid>


            </Grid>
        </Grid >
    );
};

export default AmbientalChart;






const chartStyles = {
    oxigeno: {
        borderColor: 'rgb(0, 121, 221, 0.85)',
        backgroundColor: 'rgba(0, 121, 221, 0.25)',
    },
    saturacion: {
        borderColor: 'rgba(0, 188, 124, 0.85)',      // 'rgba(0, 188, 124, 0.56)',
        backgroundColor: 'rgba(0, 188, 124, 0.25)',
    },
    salinidad: {
        borderColor: 'rgba(254, 176, 25, 0.70)',     // 'rgba(254, 176, 25, 0.56)',
        backgroundColor: 'rgba(254, 176, 25, 0.25)',
    },
    temperatura: {
        borderColor: 'rgba(255, 18, 3, 0.85)',      // 'rgb(255, 18, 3, 0.56)',
        backgroundColor: 'rgba(255, 18, 3, 0.25)',    // 'rgba(255, 18, 3, 0.25)',
    },
};


// ? Ajuste de estilos de botones línea / área

// Función para ajustar margen y width
const calculateToogleButtonStyles = (profundidadesPerPage, currentView) => {
    // currentView,         // Tipo: string -> [x1, x2, x4] - estaciones
    // profundidadesPerPage,// Tipo: number -> [1, 2, 3, 4] - profundidades
    switch (currentView) {
        case "x1":
            switch (profundidadesPerPage) {
                case 1:
                    return { buttWidth: '5vw', buttHeight:'2.2vh',   buttMTop: '-3.05vh', buttMLeft:'7.0vw', buttFontSz:'1.7vh', buttTextMTop:'-0.4vh' };   // Vista 1x1
                case 2:
                    return { buttWidth: '4vw', buttHeight:'1.8vh',  buttMTop: '-3.4vh', buttMLeft:'5.0vw',  buttFontSz:'1.5vh', buttTextMTop:'-0.3vh' };    // Vista 1x2
                case 3:
                    return { buttWidth: '4.5vw', buttHeight:'1.1vh',  buttMTop: '-4.0vh', buttMLeft:'7.0vw', buttFontSz:'1.1vh', buttTextMTop:'-0.25vh' };    // Vista 1x3
                default:
                    return { buttWidth: '0vw', buttHeight:'0vh',     buttMTop: '0vh', buttMLeft:'2.0vw', buttFontSz:'1vh', buttTextMTop:'0.0vh' };       // Valor por defecto
            }
        case "x2":
            switch (profundidadesPerPage) {
                case 1:
                    return { buttWidth: '3.8vw', buttHeight:'1.725vh',   buttMTop: '-3.5vh', buttMLeft:'2.0vw', buttFontSz:'1.5vh', buttTextMTop:'-0.45vh' };    // Vista 2x1
                case 2:
                    return { buttWidth: '3.3vw', buttHeight:'1.35vh', buttMTop: '-3.85vh', buttMLeft:'1.8vw', buttFontSz:'1.3vh', buttTextMTop:'-0.275vh' };  // Vista 2x2
                case 3:
                    return { buttWidth: '3vw', buttHeight:'1.1vh',  buttMTop: '-4.0vh', buttMLeft:'2.0vw', buttFontSz:'1.05vh', buttTextMTop:'-0.35vh' };    // Vista 2x3
                default:
                    return { buttWidth: '0vw', buttHeight:'1vh',     buttMTop: '0vh', buttMLeft:'2.0vw', buttFontSz:'1vh', buttTextMTop:'0.0vh' };       // Valor por defecto
            }
        case "x4":
            switch (profundidadesPerPage) {
                case 1:
                    return { buttWidth: '3vw', buttHeight:'1.2vh',  buttMTop: '-4.1vh', buttMLeft:'1.8vw', buttFontSz:'1.1vh', buttTextMTop:'-0.25vh' };    // Vista 4x1
                case 2:
                    return { buttWidth: '1.9vw', buttHeight:'0.5vh', buttMTop: '-4.35vh', buttMLeft:'1.5vw', buttFontSz:'0.75vh', buttTextMTop:'-0.35vh' };  // Vista 4x2
                default:
                    return { buttWidth: '0vw', buttHeight:'1vh',     buttMTop: '0vh', buttMLeft:'2.0vw', buttFontSz:'1vh', buttTextMTop:'0.0vh' };       // Valor por defecto
            }
        default:
            return { buttWidth: '0vw', buttHeight:'0vh', buttMTop: '0vh', buttMLeft:'2.0vw', buttFontSz:'1vh', buttTextMTop:'0.0vh' };               // Valor por defecto
    }
}




// ? Función para crear configuración del gráfico
function createChartConfig(
    data1, data2, data3, data4,translate,
    minMaxOxigeno, minMaxSaturacion, minMaxSalinidad, minMaxTemperatura,
    title, titleSize = '30vh', AxisFontSize, legendFontSize = 14, lineType = 'area') {
    // lineType: 'area' || 'line'
    return {
        type: 'line',
        data: {
            datasets: [
                createDatasetConfig(translate('oxigeno'), data1, chartStyles.oxigeno, lineType),
                createDatasetConfig(translate('saturacion'), data2, chartStyles.saturacion, lineType),
                createDatasetConfig(translate('salinidad'), data3, chartStyles.salinidad, lineType),
                createDatasetConfig(translate('temperatura'), data4, chartStyles.temperatura, lineType),
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false, /* Gráfico rectangular en lugar de cuadrado */
            interaction: {
                intersect: false,       /* Permite tooltip móvil */
                mode: 'index',          /* Permite tooltip móvil */
            },
            scales: {   /* x and y axis config */
                x: {
                    type: 'time',
                    time: {
                        parser: 'DD-MM-YYYY HH:mm:ss',   // Formato de la fecha proporcionada por tu API
                    },
                    ticks: {
                        maxTicksLimit: 8,  // Fuerza a que siempre se muestren 8 ticks
                        font: {
                            size: AxisFontSize
                        },
                        callback: function (val, index) {
                            if (this.getLabelForValue(val)) {
                                const currentDate = new Date(this.getLabelForValue(val));
                                // return format(currentDate, 'dd MMM, \nHH:mm');
                                // Dividir la fecha en dos líneas
                                return [format(currentDate, 'HH:mm'), format(currentDate, 'dd MMM')];
                            }
                        }
                    },
                    // title: { display: true, text: 'Fecha' }  // Formato de título del eje
                },
                ySaturacion: createYAxisConfig('ySaturacion', 'left', translate('saturacion'), chartStyles.saturacion, minMaxSaturacion, AxisFontSize, true),
                yOxigeno: createYAxisConfig('yOxigeno', 'left', translate('oxigeno'), chartStyles.oxigeno, minMaxOxigeno, AxisFontSize),
                ySalinidad: createYAxisConfig('ySalinidad', 'right', translate('salinidad'), chartStyles.salinidad, minMaxSalinidad, AxisFontSize),
                yTemperatura: createYAxisConfig('yTemperatura', 'right', translate('temperatura'), chartStyles.temperatura, minMaxTemperatura, AxisFontSize),
            },
            plugins: {
                legend: {
                    display: false, //ocultar leyendas
                    position: 'bottom',
                    labels: {
                        font: {
                            size: legendFontSize,
                        }
                    }
                },
                title: {
                    display: false,
                    text: title,
                    color: 'rgba(255,255,255,0.8)',
                    font: {
                        size: titleSize,
                    }
                },
                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        labelPointStyle: function (context) {
                            return {
                                pointRadius: '1vh',
                                pointStyle: 'rectRounded', // 'circle', 'cross', 'crossRot', 'dash', 'line', 'rect', 'rectRot', 'star', 'triangle',
                                // rotation: 0
                            };
                        },
                        label: function (context) {
                            if (context.raw && context.raw.y !== undefined) {
                                return ` ${context.dataset.label}: ${context.raw.y}`;
                            }
                            return null;
                        }

                    },
                    bodyFont: {
                        size: AxisFontSize, // Tamaño del texto del cuerpo del tooltip
                    },
                    titleFont: {
                        size: AxisFontSize, // Tamaño del texto del título del tooltip
                    },
                },
                zoom: {
                    pan: {
                        enabled: true,          // Habilita el desplazamiento
                        mode: 'x',              // Desplaza en el eje x
                        threshold: 10,          // Umbral mínimo de píxeles para empezar a desplazar
                        modifierKey: 'ctrl',    // El desplazamiento solo estará activo mientras se presione esta tecla
                    },
                    zoom: {
                        wheel: {
                            enabled: true, // Deshabilita el zoom con la rueda del ratón
                        },
                        pinch: {
                            enabled: false, // Deshabilita el zoom con gesto de pellizco
                        },
                        drag: {
                            enabled: true, // Habilita el zoom al arrastrar
                            threshold: 5,  // Número mínimo de píxeles que el usuario debe arrastrar para iniciar el zoom
                            speed: 0.1,    // Velocidad de zoom
                        },
                        mode: 'x', // Permite el zoom en el eje x
                    }
                }
            },

            //   hover: {
            // mode: 'nearest',
            // intersect: true
            //   },
            // ... otras opciones que puedan ser necesarias
        }
    }
}


// ? Función para crear una configuración común de los ejes Y
function createYAxisConfig(id, position, titleText, style, minMax, AxisFontSize, gridOn = false) {
    return {
        type: 'linear',
        display: true,
        position: position,
        id: id,
        grid: {
            // drawOnChartArea: position === 'left' ? true : false, // Solo cuadrícula para el eje izquierdo
            drawOnChartArea: gridOn, // Habilita la cuadrícula para este eje
            color: 'rgba(255, 255, 255, 0.08)', // Color de las líneas de la cuadrícula, ajusta según necesidad
            drawBorder: true, // Dibuja el borde del eje
            drawTicks: true, // Dibuja los ticks del eje
            tickLength: 0, // Longitud de los ticks del eje
        },
        title: {
            display: true,
            text: titleText,
            color: style.borderColor,
            font: {
                size: AxisFontSize
            }
        },
        min: minMax.min,
        max: minMax.max,
        ticks: {
            color: style.borderColor,
            font: {
                size: AxisFontSize
                // size: AxisFontSize - 2
                // size: "10vh"
            }
        },
    };
}

// ? Función para crear la configuración de los datasets
function createDatasetConfig(label, data, style, lineType, lineWidth = 0.75) {
    return {
        label: label,
        data: data.map(d => ({ x: d.time, y: d.value })),
        ...style,
        fill: lineType === 'area' ? true : false,
        tension: 0.2,
        pointRadius: 0,
        borderWidth: lineWidth, // Ajusta grosor de línea, Default: 3
    };
}

// ? Función para obtener el rango mínimo y máximo de los ejes
const getMinMax = (data, range, offset) => {
    if (Array.isArray(data) && data.length > 0) {
        const values = data.map(d => d.value);
        const minVal = Math.min(...values);
        const maxVal = Math.max(...values);
        return {
            min: minVal - (minVal * range) - offset,
            max: maxVal + (minVal * range) - offset
        }
    }
    else {
        return {
            min: 0,
            max: 10
        }
    }
};


// Función para ajustar el tamaño de la fuente proporcionalmente al tamaño de fuente de leyendas
/* const adjustFontSize = (fontSizeString, adjustmentFactor) => {
    // Extraer el valor numérico del tamaño de la fuente (por ejemplo, '1' de '1vh')
    const numericValue = parseFloat(fontSizeString);
    // Extraer la unidad del tamaño de la fuente (por ejemplo, 'vh' de '1vh')
    const unit = fontSizeString.replace(numericValue.toString(), '');
    // Ajustar el valor numérico y formar la nueva cadena de tamaño de la fuente
    const buttFontSz = (numericValue * adjustmentFactor) + unit;
    return buttFontSz;
}; */