import React, {useState} from 'react'
import { Grid } from '@mui/material'
import Oxigeno from '../Sensores/Oxigeno'
import Salinidad from '../Sensores/Salinidad'
import Saturacion from '../Sensores/Saturacion'
import { Temperatura } from '../Sensores/Temperatura'
// import Chart from '../Grafico/grafico'
import AmbientalChart from '../../Grafico/AmbientalGraph'
import Alertas from '../alertas'

const Prof_3_x2 = ({ alerta,translate, profundidad, showOxigeno, showSaturacion, showSalinidad, showTemperatura, toggleOxigeno, toggleSaturacion, toggleSalinidad, toggleTemperatura, title, pauseRequest, setPauseRequest, profundidadesPerPage, currentView  }) => {

    const commonProps = {
        fullwidth:'7vw',
        fullHeight:'5.5vh',
        // ajuste de la card de oxígeno
        width: '3.7vw',
        height: '2.2vw',
        //Tamaño de icono
        heightIMG: '3vh',
        widthIMG: '1.5vw',
        sinText: true,
        // ajuste de la posición del valor de oxígeno
        fontSizeH1: '2vh',
        fontSizeTypo1: '1.3vh',
        //tamaño card del valor de oxigeno
        width1: '3.7vw',
        height1: '2.2vw',
        // separación de la card de oxígeno y su valor
        marginLeft1: '-0.5vw',
    };

    const graphProps = {
        width       : "59vw",
        height      : "19vh",
        profundidad : profundidad,
        data1       : profundidad.O2_val? profundidad.O2_val : null,
        data2       : profundidad.DO2_val? profundidad.DO2_val : null,
        data3       : profundidad.sal_val? profundidad.sal_val : null,
        data4       : profundidad.temp_val? profundidad.temp_val : null,
        title       : title,
        // titleSize   : '40vw',
        // axisFontSize        : 14,
        axisFontSize     : window.innerHeight*0.010 , //"20vh",
        legendFontSize      : "1.4vh",
        showOxigeno           : showOxigeno,
        showSaturacion        : showSaturacion,
        showSalinidad         : showSalinidad,
        showTemperatura       : showTemperatura,
        toggleOxigeno         : toggleOxigeno,
        toggleSaturacion      : toggleSaturacion,
        toggleSalinidad       : toggleSalinidad,
        toggleTemperatura     : toggleTemperatura,
        // Activar Solicitudes de datos
        pauseRequest          : pauseRequest, 
        setPauseRequest       : setPauseRequest,
        profundidadesPerPage  : profundidadesPerPage,
        currentView           : currentView,
        translate             : translate,
     }

    const styleHeight = '1vh'
    const styleLeft = '1vw'
    const initialAlerts = alerta?.alertasActivas || [];
    const [activeAlerts, setActiveAlerts] = useState(initialAlerts);

    return (
        <Grid container height={'26vh'} style={{marginTop:'1vh'}}>
            <Grid container justifyContent="flex-start" alignContent={'center'} item xs={12}>
                <h1 style={{fontSize:"2vh", height:'2vh', marginLeft:'0.5vw'}}>
                    {profundidad.profundidad ? `${profundidad.profundidad}m` : ''}
                </h1>
            </Grid>
            <Alertas activeAlerts={activeAlerts} setActiveAlerts={setActiveAlerts} translate={translate}/>
            {/* Grafico */}
            <Grid container justifyContent="center" item xs={10} height="10vh" style={{marginTop: "2vh", paddingLeft: "0.5vw" }}>
                <Grid item xs={12} >
                    <AmbientalChart
                        {...graphProps}
                    />
                </Grid>
            </Grid>

            {/* Sensores */}
            <Grid container item xs={2} height={'23vh'} style={{ marginTop: "-2.5vh",  }}>
                {/* Cards Oxigeno */}
                <Grid item xs={12} marginLeft={styleLeft} height={styleHeight}>
                    <Oxigeno
                        showOxigeno={showOxigeno}
                        toggleOxigeno={toggleOxigeno}
                        {...commonProps}
                        marginTopIMG= "0.5vh"
                        medicion={profundidad.O2_val[profundidad.O2_val.length - 1] && profundidad.O2_val[profundidad.O2_val.length - 1].value ? profundidad.O2_val[profundidad.O2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Saturacion */}
                <Grid item xs={12} marginLeft={styleLeft} height={styleHeight} >
                    <Saturacion
                        showSaturacion={showSaturacion}
                        toggleSaturacion={toggleSaturacion}
                        {...commonProps}
                        marginTopIMG= "-0.2vh"
                        medicion={profundidad.DO2_val[profundidad.DO2_val.length - 1] && profundidad.DO2_val[profundidad.DO2_val.length - 1].value ? profundidad.DO2_val[profundidad.DO2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Salinidad */}
                <Grid item xs={12} marginLeft={styleLeft} height={styleHeight}>
                    <Salinidad
                        showSalinidad={showSalinidad}
                        toggleSalinidad={toggleSalinidad}
                        {...commonProps}
                        marginTopIMG= "-0.2vh"
                        medicion={profundidad.sal_val[profundidad.sal_val.length - 1] && profundidad.sal_val[profundidad.sal_val.length - 1].value ? profundidad.sal_val[profundidad.sal_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Temperatura */}
                <Grid item xs={12} marginLeft={styleLeft} height={styleHeight}>
                    <Temperatura
                        showTemperatura={showTemperatura}
                        toggleTemperatura={toggleTemperatura}
                        {...commonProps}
                        marginTopIMG= "1vh"
                        medicion={profundidad.temp_val[profundidad.temp_val.length - 1] && profundidad.temp_val[profundidad.temp_val.length - 1].value ? profundidad.temp_val[profundidad.temp_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Prof_3_x2