import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Alert,
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import './utils/css/Registro.css';
import UserForm from '../components/Registro/UserForm';
import Acceso from '../components/Registro/Acceso';
import Verificacion from '../components/Registro/Verificacion';
import { ObtenerUsuarios, CrearUsuario, EditarUsuario } from '../utils/apiServer';
import Logo from '../assets/img/ITG_BLANCO.png';
import Traductor from '../components/Traductor';
import '../assets/css/Registro.css'

const initialUser = {
  username: '',
  correo: '',
  cargo: '',
  password: '',
  permisos: {},
};

const Registro = ({cargo, translate ,handleClick ,openMenuIdioma ,handleCloseIdioma ,handleLanguageChange}) => {
  const [user, setUser] = useState(initialUser);
  const [activeStep, setActiveStep] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
  const [editar, setEditar] = useState(false); // Variable de estado para indicar si se está editando


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setUser(user); // Esto podría necesitar ser ajustado según la estructura de tus datos
  };

  const Exit = () => {
    window.location.href = '/home';
  };

  const actualizarListaUsuarios = async () => {
    try {
        const usuariosObtenidos = await ObtenerUsuarios();
        const usuariosOrdenados = usuariosObtenidos.sort((a, b) =>
            a.username.localeCompare(b.username)
        );
        // const usuariosTransformados = usuariosOrdenados.map(usuario => ({
        //     ...usuario,
        //     permisos: transformarPermisos(usuario)
        // }));
        // console.log('usuariosTransformados', usuariosTransformados)

        setUsuarios(usuariosOrdenados);
    } catch (error) {
        console.error('Error al obtener usuarios:', error);
    }
};
  
  useEffect(() => {
    // Llamar a la función para obtener y mostrar la lista de usuarios al cargar la página
    actualizarListaUsuarios();
  }, []);

  const confiramcionUser = async (user) => {

    // console.log('confiramcionUser',user)
    try {
      // const clientName = localStorage.getItem('username');
      const clientName = localStorage.getItem('username');
      if (!clientName) {
        throw new Error('No se pudo obtener el nombre de usuario del localStorage.');
      }
      await CrearUsuario(user, clientName, setAlertMessage, setAlertSeverity, setUser, initialUser, setActiveStep, translate);
      // Después de crear el usuario, actualiza la lista de usuarios
      await actualizarListaUsuarios();
    } catch (error) {
      console.error('Error al crear usuario:', error);
    }
  };
  
  // Función de confirmación para editar usuario
const confiramcionUserEdit = async (user) => {
  // //console.log('user', user)
  try {
      // const clientName = localStorage.getItem('username');
      const clientName = localStorage.getItem('username');
      if (!clientName) {
        throw new Error('No se pudo obtener el nombre de usuario del localStorage.');
      }
      // Llamar a la función para editar el usuario
      const response = await EditarUsuario(user, clientName);
      // //console.log('response registro', response);
      // Mostrar mensaje de éxito
      // setAlertMessage('Usuario editado con éxito!');
      setAlertMessage(translate('edit_success_message'));
      setAlertSeverity('success');
      setUser(initialUser); // Resetear el usuario después de guardar con éxito
      setActiveStep(0); // Volver al primer paso del stepper
      setEditar(false)
      // Después de editar el usuario, actualizar la lista de usuarios
      await actualizarListaUsuarios();
  } catch (error) {
      // Capturar y manejar cualquier error que ocurra durante la edición del usuario
      //console.log('Error al editar usuario:', error);
      // setAlertMessage('Hubo un error al editar el usuario.');
      setAlertMessage(translate('edit_error_message'));
      // 
      setAlertSeverity('error');
  }
};

  const steps = [ `${translate('InformaciónUser')}`  , `${translate('Acceso')}` , `${translate('verificacion')}` ];

  return (
    <Grid
      container
      justifyContent="center"
      className="rootRegister"
      style={{
        marginTop: '-5vh',
        cursor: 'default !important',
        userSelect: 'none',
      }}
    >
      <Grid container justifyContent="flex-start" item xs={6}>
        <IconButton onClick={Exit} edge="start" color="inherit" aria-label="menu">
          <img
            src={Logo}
            style={{
              paddingLeft: '0.5vw',
              height: '16vh',
              aspectRatio: '1.4',
              marginTop: '1vh',
            }}
          />
        </IconButton>
      </Grid>
      <Grid container justifyContent="flex-end" item xs={6}>
        <IconButton onClick={handleClick} style={{marginRight:'1vw', height:'3vh'}}>
          <TranslateIcon style={{fontSize:'2vh', color:'#e84e0e'}}/>
        </IconButton>
        {/* Menú de idiomas */}
        <Traductor handleLanguageChange ={handleLanguageChange} openMenuIdioma ={openMenuIdioma} handleCloseIdioma ={handleCloseIdioma} />
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: '-8vh', }}>
        <Grid container justifyContent="center" item xs={12}>
          <Typography className="h4" variant="h4" style={{fontSize:'3.5vh'}}>
            {!editar ? `${translate('Registro')}` : `${translate('Edit')} ${usuarioSeleccionado.username}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        {activeStep > 0 && (
          <Button className='button-atras' variant='outlined' color='inherit'  onClick={handleBack}>
            {translate('atras')}
          </Button>
        )}
      </Grid>
      <Grid container justifyContent="center" item xs={4}>
        <Stepper className="Steep"  activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} color='success' className={index === activeStep ? 'activeStep' : ''}>
              <StepLabel style={{fontSize:'2vh'}}>{label} </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={2}>
        {activeStep < steps.length - 1 && (
          <Button className='button-siguiente' variant='outlined' color='inherit' onClick={handleNext}>
            {translate('siguiente')}
          </Button>
        )}
      </Grid>
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        style={{
          marginBottom: '2vh',
          height: '60vh',
          minWidth: '80vw',
          maxWidth: '80vw',
          backgroundColor: '#0f0f0f',
          borderRadius: '2vh',
        }}
      >
        <Grid item xs={4}>
          {activeStep === 0 && (
            <UserForm
              translate ={translate}
              handleInputChange={handleInputChange}
              user={user}
              setUser={setUser}
              usuarios={usuarios}
              actualizarListaUsuarios={actualizarListaUsuarios}
              usuarioSeleccionado={usuarioSeleccionado}
              setUsuarioSeleccionado={setUsuarioSeleccionado}
              setEditar={setEditar}
              editar={editar}
              cargo={cargo}
            />
          )}
        </Grid>
        <Grid container justifyContent="center" item xs={12}>
          {activeStep === 1 && <Acceso user={user} setUser={setUser} translate ={translate}  />}
        </Grid>
        <Grid container justifyContent="center" item xs={12}>
          {activeStep === 2 && <Verificacion user={user} initialUser={initialUser} handleConfirmation={confiramcionUser} editar={editar} confiramcionUserEdit={confiramcionUserEdit} translate ={translate}/>}
        </Grid>
      </Grid>
      {/* Alert para mostrar mensaje */}
      {alertMessage && (
        <Alert
          severity={alertSeverity}
          onClose={() => setAlertMessage(null)}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999, // Asegura que la alerta esté en la parte superior
          }}
        >
          {alertMessage}
        </Alert>
      )}
    </Grid>
  );
};

export default Registro;
