import React,{useState} from 'react'
import { Grid } from '@mui/material'
import Oxigeno from '../Sensores/Oxigeno'
import Salinidad from '../Sensores/Salinidad'
import Saturacion from '../Sensores/Saturacion'
import { Temperatura } from '../Sensores/Temperatura'
// import Chart from '../Grafico/grafico'
import AmbientalChart from '../../Grafico/AmbientalGraph'
import Alertas from '../alertas'

const Prof_1_Normal = (
    { profundidad, translate,alerta,
        showOxigeno, showSaturacion, showSalinidad, showTemperatura, toggleOxigeno, toggleSaturacion, toggleSalinidad, toggleTemperatura, title, pauseRequest, setPauseRequest, profundidadesPerPage, currentView }) => {

    const commonProps = {
        // ajuste de la card
        width: '10vw',
        height: '15vh',
        //Tamaño de icono
        heightIMG: '10vh',
        widthIMG: '5vw',
        sinText: true,
        // ajuste de la posición del valor de oxígeno
        fontSizeH1 : '6vh',
        fontSizeTypo1: '2.5vh',
        //tamaño card del valor de oxigeno
        width1: '12vw',
        height1: '15vh',
        // separación de la card de oxígeno y su valor
        marginLeft1: '-1.5vw',
      };

    const graphProps = {
        width       : "95vw",
        height      : "50vh",
        profundidad : profundidad,
        data1       : profundidad.O2_val? profundidad.O2_val : null,
        data2       : profundidad.DO2_val? profundidad.DO2_val : null,
        data3       : profundidad.sal_val? profundidad.sal_val : null,
        data4       : profundidad.temp_val? profundidad.temp_val : null,
        title       : title,
        titleSize   : '40vw',
        // lineType: 'line'
        axisFontSize     : window.innerHeight*0.025 , //"20vh",
        legendFontSize        : "2.5vh",
        showOxigeno           : showOxigeno,
        showSaturacion        : showSaturacion,
        showSalinidad         : showSalinidad,
        showTemperatura       : showTemperatura,
        toggleOxigeno         : toggleOxigeno,
        toggleSaturacion      : toggleSaturacion,
        toggleSalinidad       : toggleSalinidad,
        toggleTemperatura     : toggleTemperatura,
        // Activar Solicitudes de datos
        pauseRequest          : pauseRequest, 
        setPauseRequest       : setPauseRequest,
        profundidadesPerPage  : profundidadesPerPage,
        currentView           : currentView,
        translate              : translate
    }
    const initialAlerts = alerta?.alertasActivas || [];
    const [activeAlerts, setActiveAlerts] = useState(initialAlerts);
    // console.log('prof',profundidad)
    
    return (
        <Grid container justifyContent="center" item xs={12} style={{ marginTop: "2vh" }}>
            <Grid container justifyContent="flex-start" item xs={12} style={{ marginTop: "-3vh" }}>
                <h1 style={{ cursor: 'default !important', userSelect: 'none', fontSize:'3vh', marginTop:'1vh' }}>
                    {profundidad && profundidad.profundidad ? `${profundidad.profundidad}m` : ''} 
                    {/* {data && data.Battery ? data.Battery : ''} */}
                </h1>
            </Grid>

            <Alertas activeAlerts={activeAlerts} setActiveAlerts={setActiveAlerts} translate={translate}/>

            {/* Grafico */}
            <Grid container justifyContent="center" item xs={12} style={{ height: "58vh" }} >
                <Grid item xs={12}>
                    <AmbientalChart 
                        {...graphProps}
                    />
                </Grid>
            </Grid>

            {/* Sensores */}
            <Grid container justifyContent="center" align={'center'} >
                {/* Cards Oxigeno */}
                <Grid item xs={3} md={3} >
                    <Oxigeno
                        showOxigeno={showOxigeno}
                        toggleOxigeno={toggleOxigeno}
                        {...commonProps}
                        medicion={profundidad.O2_val[profundidad.O2_val.length - 1] && profundidad.O2_val[profundidad.O2_val.length - 1].value ? profundidad.O2_val[profundidad.O2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards saturacion */}
                <Grid item xs={3} md={3} >
                    <Saturacion
                        //funcion de botones
                        showSaturacion={showSaturacion}
                        toggleSaturacion={toggleSaturacion}
                        {...commonProps}
                        medicion={profundidad.DO2_val[profundidad.DO2_val.length - 1] && profundidad.DO2_val[profundidad.DO2_val.length - 1].value ? profundidad.DO2_val[profundidad.DO2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* card de salinidad */}
                <Grid item xs={3} md={3}>
                    <Salinidad //función de botones
                        showSalinidad={showSalinidad}
                        toggleSalinidad={toggleSalinidad}
                        {...commonProps}
                        medicion={profundidad.sal_val[profundidad.sal_val.length - 1] && profundidad.sal_val[profundidad.sal_val.length - 1].value ? profundidad.sal_val[profundidad.sal_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Temperatura */}
                <Grid item xs={3} md={3}  >
                    <Temperatura
                        showTemperatura={showTemperatura}
                        toggleTemperatura={toggleTemperatura}
                        {...commonProps}
                        medicion={profundidad.temp_val[profundidad.temp_val.length - 1] && profundidad.temp_val[profundidad.temp_val.length - 1].value ? profundidad.temp_val[profundidad.temp_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Prof_1_Normal