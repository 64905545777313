import React from 'react';
import {Grid,IconButton,Typography,AppBar } from '@mui/material';
import LogoNaranjo from '../../assets/img/ITG-Logotipo-SobreNegro.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModalCalendario from './modal/ModalCalendario';
import TranslateIcon from '@mui/icons-material/Translate';
import Traductor from '../../../../components/Traductor';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TemporaryDrawer from './TemporaryDrawer'; 
import Nanoox from '../../assets/img/Nanoox.png'

export function MyAppBar({
    nameCentro,
    translate ,
    handleClick ,
    openMenuIdioma ,
    handleCloseIdioma ,
    handleLanguageChange, 
    idioma,
    ultimaHora,notificationOpen, setNotification_open, setNotification_msg, setNotification_status,
    setNameCentro,
    SWVersion,
    centroSeleccionado,
    regionSeleccionada,
    handleCentroClick,
    areaSeleccionada,
    handleAreaClick,
    theme,
    handleRegionClick,
    capitalize,
    consolidado_estados,
    selectedRegion, setSelectedRegion,
    selectedArea, setSelectedArea,
    setMostrarAlertas,
    mostrarAlertas
      }) {

  const [openModal, setOpenModal] = React.useState(false)//CALENDARIO
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  //  * --------------------------handle MouseEnter
  const handleLogoMouseEnter = () => {
    setOpen(true); 
  };
    
  const handleLogoMouseLeave = () => {
      setOpen(false); 
  };


  const MyDrawerProps = {
    SWVersion: SWVersion,
    theme: theme,
    handleAreaClick: handleAreaClick,
    handleRegionClick: handleRegionClick,
    areaSeleccionada: areaSeleccionada,
    handleCentroClick: handleCentroClick,
    regionSeleccionada: regionSeleccionada,
    centroSeleccionado: centroSeleccionado,
    setNameCentro: setNameCentro,
    translate: translate,
    capitalize:capitalize,
    consolidado_estados:consolidado_estados,
    selectedRegion:selectedRegion, 
    setSelectedRegion:setSelectedRegion,
    selectedArea:selectedArea, 
    setSelectedArea:setSelectedArea,

    open:open,
    setOpen:setOpen,
    toggleDrawer:toggleDrawer,
    handleLogoMouseEnter:handleLogoMouseEnter, 
    handleLogoMouseLeave:handleLogoMouseLeave,
    setMostrarAlertas:setMostrarAlertas,
    mostrarAlertas:mostrarAlertas

  } 




  return (
    // <>
    // <AppBar position="fixed"  style={{height:"8vh"}}>
      <Grid container justifyContent={'center'} style={{height:"8vh", marginTop:'0vh', backgroundColor:'#171717'}}>
        <Grid container justifyContent='center' alignContent='center' item xs={10} >
            <Grid container justifyContent='center' alignContent='center'  alignItems={'center'} item xs={1}>
              <IconButton
                color="inherit"
                onClick={toggleDrawer(true)}
                edge="start"
                onMouseEnter={() => handleLogoMouseEnter()}
                onMouseLeave={() => handleLogoMouseLeave()}
                style={{position: 'fixed',}}
              >
                <img
                    src={LogoNaranjo}
                    alt="Icono"
                    style={{ width: "3.8vw", aspectRatio: '1.2', marginLeft: "1vw",  color:"white" }}
                />
              </IconButton> 
            </Grid>
             {/* Botón Calendario */}
            <Grid container justifyContent='center' alignContent='center' item xs={1} >
                    <IconButton onClick={() => setOpenModal(!openModal)} style={{ position: 'fixed',  width: "2.8vw", height: "6vh", marginLeft: '-0.25vw' }} >
                        <CalendarMonthIcon style={{fontSize:"1.75vw",color: "whitesmoke", /* position: 'fixed', top: '1.3vh', left: '10vw' */ }} />
                    </IconButton>
                    <ModalCalendario openModal={openModal} setOpenModal={setOpenModal} idioma={idioma} translate={translate} notificationOpen={notificationOpen} setNotification_open={setNotification_open} setNotification_msg={setNotification_msg} setNotification_status={setNotification_status} />

            </Grid>
            <Grid container justifyContent='center' alignContent='center' item xs={4} >
              <AccessTimeIcon style={{ fontSize: '1.8vw', marginLeft: '1.5vw' }} sx={{ color: 'white' }} />
              <Typography variant="h6" component="div" sx={{ marginLeft: '0.5vw',marginTop:'0.5vh', fontSize: '1.1vw' }}>
                {/* Última Medición: */}
                {translate('ultima_medicion')}:  {ultimaHora}
                {/* {datos.ultima} */}
              </Typography>
            </Grid>
            <Grid container justifyContent='center' alignContent='center' item xs={5}>
                    {nameCentro && (
                        <Grid container  style={{ alignItems: 'center', justifyContent: 'center' }}>
                          <Grid container justifyContent='center'>
                              <Typography style={{ fontSize: '0.75vw', fontWeight: 100 }}>{translate('accessed_center')}</Typography>
                          </Grid>
                          <Grid container justifyContent='center'>
                              <Typography style={{ fontSize: '1vw', fontWeight: 700 }}>{capitalize(nameCentro)}</Typography>
                          </Grid>
                        </Grid>
                    )}
            </Grid>
            <Grid container justifyContent='center' alignContent='center' item xs={1}>
                <IconButton  onClick={handleClick} style={{marginRight:'1vw'}}>
                  <TranslateIcon style={{fontSize:'1.1vw', color:'#e84e0e'}}/>
                </IconButton>
                {/* Menú de idiomas */}
                <Traductor handleLanguageChange ={handleLanguageChange} openMenuIdioma ={openMenuIdioma} handleCloseIdioma ={handleCloseIdioma} />
            </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" alignContent='center' item xs={2} style={{ marginLeft:"-1vw"}}   >
              <img
                  src={Nanoox}
                  alt="Icono"
                  style={{ width: '13vw', aspectRatio:'3.6', marginLeft: "-0.3vw",  color:"white",}}
              />
            </Grid>
            {/* Drawer */}
            <TemporaryDrawer  {...MyDrawerProps} />
      </Grid>

      
    // </AppBar>
  
      // </>
  );
}
