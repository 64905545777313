import React from 'react'
import { Grid } from '@mui/material'

const Acciones = ({problema}) => {

    // Formato para la hora
    const formatTime = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convertir de segundos a milisegundos
        return date.toLocaleTimeString('es-ES');
    };

    console.log('problemas',problema?.tags[0])

  return (
    <Grid container justifyContent={'center'} style={{ height:'45vh'}}>
      {/* Encabezado de la tabla */}
      <Grid container justifyContent={'center'} style={{height:'5vh', backgroundColor:'#504f4f', marginTop:'-0.5vh'}}>
        <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}} item xs={4}><p>Tiempo de recuperación</p></Grid>
        <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}} item xs={2}><p>Estado</p></Grid>
        <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}} item xs={2}><p>Duración</p></Grid>
        <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}} item xs={4}><p>Tags</p></Grid>
      </Grid> 
      
      {/* Filas de la tabla */}
        <Grid container justifyContent={'center'}  alignContent={'start'} style={{height:'65vh'}}>
                <Grid container justifyContent={'center'} style={{ borderBottom: '0.1vh solid #000',height:'5vh'}}>
                    <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}}  item xs={4}>{formatTime(problema?.clock)}</Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}}  item xs={2}>{problema?.severity}</Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}}  item xs={2}>--</Grid> {/* Aquí podrías calcular la duración */}
                    <Grid container justifyContent={'center'} alignContent={'center'} style={{fontSize:'0.85vw', height:'5vh'}}  item xs={4}>{problema?.tags[0]?.tag} - {problema?.tags[0]?.value} </Grid> {/*Aquí podrías agregar tags si los hay*/}
                </Grid>
        </Grid>
    
    </Grid>
  )
}

export default Acciones