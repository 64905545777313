import React, { useState, useEffect  } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Grid, Paper, InputBase, Divider, IconButton } from '@mui/material';
import Usuario from '../../pages/Usuarios'
import 'swiper/css';
import 'swiper/css/scrollbar';
import '../../assets/css/carrusel.css'

const CustomSelect = ({ label, value, onChange, options }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (option) => {
    onChange(option); // Pasar el objeto completo del elemento seleccionado
    handleClose();
  };

  return (
    <FormControl fullWidth>
      <InputLabel style={{fontSize:'1.8vh'}}>{label}</InputLabel>
      <InputBase
        fullWidth
        value={value}
        onClick={handleOpen}
        inputProps={{ readOnly: true }}
        style={{ marginTop:'1vh', marginLeft:'0.5vw', fontSize:'1.8vh',zIndex:1, width:'12vw', height:'4vh'}}
        // style={Add your custom styles here if needed}
      />
      {open && (
        <Paper style={{zIndex:1}}>
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => handleMenuItemClick(option.value)} style={{zindex:1, fontSize:'1.8vh'}}>
            {option.label}
          </MenuItem>
        ))}
      </Paper>
      )}
    </FormControl>
  );
};

const UserForm = ({translate, cargo, usuarios,initialUser, user, setEditar, editar, setUser, handleInputChange, actualizarListaUsuarios, usuarioSeleccionado, setUsuarioSeleccionado }) => {
  const [usuariosActualizados, setUsuariosActualizados] = useState([]);
  const [opciones, setOpciones] = useState([])


  useEffect(() => {
    let opcionesActualizadas = [];
  
    if (cargo === "Jefe Area") {
      opcionesActualizadas = [
        { value: "Jefe Centro", label: translate('jefe_centro') },
        { value: "Operario", label: translate('operario') }
      ];
    } else if (cargo === "Jefe Centro") {
      opcionesActualizadas = [
        { value: "Operario", label: translate('operario') }
      ];
    } else if (cargo === "Jefe Region") {
      opcionesActualizadas = [
        { value: "Jefe Area", label: translate('jefe_area') },
        { value: "Jefe Centro", label: translate('jefe_centro') },
        { value: "Operario", label: translate('operario') }
      ];
    } else if (cargo === "Gerencia") {
      opcionesActualizadas = [
        { value: "Jefe Region", label: translate('jefe_region') },
        { value: "Jefe Area", label: translate('jefe_area') },
        { value: "Jefe Centro", label: translate('jefe_centro') },
        { value: "Operario", label: translate('operario') }
      ];
    } else {
      opcionesActualizadas = [
        { value: "Gerencia", label: translate('gerencia') },
        { value: "Jefe Region", label: translate('jefe_region') },
        { value: "Jefe Area", label: translate('jefe_area') },
        { value: "Jefe Centro", label: translate('jefe_centro') },
        { value: "Operario", label: translate('operario') }
      ];
    }
  
    setOpciones(opcionesActualizadas);
  }, []);

  useEffect(() => {
    // Aquí puedes realizar cualquier acción necesaria cuando la lista de usuarios cambie
    if(usuarios != usuariosActualizados){
      setUsuariosActualizados(usuarios);
    }
  }, [usuarios]);

// console.log('user', user)
  const handleCargoChange = (cargo) => {
    setUser((prevUser) => ({
      ...prevUser,
      cargo: cargo,
    }));
  };


  // const handleMenuItemClick = (option) => {
  //   onChange(option);
  //   handleClose();
  // };

  return (
    <Grid container justifyContent='center'>
      {/* <Grid container justifyContent='center'> */}
      {/* <form noValidate style={{marginTop:'5vh', border:'solid', width:'100vw'}}> */}
        <Grid container justifyContent='center' spacing={2} style={{marginTop:'5vh',  width:'100vw'}} > 
          <Grid item xs={6}> 
            <Paper 
            component="form"
            style={{ backgroundColor:'rgb(1,1,1)',alignItems: 'center', width: '13vw', height:'5.5vh', border:'solid 0.1vh #474747',  }}>
                <InputBase
                  fullWidth
                  name="username"
                  value={user.username}
                  style={{marginLeft:'0.5vw', marginTop:'0.9vh', fontSize:'1.8vh', width: '12vw'}}
                  placeholder={translate('username')}
                  onChange={handleInputChange}
                />
            </Paper>
          </Grid>
          <Grid item xs={6}> 
              <Paper 
                component="form"
                style={{ backgroundColor:'rgb(1,1,1)',alignItems: 'center', width: '13vw', height:'5.5vh', border:'solid 0.1vh #474747',  }}>
                <InputBase
                  fullWidth
                  name="correo"
                  value={user.correo}
                  type="email"
                  style={{marginLeft:'0.5vw', marginTop:'0.9vh', fontSize:'1.8vh', width: '12vw'}}
                  placeholder={translate('email')}
                  onChange={handleInputChange}
                />
            </Paper>
          </Grid>
          <Grid item xs={6}> 
            {!editar &&
              <Paper 
                component="form"
                style={{ backgroundColor:'rgb(1,1,1)',alignItems: 'center', width: '13vw', height:'5.5vh', border:'solid 0.1vh #474747',  }}>
                <InputBase
                  fullWidth
                  name="password"
                  type="email"
                  style={{marginLeft:'0.5vw', marginTop:'0.9vh', fontSize:'1.8vh', width: '12vw'}}
                  placeholder={translate('password')}
                  onChange={handleInputChange}
                />
            </Paper>
            }
          </Grid>
          <Grid item xs={6}> 
            <Paper 
                component="form"
                style={{ backgroundColor:'rgb(1,1,1)',alignItems: 'center', width: '13vw', height:'5.5vh', border:'solid 0.1vh #474747', zIndex:33 }}>
                <CustomSelect
                   label={translate('selected_cargo')}
                   value={user.cargo}
                   onChange={handleCargoChange}
                   options={opciones}
                  //  {[
                  //   { value: "Gerencia", label: translate('gerencia') },
                  //   { value: "Jefe Region", label: translate('jefe_region') },
                  //   { value: "Jefe Area", label: translate('jefe_area') },
                  //   { value: "Jefe Centro", label: translate('jefe_centro') },
                  //   { value: "Operario", label: translate('operario') }
                  // ]}
                />
            </Paper>
          </Grid>
        </Grid>
      {/* </form> */}
      {/* </Grid> */}
      <Grid>
      <Usuario  translate ={translate} initialUser={initialUser} usuarios={usuarios} user={user} setEditar={setEditar} editar={editar} setUser={setUser} 
              actualizarListaUsuarios={actualizarListaUsuarios} usuarioSeleccionado={usuarioSeleccionado} setUsuarioSeleccionado={setUsuarioSeleccionado} />
      </Grid>

    </Grid>
  );
};

export default UserForm;
