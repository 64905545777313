import React from 'react'
import { Grid, Typography, Popper, Button   } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModalTags = ({ anchorEl, handleClose, tags }) => {
  const id = Boolean(anchorEl) ? 'simple-popper' : undefined;

  return (
    <Popper 
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{width:'34vw'}}
    >
         {/* Botón para cerrar el Popper */}
         <Button 
         variant='outlined'
         color='inherit'
          onClick={handleClose} 
          sx={{ 
            position: 'absolute', 
            top: '0.2vw', 
            right: '0.2vw', 
            padding: '0.5vw' ,
            minWidth:'1.5vw',
            maxWidth:'1.5vw',
            minHeight:'2.5vh',
            maxHeight:'2.5vh',
          }}
        >
          <CloseIcon sx={{ fontSize: '0.85vw' }} />
        </Button>

        <Grid container sx={{ p: 1, bgcolor: 'background.paper', borderRadius:'0.5vw', cursor: 'default !important', userSelect: 'none', }}>
            {tags.map((tag, index) => (
                <Grid container justifyContent={'center'} alignContent={'center'} item xs={2} style={{ margin: '0.15vw',  textAlign:'center', borderRadius:'0.2vw', backgroundColor:'#2b2b2b' }}>
                    <Typography variant="body2" style={{fontSize:'0.7vw'}}>{`${tag.tag}: ${tag.value}`}</Typography>
                </Grid>
            ))}
        </Grid>
    </Popper >
  )
}

export default ModalTags