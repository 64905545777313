import React,{useState} from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const Niveles = ({ item, total }) => {
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} item xs={2} style={{backgroundColor:item.color, minWidth:"14%", maxWidth:'14%', height:"7vh", borderRadius:'0.5vw', margin:'0.5vw'}}>
          <Grid container justifyContent="center" alignContent={'center'} style={{fontSize:"1.5vw", height:'3vh'}}>
              {total}
          </Grid>
          <Grid container justifyContent="center" alignContent={'center'} style={{fontSize:"1.1vw", height:'3vh'}}>
              {item.nivel}
          </Grid>
    </Grid>
  );
};

export default Niveles;