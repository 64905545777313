

import React,{useState, useRef, useEffect} from 'react';
import { FormControl, InputLabel, MenuItem, Paper, InputBase, Divider, IconButton, Grid, capitalize } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CustomSelect = ({ label, value, onChange, capitalize, region,datosArray }) => {
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (regionName) => {
    // console.log('region', regionName);
    onChange(regionName); // Pasar el nombre de la región a onChange
    handleClose();
  };

  return (
    <FormControl fullWidth>
      <Grid container justifyContent='center' >
        <InputLabel style={{fontSize:'1.8vh'}}>{label}</InputLabel>
        <InputBase
          fullWidth
          value={value}
          onClick={handleOpen}
          disabled={!region}
          inputProps={{ readOnly: true }}
          style={{ marginTop:'1vh', marginLeft:'0.5vw', fontSize:'1.8vh',zIndex:1}}
        />
        <Divider sx={{ height: '3.5vh',marginLeft:'9vw', marginTop:'-3.7vh' }} orientation="vertical" />
         <IconButton disabled={!region} sx={{marginTop:'-3.5vh',marginLeft:'10.9vw'}} >
            {open ? <KeyboardArrowUpIcon style={{fontSize:'2vh'}} /> :<KeyboardArrowDownIcon style={{fontSize:'2vh'}} /> }
          </IconButton>
      </Grid>
      {open && (
        <Paper ref={wrapperRef}>
          {Object?.keys(datosArray?.[region]).map((AREA) => {
              return(
              <MenuItem style={{fontSize:'1.8vh'}} key={AREA} value={AREA} onClick={()=>handleMenuItemClick(AREA)}>
                {capitalize(String(AREA))} 
              </MenuItem> 
            )})}
        </Paper>
      )}
    </FormControl>
  );
};

const AreaSelector = ({  translate, area,region, handleAreaChange,datosArray, capitalize  }) => {

    return (
      <Paper  
      component="form"
                style={{ backgroundColor:'rgb(1,1,1)',alignItems: 'center', width: '13vw', height:'5.5vh', border:'solid 0.1vh #474747', zIndex:33 }}>
                  
        <CustomSelect
          value={capitalize(String(area))}
          onChange={handleAreaChange}
          label={translate('selected_area')}
          id="region-select"
          datosArray={datosArray}
          region={region}
          capitalize={capitalize}
        />
      </Paper>
    );
}
export default AreaSelector