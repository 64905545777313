import React, { useState, useEffect, useContext } from "react";

import {
    FormControlLabel,
    Checkbox,
    Button,
    Box,
    Grid,
    Tooltip
} from "@mui/material";

import styled from '@emotion/styled';

/* React date range */
import moment from 'moment';
import { DateRangePicker, defaultInputRanges, defaultStaticRanges } from "react-date-range";
// import { es } from 'date-fns/locale'
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import jp from 'date-fns/locale/ja';
import zh from 'date-fns/locale/zh-CN';
import fr from 'date-fns/locale/fr';
import ar from 'date-fns/locale/ar-SA';
import ru from 'date-fns/locale/ru';
import pt from 'date-fns/locale/pt-BR';
import no from 'date-fns/locale/nb'; // Para noruego bokmål
import {
    addDays,
    addWeeks,
    startOfWeek,
    endOfWeek,
} from "date-fns";
/* Estilos */
import './css/nanoox_default.css'
import './css/nanoox_styles.css'


const StyledButton = styled(Button)`
  width: 5.5vw;
  height: 5vh;
  padding: 0;
  margin-top: -1vh;
  margin-left: 0;
  font-size: 1.5vh;
  line-height: 2;
  border-radius: 1vh;
  background-color: ${props => props.disabled ? '#808080' : '#e84e0e'};
  color: ${props => props.disabled ? '#ffffff' : '#bfbfbf'};
  &:hover {
    background-color: ${props => props.disabled ? '#808080' : '#ffba9b'};
    color: ${props => props.disabled ? '#ffffff' : '#24305e'};
  }
`;







function DateSelectorNanoox({ fetchDatedData, closeModal, pauseRequest, translate, makeReport, idioma, setNotification_open, setNotification_msg, setNotification_status }) {
    const calendar_ranges_nanoox = [
        {
            ...defaultStaticRanges[0],
            // label: 'Hoy'
            label: translate('hoy')
        },
        {
            ...defaultStaticRanges[1],
            label: translate('ayer')
            // label: 'Ayer'
        },
        {
            ...defaultStaticRanges[2],
            label: translate('esta_semana'),
            // label: 'Esta Semana',
            range: () => ({
                startDate: addDays(startOfWeek(addWeeks(new Date(), 0)), +1),
                endDate: addDays(endOfWeek(addWeeks(new Date(), 0)), +1)
            }),
        },
        {
            ...defaultStaticRanges[3],
            label: translate('semana_pasada'),
            // label: 'Semana Pasada',
            range: () => ({
                startDate: addDays(startOfWeek(addWeeks(new Date(), -1)), +1),
                endDate: addDays(endOfWeek(addWeeks(new Date(), -1)), +1)
            }),
        },
        {
            ...defaultStaticRanges[5],
            label: translate('mes_pasado')
            // label: 'Mes Pasado'
        },
        /* {
            ...defaultStaticRanges[4],
            label: 'Este Mes'
        },
        {
            ...defaultStaticRanges[5],
            label: 'Mes Pasado'
        }, */
    ]
    
    const input_ranges = [{
        ...defaultInputRanges[0],
        // label: 'Días hasta hoy',
        label: translate('dias_hasta_hoy')
    },
    ]
    
    
    /* Date Range */
    // Del calendario
    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -7),       // Última semana
            endDate: new Date(),                      // Hoy
            key: 'selection'
        }
    ]);

    // Entendible por personas y BE
    const [dateRange, setDateRange] = useState({
        startDate: addDays(new Date(), -7),
        endDate: new Date(),
        reporteSensores: false, // Por defecto, seleccionado
        reporteHistorialControl: false,
    });

    // Función para actualizar la fecha de inicio y la fecha de fin
    const updateDateRange = (newStartDate, newEndDate) => {
        setDateRange({
            startDate: newStartDate,
            endDate: newEndDate,
        });
    };


    // Manejador para el cambio del checkbox
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setDateRange(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };
    // Actualizar fechas para BE al cambiar rango en calendario
    useEffect(() => {
        if (state) {
            const start_date = moment(state[0].startDate).format('DD/MM/YYYY')
            const end_date = moment(state[0].endDate).format('DD/MM/YYYY')
            updateDateRange(start_date, end_date)
        }

    }, [state]
    );


    const buttonClick = () => {
        const { startDate, endDate, reporteSensores, reporteHistorialControl } = dateRange;
        if (reporteSensores && reporteHistorialControl) {
            console.log("GenerarReporteHistorial", { startDate, endDate });
            console.log("GenerarReporte", { startDate, endDate });
            makeReport(startDate, endDate, 'reporteSensores'); // Llama a makeReport con el tipo de reporte
            makeReport(startDate, endDate, 'reporteHistorialControl'); // Llama a makeReport con el tipo de reporte
            closeModal();
            
        } else if (reporteHistorialControl) {
            console.log("GenerarReporteHistorial", { startDate, endDate });
            makeReport(startDate, endDate, 'reporteHistorialControl'); // Llama a makeReport con el tipo de reporte
            closeModal();
        } else if (reporteSensores) {
            console.log("GenerarReporte", { startDate, endDate });
            makeReport(startDate, endDate, 'reporteSensores'); // Llama a makeReport con el tipo de reporte
            closeModal();
        }
    }


    // Función para verificar si algún checkbox está seleccionado
    const isAnyCheckboxSelected = () => {
        return dateRange.reporteSensores || dateRange.reporteHistorialControl;
    };
        


    return (
        <Box
            backgroundColor='transparent'
            // marginLeft='5vw'
            // display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={'2vh'}
        // border='1px solid white'
        >
            <Grid container align='center' >
                {/* Calendario */}
                <Grid item xs={12} style={{ cursor: 'default !important', userSelect: 'none', }} >
                    <DateRangePicker
                        /* https://github.com/hypeserver/react-date-range */
                        /* Comienza semana el lunes */
                        // locale={es}
                        locale={
                            idioma === 'en' ? en :
                            idioma === 'jp' ? jp :
                            idioma === 'zh' ? zh :
                            idioma === 'fr' ? fr :
                            idioma === 'ar' ? ar :
                            idioma === 'ru' ? ru :
                            idioma === 'pt' ? pt :
                            idioma === 'es' ? es :
                            idioma === 'no' ? no :
                            null
                        }
                        weekStartsOn={1}
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        inputRanges={input_ranges}
                        staticRanges={calendar_ranges_nanoox}
                        fixedHeight={true}
                        style={{ 'width': '40vw',  }}
                    />

                    {/* Botones */}
                    <Grid container justifyContent='center' align='center' style={{ height:'6vh',width:'47vw', zIndex:1, marginLeft: '0vw', backgroundColor:'#282828', borderBottomLeftRadius:'2vh', borderBottomRightRadius:'2vh'  }} >
                        
                     

                         {/* Checkbox de reporte sensores */}
                         {/* <Grid container justifyContent='center' alignContent='center' item xs={3}  marginLeft='1vw' style={{ cursor: 'default !important', userSelect: 'none', }}  >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dateRange.reporteSensores}
                                        onChange={handleCheckboxChange}
                                        name="reporteSensores"
                                        color="primary"
                                    />
                                }
                                label={
                                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1.4vh', color: 'rgba(180, 180, 180, 1)' }}>
                                        <span>{translate('reporte_sensores')}</span>
                                    </div>
                                }
                            />
                        </Grid> */}
                        {/* <Grid container justifyContent='center' alignContent='center' item xs={5}  marginLeft='-0.75vw' style={{ cursor: 'default !important', userSelect: 'none', }}   >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dateRange.reporteHistorialControl}
                                        onChange={handleCheckboxChange}
                                        name="reporteHistorialControl"
                                        color="primary"
                                    />
                                }
                                label={
                                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1.4vh', color: 'rgba(180, 180, 180, 1)' }}>
                                        <span>{translate('reporte_historial_control')}</span>
                                    </div>
                                }
                            />
                        </Grid> */}
                           {/* Botón Obtener datos*/}
                           <Grid container justifyContent='center' alignContent='center' item xs={12}   >
                            <Tooltip title="Seleccionar para Generar Reporte">
                                <StyledButton
                                    variant="contained"
                                    style={{height:'4vh',width:'4.5vw', fontSize:'1.5vh',marginLeft:'0vw'}}
                                    onClick={() => buttonClick()}
                                    // disabled={!isAnyCheckboxSelected()}
                                >
                                    {translate('aceptar')}
                                </StyledButton>
                            </Tooltip>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
}

export default DateSelectorNanoox;