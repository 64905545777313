// import React from 'react';
// import BombaNoData from '../BombaNoData/BombaNoData';
// import BombaDesconectadaPS from './BombaDesconectadaPS';
// import BombaParadaEmergencia from './BombaParadaEmergencia';
// import BombaSinEnergia from './BombaSinEnergia';
// import BombaIniciar from './BombaIniciar';
// import BombaAlarma from './BombaAlarma';
// import BombaEnBuenEstado from './BombaEnBuenEstado';

// const BombaStatusPS = ({ bomba }) => {
//   if (!bomba.aviot) {
//     return <BombaNoData bomba={bomba} />;
//   } else if (!bomba.PS_Modbus_Status) {
//     return <BombaDesconectadaPS bomba={bomba} />;
//   } else if (bomba.InStop === 1) {
//     return <BombaParadaEmergencia bomba={bomba} />;
//   } else if (bomba.Status === 0) {
//     return <BombaSinEnergia bomba={bomba} />;
//   } else if (bomba.Status === 2) {
//     return <BombaIniciar bomba={bomba} />;
//   } else if (bomba.Status === 13) {
//     return <BombaAlarma bomba={bomba} />;
//   } else if (bomba.Status === 9) {
//     return <BombaEnBuenEstado bomba={bomba} />;
//   } else {
//     return <p> Cargando ...  </p>; // Caso no manejado
//   }
// };

// export default BombaStatusPS;
// src/components/SoftStarterStatus/BombaStatusPS.jsx
import React from 'react';
import BombaCommonStatus from '../Common/BombaCommonStatus';
import BombaOperativa  from '../Common/BombaOperativa';
import BombaNoData from '../Common/BombaNoData'

const BombaStatusPS = ({ bomba, modulo ,configuracionUmbrales, isSelected, color, controlType, translate }) => {
  if (!bomba.aviot_status) {
    return <BombaNoData message="BOMBA SIN DATOS" bomba={bomba} modulo={modulo} translate={translate} />;
  }

  if (!bomba.PS_Modbus_Status) {
    return <BombaCommonStatus message="Partidor Suave Desconectado" bomba={bomba} modulo={modulo}  translate={translate} />;
  }

  if (bomba.InStop === '1') {
    return <BombaCommonStatus message="Parada de Emergencia" bomba={bomba}  modulo={modulo} translate={translate} />;
  }

  // if (bomba.Status === 0) {
  //   return <BombaCommonStatus message="Absence of power" bomba={bomba}  modulo={modulo} />;
  // }
  if (bomba.Status === 0) {
    if (bomba.InStart === '0') {
      return (
        <BombaOperativa
          bomba={bomba}
          modulo={modulo}
          isSelected={isSelected}
          color={color}
          controlType={controlType}
          configuracionUmbrales={configuracionUmbrales}
          translate={translate}
        />
      );
    } else {
      return (
        <BombaCommonStatus
          message="Absence of power"
          bomba={bomba}
          modulo={modulo}
          translate={translate}
        />
      );
    }
  }

  if (bomba.Status === 2) {
    if (bomba.InStart === '0') {
      return (
        <BombaOperativa
          bomba={bomba}
          modulo={modulo}
          isSelected={isSelected}
          color={color}
          controlType={controlType}
          configuracionUmbrales={configuracionUmbrales}
          translate={translate}
        />
      );
    } else {
      return <BombaCommonStatus message="Starter ready" bomba={bomba}  modulo={modulo} translate={translate} />;
    }
  }

  if (bomba.Status === 13) {
    return <BombaCommonStatus message="Alarm"  bomba={bomba} modulo={modulo} translate={translate}/>;
  }

  if (bomba.Status === 9) {
    return <BombaOperativa bomba={bomba} modulo={modulo} translate={translate} />;
  }

  return  <BombaOperativa bomba={bomba} modulo={modulo} isSelected={isSelected} translate={translate}
  color={color}
  controlType={controlType} configuracionUmbrales={configuracionUmbrales} />;
};

export default BombaStatusPS;
