
import React from "react";
import { Grid, Tooltip, Button, ButtonGroup } from "@mui/material";
import PowerIcon from '@mui/icons-material/Power';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const DetalleCan = ({
  can,
  StyleTooltip,
  idx,
  translate
}) => {
  return (
        <Grid container justifyContent="center"  item xs={12} key={idx}>
          <Grid container justifyContent="center" item xs={7} key={`can-info-${idx}`} style={{ width: "14vw", height: "4vh", marginTop: "-1vh", marginLeft: "1vw", border: "0.1px solid #ccc", borderRadius: "1vh", backgroundColor: "#161616" }}>
            <Grid container justifyContent={'center'} alignContent={'center'} item xs={3} style={{ borderRight: "0.1px solid #ccc", color: "white", height: "3.8vh", alignItems: "center", justifyContent: "center" }}>
              <h1 style={{  fontSize: "0.85vw", fontWeight:200   }}>
                {can.voltaje.toFixed(2)} V
                {/* 417.600 V */}

              </h1>
            </Grid>

            <Grid container justifyContent={'center'} alignContent={'center'} item xs={3} style={{  color: "white", height: "3.8vh" }}>
              <h1 style={{  fontSize: "0.85vw", fontWeight:200  }}>
                {can.corriente.toFixed(2)} A
              </h1>
            </Grid>
            <StyleTooltip key={idx} title={`${translate('ultima_medicion_presion')} ${can.last_measure_press}`}  placement="bottom" classes={{ tooltip: 'custom-tooltip' }}>
              <Grid container justifyContent={'center'} alignContent={'center'} item xs={3} style={{ borderLeft:"0.1vh solid #ccc", color: "white", height: "3.8vh" }}>
                <h1 style={{ fontSize: (can.presion === -2) ?  '0.65vw' : '0.75vw', fontWeight:200 , color:(can.presion === -2) ?  '#d9c089' : 'white' }}>
                  {/* {can.presion.toFixed(2)} bar */}
                  {(can.presion === -2) ?  translate('sensor_failure') : `${can.presion.toFixed(2)} bar`}
                </h1>
              </Grid>
            </StyleTooltip>
            <Grid container justifyContent={'center'} alignContent={'center'} item xs={3} style={{ borderLeft: "0.1vh solid #ccc", color: "white", height: "3.8vh" }}>
                <h1 style={{ fontSize: '0.75vw', fontWeight:200 , color: 'white' }}>
                  {/* {can.presion.toFixed(2)} bar */}
                  {`${can.sal} ppt`}
                </h1>
              </Grid>
          </Grid>
          {/* <Grid container justifyContent="center" item xs={4} key={`button-${idx}`}>
            <Tooltip title="">
              <Button style={{ width: "7vw", height: "4vh", marginTop: "-1vh", marginLeft: "1vw", borderRadius: "1vh", backgroundColor: can.estado ? "#008000" : "#FF0000", color: "white", fontSize: "0.85vw" }}>
                <h3>{can.estado}</h3>
                {can.estado ? (
                  <h1 style={{ fontSize: "0.85vw", marginTop: "0.6vw", marginLeft: "-0.1vw" }}>
                    {translate('status_can_connect')}
                  </h1>
                ) : (
                  <h1 style={{ fontSize: "0.75vw", marginTop: "0.6vw", marginLeft: "0.1vw" }}>
                    {translate('status_can_disconect')}
                  </h1>
                )}
              </Button>
            </Tooltip>
          </Grid> */}
          <Grid container justifyContent="center" alignContent={'center'} alignItems={'center'} item xs={3} key={`button-${idx}`} style={{marginTop: "-1vh", }}>
            <ButtonGroup variant="text" color="inherit" style={{ }} >
              <Tooltip title= {can.estado ? translate('tarjeta_controladora_on') : translate('tarjeta_controladora_off')}>
                <Button style={{ width: "2.5vw", height: "4vh", marginLeft: "1vw", color: "white", fontSize: "1.7vh", backgroundColor:'#161616' }}>
                    <FiberManualRecordIcon style={{ color: can.estado ? "#008000" : "#FF0000", fontSize:'1.5vw'}}/>
                </Button>
              </Tooltip>
              {/* FUENTE */}
              {can.source ==='1' ? (
                <Tooltip title={translate('conect_fuente')}>
                    <Button variant="contained"  color="inherit"  style={{ minWidth:'2.5vw', maxWidth:'2.5vw', height: "4vh", backgroundColor:'#161616'}}>
                      <PowerIcon style={{ fontSize:'1.5vw'}}/>
                    </Button>
                </Tooltip>
              ) : (
                <Tooltip title={translate('conect_battery')}>
                    <Button variant="contained"  color="inherit"  style={{ minWidth:'2.5vw', maxWidth:'2.5vw', height: "4vh",backgroundColor:'#161616'}}>
                      <BatterySaverIcon style={{ fontSize:'1.5vw'}}/>
                    </Button>
                </Tooltip>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>

  );
};

export default DetalleCan;
