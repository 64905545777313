// import React, { useState } from 'react';
// import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// const apiUrl = 'http://192.168.20.93/zabbix/api_jsonrpc.php';

// const ProblemasTabla = () => {
//   const [problems, setProblems] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const authToken = localStorage.getItem('token');

//   const fetchProblems = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           jsonrpc: '2.0',
//           method: 'problem.get',
//           params: {
//             output: 'extend',
//             expandDescription: true,
//             expandData: true,
//             selectHosts: ['host'],
//             selectTags: 'extend',
//             selectAck: ['acknowledged'],
//           },
//           auth: authToken,
//           id: 1,
//         }),
//       });
//       console.log('response', response)
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();
//       console.log('data', data)

//       if (data.error) {
//         throw new Error(data.error.message);
//       }

//       setProblems(data.result);
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const formatDate = (timestamp) => {
//     const date = new Date(timestamp * 1000);
//     return date.toLocaleString();
//   };

//   const calculateDuration = (startTimestamp, endTimestamp) => {
//     const duration = (endTimestamp - startTimestamp) / 60; // Duration in minutes
//     return duration.toFixed(2) + ' minutes';
//   };

//   const isResolved = (r_eventid) => r_eventid !== '0' ? 'Resuelto' : 'No Resuelto';

//   const handleFetchProblems = () => {
//     fetchProblems();
//   };

//   return (
//     <div>
//       <Button variant="contained" color="primary" onClick={handleFetchProblems}>
//         Consultar Problemas
//       </Button>
//       {loading && <p>Cargando...</p>}
//       {error && <p>Error: {error}</p>}
//       <TableContainer component={Paper} style={{ marginTop: '20px' }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Tiempo</TableCell>
//               <TableCell>Severidad</TableCell>
//               <TableCell>Tiempo de Recuperación</TableCell>
//               <TableCell>Estado</TableCell>
//               <TableCell>Host</TableCell>
//               <TableCell>Problema</TableCell>
//               <TableCell>Duración</TableCell>
//               <TableCell>Ack</TableCell>
//               <TableCell>Acciones</TableCell>
//               <TableCell>Tags</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {problems.slice().reverse().map((problem) => (
//               <TableRow key={problem.eventid}>
//                 <TableCell>{formatDate(problem.clock)}</TableCell>
//                 <TableCell>{problem.severity}</TableCell>
//                 <TableCell>{ '-'}</TableCell>
//                 <TableCell>{isResolved(problem.r_eventid)}</TableCell>
//                 <TableCell>{'-'}</TableCell>
//                 <TableCell>{problem.name}</TableCell>
//                 <TableCell>{problem.r_eventid ? calculateDuration(problem.clock, problem.r_clock) : '-'}</TableCell>
//                 <TableCell>{problem.acknowledged === '1' ? 'Sí' : 'No'}</TableCell>
//                 <TableCell><Button>Acciones</Button></TableCell>
//                 <TableCell>
//                   {problem.tags.map(tag => `${tag.tag}: ${tag.value}`).join(', ')}
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </div>
//   );
// };

// export default ProblemasTabla;

import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const apiUrl = 'http://192.168.20.93/zabbix/api_jsonrpc.php';

const ProblemasTabla = () => {
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const authToken = localStorage.getItem('token');

  const fetchProblems = async (status) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          method: 'problem.get',
          params: {
            output: 'extend',
            expandDescription: true,
            expandData: true,
            selectHosts: ['host'],
            selectTags: 'extend',
            selectAck: ['acknowledged'],
            // No se puede usar "status" directamente con la API de problemas, se usa un valor diferente
            // Filtra solo problemas activos
            filter: status === '0' ? { status: '0' } : undefined,
          },
          auth: authToken,
          id: 1,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error.message);
      }

      setProblems(data.result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchResolvedProblems = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          method: 'event.get',
          params: {
            output: 'extend',
            select_acknowledges: 'extend',
            selectTags: 'extend',
            selectSuppressionData: 'extend',
            value: 0,  // 0 para eventos resueltos
            sortfield: ['clock'],
            sortorder: 'DESC',
            limit: 10,  // Ajusta según tus necesidades
          },
          auth: authToken,
          id: 6,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error.message);
      }

      setProblems(data.result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const calculateDuration = (startTimestamp, endTimestamp) => {
    const duration = (endTimestamp - startTimestamp) / 60; // Duration in minutes
    return duration.toFixed(2) + ' minutes';
  };

  const isResolved = (r_eventid) => r_eventid !== '0' ? 'Resuelto' : 'No Resuelto';

  const handleFetchProblems = (status) => {
    fetchProblems(status);
  };

  const handleFetchResolvedProblems = () => {
    fetchResolvedProblems();
  };
console.log('problems',problems)
  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => handleFetchProblems('0')}>
        Consultar Problemas Actuales
      </Button>
      <Button variant="contained" color="secondary" onClick={handleFetchResolvedProblems}>
        Consultar Problemas Resueltos
      </Button>
      <Button variant="contained" color="success" onClick={() => handleFetchProblems()}>
        Consultar Todos los Problemas
      </Button>
      {loading && <p>Cargando...</p>}
      {error && <p>Error: {error}</p>}
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tiempo</TableCell>
              <TableCell>Severidad</TableCell>
              <TableCell>Tiempo de Recuperación</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Host</TableCell>
              <TableCell>Problema</TableCell>
              <TableCell>Duración</TableCell>
              <TableCell>Ack</TableCell>
              <TableCell>Acciones</TableCell>
              <TableCell>Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {problems.slice().reverse().map((problem) => (
              <TableRow key={problem.eventid}>
                <TableCell>{formatDate(problem.clock)}</TableCell>
                <TableCell>{problem.severity}</TableCell>
                <TableCell>{problem.r_clock ? formatDate(problem.r_clock) : '-'}</TableCell>
                <TableCell>{isResolved(problem.r_eventid)}</TableCell>
                <TableCell>{problem.host ? problem.host.host : '-'}</TableCell>
                <TableCell>{problem.name}</TableCell>
                <TableCell>{problem.r_eventid ? calculateDuration(problem.clock, problem.r_clock) : '-'}</TableCell>
                <TableCell>{problem.acknowledged === '1' ? 'Sí' : 'No'}</TableCell>
                <TableCell><Button>Acciones</Button></TableCell>
                <TableCell>
                  {problem.tags.map(tag => `${tag.tag}: ${tag.value}`).join(', ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProblemasTabla;
