import React from 'react'
import { Grid } from '@mui/material'
import AppNanoox from '../../assets/img/APP-nanoox-1 (003).png'
import AppScapp from '../../assets/img/APP-scapp.png'
import AppPss from '../../assets/img/APP pss.png'
import AppEnviro from '../../assets/img/APP enviro.png'





const Base = () => {
  return (
    <Grid container justifyContent='center' style={{marginTop:'5vh',}}>
        <Grid container justifyContent='center' item xs={3}>
            <img src={AppScapp} style={{  height: "12vh", aspectRatio:'1'}}/>
        </Grid>
        <Grid container justifyContent='center'  item xs={3}>
            <img src={AppNanoox} style={{ height: "12vh", aspectRatio:'1',border:'solid 0.01vh rgb(255,255,255,0.2)', borderRadius:'3vh'}}/>
        </Grid>
        <Grid container justifyContent='center' item xs={3}>
            <img src={AppEnviro} style={{  height: "12vh", aspectRatio:'1', border:'solid 0.01vh rgb(255,255,255,0.2)', borderRadius:'3vh'}}/>
        </Grid>
        <Grid  container justifyContent='center' item xs={3}>
            <img src={AppPss} style={{ height: "12vh", aspectRatio:'1'}}/>
        </Grid>

    </Grid>
  )
}

export default Base