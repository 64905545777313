import React,{useState, useEffect} from 'react'
import { Grid, Alert, AlertTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { obtenerUmbrales } from '../../utils/Server_api';
import { styled } from '@mui/material/styles';


const BootstrapTooltip = styled(({ className, styleColor, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme, styleColor }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: styleColor || theme.palette.common.black,


    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: styleColor || theme.palette.common.black, // Usar styleColor o un color por defecto
      fontSize:'1vw',
      minWidth:'14vw',
      maxWidth:'17vw',
      height:'3vh'
    },
  }));

  

const Alertas = ({activeAlerts, setActiveAlerts, translate}) => {
    const [umbrales, setUmbrales] = useState(null)
    // //console.log('activeAlerts',activeAlerts)
    const sensorNames = {
        "O2": translate('oxigeno'),
        "DO2": translate('saturacion'),
        "Temp": translate('temperatura'),
        "Sal":  translate('salinidad')
    };
    
    const severityMap = {
        "C": "rgb(211, 47, 47,0.8)",
        "A": "rgb(253, 183, 70,0.8)",
        "B": "rgb(0, 128, 0,  0.8)"

    };

    const iconMapping = {
        'C': <ErrorOutlineIcon sx={{ fontSize:'2vh' }} />,
        'A': <WarningAmberIcon sx={{ fontSize: '2vh' }} />
    };

    const handleClose = (index, alerta) => {
        //console.log('alerta', alerta._id)
        // Crear una copia del estado actual de las alertas
        const updatedAlerts = [...activeAlerts];
        // Remover la alerta en el índice especificado
        updatedAlerts.splice(index, 1);
        // Actualizar el estado con la nueva lista de alertas

        const getCurrentFormattedDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
        };
    
        const formattedDateTime = getCurrentFormattedDateTime();
    
        // Crear el objeto con la estructura requerida
        const data = {
            _id: alerta._id,
            usuario_revision: localStorage.getItem('username'),
            fin: formattedDateTime
        };
        let centro_id = alerta.centro_id
        //console.log('data', data)

        // const respuesta = revisarAlertas(centro_id, data, false, setActiveAlerts , updatedAlerts, );
        // //console.log('respuesta', respuesta)
        setActiveAlerts(updatedAlerts);

    };

    const getUmbralAtencion = (sensor) => {
        switch (sensor) {
          case 'O2':
            return umbrales?.find(umbral => umbral.nombre === 'Oxígeno')?.atencion;
          case 'DO2':
            return umbrales?.find(umbral => umbral.nombre === 'Doxígeno')?.atencion;
          case 'SAL':
            return umbrales?.find(umbral => umbral.nombre === 'Salinidad')?.atencion;
          case 'TEMP':
            return umbrales?.find(umbral => umbral.nombre === 'Temperatura')?.atencion;
          default:
            return null;
        }
      };

    const getUmbralCritico = (sensor) => {
    switch (sensor) {
        case 'O2':
        return umbrales?.find(umbral => umbral.nombre === 'Oxígeno')?.critica;
        case 'DO2':
        return umbrales?.find(umbral => umbral.nombre === 'Doxígeno')?.critica;
        case 'SAL':
        return umbrales?.find(umbral => umbral.nombre === 'Salinidad')?.critica;
        case 'TEMP':
        return umbrales?.find(umbral => umbral.nombre === 'Temperatura')?.critica;
        default:
        return null;
    }
    };
        // console.log('activeAlerts', activeAlerts)

      const groupedAlerts = {
        "O2": [],
        "DO2": [],
        "Temp": [],
        "Sal": []
      };
  
      activeAlerts.filter(alerta => alerta.alerta !== 'B').forEach(alerta => {
          groupedAlerts[alerta.sensor]?.push(alerta);
      });
  


        useEffect(() => {
            const fetchUmbrales = async () => {
              try {
                const umbralesResult = await obtenerUmbrales();
                setUmbrales(umbralesResult);
                // console.log('umbralesResult',umbralesResult)
              } catch (error) {
                console.error('Error al obtener los umbrales:', error);
              }
            };
        
            fetchUmbrales();
          }, []); // El array vacío asegura que este efecto se ejecute solo una vez, cuando el componente se monta
        

  return (
    <Grid 
        container justifyContent="flex-start" 
        style={{
            position: 'absolute',
            marginTop: '4.5vh',
            marginLeft: '25vw',
            transform: 'translate(-50%, -50%)',
            width: '46.8vw',
            height:'5vh',
            // border: '0.1vh solid #fff',
            boxShadow: 24,
            p: 4,
            zIndex:1
        }}
    >
        {/* {activeAlerts?.filter(alerta => alerta.alerta !== 'B').map((alerta, index) => ( */}
        {Object.keys(groupedAlerts).map((sensor, index) => {
            const alerta = groupedAlerts[sensor]?.[groupedAlerts[sensor].length - 1];

            if (!alerta) return null;

            return (
            <BootstrapTooltip title={`${translate('fecha')}: ${alerta.fecha}`} styleColor={severityMap[alerta.alerta]}>
                <Grid 
                container justifyContent='center'
                style={{height:'5vh', width:'11.6vw', overflow:'hidden', borderRadius:'1vh', position:'relative'}}
                key={index} 
                backgroundColor={severityMap[alerta.alerta]}
                iconMapping={iconMapping}
                >
                    <Grid container justifyContent='center' alignContent='center' item xs={2}  style={{overflow:'hidden'}}>
                        {iconMapping[alerta.alerta]}
                    </Grid>
                    <Grid container justifyContent='center' alignContent='center' item xs={8} style={{overflow:'hidden'}}>
                        <Grid container justifyContent='center' style={{fontSize:'1.5vh', marginTop:'0vh',height:'2vh', overflowY:'hidden'}}>
                            {sensorNames[alerta.sensor]}: {alerta.valor.toFixed(1)} 
                        </Grid>
                        {alerta.alerta === 'C' ? (
                            <Grid container justifyContent='center' style={{fontSize:'1.5vh', marginTop:'0vh',height:'2vh', overflowY:'hidden'}}>
                                {translate('umbral_c')}: {getUmbralCritico(alerta.sensor)}
                            </Grid>
                        ): alerta.alerta === 'A' && (
                            <Grid container justifyContent='center' style={{fontSize:'1.5vh', marginTop:'0vh',height:'2vh', overflow:'hidden'}}>
                                {translate('umbral_a')}: {getUmbralAtencion(alerta.sensor)}
                            </Grid>
                        )}
                    </Grid>
                    <Grid container alignContent='center' item xs={2}>
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => handleClose(index, alerta)}
                        style={{margiLeft:'1vw'}}
                        >
                            <CloseIcon style={{fontSize:'1.8vh'}}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </BootstrapTooltip>
        // ))}
        );
      })}
    </Grid>
  )
}

export default Alertas
// import React, { useState, useEffect } from 'react';
// import { Grid, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import { obtenerUmbrales } from '../../utils/Server_api';
// import { styled } from '@mui/material/styles';

// const BootstrapTooltip = styled(({ className, styleColor, ...props }) => (
//     <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme, styleColor }) => ({
//     [`& .${tooltipClasses.arrow}`]: {
//         color: styleColor || theme.palette.common.black,
//     },
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: styleColor || theme.palette.common.black,
//         fontSize: '1vw',
//         minWidth: '14vw',
//         maxWidth: '17vw',
//         height: '3vh'
//     },
// }));

// const Alertas = ({ activeAlerts, setActiveAlerts, translate }) => {
//     const [umbrales, setUmbrales] = useState(null);

//     const sensorNames = {
//         "O2": "Oxígeno",
//         "DO2": "Saturación",
//         "Temp": "Temperatura",
//         "Sal": "Salinidad"
//     };

//     const severityMap = {
//         "C": "rgb(211, 47, 47,0.8)",
//         "A": "rgb(253, 183, 70,0.8)",
//         "B": "rgb(0, 128, 0, 0.8)"
//     };

//     const iconMapping = {
//         'C': <ErrorOutlineIcon sx={{ fontSize: '2vh' }} />,
//         'A': <WarningAmberIcon sx={{ fontSize: '2vh' }} />
//     };

//     const handleClose = (index, alerta) => {
//         const updatedAlerts = [...activeAlerts];
//         updatedAlerts.splice(index, 1);
//         setActiveAlerts(updatedAlerts);
//     };

//     const getUmbralAtencion = (sensor) => {
//         switch (sensor) {
//             case 'O2':
//                 return umbrales?.find(umbral => umbral.nombre === 'Oxígeno')?.atencion;
//             case 'DO2':
//                 return umbrales?.find(umbral => umbral.nombre === 'Doxígeno')?.atencion;
//             case 'SAL':
//                 return umbrales?.find(umbral => umbral.nombre === 'Salinidad')?.atencion;
//             case 'TEMP':
//                 return umbrales?.find(umbral => umbral.nombre === 'Temperatura')?.atencion;
//             default:
//                 return null;
//         }
//     };

//     const getUmbralCritico = (sensor) => {
//         switch (sensor) {
//             case 'O2':
//                 return umbrales?.find(umbral => umbral.nombre === 'Oxígeno')?.critica;
//             case 'DO2':
//                 return umbrales?.find(umbral => umbral.nombre === 'Doxígeno')?.critica;
//             case 'SAL':
//                 return umbrales?.find(umbral => umbral.nombre === 'Salinidad')?.critica;
//             case 'TEMP':
//                 return umbrales?.find(umbral => umbral.nombre === 'Temperatura')?.critica;
//             default:
//                 return null;
//         }
//     };

//     useEffect(() => {
//         const fetchUmbrales = async () => {
//             try {
//                 const umbralesResult = await obtenerUmbrales();
//                 setUmbrales(umbralesResult);
//             } catch (error) {
//                 console.error('Error al obtener los umbrales:', error);
//             }
//         };
//         fetchUmbrales();
//     }, []);

//     const sortedAlerts = activeAlerts
//         ?.filter(alerta => alerta.alerta !== 'B')
//         .sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

//     return (
//         <Grid
//             container
//             justifyContent="flex-start"
//             style={{
//                 position: 'relative',
//                 marginTop: '4.5vh',
//                 // marginLeft: '25vw',
//                 width: '46.8vw',
//                 height: '5vh',
//                 boxShadow: 24,
//                 p: 4,
//             }}
//         >
//             {['O2', 'DO2', 'Temp', 'Sal'].map((sensorType) => (
//                 <Grid container justifyContent={'flex-start'} key={sensorType} style={{ position: 'relative', width: '11.6vw' }}>
//                     {sortedAlerts
//                         ?.filter(alerta => alerta.sensor === sensorType)
//                         .map((alerta, index) => (
//                           <BootstrapTooltip title={`${translate('fecha')}: ${alerta.fecha}`} styleColor={severityMap[alerta.alerta]}>
//                                      <Grid 
//                                           container justifyContent='center'
//                                           style={{height:'5vh', width:'11.6vw', overflowY:'hidden', borderRadius:'1vh', position:'absolute'}}
//                                           key={index} 
//                                           backgroundColor={severityMap[alerta.alerta]}
//                                           iconMapping={iconMapping}
//                                           >
//                                               <Grid container justifyContent='center' alignContent='center' item xs={2}>
//                                                   {iconMapping[alerta.alerta]}
//                                               </Grid>
//                                               <Grid container justifyContent='center' alignContent='center' item xs={8}>
//                                                   <Grid container justifyContent='center' style={{fontSize:'1.5vh', marginTop:'0vh',height:'2vh', overflowY:'hidden'}}>
//                                                       {sensorNames[alerta.sensor]}: {alerta.valor.toFixed(1)} 
//                                                   </Grid>
//                                                   {alerta.alerta === 'C' ? (
//                                                       <Grid container justifyContent='center' style={{fontSize:'1.5vh', marginTop:'0vh',height:'2vh', overflowY:'hidden'}}>
//                                                           Umbral Critico:{getUmbralCritico(alerta.sensor)}
//                                                       </Grid>
//                                                   ): alerta.alerta === 'A' && (
//                                                       <Grid container justifyContent='center' style={{fontSize:'1.5vh', marginTop:'0vh',height:'2vh', overflowY:'hidden'}}>
//                                                           Umbral Atención:{getUmbralAtencion(alerta.sensor)}
//                                                       </Grid>
//                                                   )}
//                                               </Grid>
//                                               <Grid container alignContent='center' item xs={2}>
//                                                   <IconButton
//                                                   aria-label="close"
//                                                   color="inherit"
//                                                   size="small"
//                                                   onClick={() => handleClose(index, alerta)}
//                                                   style={{margiLeft:'1vw'}}
//                                                   >
//                                                       <CloseIcon style={{fontSize:'1.8vh'}}/>
//                                                   </IconButton>
//                                               </Grid>
//                                           </Grid>
//                                       </BootstrapTooltip>
//                         ))}
//                 </Grid>
//             ))}
//         </Grid>
//     );
// }

// export default Alertas;
