import React from 'react'
import {Divider, Grid, IconButton} from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PublicIcon from '@mui/icons-material/Public';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LogoutIcon from '@mui/icons-material/Logout';
import AppsIcon from '@mui/icons-material/Apps';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

const severityMap = {
    "C": "rgb(211, 47, 47)",
    "A": "rgb(253, 183, 70)",
    "B": "rgb(0, 128, 0)"
};

const DrawerList = ({
    consolidado_estados,
    showOptions, 
    handleHome,
    styleFont,
    translate,
    setShowRegions,
    handleExit,
    showRegions,
    selectedRegion,
    capitalize,
    showAreas,
    handleAreaClick,
    selectedArea,
    posicionArea,
    setPosicionArea,
    selectedCentro,
    handleCentroClick,
    handleRegionClick,
    setNameCentro,
    navigate,
    setShowAreas,
    open,
    setMostrarAlertas,
    mostrarAlertas
}) => {
  return (

        <Grid container style={{ overflowY:'auto',maxHeight:'74vh', }} >
            {showOptions && (
                <Grid container style={{backgroundColor:'#252525'}}>
                    <Grid onClick={handleHome} container style={{backgroundColor:'#252525',marginTop:'1vh'}}>
                            <Grid container justifyContent='center'  >
                                    {/* boton para selecicionar ciclos */}
                                    <Grid container justifyContent='center' item xs={11}
                                        sx={{
                                            height: '5vh',
                                            width:'12vw',
                                            borderRadius:'1vh',
                                            justifyContent: 'initial',
                                            px: 1,
                                            fontSize: "1.5vh", 
                                            '&:hover': {
                                                backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                            },
                                        }}
                                    >
                                        {/* icono de las regiones */}
                                        <Grid  container justifyContent='center' item xs={2} alignContent='center'>
                                            <AppsIcon style={{fontSize:styleFont}} />
                                        </Grid>
                                        {/* gestionar ciclos */} 
                                        <Grid  item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                            <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}>  Home</p>
                                        </Grid>
                                    </Grid>
                            </Grid>
                    </Grid>
                    <Grid onClick={() => {window.location.href='/PSS Servicio SLA'}}  container style={{backgroundColor:'#252525',marginTop:'1vh'}}>
                            <Grid container justifyContent='center'  >
                                    {/* boton para selecicionar ciclos */}
                                    <Grid container justifyContent='center' item xs={11}
                                        sx={{
                                            height: '5vh',
                                            width:'12vw',
                                            borderRadius:'1vh',
                                            justifyContent: 'initial',
                                            px: 1,
                                            fontSize: "1.5vh", 
                                            '&:hover': {
                                                backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                            },
                                        }}
                                    >
                                        {/* icono de las regiones */}
                                        <Grid  container justifyContent='center' item xs={2} alignContent='center'>
                                            <TimerOutlinedIcon style={{fontSize:styleFont}} />
                                        </Grid>
                                        {/* gestionar ciclos */} 
                                        <Grid  item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                            <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}> SLA </p>
                                        </Grid>
                                    </Grid>
                            </Grid>
                    </Grid>
                    <Grid onClick={handleExit} container justifyContent='center' alignContent='center' style={{  borderBottom: '0.1vh solid #3f3f3f',height: '6vh',backgroundColor:'#252525',}}>
                            <Grid container justifyContent='center'  >
                                    {/* boton cerrar sesion */}
                                    <Grid container justifyContent='center' item xs={11}
                                        sx={{
                                            height: '5vh',
                                            width:'12vw',
                                            borderRadius:'1vh',
                                            justifyContent: 'initial',
                                            px: 1,
                                            fontSize: "1.5vh", 
                                            '&:hover': {
                                                backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                            },
                                        }}
                                    >
                                        {/* icono de las regiones */}
                                        <Grid container justifyContent='center' item xs={2} alignContent='center'>
                                            <LogoutIcon style={{fontSize:styleFont}} />
                                        </Grid>
                                        {/* nombre de las regiones */} 
                                        <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                            <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}>  {translate('cerrar_sesion')}    </p>
                                        </Grid>
                                    </Grid>
                            </Grid>
                    </Grid>
                </Grid>
            )}
            {/* Texto para las Regiones y boton para ocultar las regiones */}
            <Grid container >
                {/* <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}></p> */}
                <p style={{ color: 'white', fontSize: styleFont, marginLeft: '0.4vw' }}>
                    {translate('regions')}
                    <IconButton
                        onClick={() => setShowRegions(!showRegions)}
                        disabled={selectedRegion===null}
                        style={{ color: 'white' }}
                    >
                        {showRegions ? <ExpandLessIcon  style={{fontSize:styleFont}}  /> : <ExpandMoreIcon  style={{fontSize:styleFont}} />}
                    </IconButton>
                </p>
            </Grid>
            {/* Region seleccionada al ocultar las demas regiones */}
            {showRegions === false &&
            <Grid container justifyContent='center'  >
                {/* boton para selecicionar regiones */}
                <Grid container justifyContent='center' item xs={11}
                    sx={{
                        height: '5vh',
                        width:'12vw',
                        borderRadius:'1vh',
                        justifyContent: 'initial',
                        px: 1,
                        fontSize: "1.5vh", // Ajuste del tamaño del texto para centros
                        backgroundColor:  'rgb(232, 78, 14,0.6)',
                    }}
                >
                    {/* icono de las regiones */}
                    <Grid container justifyContent='center' item xs={2} alignContent='center' style={{backgroundColor: severityMap[selectedRegion?.statusRegions], height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh' }}>
                        <PublicIcon style={{fontSize:styleFont}} />
                    </Grid>
                    {/* nombre de las regiones */} 
                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                        <p sx={{ opacity: open ? 1 : 0, fontSize: '1.2vh', }}>{selectedRegion && capitalize(selectedRegion.name)}</p>
                    </Grid>
                </Grid>
            </Grid>
    
}
            {/* mapeo de Regiones */}
            {showRegions &&
                consolidado_estados?.map((region, index) => (
                    <Grid container key={index} style={{ marginTop:'1vh'}} >
                            <Grid container justifyContent='center'  >
                                    {/* boton para selecicionar regiones */}
                                        <Grid container justifyContent='center'   item xs={12}
                                            sx={{
                                                height: '5vh',
                                                width:'12vw',
                                                borderRadius:'1vh',
                                                justifyContent: 'initial',
                                                px: 1,
                                                fontSize: "1.5vh", // Ajuste del tamaño del texto para centros
                                                '&:hover': {
                                                    backgroundColor: 'rgb(255, 120, 60)', // puedes cambiar el color y grosor del borde a tu gusto
                                                },
                                                backgroundColor:  (selectedRegion && selectedRegion.clientId ===  region.clientId) && (selectedRegion && selectedRegion.name ===  region.name) ? 'rgb(232, 78, 14,0.6)' : '#3f3f3f',
                                            }}
                                            // onClick={() => handleRegionClick(region)}
                                            onClick={() => {
                                                handleRegionClick(region);
                                                navigate(`/Nanoox/${region.name}`);
                                                localStorage.setItem('REGION', region);
                                            }}
                                        >
                                            {/* icono de las regiones */}
                                            <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[region.statusRegions] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                                                <PublicIcon style={{fontSize:styleFont}} />
                                            </Grid>
                                            {/* nombre de las regiones */} 
                                            <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                <p sx={{ opacity: open ? 1 : 0, color: selectedRegion === region && 'white', fontSize: '1.2vh', }}>{capitalize(region.name)}</p>
                                            </Grid>
                                        </Grid>
                            </Grid>
                    </Grid>
                ))
            }
            {/* Texto para las áreas y boton para ocultar las areas */}
            <Grid container  style={{overflowY:'hidden', }} >
                {/* <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}></p> */}
                <p style={{ color: 'white', fontSize: styleFont, marginLeft: '0.4vw' }}>
                    {translate('areas')}
                    <IconButton
                        onClick={() => setShowAreas(!showAreas)}
                        disabled={selectedArea===null}
                        style={{ color: 'white' }}
                    >
                        {showAreas ? <ExpandLessIcon  style={{fontSize:styleFont}}  /> : < ExpandMoreIcon  style={{fontSize:styleFont}} />}
                    </IconButton>
                </p>
            </Grid>
            {/* Area seleccionada al ocultar las demas areas */}
            {showAreas === false &&
                <Grid container justifyContent='center'>
                {/* boton para seleccionar areas */}
                <Grid container justifyContent='center' item xs={11}
                    sx={{
                        height: '5vh',
                        width:'12vw',
                        borderRadius:'1vh',
                        justifyContent: 'initial',
                        px: 1,
                        fontSize: "1.5vh",
                        backgroundColor: 'rgb(255, 120, 60, 0.6)',
                        marginTop:'0.5vh',
                    }}
                >
                    {/* icono de la areas */}
                    
                                
                    <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[selectedRegion?.areas?.[posicionArea]?.statusArea] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>       
                        <TravelExploreIcon  style={{fontSize:styleFont}}/>
                    </Grid>
                    {/* nombre de la area seleccionada */}
                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                        <p sx={{ opacity: open ? 1 : 0, fontSize: '1.2vh', }}>{capitalize(selectedArea)}</p>
                    </Grid>
                </Grid>
            </Grid>
            }
            {/* mapeo de Areas */}
            <Grid container   style={{overflowY:'hidden', }}>
                { showAreas && selectedRegion && selectedRegion.areas.map((area, index) => (
                    <Grid  key={index} container justifyContent='center' style={{marginTop:'1vh'}}>
                    {/* boton para seleccionar areas */}
                    {/* <Badge badgeContent={ <TravelExploreIcon  style={{fontSize:styleFont}}/>} 
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            sx={{marginLeft:'0vw', '& .MuiBadge-badge': 
                            { backgroundColor: severityMap[area.statusArea], height:'3vh', width:'1.5vw', 
                            marginTop:'0.8vh', marginRight:'0.1vw', fontSize:'1.8vh' }  }}> */}
                        <Grid container justifyContent='center' item xs={12}
                            sx={{
                                height: '5vh',
                                width:'12vw',
                                borderRadius:'1vh',
                                justifyContent: 'initial',
                                px: 1,
                                fontSize: "1.5vh",
                                '&:hover': {
                                    backgroundColor: 'rgb(247, 130, 78)', // puedes cambiar el color y grosor del borde a tu gusto
                                },
                                backgroundColor: selectedArea === area.name ? 'rgb(255, 120, 60, 0.6)' : '#3f3f3f',
                                // marginTop:'1vh',
                            }}
                            // onClick={() => handleAreaClick(area)}
                            // onClick={() => {handleAreaClick(area);
                            //     localStorage.setItem('AREA', area.name)
                            //     navigate(`/Enviro/${selectedRegion.name}/${area.name}`);
                            
                            onClick={() => {handleAreaClick(area);
                                localStorage.setItem('AREA', area.name)
                                navigate(`/Nanoox/${selectedRegion.name}/${selectedArea}`);
                                    setPosicionArea(index)
                                }}
                        >
                        
                        <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[area.statusArea] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                            <TravelExploreIcon  style={{fontSize:styleFont}}/>
                        </Grid>
                        <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                            <p sx={{ opacity: open ? 1 : 0, color: selectedArea === area.name && 'white', fontSize: '1.2vh',  }}>{capitalize(area.name)}</p>
                        </Grid>
                        </Grid>
                    {/* </Badge> */}
                {/* <Divider style={{ color: "white", backgroundColor: "#2f2f2f", height: "0.01vh", width:'14.9vw' }} /> */}
            </Grid>
                ))}
            </Grid>
            {/* Texto para los centros */}
            <Grid container >
                <p style={{ color: 'white', fontSize: styleFont, marginLeft: '0.2vw' }}>{translate('centers')}</p>
            </Grid>
            <Divider style={{ color: "white", backgroundColor: "#2f2f2f", height: "0.01vh"}} />
            {/* mapeo de Centros */}
            <Grid container  style={{overflowY:'hidden', }} >
            { selectedRegion && selectedArea && selectedRegion.areas.map((area, index) => (
                selectedArea === area.name && (
                    area.centers.map((centro, index) => (
                    <Grid  key={index} container justifyContent='center' style={{marginTop:'1vh'}}>
                                {/* boton para seleccionar areas */}
                                {/* <Badge badgeContent={ <TravelExploreIcon  style={{fontSize:styleFont}}/>} 
                                        anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                        sx={{marginLeft:'0vw', '& .MuiBadge-badge': 
                                        { backgroundColor: severityMap[area.statusArea], height:'3vh', width:'1.5vw', 
                                        marginTop:'0.8vh', marginRight:'0.1vw', fontSize:'1.8vh' }  }}> */}
                                    <Grid container justifyContent='center' item xs={12}
                                        sx={{
                                            height: '5vh',
                                            width:'12vw',
                                            borderRadius:'1vh',
                                            justifyContent: 'initial',
                                            px: 1,
                                            fontSize: "1.5vh",
                                            '&:hover': {
                                                backgroundColor: 'rgb(247, 130, 78)', // puedes cambiar el color y grosor del borde a tu gusto
                                            },
                                            backgroundColor: selectedCentro === centro ? 'rgb(255, 120, 60, 0.6)' : '#3f3f3f',
                                            // marginTop:'1vh',
                                        }}
                                        // onClick={() => handleAreaClick(area)}
                                        onClick={() => {
                                            handleCentroClick(centro.name);
                                            setNameCentro(centro.name);
                                            navigate(`/Nanoox/${selectedRegion.name}/${selectedArea}/${centro.name}`);
                                            localStorage.setItem('CENTRO',centro.name)
                                        }}
                                    >
                                    
                                    <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[centro.statusCenter] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                                        <FmdGoodIcon  style={{fontSize:styleFont}}/>
                                    </Grid>
                                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                        <p sx={{ opacity: open ? 1 : 0, color: selectedCentro === centro.name && 'white', fontSize: '1.2vh',  }}>{capitalize(centro.name)}</p>
                                    </Grid>
                                    </Grid>
                                {/* </Badge> */}
                            {/* <Divider style={{ color: "white", backgroundColor: "#2f2f2f", height: "0.01vh", width:'14.9vw' }} /> */}
                        </Grid>
                )))
            ))}

            </Grid>
        </Grid>

    );
}

export default DrawerList