import React from 'react'
import { Menu, MenuItem } from '@mui/material'

const Traductor = ({handleLanguageChange, openMenuIdioma, handleCloseIdioma}) => {
  return (
    <Menu
        anchorEl={openMenuIdioma}
        open={Boolean(openMenuIdioma)}
        onClose={handleCloseIdioma}
    >
        <MenuItem onClick={() => handleLanguageChange('es')}>Español</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('no')}>Noruego</MenuItem> {/* Noruego */}
        <MenuItem onClick={() => handleLanguageChange('jp')}>Japonés</MenuItem> {/* Japonés */}
        <MenuItem onClick={() => handleLanguageChange('zh')}>Chino Mandarín</MenuItem> {/* Chino Mandarín */}
        <MenuItem onClick={() => handleLanguageChange('fr')}>Francés</MenuItem> {/* Francés */}
        <MenuItem onClick={() => handleLanguageChange('ar')}>Árabe</MenuItem> {/* Árabe */}
        <MenuItem onClick={() => handleLanguageChange('ru')}>Ruso</MenuItem> {/* Ruso */}
        <MenuItem onClick={() => handleLanguageChange('pt')}>Portugués</MenuItem> {/* Portugués */}
        {/* Agrega más idiomas según sea necesario */}
    </Menu>
  )
}

export default Traductor