import React, { useState, useEffect } from 'react';
import translations from './translations.json';

const LanguageProvider = ({ children }) => {
  const storedLanguage = localStorage.getItem('selectedLanguage');
  const defaultLanguage = storedLanguage && translations[storedLanguage] ? storedLanguage : 'en'; // Idioma por defecto

  const [language, setLanguage] = useState(defaultLanguage);

  const translate = (key) => {
    return translations[language]?.[key] || key; // Uso de encadenamiento opcional
  };

  const changeLanguage = (newLanguage) => {
    if (translations[newLanguage]) {
      setLanguage(newLanguage);
    } else {
      console.warn(`Language ${newLanguage} not found in translations.`);
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage && translations[savedLanguage]) {
      setLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedLanguage', language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ translate, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageContext = React.createContext();

export default LanguageProvider;
