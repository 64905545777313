const LOCAL_SERVER_URL =`${localStorage.getItem("serverName")}` //"http://192.168.20.145:4024";


export async function _SERVER_URL() {
    const maxAttempts = 3;
    let attempts = 0;

    while (attempts < maxAttempts) {
        try {
            const controller = new AbortController();
            const signal = controller.signal;
            const timeout = setTimeout(() => {
                controller.abort();
            }, 5000);

            const URL_EMPRESA = LOCAL_SERVER_URL; // Modifica esta línea con la URL que deseas usar
            const ENDPOINT_TEST = 'check_server_status';

            const publicResponse = await fetch(`${URL_EMPRESA}/${ENDPOINT_TEST}`, { method: 'HEAD', signal, keepalive: false });
            clearTimeout(timeout);
            
            if (publicResponse && publicResponse.ok) {
                return URL_EMPRESA;
            }
        } catch (error) {
            console.error('Error al intentar obtener la URL del servidor:', error);
        }
        
        attempts++;
    }

    console.error('No se pudo obtener la URL del servidor después de varios intentos.');
    // Puedes lanzar una excepción, devolver un valor predeterminado o manejar el error de otra manera aquí
}

// ? METODO LOGIN
// Asegúrate de que esta línea esté fuera de cualquier función para que sea accesible en todo el módulo.
const SERVER_URL = await LOCAL_SERVER_URL;
// console.log('SERVER_URL ', SERVER_URL);

// ? METODO LOGIN
export async function LoginUsuario({ input_username, input_password, input_email, fileContent }) {
    // console.log("entro en la funcion del login", "/", "usuario: ", input_username, "/", "contraseña: ", input_password);
    // fileContent : url del servidor
    // Validar si SERVER_URL es null
    if (!fileContent) {
        console.error("SERVER_URL es null. No se puede hacer la petición.");
        return false;
    }

    let username = input_username;
    let password = input_password;

    try {

        console.log('SERVER_URL ', fileContent);
        console.log('solicitando login en ', `${fileContent}/login`);

        const response = await fetch(`${fileContent}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password,
            }),
            keepalive: false,
        });

        const data = await response.json();
        console.log("Respuesta del servidor:", data);
        // localStorage.setItem('Respuesta del servidor', data);

        if (!response.ok) {
            throw new Error(data.mensaje || "Error al iniciar sesión");
        }

        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('username', data.username);
        localStorage.setItem('cargo', data.cargo);


        console.log('%c CONEXIÓN EXITOSA','color:green; font-size:5vh')
        window.location.href = "/home";
        return true;

    } catch (error) {
        console.error("Hubo un error al iniciar sesión:", error);
        return false;
    }
}


// ? METODO SESSION EXPIRED

export async function ExpiredSession({fileContent, setSessionExpired}){
    const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        setSessionExpired(true);
        return;
      }

      const response = await fetch(`${LOCAL_SERVER_URL}/validate_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        keepalive: true,
      });

      console.log('respons ExpiredSessione',response)

      if (response.status === 401) {
        setSessionExpired(true);
      }
}


// ? METODO DE refreshToken
export async function refreshToken() {
    let SERVER_URL = await _SERVER_URL();
    const refreshToken = localStorage.getItem("refresh_token");
    try {
        const response = await fetch(`${SERVER_URL}/token/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refresh_token: refreshToken
            }),
            keepalive: false
        });
        const data = await response.json();
        if (data.access_token) {
            localStorage.setItem("access_token", data.access_token);
            console.log('token refrescado exitosamente');
            return data.access_token;
        } else {
            console.log('Eliminando credenciales');
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            throw new Error(data.message || "Error al refrescar el token");
        }
    } catch (error) {
        console.error("Hubo un error al refrescar el token:", error);
        return null;
    }
}

// ? METODO DE CERRAR SESION
export function cerrarSesion(setIsAuthenticated) {
    try {
        console.log('Eliminando credenciales');
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        setIsAuthenticated(false);
        return true;
    } catch (error) {
        console.error("Error cerrando sesión:", error);
        return null;
    }
}

// ? Función de consulta de centros
export async function get_center() {
    // console.log("obteniendo zonas  usuario");

    try {
        let SERVER_URL = await _SERVER_URL();

        const access_token = localStorage.getItem('access_token');
        const response = await fetch(`${SERVER_URL}/get_centers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            keepalive: false
        });

        const data = await response.json();

        // console.log('Respuesta completa:', response);

        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando acceder al centro");
        }

        return data;
    } catch (error) {
        console.error('Error de red:', error);
        throw error;
    }
}

// ? Función de consulta de datos del centro
export async function get_data(region, area, centro) {
    // console.log("obteniendo zonas usuario");

    try {
        let SERVER_URL = await _SERVER_URL();
        // localStorage.setItem('refresh_token', data.refresh_token);
        let data;

        const response = await fetch(`${SERVER_URL}/get_data/${region}/${area}/${centro}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            keepalive: false
        });

        data = await response.json();

        // console.log('Respuesta completa:', response);

        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando acceder al centro");
        }

        return data;
    } catch (error) {
        console.error('Error de red:', error);
        throw error;
    }
}