import React from 'react';
import { Grid } from '@mui/material';


const Fallas = ({fallaSeleccionada, translate}) => {
    const getSensorName = (sensor) => {
    switch(sensor) {
        case 'device_com': return translate('device_com_falla');
        case 'sensor_com': return translate('sensor_com_falla');
        case 'device': return translate('device_falla');
        case 'sensor': return translate('sensor_falla');
    default: return sensor;
    }
    };

    const getSensorFallaEstable = (falla) => {
      switch(falla) {
        case 'device_com': return translate('device_com_falla_e');
        case 'sensor_com': return translate('sensor_com_falla_e');
        case 'device': return translate('device_falla_e');
        case 'sensor': return translate('sensor_falla_e');
        default: return falla;
      }
    };

    const getBackgroundColor = (alerta) => {
      switch (alerta) {
      case false:
      return '#ac2929';
      case true:
      return 'green';
      default:
      return 'transparent';
      }
      };
    

    const [año, mes, dia, hora ] = fallaSeleccionada?.fecha?.split('-') ? fallaSeleccionada?.fecha?.split('-') : '';
    const [modulo, jaula, bomba,can,sensor] = fallaSeleccionada?.variable?.split('-') ? fallaSeleccionada?.variable?.split('-') : '';

    if (!fallaSeleccionada) {
        return (
        <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} 
            style={{backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000', fontSize:'1vw', color:'#ffffff', marginBottom:'1vh',  height:'6vh'}}>
            <strong>{translate('no_alerta_seleccionada')}</strong>
        </Grid>
        ) //<p>No hay alerta seleccionada</p>;
    }
    
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} style={{backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000'}}>
      <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={9} style={{fontSize:'1vw', color:'#ffffff', marginBottom:'-2vh',  height:'6vh'}}>
        <strong>{translate('detalles_falla')}</strong>
        {/* Detalles de la Alerta Seleccionada */}
      </Grid>
      <Grid container justifyContent={'center'} alignContent={'flex-start'} item xs={11} style={{fontSize:'0.75vw', height:'3vh'}}>
        <Grid container justifyContent={'center'} alignContent={'center'} style={{borderRadius:'0.2vw', height:'2vh',backgroundColor:getBackgroundColor(fallaSeleccionada.alerta)}}>
        { fallaSeleccionada?.alerta ? getSensorFallaEstable(sensor) : getSensorName(sensor)} 
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('fecha')}:
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {dia} - {mes} - {año}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('hora')}:
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {hora}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('modulo')}:
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {modulo}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('jaula')}:
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.8vw', height:'3vh'}}>
          {jaula}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Fallas