import React, { useState, useEffect } from 'react';
import { Card, Grid, Button, IconButton, Tooltip, /* Pagination, Stack */ } from '@mui/material';
import Battery from './utils/Bateria/Batery';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AmbientalLoader from './utils/AmbientalLoader';
import ModalBattery from '../components/ModalComponents/ModalBattery'

import { aplicarSubmuestreo, generarNewLocalCenterData, changeToNextDepth, changeToPreviousDepth, handle_sensor_states } from './utils/HomeUtils'
import { fetchAlertas } from '../utils/Server_api';


const Home = ({
    alertas,
    setAlertas,
    batch_data,          // Tipo: boolean
    centerData,          // Tipo: array
    localCenterData,     // Tipo: array
    setLocalCenterData,  // Tipo: function
    lastTime,            // Tipo: number
    setLastTime,         // Tipo: function
    profundidadesPerPage,// Tipo: number -> [1, 2, 3, 4] - profundidades
    currentPageStation,  // Tipo: number
    currentView,         // Tipo: string -> [x1, x2, x4] - estaciones
    itemsPerPage,        // Tipo: number
    depthComponentMap,   // Tipo: object
    noDataAvailable,     // Tipo: boolean
    pauseRequest,        // Tipo: boolean
    setPauseRequest,     // Tipo: function
    translate            // Tipo: function
}) => {


    // const [alertas, setAlertas] = useState([]);
//console.log('alertas',alertas)
    //? Descripción: Estado que gestiona la página actual de profundidades para cada estación.
    const [currentPageDepths, setCurrentPageDepths] = useState([]);
    //? Descripción: Estado que gestiona la visibilidad de las series de datos para cada profundidad y estación.
    const [depthVisibility, setDepthVisibility] = useState([]);
    //? Descripción: Estado que gestiona la apertura y cierre de los modales de batería para cada estación.
    const [openModalBatteries, setOpenModalBatteries] = useState([]);

    //* ------------------------------- startIndexForCurrentPage ------------------------------------
    //? Descripción: Representa el índice del último elemento que se mostrará en la página actual.
    //? Se calcula multiplicando el número de la página actual (currentPageStation) por la cantidad de elementos por página (itemsPerPage).
    // * Salto en bloques:
    // const startIndexForCurrentPage = currentPageStation * itemsPerPage;
    // * Salto de "uno en uno"
    const startIndexForCurrentPage = currentPageStation ; 


    //* ------------------------------- endIndexForCurrentPage --------------------------------------
    //? Descripción: Representa el índice del primer elemento que se mostrará en la página actual.
    //? Se calcula sumando la cantidad de elementos por página (itemsPerPage) al índice del último elemento.
    // * Salto en bloques:
    // const endIndexForCurrentPage = (currentPageStation + 1) * itemsPerPage;
    // * Salto de "uno en uno"
    const endIndexForCurrentPage = startIndexForCurrentPage + itemsPerPage;   


    //* ------------------------------- ComponentToRender ----------------------------------------------
    //? Descripción: Estado que determina el componente a renderizar según la vista y la cantidad de profundidades por página.
    const ComponentToRender = depthComponentMap[currentView][profundidadesPerPage];

    //* ------------------------------- Estado: cardStyle -----------------------------------------------------
    //? Descripción: Estado que define el estilo de la tarjeta general según la vista actual.
    const cardStyle =
        currentView === 'x1'
            ? { width: '98vw', height: '90vh', backgroundColor: 'black', marginTop: '-1vh' }
            : currentView === 'x2'
                // ? { width: '49.5vw', height: '91vh', backgroundColor: 'black', marginTop: '1vh' }
                ? { width: '98vw', height: '91vh', backgroundColor: 'black', marginRight: '0.2vw', marginLeft: '0.2vw' }
                : { width: '90vw', height: '45.6vh', backgroundColor: 'black', marginTop: '0.5vh', marginRight: '0.2vw', marginLeft: '0.2vw' };
    //* ------------------------------- Estado: profButtonStyle ------------------------------------------------
    //? Descripción: Estado que define el estilo de los botones de cambio de profundidad.
    const profButtonStyle = { fontSize: "1.2vh", width: "2vw", height: "4vh", marginTop: "6vh" }

    //* ------------------------------- conditionalGrid --------------------------------------------
    //? Descripción: Calcula el número de columnas en la cuadrícula de tarjetas según la vista actual.
    const conditionalGrid = (currentView === 'x1' ? 12 : 6);

    //* ------------------------------- marginLeftTitle ----------------------------------------------
    //? Descripción: Variable que determina el margen izquierdo del título según la vista y la cantidad de profundidades por página.
    let marginLeftTitle;

    if (currentView === 'x1') {
        marginLeftTitle = profundidadesPerPage === 1
            ? '-8vw'
            : profundidadesPerPage === 2
                ? '-35vw'
                : profundidadesPerPage === 3
                    ? '-8vw'
                    : null; // Puedes manejar un valor predeterminado o null según tus necesidades
    } else if (currentView === 'x2') {
        marginLeftTitle = profundidadesPerPage === 1
            ? '-4vw'
            : profundidadesPerPage === 2
                ? '-16vw'
                : profundidadesPerPage === 3
                    ? '-12vw'
                    : '-4.5vw';
    } else if (currentView === 'x4') {
        marginLeftTitle = profundidadesPerPage === 1
            ? '-16vw'
            : '-21vw';
    } else {
        // Manejar cualquier otro caso o asignar un valor predeterminado si es necesario
        marginLeftTitle = '1vw';
    }


    // -- Funciones -- //

    //* ------------------------------- Función: toggleDataSeriesVisibility ------------------------------------
    //? Descripción: Cambia la visibilidad de una serie de datos específica para una profundidad y estación determinadas.
    const toggleDataSeriesVisibility = (stationIndex, depthIndex, dataSeries) => {
        //? Parámetros:
        // - stationIndex: Índice de la estación.
        // - depthIndex: Índice de la profundidad.
        // - dataSeries: Serie de datos a la que se cambiará la visibilidad ('showOxigeno', 'showSaturacion', 'showSalinidad', 'showTemperatura').
        setDepthVisibility((prevVisibility) => {
            const newVisibility = [...prevVisibility];
            newVisibility[stationIndex] = {
                ...newVisibility[stationIndex],
                depths: [...newVisibility[stationIndex].depths],
            };
            newVisibility[stationIndex].depths[depthIndex] = {
                ...newVisibility[stationIndex].depths[depthIndex],
                [dataSeries]: !newVisibility[stationIndex].depths[depthIndex][dataSeries],
            };
            return newVisibility;
        });
    };


    //*  /*  Cambio de profundidades */
    //* ------------------------------- Función: handleNextDepth -------------------------------------------
    const handleNextDepth = (stationIndex) => {
        changeToNextDepth(stationIndex, currentPageStation, profundidadesPerPage, currentView, setCurrentPageDepths, localCenterData)
    };


    //* ------------------------------- Función: handlePreviousDepth -------------------------------------------
    const handlePreviousDepth = (stationIndex) => {
        changeToPreviousDepth(stationIndex, currentPageStation, profundidadesPerPage, currentView, setCurrentPageDepths, localCenterData)
    };


    //* ------------------------------- Función: handleOpenBatteryModal ----------------------------------------
    //? Descripción: Abre el modal de la batería para la estación específica.
    const handleOpenBatteryModal = (index) => {
        //? Parámetros:
        // - index: Índice de la estación.
        const newOpenModalBatteries = [...openModalBatteries];
        newOpenModalBatteries[index] = true;
        setOpenModalBatteries(newOpenModalBatteries);
    };

    //* ------------------------------- Función: handleCloseBatteryModal ---------------------------------------
    //? Descripción: Cierra el modal de la batería para la estación específica.
    const handleCloseBatteryModal = (index) => {
        //? Parámetros:
        // - index: Índice de la estación.
        const newOpenModalBatteries = [...openModalBatteries];
        newOpenModalBatteries[index] = false;
        setOpenModalBatteries(newOpenModalBatteries);
    };

    //* ------------------------------- Función: generateStationProps ---------------------------------------
    //? Descripción: Cierra el modal de la batería para la estación específica.
    const generateStationProps = (nombreEstacion, profundidad, depthIndex, stationIndex, alerta) => {
        return (
            {
                alerta:alerta,
                key: `Profundidad_${depthIndex}_${currentPageDepths[stationIndex]}`,
                title: depthIndex === 0 ? `Estación ${nombreEstacion}` : '',
                profundidad: profundidad,
                estacionIndex: stationIndex,
                toggleOxigeno: () => toggleDataSeriesVisibility(stationIndex, depthIndex, 'showOxigeno'),
                toggleSaturacion: () => toggleDataSeriesVisibility(stationIndex, depthIndex, 'showSaturacion'),
                toggleSalinidad: () => toggleDataSeriesVisibility(stationIndex, depthIndex, 'showSalinidad'),
                toggleTemperatura: () => toggleDataSeriesVisibility(stationIndex, depthIndex, 'showTemperatura'),
                showOxigeno: depthVisibility?.[stationIndex]?.depths?.[depthIndex]?.showOxigeno,
                showSaturacion: depthVisibility?.[stationIndex]?.depths?.[depthIndex]?.showSaturacion,
                showSalinidad: depthVisibility?.[stationIndex]?.depths?.[depthIndex]?.showSalinidad,
                showTemperatura: depthVisibility?.[stationIndex]?.depths?.[depthIndex]?.showTemperatura,
                pauseRequest: pauseRequest,
                setPauseRequest: setPauseRequest,
                profundidadesPerPage: profundidadesPerPage,
                currentView: currentView,
                translate:translate
            }
        )
    };


    // -- useEffect -- //
    //* ------------------------------- useEffect: currentPageDepths ------------------------------------------
    //? Descripción: Efecto que actualiza los datos locales del centro cuando cambian los datos del centro.
    useEffect(() => {
        if (localCenterData) {
            setCurrentPageDepths(Array(localCenterData.length).fill(0))
        }
    }, [localCenterData])

    //* ------------------------------- useEffect: Actualizar datos de los gráficos ---------------------------
    //? Descripción: Efecto que actualiza los datos locales del centro cuando cambian los datos del centro.
    useEffect(() => {
        if (centerData) {
            const newLocalCenterData = generarNewLocalCenterData(centerData, localCenterData, batch_data);
            // Pasamos por cada estación y profundidad para actualizar el estado.
            aplicarSubmuestreo(centerData, batch_data, handle_sensor_states, setLastTime, newLocalCenterData, lastTime);
            // Finalmente, actualizamos el estado con las nuevas profundidades.
            setLocalCenterData(newLocalCenterData);
        }
    }, [centerData, batch_data]);

    //* ------------------------------- useEffect: depthVisibility ----------------------------------------------
    //? Descripción: Efecto que inicializa el estado de visibilidad para cada estación y profundidad cuando cambian los datos locales del centro.
    useEffect(() => {
        if (localCenterData) {
            // Inicializa el estado de visibilidad para cada estación y profundidad
            const initialVisibility = localCenterData.map((estacion) => ({
                depths: Array(estacion.profundidades.length).fill({
                    showOxigeno: true,
                    showSaturacion: true,
                    showSalinidad: true,
                    showTemperatura: true,
                }),
            }));
            setDepthVisibility(initialVisibility);
            // //console.log('Datos del centro: ', localCenterData)
        }
    }, [localCenterData]);

    //* ------------------------------- useEffect: openModalBatteries -------------------------------------------
    //? Descripción: Efecto que inicializa el estado de modales de batería cuando cambian los datos locales del centro.
    useEffect(() => {
        if (localCenterData) {
            setOpenModalBatteries(Array(localCenterData.length).fill(false))
        }
    }, [localCenterData])

    // //*-------------------- alerta
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const loggedIn = true
    //         const data = await fetchAlertas(loggedIn);
    //         //console.log('data alerta ', data)
    //         if (Array.isArray(data)) {
    //             setAlertas(data);
    //         } else {
    //             // Manejar el caso en el que no se recibe un array
    //             //console.error('fetchAlertas no devolvió un array válido:', data);
    //         }
    //     };
    
    //     // Ejecutar la función inmediatamente al cargar el componente
    //     fetchData();
    
    //     // Configurar el intervalo para ejecutar la función cada minuto
    //     const interval = setInterval(fetchData, 60000);
    
    //     // Limpiar el intervalo al desmontar el componente
    //     return () => clearInterval(interval);
    // }, []);


    function transformData(alertas) {
        return alertas.reduce((resultado, alerta) => {
            const [nombre, profundidadStr, sensor] = alerta.variable.split('-');
            const profundidad = `${profundidadStr}`;
    
            // Buscar si ya existe un objeto para la estación
            let estacion = resultado.find(e => e.Nombre === nombre);
    
            // Si no existe, se crea un objeto y se agrega al resultado
            if (!estacion) {
                estacion = {
                    Nombre: nombre,
                    profundidades: []
                };
                resultado.push(estacion);
            }
    
            // Buscar si ya existe un objeto para la profundidad
            let profundidadObj = estacion.profundidades.find(p => p.profundidad === profundidad);
    
            // Si no existe, se crea un objeto para la profundidad y se agrega a la estación
            if (!profundidadObj) {
                profundidadObj = {
                    profundidad: profundidad,
                    alertasActivas: []
                };
                estacion.profundidades.push(profundidadObj);
            }
    
            // Agregar la alerta al campo alertasActivas de la profundidad
            profundidadObj.alertasActivas.push({
                "_id": alerta._id,
                "alerta": alerta.alerta,
                "centro_id": alerta.centro_id,
                "fecha": alerta.fecha,
                "valor": alerta.valor,
                "sensor": sensor
            });
    
            return resultado;
        }, []);
    }
    ////console.log(transformData(alertas));
    // Transformar las alertas y agregarlas a centerData
    const resultadoTransformado = transformData(alertas);

    // Función para verificar si un objeto ya existe en un array
    function objetoYaExiste(objeto, array) {
        return array?.some(item => areObjectsEqual(item, objeto));
    }

    // Función para comparar dos objetos
    function areObjectsEqual(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    // Agregar los resultados transformados a centerData evitando duplicados
    resultadoTransformado?.forEach((estacion, index) => {
        if (!objetoYaExiste(estacion, centerData[index])) {
            centerData[index]?.push(estacion);
        }
    });


    

    return (
        <Grid container justifyContent="center" style={{ fontWeight: 1000, fontSize: '5vh', cursor: 'default !important', userSelect: 'none', marginTop: '7vh' }}>
            {localCenterData.length === 0 ? (
                <Grid container justifyContent='center' style={{ marginTop: '10vh' }}>
                    <AmbientalLoader noDataAvailable={noDataAvailable} />
                </Grid>
            ) : (
                <>
                    {localCenterData
                        .slice(startIndexForCurrentPage, endIndexForCurrentPage)
                        .map((estacion, stationIndex) => {
                            // currentPageDepth : Página acual en la que se muestran profundidades
                            let cantidad_profundidades = estacion?.profundidades?.length
                            let estacion_actual = stationIndex + startIndexForCurrentPage
                            let profundidadActual = profundidadesPerPage + currentPageDepths[estacion_actual]*profundidadesPerPage // (1 + currentPageDepths[stationIndex]) * profundidadesPerPage
                            // //console.log('localCenterData',localCenterData)
                            return (
                                <Grid container justifyContent="center" alignItems="center" key={estacion_actual} item xs={conditionalGrid}>
                                    <Card style={cardStyle}>
                                        <Grid container justifyContent="center" style={{ marginTop: currentView === 'x4' && profundidadesPerPage === 2 ? '-5vh' : '-4.5vh', marginBottom: '-4vh' }}>

                                            {/* // * --  Flechas cambio Profundidades --  */}
                                            <Grid container justifyContent="center" item xs={2}>
                                                <Tooltip title="Anterior profundidad" >
                                                    <>
                                                        <IconButton onClick={() => handlePreviousDepth(estacion_actual)} disabled={currentPageDepths[estacion_actual] <= 0} style={profButtonStyle}>
                                                            <KeyboardArrowUpIcon fontSize="medium" style={{ fontSize: "4vh" }} />
                                                        </IconButton>
                                                    </>
                                                </Tooltip>

                                                <Tooltip title="Siguiente profundidad" >
                                                    <>
                                                        <IconButton onClick={() => handleNextDepth(estacion_actual)} disabled={profundidadActual >= cantidad_profundidades} style={profButtonStyle}>
                                                            <KeyboardArrowDownIcon fontSize="medium" style={{ fontSize: "4vh" }} />
                                                        </IconButton>
                                                    </>
                                                </Tooltip>
                                            </Grid>

                                            {/* // * -- Titulo de estación */}
                                            <Grid container justifyContent="center" alignContent={'center'} item xs={9} >
                                                <h2 style={{
                                                    height: '5vh',
                                                    fontSize: currentView === 'x1' ? '4vh' : '2.5vh',
                                                    marginLeft: marginLeftTitle,
                                                    marginTop: '6vh',
                                                    cursor: 'default !important', userSelect: 'none',
                                                    color: '#cfcfcf'
                                                }}
                                                >
                                                    {`${translate('estacion')} ${estacion && estacion.nombre}`}
                                                </h2>
                                            </Grid>

                                            {/* // * --  Icono Battery --  */}
                                            <Grid container justifyContent="center" item xs={1} >
                                                <Button
                                                    variant='text'
                                                    color='inherit'
                                                    onClick={() => handleOpenBatteryModal(estacion_actual)}
                                                    style={{ fontWeight: 1000, fontSize: '5vh', cursor: 'pointer', marginLeft: '-2.5vw', height: '3.5vh', marginTop: '5vh' }}
                                                >
                                                    {/* <Battery battery={estacion?.battery?.length > 0 ? estacion.battery[estacion.battery.length - 1]?.value : ''} /> */}
                                                    <Battery translate={translate} battery={estacion?.battery?.[estacion.battery.length - 1]?.value} />

                                                </Button>
                                            </Grid>
                                        </Grid>

                                        {/* // * --   Renderización de gráficos  --  */}
                                        {estacion.profundidades
                                            .slice(currentPageDepths[estacion_actual], currentPageDepths[estacion_actual] + profundidadesPerPage)
                                            // .slice(currentPageDepth, currentPageDepth + profundidadesPerPage)
                                            .map((profundidad, depthIndex) => {
                                                // const profundidad_valida = profundidad.O2_val?.length > 0 || profundidad.O2_val?.DO2_val > 0 || profundidad.O2_val?.sal_val > 0 || profundidad.O2_val?.temp_val > 0
                                                // //console.log('profundidad: ', profundidad_valida )
                                                // if(profundidad_valida){
                                                    // const alerta = {}
                                                const alerta = resultadoTransformado[stationIndex]?.profundidades[depthIndex]
                                                return (
                                                    <ComponentToRender
                                                        {...generateStationProps(estacion.Nombre, profundidad, depthIndex, estacion_actual, alerta)}
                                                    />
                                                )
                                            })}
                                    </Card>
                                    <ModalBattery
                                        batteryData={estacion.battery} clientName={`${translate('estacion')} ${estacion.nombre}`}
                                        openModalBattery={openModalBatteries && openModalBatteries[estacion_actual]}
                                        handleCloseBattery={() => handleCloseBatteryModal(estacion_actual)}
                                        translate={translate}
                                    />
                                </Grid>
                            )

                        })}
                </>
            )}
        </Grid>
    );
}

export default Home;
