import React from 'react';
import { BatteryFull, Battery80, Battery60, Battery30, Battery20 } from '@mui/icons-material';


const BatteryIcon = ({ battery, translate}) => {
    const renderBatteryIcon = () => {
        let iconComponent;

        if (battery === 0) {
            iconComponent = <BatteryFull style={{ fontSize: '4vh', color: '#51191f', transform: 'rotate(90deg)', marginTop: '1vh', marginLeft: '0.5vw' }} />;
        } else if (battery <= 10) {
            iconComponent = <Battery20 style={{ fontSize: '4vh', color: 'red', transform: 'rotate(90deg)', marginTop: '1vh', marginLeft: '0.5vw' }} />;
        } else if (battery <= 20) {
            iconComponent = <Battery20 style={{ fontSize: '4vh', color: 'orange', transform: 'rotate(90deg)', marginTop: '1vh', marginLeft: '0.5vw' }} />;
        } else if (battery <= 30) {
            iconComponent = <Battery30 style={{ fontSize: '4vh', transform: 'rotate(90deg)', marginTop: '1vh', marginLeft: '0.5vw' }} />;
        } else if (battery <= 60) {
            iconComponent = <Battery60 style={{ fontSize: '4vh', transform: 'rotate(90deg)', marginTop: '1vh', marginLeft: '0.5vw' }} />;
        } else if (battery <= 80) {
            iconComponent = <Battery80 style={{ fontSize: '4vh', transform: 'rotate(90deg)', marginTop: '1vh', marginLeft: '0.5vw' }} />;
        } else {
            iconComponent = <BatteryFull style={{ fontSize: '4vh', transform: 'rotate(90deg)', marginTop: '1vh', marginLeft: '0.5vw' }} />;
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                <span style={{ marginTop: '1vh', fontSize: "1.8vh" }}>{battery}%</span>
                {iconComponent}
            </div>
        );
    };

    return (
        <div style={{ fontSize: '2.5vh', textAlign: 'center', cursor: 'pointer' }} >
            {renderBatteryIcon()}
        </div>
    );
};

export default BatteryIcon;
