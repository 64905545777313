
// import React from 'react';
// import BombaNoData from '../BombaNoData/BombaNoData';
// import BombaDesconectada from './BombaDesconectada';
// import BombaFalla from './BombaFalla';
// import BombaEnBuenEstado from './BombaEnBuenEstado';

// const BombaStatusVDF = ({ bomba }) => {
//   if (!bomba.aviot) {
//     return <BombaNoData bomba={bomba} />;
//   } else if (!bomba.Status) {
//     return <BombaDesconectada bomba={bomba} />;
//   } else if (bomba.Fault !== 0) {
//     return <BombaFalla bomba={bomba} />;
//   } else {
//     return <BombaEnBuenEstado bomba={bomba} />;
//   }
// };

// export default BombaStatusVDF;

import React from 'react';
import BombaCommonStatus from '../Common/BombaCommonStatus';
import BombaOperativa from '../Common/BombaOperativa';
import BombaNoData from '../Common/BombaNoData'

const BombaStatusVDF = ({ bomba, modulo , color, controlType, translate }) => {
  if (!bomba.aviot_status) {
    return <BombaNoData message="BOMBA SIN DATOS" />;
  }

  if (!bomba.Device_Modbus_Status) {
    return <BombaCommonStatus message="VDF DESCONECTADO" bomba={bomba} modulo={modulo}  translate={translate}/>;
  }

  if (bomba.Fault === 0) {
    return <BombaCommonStatus message={bomba.Fault} bomba={bomba} modulo={modulo}  translate={translate} />;
  }

  return <BombaOperativa bomba={bomba} modulo={modulo}  translate={translate}
  color={color}
  controlType={controlType} />;
};

export default BombaStatusVDF;
