import React from 'react'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Grid } from '@mui/material';

const SensorInfo = ({ label, value, unit, O2 ,itemXs}) => {
    return (
        <Grid container justifyContent="center" item xs={itemXs} style={{ borderLeft:  'solid 0.1vh white',borderBottom: O2 === 1 && 'solid 0.1vh white', color: "white",}}>
          <Grid container justifyContent="center" alignContent="center" item xs={4}>
            <img
                src={label}
                alt="Icono"
                style={{ width: '1.5vw', aspectRatio:1, marginLeft: "-0.3vw", color: "white" }}
            />
          </Grid>
          <Grid container justifyContent="center" alignContent="center" item xs={8}>
              <Grid style={{ fontSize: "1.15vw", marginLeft: "-0.3vw", color: "white" }}>
                {value}{unit}
              </Grid>
          </Grid>
        </Grid>
      );
    };

export default SensorInfo