import React, {useState} from 'react'
import { Grid } from '@mui/material'
import Oxigeno from '../Sensores/Oxigeno'
import Salinidad from '../Sensores/Salinidad'
import Saturacion from '../Sensores/Saturacion'
import { Temperatura } from '../Sensores/Temperatura'
// import Chart from '../Grafico/grafico'
import AmbientalChart from '../../Grafico/AmbientalGraph'
import Alertas from '../alertas'


const Prof_1_x4 = ({ alerta,translate, profundidad, showOxigeno, showSaturacion, showSalinidad, showTemperatura, toggleOxigeno, toggleSaturacion, toggleSalinidad, toggleTemperatura, title, pauseRequest, setPauseRequest, profundidadesPerPage, currentView  }) => {

    const commonProps = {
        // Tamaños generales
        fullwidth: '10vw',
        fullHeight: '8vh',
        // Tamaño de icono
        heightIMG: '4.5vh',
        widthIMG: '2.25vw',
        // Ajuste de la card de oxígeno
        width: '4.5vw',
        height: '8.5vh', 
        // Ajuste de la posición del valor de oxígeno
        fontSizeH1: '3.2vh',
        fontSizeTypo1: '1.7vh',
        // Tamaño card del valor de oxigeno
        width1: '5.5vw',
        height1: '8.5vh',
        // Separación de la card de oxígeno y su valor
        marginLeft1: '-0.8vw',
    };

    const graphProps = {
        width               : "35vw",
        height              : "30vh",
        data1               : profundidad.O2_val,
        data2               : profundidad.DO2_val,
        data3               : profundidad.sal_val,
        data4               : profundidad.temp_val,
        title               : title,
        titleSize           : '20vw',
        // axisFontSize        : 12,
        axisFontSize     : window.innerHeight*0.02 , //"20vh",
        legendFontSize      : "1.4vh",
        showOxigeno         : showOxigeno,
        showSaturacion      : showSaturacion,
        showSalinidad       : showSalinidad,
        showTemperatura     : showTemperatura,
        toggleOxigeno       : toggleOxigeno,
        toggleSaturacion    : toggleSaturacion,
        toggleSalinidad     : toggleSalinidad,
        toggleTemperatura   : toggleTemperatura,
        // Activar Solicitudes de datos
        pauseRequest          : pauseRequest, 
        setPauseRequest       : setPauseRequest,
        profundidadesPerPage  : profundidadesPerPage,
        currentView           : currentView,
        translate             : translate
    }
    const initialAlerts = alerta?.alertasActivas || [];
    const [activeAlerts, setActiveAlerts] = useState(initialAlerts);
    return (
        <Grid container style={{marginTop:'1vh'}}>
            <Grid container justifyContent="flex-start" item xs={12} style={{ marginTop: "-3vh" }} >
                <h1 style={{fontSize:'2vh', marginTop:'3.5vh', marginLeft:'2.5vw', height:'2.5vh'}}>
                    {profundidad.profundidad  ? `${profundidad.profundidad}m` : ''} 
                </h1>
            </Grid>
            <Alertas activeAlerts={activeAlerts} setActiveAlerts={setActiveAlerts} translate={translate}/>
            <Grid container justifyContent="center" item xs={9} >
                <Grid item xs={12}>
                    <AmbientalChart
                        {...graphProps}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" item xs={2} style={{marginTop:'-4vh',}} >
                {/* Cards Oxigeno */}
                <Grid item xs={12} height={'7vh'} style={{marginLeft:"2vw"}}>
                    <Oxigeno
                        showOxigeno={showOxigeno}
                        toggleOxigeno={toggleOxigeno}
                        {...commonProps}
                        marginTopIMG= "0.5vh"
                        medicion={profundidad.O2_val[profundidad.O2_val.length - 1] && profundidad.O2_val[profundidad.O2_val.length - 1].value ? profundidad.O2_val[profundidad.O2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Saturacion */}
                <Grid item xs={12} height={'7vh'} style={{marginLeft:"2vw"}} >
                    <Saturacion
                        showSaturacion={showSaturacion}
                        toggleSaturacion={toggleSaturacion}
                        {...commonProps}
                        marginTopIMG= "0vh"
                        medicion={profundidad.DO2_val[profundidad.DO2_val.length - 1] && profundidad.DO2_val[profundidad.DO2_val.length - 1].value ? profundidad.DO2_val[profundidad.DO2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Salinidad */}
                <Grid item xs={12} height={'7vh'} style={{marginLeft:"2vw"}} >
                    <Salinidad
                        showSalinidad={showSalinidad}
                        toggleSalinidad={toggleSalinidad}
                        {...commonProps}
                        marginTopIMG= "-0.5vh"
                        medicion={profundidad.sal_val[profundidad.sal_val.length - 1] && profundidad.sal_val[profundidad.sal_val.length - 1].value ? profundidad.sal_val[profundidad.sal_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Temperatura */}
                <Grid item xs={12} height={'7vh'} style={{marginLeft:"2vw"}} >
                    <Temperatura
                        showTemperatura={showTemperatura}
                        toggleTemperatura={toggleTemperatura}
                        {...commonProps}
                        marginTopIMG= "0.5vh"
                        medicion={profundidad.temp_val[profundidad.temp_val.length - 1] && profundidad.temp_val[profundidad.temp_val.length - 1].value ? profundidad.temp_val[profundidad.temp_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
            </Grid>
        </Grid>

    )
}

export default Prof_1_x4