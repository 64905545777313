import React,{useState,useEffect} from 'react'
import { Grid } from "@mui/material"
import O2 from "./assets/img/oxigeno.png";
import DO2 from "./assets/img/saturacion.png"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SensorInfo from './SensorInfo';

const BombaCommonInStatus = ({ message, bomba,color,configuracionUmbrales,translate ,StyleTooltip}) => {

    const isPartidorSuaveDesconectado = message === 'Partidor Suave Desconectado';
    
    const [colorO2, setColorO2] = useState('#0d0d0d'); // Estado para almacenar el color actual

    // funcion para color de "led" inyeccion de O2
    const getColorIny = () => {
        // Comprobamos si el valor es menor o igual a cero
        if (bomba.RLY !== '1') {
        // Si es menor o igual a cero, devolvemos el valor en rojo
        return '#0d0d0d';
        } else{
            // De lo contrario, devuelve un color verde claro
            return "green";
        }
    };
    // funcion para color de voltaje 
    const getColor = () => {
        // Comprobamos si el valor es menor o igual a cero
        if (bomba.Vo <= 0) {
        // Si es menor o igual a cero, devolvemos el valor en rojo
        return "#0d0d0d";
        } else{
            // De lo contrario, devuelve un color azul claro
            // return "green";
            return "#0d0d0d";
        }
    };
    // console.log("o2Threshold",configuracionUmbrales && configuracionUmbrales)

  return (
    <Grid container justifyContent={'center'} style={{}}>
       
        <Grid container item xs={12} style={{ zIndex:1, height:'9vh', borderRadius:'0.5vw'}}>
        {/* Lado con 3 cuadrículas */}
            <Grid container item xs={5} style={{}}>
                {/* Corriente y Voltaje */}
                <StyleTooltip title={`Ultima medicion de voltaje ${bomba.last_measure_sensor}`}  placement="top" classes={{ tooltip: 'custom-tooltip' }}>
                    <Grid container justifyContent="center" alignContent={'center'} item xs={12} 
                    style={{  
                        fontSize:"0.85vw", //color: "#e7bcbc",
                        borderTopLeftRadius:"1vh",
                        backgroundColor: getColor() 
                        }}
                >
                    {bomba.Vo.toFixed(2)}V 
                    </Grid>
                </StyleTooltip>
                {/* Inyeccion */}
                <Grid container justifyContent="center" alignContent={'center'} item xs={12} 
                style={{ borderTop: 'solid 0.1vh #ccc',borderBottomLeftRadius:"1vh", fontSize:"0.85vw", //color: "#e7bcbc",
                 backgroundColor: getColor()  /*backgroundColor: '#0d0d0d' */}}>
                    {bomba.Io.toFixed(2)}A 
                </Grid>
            {/* Informaciones */}
                <Grid container justifyContent="center" item xs={12} style={{ borderTop: 'solid 0.1vh #ccc',borderBottomLeftRadius:"1vh", fontSize:"0.85vw", backgroundColor:'#0d0d0d'  }}>
                <RadioButtonUncheckedIcon
                    style={{
                    fontSize: '0.75vw',
                    marginRight: '0.2vw',
                    marginTop: '0.5vh',
                    backgroundColor: getColorIny(),
                    color: 'white',
                    borderRadius: '1vh',
                    }}
                />
                    Iny O2
                </Grid>
            </Grid>
            {/* Lado con 2 cuadrículas */}
            <StyleTooltip title={`Ultima medicion de sensores ${bomba.last_measure_sensor}`}  placement="top" classes={{ tooltip: 'custom-tooltip' }}>
                {bomba.O2 < 0 ? (
                    <Grid container justifyContent={'center'} alignContent={'center'} item xs={7}  style={{  borderLeft:'solid 0.1vh ', backgroundColor:'#2d1e10', borderTopRightRadius:'1vh' }}>
                        {/* Error */}
                        <h1 style={{ fontSize: "1vw", color:'#d9c089' }}>{translate('sensor_failure')}</h1>
                    </Grid>
                    ) : (
                    <Grid container item xs={7}  style={{backgroundColor:bomba.O2_color, borderTopRightRadius:'0.5vw' }}>
                        <SensorInfo label={O2} value={bomba.O2}  unit="mg" O2={1}  itemXs={12}/>
                        <SensorInfo label={DO2} value={bomba.SO2}  unit="%" itemXs={12}/>            
                    </Grid>
                )}
            </StyleTooltip>
        </Grid>


        <Grid container justifyContent={'center'} alignItems="center" alignContent={'center'} style={{height:'5vh', zIndex:1,  borderTop:'solid 0.1vh',borderBottomRightRadius:'0.5vw',borderBottomLeftRadius:'0.5vw'}} >
            <Grid container justifyContent={'center'} alignItems="center" alignContent={'center'}  item xs={2} style={{}}>
                <ErrorOutlineOutlinedIcon style={{fontSize: "1vw",color: "#de3e32"}}/>
            </Grid>
            <Grid container justifyContent={'center'} alignItems="center" alignContent={'center'}  item xs={2} style={{}}>
                <h1 style={{fontSize: "0.9vw",color: "#e7bcbc"}}>{bomba.alias}</h1>
            </Grid>
            <Grid container justifyContent={'center'} alignItems="center" alignContent={'center'}  item xs={8} style={{height: isPartidorSuaveDesconectado ? '3.3vh': '', }}>
                {isPartidorSuaveDesconectado ?(
                    <Grid container justifyContent={'center'}>
                        <Grid container justifyContent={'center'} style={{color: "#e7bcbc", fontSize:"0.75vw", marginTop:'0vh'  }}>
                            Partidor Suave
                        </Grid>
                        <Grid container justifyContent={'center'} style={{color: "#e7bcbc", fontSize:"0.75vw", marginTop:'-0.3vh'  }}>
                            Desconectado
                        </Grid>
                    </Grid>
                ):(
                    <h4 style={{color: "#e7bcbc", fontSize:"0.75vw"  }}>
                        {message} 
                    </h4>
                )}
                
            </Grid>
        </Grid>
    </Grid>
  )
}

export default BombaCommonInStatus