import moment from 'moment';


// const LOCAL_SERVER_URL = "http://172.16.1.88:4023";
const LOCAL_SERVER_URL =`${localStorage.getItem("serverName")}` //"http://192.168.20.145:4023";
const LOCAL_IP = "http://localhost:4000"


export async function _SERVER_URL() {
    // Número máximo de intentos
    const maxAttempts = 3;
    let attempts = 0;
    
    while (attempts < maxAttempts) {
        try {
            const controller = new AbortController();
            const signal = controller.signal;
            const timeout = setTimeout(() => {
                controller.abort();
            }, 5000);
    
            const URL_EMPRESA = LOCAL_SERVER_URL; // Modifica esta línea con la URL que deseas usar
            const ENDPOINT_TEST = 'check_server_status';
    
            //console.log(URL_EMPRESA);
    
            const publicResponse = await fetch(`${URL_EMPRESA}/${ENDPOINT_TEST}`, { method: 'HEAD', signal, keepalive: false });
            clearTimeout(timeout);
    
            //console.log((publicResponse && publicResponse.ok) ? 'IP Pública' : 'IP Local');
    
            return (publicResponse && publicResponse.ok) ? `${URL_EMPRESA}` : LOCAL_SERVER_URL;
    
        } catch (error) {
            console.error('Error al intentar conectarse:', error);
            attempts++;
            if (attempts < maxAttempts) {
                //console.log(`Reintentando en 2 segundos (intentos restantes: ${maxAttempts - attempts})`);
                await new Promise(resolve => setTimeout(resolve, 2000));
            } else {
                //console.log('Se alcanzó el número máximo de intentos. No se pudo establecer conexión.');
                return "Error en conexión";
            }
        }
    }
    
}

// // ? METODO GetCenters
// export async function GetCenters({ service}) {
//     //console.log("entro a rutina para solicitud de", service );
    
//     let SERVER_URL = await _SERVER_URL();

//     let username = localStorage.getItem("username")
//     let access_token =  localStorage.getItem("access_token");
//     let refresh_token =  localStorage.getItem("refresh_token");

    


//     try {
        
//         //console.log('solicitando centros en ',  `${SERVER_URL}/${service.toLowerCase()}/get_centers`);

//         // const response = await fetch(`${SERVER_URL}/${service}/get_centers/`, {
//         const response = await fetch(   `${SERVER_URL}/${service.toLowerCase()}/get_centers`, {
          
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 username: username,
//                 access_token : access_token, 
//                 refresh_token: refresh_token                               
//             }),
//             keepalive: false,
//         });

//         const data = await response.json();
//         //console.log("Respuesta del servidor:", data);

//         if (!response.ok) {
//             throw new Error(data.mensaje || "Error al iniciar sesión");
//         }
                
//         //console.log(data.IDs)
//         //console.log('%c CONEXIÓN EXITOSA','color:green; font-size:5vh')
//         // window.location.href = "/enviro";
//         return [true, data.IDs]

//     } catch (error) {
//         console.error("Hubo un error al iniciar sesión:", error);
//         return [false, null];
//     }
// }

// Método GetCenters
// export async function GetCenters({ service, credenciales}) {
//     console.log("entro a rutina para solicitud de GetCenters", service );
//     try {
        
//         let SERVER_URL = await LOCAL_SERVER_URL;

//         let username = localStorage.getItem("username")
//         let access_token =  localStorage.getItem("access_token");
//         let refresh_token =  localStorage.getItem("refresh_token");
//         //console.log('solicitando centros en ',  `${SERVER_URL}/${service.toLowerCase()}/get_centers`);

//         const endpoint = `/${service.name.toLowerCase()}/get_centers`;
//         const fullUrl = `${SERVER_URL}${endpoint}`;
//         console.log('respuesta fullUrl', fullUrl)

//         // const response = await fetch(`${SERVER_URL}/${service.toLowerCase()}/get_centers`, {
//         const response = await fetch(fullUrl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 username: username,
//                 access_token : access_token, 
//                 refresh_token: refresh_token                               
//             }),
//             keepalive: false,
//         });

//         const data = await response.json();
//         console.log("Respuesta del servidor:", data);

//         if (!response.ok) {
//             throw new Error(data.mensaje || "Error al iniciar sesión");
//         }
                
//         //console.log(data.IDs)
//         //console.log('%c CONEXIÓN EXITOSA','color:green; font-size:2vh')
//         return [true, data.IDs]

//     } catch (error) {
//         console.error("Hubo un error al obtener los centros:", error);
//         return [false, null];
//     }
// }

// export async function GetCenters({ service,  }) {
//     console.log("Entro a rutina para solicitud de GetCenters", service);
//     try {
//         let SERVER_URL = await LOCAL_SERVER_URL;

//         let username = localStorage.getItem("username");
//         let access_token = localStorage.getItem("access_token");
//         let refresh_token = localStorage.getItem("refresh_token");

//         const endpoint = `/${service.name.toLowerCase()}/get_centers`;
//         const fullUrl = `${SERVER_URL}${endpoint}`;
//         console.log('Respuesta fullUrl', fullUrl);

//         const response = await fetch(fullUrl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 username: username,
//                 access_token: access_token,
//                 refresh_token: refresh_token
//             }),
//             keepalive: false,
//         });

//         const data = await response.json();
//         console.log("Respuesta del servidor:", data);

//         if (data.mensaje === "El usuario no corresponde a esta sesion") {
//             console.log("La sesión ha expirado");
//         }

//         if (!response.ok) {
//             throw new Error(data.mensaje || "Error al iniciar sesión");
//         }

//         return [true, data.IDs];

//     } catch (error) {
//         console.error("Hubo un error al obtener los centros:", error.message || error);
//         return [false, 'null'];
//     }
// }

export async function GetCenters({ service }) { 
    console.log("Entro a rutina para solicitud de GetCenters", service);
    try {
        let SERVER_URL = await LOCAL_SERVER_URL;

        let username = localStorage.getItem("username");
        let access_token = localStorage.getItem("access_token");
        let refresh_token = localStorage.getItem("refresh_token");

        const endpoint = `/${service.name.toLowerCase()}/get_centers`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        console.log('Respuesta fullUrl', fullUrl);

        const response = await fetch(fullUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                access_token: access_token,
                refresh_token: refresh_token
            }),
            keepalive: false,
        });

        const data = await response.json();
        console.log("Respuesta del servidor:", data);

        // Manejar la sesión expirada
        if (data.mensaje === "El usuario no corresponde a esta sesion") {
            console.log("La sesión ha expirado");
            return ['session_expired', null];  // Retorna el estado de sesión expirada
        }

        // Manejar errores HTTP
        if (!response.ok) {
            throw new Error(data.mensaje || "Error al obtener los centros");
        }

        return [true, data.IDs];  // Retorna los centros si todo está bien

    } catch (error) {
        console.error("Hubo un error al obtener los centros:", error.message || error);
        return [false, null];  // Devuelve null como valor, no como cadena
    }
}


// ? METODO DE refreshToken
export async function refreshToken() {
    let SERVER_URL = await _SERVER_URL();
    const refreshToken = localStorage.getItem("refresh_token");
    try {
        const response = await fetch(`${SERVER_URL}/token/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refresh_token: refreshToken
            }),
            keepalive: false
        });
        const data = await response.json();
        if (data.access_token) {
            localStorage.setItem("access_token", data.access_token);
            //console.log('token refrescado exitosamente');
            return data.access_token;
        } else {
            //console.log('Eliminando credenciales');
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("empresa");
            throw new Error(data.message || "Error al refrescar el token");
        }
    } catch (error) {
        console.error("Hubo un error al refrescar el token:", error);
        return null;
    }
}

// ? METODO DE CERRAR SESION
export function cerrarSesion(setIsAuthenticated) {
    try {
        //console.log('Eliminando credenciales');
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("empresa");
        setIsAuthenticated(false);
        return true;
    } catch (error) {
        console.error("Error cerrando sesión:", error);
        return null;
    }
}




 
// ? FUNCIONES USUARIOS

//METODO CREACION DE USUARIO
export async function CrearUsuario(user, clientName, setAlertMessage, setAlertSeverity, setUser, initialUser, setActiveStep,translate) {
    // clientName => usuario que esta creando
    // console.log('clientNameuser',user)
    try {
        // Obtener la URL del servidor
        const SERVER_URL = await LOCAL_SERVER_URL;
        //console.log('SERVER_URL', SERVER_URL)

        // Realizar la solicitud POST al servidor
        const response = await fetch(`${SERVER_URL}/crear_usuario/${clientName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });

        if (response && response.ok) {
            // setAlertMessage('Usuario creado con éxito!');
            setAlertMessage(translate('create_success_message'));
            setAlertSeverity('success');
            setUser(initialUser); // Resetear el usuario después de guardar con éxito
            setActiveStep(0); // Volver al primer paso del stepper
          }

        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            // front
            let errorMessage;
            if (response.status === 409) {
              errorMessage = translate('create_errorExiste_message');
            } else {
              errorMessage = translate('create_error1_message');
            }
            setAlertMessage(errorMessage);
            setAlertSeverity('error');
            // Si la respuesta no es exitosa, lanzar un error con el mensaje del servidor
            const errorData = await response.json();
            throw new Error(errorData.mensaje || translate('create_error2_message'));
        }

        // Si la respuesta es exitosa, devolver los datos recibidos del servidor
        return await response.json();
    } catch (error) {
        // Capturar y manejar cualquier error que ocurra durante la solicitud
        console.error('Hubo un error al crear nuevo usuario:', error);
        //console.log( {error});
        setAlertMessage(translate('create_error3_message'));
        setAlertSeverity('error');
        // setAlertMessage('Hubo un error al crear nuevo usuario:', error)

        throw error;
    }
}

//METODO PARA SOLICITAR LOS USUSRIOS QUE SE PUEDEN EDITAR 
export async function ObtenerUsuarios(user) {
    // //console.log("Obteniendo usuarios que se pueden editar");

    let SERVER_URL = await LOCAL_SERVER_URL;
    let access_token =  localStorage.getItem("access_token");


    // //console.log('SERVER_URL',`${SERVER_URL}/ediciones_usuario/`)

    try {
        const response = await fetch(`${SERVER_URL}/ediciones_usuario`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${access_token}`
            },
            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        // console.log("Usuarios que se pueden editar:", data);

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error al obtener usuarios para editar");
        }

        return data;

    } catch (error) {
        console.error("Hubo un error al obtener usuarios para editar:", error);
        throw error;
    }
  };

// Función para editar usuario
export async function EditarUsuario(user, clientName) {
    console.log('user',user)
    try {
        // Obtener la URL del servidor
        const SERVER_URL = await LOCAL_SERVER_URL;
        let userEdit = localStorage.getItem('usuario')
        
        // Realizar la solicitud POST al servidor para editar el usuario
        const response = await fetch(`${SERVER_URL}/editar_usuario/${clientName}/${userEdit}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });

        // Verificar si la respuesta es exitosa
        if (response.ok) {

            // Si la respuesta es exitosa, devolver los datos recibidos del servidor
            return await response.json();
        } else {
            // Si la respuesta no es exitosa, lanzar un error con el mensaje del servidor
            const errorData = await response.json();
            throw new Error(errorData.mensaje || 'Error al editar usuario');
        }
    } catch (error) {
        // Capturar y manejar cualquier error que ocurra durante la solicitud
        console.error('Hubo un error al editar usuario:', error);
        throw error;
    }
}


//METODO PARA ELIMINAR USUARIO
export async function EliminarUsuario(clientName, usuario) {
    // ClienteName nombre del usuario que esta elimiando
    // usuario es el que se esta eliminando
    //console.log("eliminando  usuario")

    let SERVER_URL = await LOCAL_SERVER_URL;


    try {
        const response = await fetch(`${SERVER_URL}/delete_user/${clientName}/${usuario}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },

            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        //console.log(data)

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando eliminar  usuario");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al eliminar  usuario:", error);
        throw error;
    }
}


//METODO PARA CREAR CENTRO
export async function CrearCentro(center, setAlertMessage, setAlertSeverity,translate) {
    // clientName => usuario que esta creando
    //console.log('center',center)
    try {
        // Obtener la URL del servidor
        const SERVER_URL = await LOCAL_SERVER_URL;
        console.log('SERVER_URL', SERVER_URL)

        // Realizar la solicitud POST al servidor
        const response = await fetch(`${SERVER_URL}/crear_centro`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(center)
        });
        if (response && response.ok) {
            // setAlertMessage('Usuario creado con éxito!');
            setAlertMessage(translate('create_center_success_message'));
            setAlertSeverity('success');
          }

        // Verificar si la respuesta es exitosa
        if (!response.ok) {
            // front
            let errorMessage;
            if (response.status === 409) {
              errorMessage = translate('create_center_errorExiste_message');
            } else {
              errorMessage = translate('create_center_error1_message');
            }
            setAlertMessage(errorMessage);
            setAlertSeverity('error');
            // Si la respuesta no es exitosa, lanzar un error con el mensaje del servidor
            const errorData = await response.json();
            throw new Error(errorData.mensaje || translate('create_center_error2_message'));
        }

        // Si la respuesta es exitosa, devolver los datos recibidos del servidor
        return await response.json();
    } catch (error) {
        // Capturar y manejar cualquier error que ocurra durante la solicitud
        console.error('Hubo un error al crear nuevo usuario:', error);
        //console.log( {error});
        setAlertMessage(translate('create_center_error3_message'));
        setAlertSeverity('error');
        // setAlertMessage('Hubo un error al crear nuevo usuario:', error)

        throw error;
    }
}

//METODO PARA SOLICITAR LOS USUSRIOS QUE SE PUEDEN EDITAR 
export async function ObtenerCentros() {
    // //console.log("Obteniendo usuarios que se pueden editar");

    let SERVER_URL = await LOCAL_SERVER_URL;
    let access_token =  localStorage.getItem("access_token");


    try {
        const response = await fetch(`${SERVER_URL}/obtener_centros`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${access_token}`
            },
            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        // //console.log("Usuarios que se pueden editar:", data);

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error al obtener centros para editar");
        }

        return data;

    } catch (error) {
        console.error("Hubo un error al obtener centros para editar:", error);
        throw error;
    }
  };


  export async function EliminarCentro(centerId) {
    // ClienteName nombre del usuario que esta elimiando
    // usuario es el que se esta eliminando
    //console.log("eliminando  centro")

    let SERVER_URL = await LOCAL_SERVER_URL;


    try {
        const response = await fetch(`${SERVER_URL}/eliminar_centro/${centerId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        //console.log(data)

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando eliminar  centro");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al eliminar  centro:", error);
        throw error;
    }
}


export async function EditarCentro(center, setAlertMessage, setAlertSeverity,translate, centroSeleccionado) {
    // clientName => usuario que esta creando
    //console.log('center edit',center)
    try {
        // Obtener la URL del servidor
        const SERVER_URL = await LOCAL_SERVER_URL;
        //console.log('SERVER_URL', SERVER_URL)

        // Realizar la solicitud POST al servidor
        const response = await fetch(`${SERVER_URL}/editar_centro/${centroSeleccionado}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(center)
        });
//console.log('response',response)
        if (response && response.ok) {
            // setAlertMessage('Usuario creado con éxito!');
            setAlertMessage(translate('edit_center_success_message'));
            setAlertSeverity('success');
          }

        // Si la respuesta es exitosa, devolver los datos recibidos del servidor
        return await response.json();
    } catch (error) {
        // Capturar y manejar cualquier error que ocurra durante la solicitud
        console.error('Hubo un error al editar el centro:', error);
        //console.log( {error});
        setAlertMessage(translate('edit_center_error3_message'));
        setAlertSeverity('error');
        // setAlertMessage('Hubo un error al crear nuevo usuario:', error)

        throw error;
    }
}


// /<SERVICE>/consolidado_estados', methods=['GET']

// -- API consolidado_estados -- //
export async function fetchConsolidadoCenters(PAGE) {
    try {
        let SERVER_URL = await LOCAL_SERVER_URL;
        let access_token =  localStorage.getItem("access_token");
        
        console.log('SERVER_URL PAGE', PAGE)
        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return {};
        }
        const endpoint = `/${PAGE}/consolidado_estados`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        console.log('respuesta fullUrl', fullUrl)

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${access_token}`
            },
            keepalive: false
        });

        // console.log('respuesta servidor', response)

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        // console.log('Respuesta del servidor:', result);
        
        // Retornar el resultado
        return result;
    } catch (error) {
        console.error('Error:', error);
        return {};  // Retornar un objeto vacío en caso de error
    }
};



// -- API consolidado_servicios -- //
export async function fetchConsolidadoService( ) {
    try {
        let SERVER_URL = await LOCAL_SERVER_URL;
        let access_token =  localStorage.getItem("access_token");
        
        const Region = localStorage.getItem('REGION')
        const Area = localStorage.getItem('AREA')
        // console.log('SERVER_URL fetchStatusCenters', SERVER_URL
        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return {};
        }
        const endpoint = `/estado_servicio_2/${Area}/${Region}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        // console.log('respuesta fullUrl', fullUrl)

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${access_token}`
            },
            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        // console.log('respuesta servidor estado_servicio_2', response)

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        // console.log('Respuesta del servidor:', result);
        // console.log('respuesta servidor estado_servicio_2', result)
        
        // Retornar el resultado
        return result;
    } catch (error) {
        console.error('Error:', error);
        return {};  // Retornar un objeto vacío en caso de error
    }
};