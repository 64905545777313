import React from 'react';
import { Grid, Paper, Modal, Button, Typography, Card , CardContent, Avatar, IconButton, Divider, Badge  } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,  } from 'swiper/modules';
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper.min.css';
import ImageSection from './ImageSection';
// import GradientDivider from './GradientDivider';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
// import { capitalize } from '../pages/utils';  // Asegúrate de tener esta función de utilidades
// import { severityMap, style } from './styles'; // Asegúrate de tener estos estilos
import { styled } from '@mui/system';
import Nanoox from '../assets/img/APP-nanoox-1 (003).png';
import TextNanoox from '../assets/img/image (7).png';
import Pss from '../assets/img/APP pss.png';
import TextPss from '../assets/img/logo pss 1 blanco sin fondo.png'
import Enviro from '../assets/img/APP enviro.png';
import TextEnviro from '../assets/img/logo ENVIRO blanco sin fondo.png'
import Scapp from '../assets/img/APP-scapp.png';
import TextScapp from '../assets/img/logos-SCAPP-b.png';

const GradientDivider = styled(Divider)(({ theme }) => ({
    height: '0.5vh',
    width:'13.8vw',
    marginTop:'1vh',
    color: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,0))'
  }));


  const style = {
    container: {
      border: 'solid 0.1vh red',
      borderRadius:'3vh',
      // borderTopRightRadius:'3vh',
      // marginRigth: '1vw',
      marginTop: '1vh',
      textAlign: 'center',
      width: '6vw',
      height:'9vh'
    },
    img: {
      // minHeight: '7vh',
      height: '9vh',
      borderRadius:'50vh',
      // aspectRatio:16,
      // width:'3vw',
      marginLeft: '0.5vh',
      selected: {
        backgroundColor: 'rgb(255,255,255,0.1)', // Cambia el color de fondo para indicar la selección
      },
      // marginBottom: '5vh',
    },
    content: {
      // marginLeft: '1vw',
      border: 'solid 0.1vh ',
      width: '8vw',
      height: '5vh',
      textAlign: 'center',
      paddingTop: '0.2vh',
      fontSize: '3vh',
      borderBottomLeftRadius:'3vh',
      borderBottomRightRadius:'3vh',
      selected: {
        backgroundColor: 'rgb(255,255,255,0.1)', // Cambia el color de fondo para indicar la selección
      },
      img1: {
        height: '3vh',
      },
      img: {
        height: '2vh',
        marginTop: '-0.8vh',
      },
      imgScapp: {
        height: '2vh',
        marginTop: '0vh',
      },
      imgEnviro: {
        height: '3vh',
        marginTop: '-1.8vh',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'default !important', userSelect: 'none',
    },
    modalPaper: {
      // border: '0.01vh solid ',
      borderRadius:'2vh',
      textAlign: 'center',
      width: '25vw',
      height: '32vh',
      img:{
          height: '10vh',
          marginTop:'5vh'
      },
      content:{
          fontSize:'2.5vh',
          // marginTop:'2vh',
          // marginBottom:'3vh'
      },
      button:{
          fontSize:'2vh',
      }
    },
  };

const CentersPagination = ({  selectedRegion, selectedArea, consolidado_service, transformConsolidadoService, handleServiceClick, selectedService, capitalize, severityMap, translate }) => {
    
    const [filter, setFilter] = React.useState('ALL');
    
    if (!selectedRegion || !selectedArea) return null;

    // Obtener la lista de centros del área seleccionada
     const selectedCenters = selectedRegion.areas.find(area => area.name === selectedArea)?.centers || [];

     // Filtrar los centros según el filtro seleccionado
     const filteredCenters = selectedCenters.filter(centro => {
        if (filter === 'ALL') return true;
        if (filter === 'GOOD') return centro.statusCenter === 'A' || centro.statusCenter === 'C';
    });


    const pages = [];
    for (let i = 0; i < filteredCenters.length; i += 10) {
        pages.push(filteredCenters.slice(i, i + 10));
    }
    // console.log('pages', pages); // Verifica que tenga una página con 9 elementos

    const getRows = (centers) => {
        const rows = [];
        for (let i = 0; i < centers.length; i += 5) {
        rows.push(centers.slice(i, i + 5));
        }
        return rows;
    };
    // console.log('rows', getRows(filteredCenters));

  return (
    <Grid container justifyContent='center'>

            {/* <div style={{ display: 'flex', justifyContent: 'center', }}>
                <Button variant="contained" onClick={() => setFilter('ALL')}>Mostrar Todos</Button>
                <Button variant="contained" onClick={() => setFilter('GOOD')} style={{ marginLeft: '10px' }}>Centros con Fallas</Button>
            </div> */}
        <Swiper
        spaceBetween={10}
        slidesPerView={1}
        //   grid={{
        //     rows: 2,
        //   }}
        modules={[Pagination]}
        style={{ height: '82vh' }}
        >
        {pages?.map((centers, pageIndex) => (
            <SwiperSlide key={pageIndex}>
            <Grid container justifyContent="center" alignContent="center" style={{ height: '82vh',}}>
                {getRows(centers)?.map((row, rowIndex) => (
                <Grid container justifyContent="center" item xs={12} key={rowIndex} style={{ height: '33vh',  }}>
                    {row?.map((centro, index) => {
                    //   const serviciosStatus = transformConsolidadoService(consolidado_service)?.areas?.find(area => area.name === selectedArea)?.centers;
                    const serviciosStatus = transformConsolidadoService(consolidado_service)?.areas[index]?.centers;
                    const servicioScapp = serviciosStatus?.find(servicio => servicio?.name === "scapp");
                    const servicioEnviro = serviciosStatus?.find(servicio => servicio?.name === "enviro");
                    const servicioNanoox = serviciosStatus?.find(servicio => servicio?.name === "nanoox");
                    const servicioPss = serviciosStatus?.find(servicio => servicio?.name === "pss");
    // console.log('servicioPss AAAAAAAAAAAAAAAAA',servicioPss)
                    return (
                        <Grid container justifyContent="center" item xs={2} key={index} style={{ height: '34vh', width: '20vw', marginLeft:'1vw' }}>
                        <Card sx={{ width: '15vw', height: '30vh', borderRadius: '2vh' }}>
                            <Grid container justifyContent='center' alignContent='center'
                            style={{
                                height: '4vh', width: '11vw', textAlign: 'center', marginLeft: '1.5vw', marginTop: '0.9vh', position: 'absolute', justifyContent: 'center', borderRadius: '1vh',
                                backgroundColor: severityMap[centro.statusCenter]
                            }}>
                            <p style={{ fontSize: '2.5vh' }}> {capitalize(centro.name)} </p>
                            </Grid>
                            <Grid container justifyContent="center" style={{ height: '19vh', marginTop: '6.5vh' }} >
                            <Grid container justifyContent="center" alignContent='center' item xs={12} style={{ height: '9.5vh' }}>
                                <Grid container justifyContent='flex-end' item xs={6}>
                                <ImageSection
                                    imgSrc={Enviro}
                                    content={<img src={TextEnviro} style={style.content.imgScapp} alt="Enviro Text" />}
                                    isSelected={selectedService === 'Enviro'}
                                    handleClick={() => handleServiceClick(servicioEnviro)}
                                    serviceStatus={servicioEnviro}
                                    translate={translate}
                                />
                                </Grid>
                                <Grid container justifyContent='flex-start' item xs={6}>
                                <ImageSection
                                    imgSrc={Nanoox}
                                    content={<img src={TextNanoox} style={style.content.img} alt="Nanoox Text" />}
                                    isSelected={selectedService === 'Nanoox'}
                                    handleClick={() => handleServiceClick(servicioNanoox)}
                                    serviceStatus={servicioNanoox}
                                    translate={translate}
                                />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" alignContent='center' item xs={12} style={{ height: '9.5vh', marginTop: '0.5vh' }}>
                                <Grid container justifyContent='flex-end' item xs={6}>
                                <ImageSection
                                    imgSrc={Pss}
                                    content={<img src={TextPss} style={style.content.imgEnviro} alt="PSS Text" />}
                                    isSelected={selectedService === 'PSS'}
                                    handleClick={() => handleServiceClick(servicioPss)}
                                    serviceStatus={servicioPss}
                                    translate={translate}
                                />
                                </Grid>
                                <Grid container justifyContent='flex-start' item xs={6}>
                                <ImageSection
                                    imgSrc={Scapp}
                                    content={<img src={TextScapp} style={style.content.imgScapp} alt="SCAPP Text" />}
                                    isSelected={selectedService === 'Scapp'}
                                    handleClick={() => handleServiceClick(servicioScapp)}
                                    serviceStatus={servicioScapp}
                                    translate={translate}
                                />
                                </Grid>
                            </Grid>
                            </Grid>
                            <GradientDivider variant="middle" flexItem />
                            <Grid container justifyContent='flex-end' alignContent='center' alignItems='center' style={{ height: '4.5vh', marginTop: '-0.8vh', marginLeft: '-0.5vw' }}>
                            <FmdGoodIcon style={{ fontSize: '1.8vh', marginRight: '0.2vw', marginTop: '-0.2vh' }} />
                            <p style={{ fontSize: '1.5vh', marginRight: '0.2vw' }}> {capitalize(selectedArea)} , </p>
                            <p style={{ fontSize: '1.5vh' }}> {capitalize(selectedRegion.name)}</p>
                            </Grid>
                        </Card>
                        </Grid>
                    );
                    })}
                </Grid>
                ))}
            </Grid>
            </SwiperSlide>
        ))}
        </Swiper>
    </Grid>

  );
};

export default CentersPagination;
