import React from 'react'
import './css/loading.css';

const AmbientalLoader = ({ noDataAvailable }) => {
    return (
        <div className='wave'>
            {noDataAvailable ? (
                // Envolver el mensaje en un único elemento para cumplir con las restricciones de JSX.
                <span style={{ "--i": 1 }}>No hay datos disponibles</span>
            ) : (
                // Usar fragmentos para agrupar múltiples elementos sin añadir nodos extra al DOM.
                <>
                    <span style={{ "--i": 1 }}>C</span>
                    <span style={{ "--i": 2 }}>a</span>
                    <span style={{ "--i": 3 }}>r</span>
                    <span style={{ "--i": 4 }}>g</span>
                    <span style={{ "--i": 5 }}>a</span>
                    <span style={{ "--i": 6 }}>n</span>
                    <span style={{ "--i": 7 }}>d</span>
                    <span style={{ "--i": 8 }}>o</span>
                    <span style={{ "--i": 9 }}>.</span>
                    <span style={{ "--i": 10 }}>.</span>
                    <span style={{ "--i": 11 }}>.</span>
                </>
            )}
        </div>
    );
}

export default AmbientalLoader