import React,{useState, useRef, useEffect} from 'react';
import {Divider, Grid, Drawer, Button, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from "@mui/material/styles";

// import Logo from '../../assets/img/Nanoox.png'
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DrawerFooter from './utils/DrawerFooter';
import DrawerList from './utils/DrawerList';


const drawerWidth = '15vw';
const drawerHeight = '90vh';


const DrawerHeader = styled("div")(({ theme })  => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));



  

export default function TemporaryDrawer({
    open,
    setOpen,
    setNameCentro,
    handleCentroClick,
    translate,
    capitalize,
    SWVersion,
    handleLogoMouseEnter, handleLogoMouseLeave,
    theme,
    consolidado_estados,
    selectedRegion, setSelectedRegion,
    selectedArea, setSelectedArea,
    setMostrarAlertas,
    mostrarAlertas
}) {


    const [showRegions, setShowRegions] = useState(true);
    const [showAreas, setShowAreas] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [posicionArea, setPosicionArea] = useState(null)
    const [initialized, setInitialized] = useState(false);

    const [selectedCentro, setSelectedCentro] = useState(null);
    const drawerRef = useRef(null);
    const navigate = useNavigate();
    const styleFont='1vw'
    const styleFontText='0.75vw'
    const stylep175='0.6vw'
    const heightCelda ='5.6vh'
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    //* ------------------------ Función: handleHome ------------------------------------------------------
    //? Descripción: Redirige a la página de inicio al hacer clic en el menú de usuario.
    const handleHome = () => {
        window.location.href = "/home";
    };

            //* ------------------------ Función: handleExit ------------------------------------------------
    //? Descripción: Maneja el cierre del menú de usuario y realiza redirección.
    const handleExit = () => {
        window.location.href = "/"; // Cambia "/Añadir" por la ruta que deseas redirigir
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("empresa");
    };


    // *  -----------------------------  Función: handleRegionClick ------------------------------------------------
        //? Descripción: 
        //? Función utilizada para poder seleccionar Regiones
        //? Parámetros:
        // - region : objeto con atributo 'clientId' que corresponde al indice del cliente 'name' que corresponde a la region seleccionada
        //            y un atributo 'area' que es un array de objetos de las areas de la region seleccionada, objeto que corresponde al que se desbribe en los parametros de handleAreaClick 
        const handleRegionClick = (region) => {
            // console.log('region',region)
            setSelectedRegion(region);
        };
    
        // *  -----------------------------  Función: handleAreaClick ------------------------------------------------
        //? Descripción: 
        //? Función utilizada para poder seleccionar areas
        //? Parámetros:
        // - area : objeto con atributo 'name' que corresponde al area seleccionada y un atributo 'centers' que es un array de los centros de esa area
        const handleAreaClick = (area) => {
            setSelectedArea(area.name);
            // setCentro(area.centers)
            // console.log('area center seleccionada',area.centers)
            // console.log('areas seleccionada',area)
        };
        
        //  * --------------------------------------- useEffect: para cerrar el drawer al presionar fuera del cajon
        useEffect(() => {
        // Función para cerrar el Drawer cuando se hace clic fuera de él
        const handleOutsideClick = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            setOpen(false);
            }
        };
    
        // Agregar el event listener cuando el Drawer está abierto
        if (open) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            // Remover el event listener cuando el Drawer está cerrado
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    
        // Limpiar el event listener cuando el componente se desmonta
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
        }, [open]);
    
        
        useEffect(() => {
            if (!initialized && consolidado_estados?.length > 0) {
                const firstRegion = consolidado_estados[0];
                setSelectedRegion(firstRegion);
                localStorage.setItem('REGION', firstRegion.name);
    
                if (firstRegion.areas.length > 0) {
                    const firstArea = firstRegion.areas[0];
                    setSelectedArea(firstArea.name);
                    localStorage.setItem('AREA', firstArea.name);
                }
                setInitialized(true);
            }
        }, [consolidado_estados, initialized]);

        const DrawerListProps = {
            consolidado_estados : consolidado_estados,
            showOptions : showOptions, 
            handleHome : handleHome,
            styleFont : styleFont,
            translate : translate,
            setShowRegions : setShowRegions,
            handleExit : handleExit,
            showRegions : showRegions,
            selectedRegion : selectedRegion,
            capitalize : capitalize,
            showAreas : showAreas,
            handleAreaClick : handleAreaClick,
            selectedArea : selectedArea,
            posicionArea : posicionArea,
            setPosicionArea : setPosicionArea,
            selectedCentro : selectedCentro,
            handleCentroClick : handleCentroClick,
            handleRegionClick : handleRegionClick,
            setNameCentro : setNameCentro,
            navigate : navigate,
            setShowAreas : setShowAreas,
            open : open,
            setMostrarAlertas:setMostrarAlertas,
            mostrarAlertas:mostrarAlertas

      
        } 

        const DrawerFooterProps = {
            stylep175 : stylep175,
            translate : translate,
            SWVersion : SWVersion,

        }

  return (
    <Grid container justifyContent="center" item xs={1} sx={{ display: "flex" }} style={{ cursor: 'default !important', userSelect: 'none', }} 
        ref={drawerRef} 
        onMouseEnter={() => handleLogoMouseEnter()}
        onMouseLeave={() => handleLogoMouseLeave()}
    >
      <Drawer open={open}   variant="persistent" anchor="left"
        sx={{
        width: drawerWidth,
        // height:drawerHeight,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            width: drawerWidth,
            height:drawerHeight,
            marginTop:'6.7vh',
            marginLeft:'1vw',
            borderRadius:'2vh',
            border: 0,
            background:'linear-gradient(#393939, #1a1a1a)',
            display: 'flex',
            // backgroundColor:'#404048'
            // boxSizing: "border-box",
            // border:'solid 0.01vh '

        },
        // border:'solid 0.1vh'
        borderRadius:'2vh',
        }}>
        <DrawerHeader 
            onClick={toggleOptions}
          style={{ display: 'flex', justifyContent: 'space-between', minHeight:heightCelda, maxHeight:heightCelda,backgroundColor:'#252525', '&:hover': {
          backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
          }, }}
        >
            <Button onClick={toggleOptions} fullWidth color='inherit'>
                <Grid container justifyContent={'center'}>
                    <Grid container justifyContent={'flex-start'} alignContent={'center'} item xs={8}>
                        {/* <img
                            src={Logo}
                            style={{ paddingLeft: '0.5vw', width: '12.5vh', aspectRatio: '17/6', paddingTop: '1vh', marginTop:'-1vh' }}
                            alt={''}
                        /> */}
                    </Grid>
                    <Grid container justifyContent={'flex-end'} alignContent={'center'} item xs={4}>
                        {showOptions=== false ? (
                            <KeyboardArrowDownIcon style={{fontSize:styleFont}} />
                        ) : (
                            <KeyboardArrowUpIcon style={{fontSize:styleFont}} />
                        )}
                    </Grid>
                </Grid>
            </Button>
        </DrawerHeader>
        <Divider />
        <DrawerList  {...DrawerListProps}/>
        <DrawerFooter {...DrawerFooterProps}/>
      </Drawer>
    </Grid>
  );
}
