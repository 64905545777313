import React, { useState, useEffect } from "react";
import { Button, Menu, MenuItem , Grid, Typography } from '@mui/material';

import {  
      handleLogin
    , handleGetProblems
    , handleGetResolvedProblems 
    , handleGetEquipos
    , handleGetSLA
    , handleGetSLAsli
    , handleGetTriggerIds
    , handleGetAllProblems
    , handleGetHistoricalProblems
    , handleGetHostAvailability
    , handleGetTemplates
    , handleGetSLAReport } from "../utils/Api";

import Niveles from "../components/Problems/Niveles"
import PieChart from "../components/Problems/PieChart";
import Tabla from "../components/Problems/Tabla";
import Acciones from "../components/Problems/Acciones";

const Home = ({}) => {

  // console.log("authToken",authToken)

const [problems, setProblems] = useState([]); 
const [severityCounts, setSeverityCounts] = useState({}); 
const [severityCard, setSeverityCard] = useState([
  { nivel: 'No Clasificado',  color: '#97AAB3' },
  { nivel: 'Información',     color: '#5C7ACC' },
  { nivel: 'Advertencia',     color: '#CCA047' },
  { nivel: 'Media',           color: '#CC7F47' },
  { nivel: 'Alta',            color: '#B55C45' },
  { nivel: 'Muy Alta',        color: '#B14545' },
]);
const [page, setPage] = useState(0); // Página actual
const [rowsPerPage, setRowsPerPage] = useState(12); // Filas por página

  const [anchorEl, setAnchorEl] = useState(null); // Para controlar la apertura del menú
  const [intervalId, setIntervalId] = useState(null); // Para almacenar el ID del intervalo
  const [refreshInterval, setRefreshInterval] = useState(900); // Para almacenar el intervalo de actualización
  const [selectedProblem, setSelectedProblem] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
const handleNextPage = () => {
        if (page < Math.ceil(problems.length / rowsPerPage) - 1) {
          setPage(page + 1);
        }
      };
    
      const handlePreviousPage = () => {
        if (page > 0) {
          setPage(page - 1);
        }
      };

  const handleRefreshIntervalChange = (interval) => {
    setRefreshInterval(interval);
    handleGetProblems(setProblems); // Llamar a la función al cambiar el intervalo
    handleMenuClose(); // Cerrar el menú después de seleccionar una opción
  };

  useEffect(() => {
    // Limpiar el intervalo anterior al desmontar el componente
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);
  
  useEffect(() => {
    // Configurar el intervalo basado en la opción seleccionada
    const newIntervalId = setInterval(() => {
      handleGetProblems(setProblems);
    }, refreshInterval * 1000 * 60);
  
    // Limpiar el intervalo anterior al cambiar la opción
    return () => {
      clearInterval(newIntervalId);  // Limpiar el intervalo actual, no el anterior
    };
  }, [refreshInterval]);
  


useEffect(() => {
  const severityCounts = problems.reduce((acc, problem) => {
    const severity = problem.severity;
    acc[severity] = (acc[severity] || 0) + 1;
    return acc;
  }, {});
  setSeverityCounts(severityCounts);
}, [problems]);

// Función para obtener el total por nivel de severidad
const getTotalBySeverity = (severity) => severityCounts[severity] || 0;


  return (
    <Grid container justifyContent="center" alignContent={'center'} style={{height:''}}>
      {/* <Grid container justifyContent="flex-end">
        <Button onClick={handleMenuOpen}>Actualizar Problemas</Button>
      </Grid> */}
      <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleRefreshIntervalChange(10)}>
              Cada 10 segundos
            </MenuItem>
            <MenuItem onClick={() => handleRefreshIntervalChange(30)}>
              Cada 30 segundos
            </MenuItem>
            <MenuItem onClick={() => handleRefreshIntervalChange(60)}>
              Cada 1 minuto
            </MenuItem>
            <MenuItem onClick={() => handleRefreshIntervalChange(120)}>
              Cada 2 minutos
            </MenuItem>
            <MenuItem onClick={() => handleRefreshIntervalChange(300)}>
              Cada 5 minutos
            </MenuItem>
            <MenuItem onClick={() => handleRefreshIntervalChange(600)}>
              Cada 10 minutos
            </MenuItem>
            <MenuItem onClick={() => handleRefreshIntervalChange(900)}>
              Cada 15 minutos
            </MenuItem>
          </Menu>
      <Grid container justifyContent="flex-end" item xs ={7} style={{ marginLeft:'5.5vw', marginTop:'1vh'}} > 
        <Grid container justifyContent="center" item xs={12}  style={{backgroundColor:'#2b2b2b', borderRadius:'0.2vw', height:'8vw', minWidth:'67vw', maxWidth:'67vw', marginBottom:'0.3vh' }}>
          <Grid container justifyContent="center" alignContent={'center'} style={{backgroundColor:'#e84e0e',borderRadius:'0.2vw', fontSize:'1.4vw', height:'5vh'}} onClick={handleMenuOpen}>
              Problemas por Gravedad
          </Grid>
          <Grid container justifyContent="center" alignContent={'center'}  item xs={12} style={{ marginBottom:'1vh'}}>
              {severityCard.map((item, index) => (
                  <Niveles key={index} item={item} total={getTotalBySeverity(index.toString())} />
              ))}
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} alignContent={'center'} item xs={12} style={{ backgroundColor:'#2b2b2b', borderTopLeftRadius:'0.2vw',borderTopRightRadius:'0.2vw',   height:'70vh',minWidth:'67vw', maxWidth:'67vw',overflow:'hidden', maxWidth:'59vw' }}>
          <Tabla problemas={problems} page={page} rowsPerPage={rowsPerPage} setSelectedProblem={setSelectedProblem} />
        </Grid>
         {/* Controles de paginación */}
        <Grid container item justifyContent="space-between" alignContent={'center'} style={{ backgroundColor:'#2b2b2b',borderBottomLeftRadius:'0.2vw',borderBottomRightRadius:'0.2vw', marginTop: '-0.1vh', height:'4vh' ,minWidth:'67vw', maxWidth:'67vw',}}>
          <Button 
            variant="outlined" 
            color="inherit"
            onClick={handlePreviousPage} 
            disabled={page === 0}
            style={{height:'3vh', fontSize:'0.85vw', width:'6vw', marginLeft:'1vw'}}
          >
            Anterior
          </Button>
          <Typography style={{ fontSize:'0.85vw' }}>
            Página {page + 1} de {Math.ceil(problems.length / rowsPerPage)}
          </Typography>
          <Button 
            variant="outlined" 
            color="inherit"
            onClick={handleNextPage} 
            disabled={page >= Math.ceil(problems.length / rowsPerPage) - 1}
            style={{height:'3vh', fontSize:'0.85vw', width:'6vw', marginRight:'1vw'}}
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={3} style={{ marginLeft:'2.5vw', marginTop:'1vh'}}>
        <Grid container justifyContent={'flex-end'} alignContent={'center'} item xs={6} style={{ backgroundColor:'#2b2b2b', borderRadius:'0.2vw', height:'43vh', overflow:'hidden', minWidth:'30vw', maxWidth:'30vw', marginLeft:'2vw'}}>
          <PieChart problemas={problems}/>
        </Grid>
        <Grid container justifyContent={'flex-end'} alignContent={'center'} item xs={6} style={{ backgroundColor:'#2b2b2b', borderRadius:'0.2vw', height:'46vh',marginTop:'1vh', overflow:'hidden', minWidth:'30vw', maxWidth:'30vw', marginLeft:'2vw'}}>
          <Acciones problema={selectedProblem}/>
        </Grid>
      </Grid >

   <Grid>
   </Grid>
    </Grid>
  )
}

export default Home