
// -- Submuestreo de datos -- //
//* ----------------------------- Función: aplicarSubmuestreo --------------------------------------------
//? Descripción: Aplica sub-muestreo a los datos según la condición de batch_data y actualiza el estado local.
export function aplicarSubmuestreo(centerData, batch_data, handle_sensor_states, setLastTime, newLocalCenterData, lastTime) {
    // Parámetros:
    // - centerData: Datos originales del centro.
    // - batch_data: Booleano que indica si se está utilizando batch_data.
    // - handle_sensor_states: Función para manejar los estados de los sensores.
    // - setLastTime: Función para actualizar el estado de última hora.
    // - newLocalCenterData: Datos locales del centro actualizados.
    // - lastTime: Última hora registrada.
    centerData.forEach((station, idx_station) => {
        if (batch_data) {
            const limitePuntos = 3000; // ajustar según sea necesario
            const factorSubmuestreo = calcularFactorSubmuestreo(station.length, limitePuntos);
            const datosSubmuestreados = submuestrearDatos(station, factorSubmuestreo);

            datosSubmuestreados.forEach((one_data, idx_data) => {
                if (idx_data % factorSubmuestreo === 0) {
                    for (let idx_prof = 1; idx_prof <= 4; idx_prof++) {
                        const nombre = one_data['Nombre'];
                        const profundidad = one_data[`Profundidad_${idx_prof}`] || {};
                        const battery = one_data['Battery'];
                        if (profundidad) {
                            handle_sensor_states(profundidad, idx_station, idx_prof, newLocalCenterData, lastTime, setLastTime, battery, nombre);
                        }
                    }
                }
            });
        } else {
            for (let idx_prof = 1; idx_prof <= 4; idx_prof++) {
                const profundidad = station[`Profundidad_${idx_prof}`] || {};
                if (profundidad) {
                    handle_sensor_states(profundidad, idx_station, idx_prof, newLocalCenterData, lastTime, setLastTime);
                }
            }
        }
    });
}

//* ----------------------------- Función: calcularFactorSubmuestreo -------------------------------------
//? Descripción: Calcula el factor de sub-muestreo basado en la cantidad de datos y el límite de puntos.
function calcularFactorSubmuestreo(cantidadDatos, limitePuntos) {
    // Parámetros:
    // - cantidadDatos: Cantidad total de datos.
    // - limitePuntos: Límite de puntos a mantener.
    // Retorna: Factor de sub-muestreo.
    return Math.ceil(cantidadDatos / limitePuntos);
}

//* ----------------------------- Función: submuestrearDatos ----------------------------------------------
//? Descripción: Realiza el sub-muestreo de los datos según el factor de sub-muestreo.
function submuestrearDatos(datos, factorSubmuestreo) {
    // Parámetros:
    // - datos: Datos originales a sub-muestrear.
    // - factorSubmuestreo: Factor de sub-muestreo.
    // Retorna: Datos sub-muestreados.
    return datos.filter((_, index) => index % factorSubmuestreo === 0);
}

// -- Generación de NewLocalCenterData -- //
//* ----------------------------- Función: generar NewLocalCenterData ------------------------------------
//? Descripción: Genera los nuevos datos locales del centro a partir de los datos originales y existentes.
export function generarNewLocalCenterData(centerData, localCenterData, batch_data) {
    // Parámetros:
    // - centerData: Datos originales del centro.
    // - localCenterData: Datos locales existentes.
    // - batch_data: Booleano que indica si se está utilizando batch_data.
    // Retorna: Nuevos datos locales del centro en localCenterData

    // console.log('local center data: ',localCenterData)
    return centerData.map((station, dato_idx) => {
        // Calcula la longitud de profundidades basado en los datos locales existentes o usa 1 como valor predeterminado.
        const profundidadesLength = localCenterData[dato_idx]?.profundidades?.length || 1;
        // Genera un template para profundidades con arrays para DO2_val, O2_val, sal_val, y temp_val.
        const profundidadesTemplate = Array(profundidadesLength).fill(null).map((_, idx) => ({
            DO2_val: [],
            O2_val: [],
            sal_val: [],
            temp_val: [],
            ...localCenterData[dato_idx]?.profundidades[idx]
        }));

        return {
            nombre: batch_data ? station[0].Nombre : station.Nombre,
            profundidades: profundidadesTemplate,
            battery: [...(localCenterData[dato_idx]?.battery || [])],

        };
    });
}

// Filtra estaciones vacías, evitando su renderización, da errores con otros componentes
export function _generarNewLocalCenterData(centerData, localCenterData, batch_data) {
    // Parámetros:
    // - centerData: Datos originales del centro.
    // - localCenterData: Datos locales existentes.
    // - batch_data: Booleano que indica si se está utilizando batch_data.
    // Retorna: Nuevos datos locales del centro en localCenterData

   // Primero, mapea centerData a la estructura deseada.
    const mappedData = centerData.map((station, dato_idx) => {
        // Calcula la longitud de profundidades basado en los datos locales existentes o usa 1 como valor predeterminado.
        const profundidadesLength = localCenterData[dato_idx]?.profundidades?.length || 1;
        // Genera un template para profundidades con arrays para DO2_val, O2_val, sal_val, y temp_val.
        const profundidadesTemplate = Array(profundidadesLength).fill(null).map((_, idx) => ({
            DO2_val: [],
            O2_val: [],
            sal_val: [],
            temp_val: [],
            ...localCenterData[dato_idx]?.profundidades[idx]
        }));

        return {
            nombre: batch_data ? station[0].Nombre : station.Nombre,
            profundidades: profundidadesTemplate,
            battery: [...(localCenterData[dato_idx]?.battery || [])],

        };
    });

    console.log('mappedData: ', mappedData)
    
    // Ahora, filtra el resultado para excluir las estaciones que no tienen datos relevantes en profundidades.
    const filteredData = mappedData.filter(station => {
        const tieneDatosRelevantes = station.profundidades.some(profundidad =>
            profundidad.DO2_val.length > 0 || profundidad.O2_val.length > 0 ||
            profundidad.sal_val.length > 0 || profundidad.temp_val.length > 0
        );
        // console.log(`Estación ${station.nombre}, Tiene datos relevantes: ${tieneDatosRelevantes}`);
        return tieneDatosRelevantes;
    });

    // console.log('Datos filtrados:', filteredData);
    if(filteredData.length>0) return filteredData
    else return mappedData;
}


// -- Cambio entre profundidades -- //

//* ------------------------------- Función: handleNextDepth -----------------------------------------------
//? Descripción: Avanza a la siguiente página de profundidades para la estación y profundidad especificadas.

export const changeToNextDepth = (stationIndex, currentPageStation, profundidadesPerPage, currentView, setCurrentPageDepths, localCenterData) => {
    //? Parámetros:
    // - index: Índice de la estación 
    // * Salto en bloques:
    // const currentStationIndex = currentPageStation * profundidadesPerPage + stationIndex;
    // * Salto de "uno en uno"
    const currentStationIndex = stationIndex;
    // console.log('currentPageStation , stationIndex: ', currentPageStation, stationIndex)

    if (currentView === 'x1') {
        // Si currentView es 'x1', intercambiar los valores de las profundidades para invertir la paginación
        setCurrentPageDepths((prevDepths) => {
            const newDepths = [...prevDepths];
            const nextPageIndex = Math.min(
                newDepths[currentStationIndex] + profundidadesPerPage,
                localCenterData[currentStationIndex].profundidades.length - profundidadesPerPage
            );
            newDepths[currentStationIndex] = nextPageIndex;
            console.log('nextPageIndex', nextPageIndex)
            console.log('newDepths', newDepths);
            // Incrementar todos los demás valores
            // newDepths.forEach((depth, i) => {
                // if (i !== currentStationIndex) {
                    // newDepths[i] = Math.min(depth + profundidadesPerPage, localCenterData[i].profundidades.length - profundidadesPerPage);
                    // newDepths[i] = 0;
                // }
            // });
            console.log('newDepths', newDepths);

            return newDepths;
        });
    } else {
        // De lo contrario, paginación normal
        setCurrentPageDepths((prevDepths) => {
            const newDepths = [...prevDepths];
            const nextPageIndex = Math.min(
                newDepths[currentStationIndex] + profundidadesPerPage,
                localCenterData[currentStationIndex].profundidades.length - profundidadesPerPage
            );
            newDepths[currentStationIndex] = nextPageIndex;
            console.log('newDepths', newDepths);
            return newDepths;
        });
    }
};

//* ------------------------------- Función: handlePreviousDepth -------------------------------------------
//? Descripción: Retrocede a la página anterior de profundidades para la estación y profundidad especificadas.
//? Parámetros:
// - index: Índice de la estación
export const changeToPreviousDepth = (stationIndex, currentPageStation, profundidadesPerPage, currentView, setCurrentPageDepths, localCenterData) => {
    // * Salto en bloques:
    // const currentStationIndex = currentPageStation * profundidadesPerPage + stationIndex;
    // * Salto de "uno en uno"
    const currentStationIndex = stationIndex;
    // console.log('currentPageStation , stationIndex: ', currentPageStation, stationIndex)

    if (currentView === 'x1') {
        // Si currentView es 'x1', intercambiar los valores de las profundidades para invertir la paginación
        setCurrentPageDepths((prevDepths) => {
            const newDepths = [...prevDepths];
            const prevPageIndex = Math.max(
                newDepths[currentStationIndex] - profundidadesPerPage,
                0
            );
            console.log('prevPageIndex', prevPageIndex)

            newDepths[currentStationIndex] = prevPageIndex;
            console.log('newDepths', newDepths);
            // Decrementar todos los demás valores
            // newDepths.forEach((depth, i) => {
                // if (i !== currentStationIndex) {
                    // newDepths[i] = Math.max(depth - profundidadesPerPage, 0);
                    // newDepths[i] = 0;
                // }
            // });

            console.log('newDepths', newDepths);
            return newDepths;
        });
    } else {
        // De lo contrario, paginación normal
        setCurrentPageDepths((prevDepths) => {
            const newDepths = [...prevDepths];
            const prevPageIndex = Math.max(
                newDepths[currentStationIndex] - profundidadesPerPage,
                0
            );
            newDepths[currentStationIndex] = prevPageIndex;
            console.log('newDepths', newDepths);
            return newDepths;
        });
    }
};


//* ------------------------ Función: convertDateFormat ------------------------------------------------
//? Descripción: Convierte el formato de fecha de 'YYYY-MM-DD HH:mm:ss' a 'MM-DD-YYYY HH:mm:ss'.
const convertDateFormat = (dateString) => {
    // Parámetros:
    // - dateString: Cadena de fecha en formato 'YYYY-MM-DD HH:mm:ss'.
    // Retorna: Cadena de fecha en formato 'MM-DD-YYYY HH:mm:ss'.
    // Descomponemos la fecha en sus partes
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('-');

    // Reconstruimos la fecha en el nuevo formato
    return `${month}-${day}-${year} ${time}`;
};
//* ------------------------ Función: getTimeDateTime -------------------------------------------------
//? Descripción: Obtiene la información de fecha y hora desde la profundidad dada.
const getTimeDateTime = (profundidad) => {
    // Parámetros:
    // - profundidad: Datos de la profundidad.
    // Retorna: Objeto con la información de fecha y hora.
    if (profundidad.O2 && profundidad.O2.time) {
        const datetime = Date.parse(convertDateFormat(profundidad.O2.time))
        const time = profundidad.O2.time
        return { datetime, time };
    }
    else if (profundidad.DO2 && profundidad.DO2.time) {
        const datetime = Date.parse(convertDateFormat(profundidad.DO2.time))
        const time = profundidad.DO2.time
        return { datetime, time };
    }
    else if (profundidad.Sal && profundidad.Sal.time) {
        const datetime = Date.parse(convertDateFormat(profundidad.Sal.time))
        const time = profundidad.Sal.time
        return { datetime, time };
    }
    else if (profundidad.Temp && profundidad.Temp.time) {
        const datetime = Date.parse(convertDateFormat(profundidad.Temp.time))
        const time = profundidad.Temp.time
        return { datetime, time };
    }
    else {
        return { datetime: null, time: null };
    }
}

//* ------------------------ Función: handle_sensor_states ----------------------------------------------
//? Descripción: Maneja los estados de los sensores y actualiza el estado local.
export function handle_sensor_states(profundidad, idx_station, idx_prof, localCenterData, lastTime, setLastTime, battery, nombre) {
    // Parámetros:
    // - profundidad: Datos de la profundidad.
    // - idx_station: Índice de la estación.
    // - idx_prof: Índice de la profundidad.
    // - localCenterData: Datos locales del centro.
    // - lastTime: Última hora registrada.
    // - setLastTime: Función para actualizar el estado de última hora.
    // - battery: Valor de la batería.
    // - nombre: Nombre de la estación.
    // Retorna: Datos actualizados de la estación.
    if (!profundidad) {
        return;
    }

    const { datetime, time } = getTimeDateTime(profundidad);

    if (!time || !datetime) { return }

    if (datetime > lastTime) {
        setLastTime(datetime);

        const newLocalCenterData = [...localCenterData];
        // console.log('newLocalCenterData',newLocalCenterData)

        const stationData = newLocalCenterData[idx_station] || { profundidades: [] };
        let profundidadData = stationData.profundidades[idx_prof - 1] || {
            // Nombre: idx_prof - 2,
            O2_val: [],
            DO2_val: [],
            sal_val: [],
            temp_val: [],
        };

        // Asegúrate de que todas las propiedades sean arrays antes de usar el operador de propagación
        profundidadData = {
            ...profundidadData,
            O2_val: profundidad.O2 ? [...(Array.isArray(profundidadData.O2_val) ? profundidadData.O2_val : []), { time, value: profundidad.O2.data }] : profundidadData.O2_val,
            DO2_val: profundidad.DO2 ? [...(Array.isArray(profundidadData.DO2_val) ? profundidadData.DO2_val : []), { time, value: profundidad.DO2.data }] : profundidadData.DO2_val,
            sal_val: profundidad.Sal ? [...(Array.isArray(profundidadData.sal_val) ? profundidadData.sal_val : []), { time, value: profundidad.Sal.data }] : profundidadData.sal_val,
            temp_val: profundidad.Temp ? [...(Array.isArray(profundidadData.temp_val) ? profundidadData.temp_val : []), { time, value: profundidad.Temp.data }] : profundidadData.temp_val,
            profundidad: profundidad.profundidad
        };

        let batteryData = stationData.battery || [];
        if (battery) {
            batteryData = [...batteryData, { time, value: battery }];
        }

        let nombreData = stationData.nombre || '';
        if (nombre) {
            nombreData = nombre
        }

        stationData.profundidades[idx_prof - 1] = profundidadData;
        stationData.battery = batteryData;
        stationData.nombre = nombreData;

        return stationData;
    }

    return null;
}