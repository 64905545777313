import React, {useState} from 'react'
import { Grid } from '@mui/material'
import Oxigeno from '../Sensores/Oxigeno'
import Salinidad from '../Sensores/Salinidad'
import Saturacion from '../Sensores/Saturacion'
import { Temperatura } from '../Sensores/Temperatura'
// import Chart from '../Grafico/grafico'
import AmbientalChart from '../../Grafico/AmbientalGraph'
import Alertas from '../alertas'

const Prof_2_x4 = (
    {
        alerta,profundidad, showOxigeno, showSaturacion, showSalinidad, showTemperatura, toggleOxigeno, translate,
        toggleSaturacion, toggleSalinidad, toggleTemperatura, title, pauseRequest, setPauseRequest, profundidadesPerPage, currentView 
    }) => {
    const commonProps = {
        // Tamaños generales
        fullwidth: '5.8vw',
        fullHeight: '7.5vh',
        //Tamaño de icono
        heightIMG: '4vh',
        widthIMG: '2vw',
        sinText: false,
        // ajuste de la card de oxígeno
        width: '2.5vw',
        height: '8vh',
        // ajuste de la posición del valor de oxígeno
        fontSizeH1: '2.3vh',
        fontSizeTypo1: '1.7vh',
        //tamaño card del valor de oxigeno
        width1: '3.3vw',
        height1: '8.5vh',
        // separación de la card de oxígeno y su valor
        marginLeft1: '-0.7vw',
    };

    const graphProps = {
        width: "32vw",
        height: "15.3vh",
        data1: profundidad.O2_val,
        data2: profundidad.DO2_val,
        data3: profundidad.sal_val,
        data4: profundidad.temp_val,
        title: title,
        titleSize: '14vw',
        showOxigeno: showOxigeno,
        showSaturacion: showSaturacion,
        showSalinidad: showSalinidad,
        showTemperatura: showTemperatura,
        toggleOxigeno: toggleOxigeno,
        toggleSaturacion: toggleSaturacion,
        toggleSalinidad: toggleSalinidad,
        toggleTemperatura: toggleTemperatura,
        // axisFontSize: 10,
        axisFontSize     : window.innerHeight*0.01 , //"20vh",
        legendFontSize: "1vh",
        // Activar Solicitudes de datos
        pauseRequest          : pauseRequest, 
        setPauseRequest       : setPauseRequest,
        profundidadesPerPage  : profundidadesPerPage,
        currentView           : currentView,
        translate             : translate
    }
    const initialAlerts = alerta?.alertasActivas || [];
    const [activeAlerts, setActiveAlerts] = useState(initialAlerts);

    return (
        <Grid container>
            <Grid container justifyContent="flex-start" alignContent={'center'} item xs={12}  >
                <h1 style={{ height:'1.5vh', fontSize:"1.5vh", fontWeight:600, color:'rgba(255,255,255,0.7)', marginLeft:'0.5vw'}}>
                    {profundidad.profundidad ? `${profundidad.profundidad}m` : ''}
                </h1>
            </Grid>
            <Alertas activeAlerts={activeAlerts} setActiveAlerts={setActiveAlerts} translate={translate}/>
            <Grid container justifyContent="center" item xs={9} height="16.5vh" style={{marginLeft: "-1vw" }}>
                <Grid item xs={12} style={{ marginLeft: "0.5vw",  }}>
                    <AmbientalChart
                        {...graphProps}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" item xs={3} height='6vh' marginTop='-2vh' /* style={{  marginLeft: "-0vw" }} */ >
                {/* Cards Oxigeno */}
                <Grid item xs={6}>
                    <Oxigeno
                        showOxigeno={showOxigeno}
                        toggleOxigeno={toggleOxigeno}
                        {...commonProps}
                        medicion={profundidad.O2_val[profundidad.O2_val.length - 1] && profundidad.O2_val[profundidad.O2_val.length - 1].value ? profundidad.O2_val[profundidad.O2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Saturacion */}
                <Grid item xs={6} >
                    <Saturacion
                        showSaturacion={showSaturacion}
                        toggleSaturacion={toggleSaturacion}
                        {...commonProps}
                        medicion={profundidad.DO2_val[profundidad.DO2_val.length - 1] && profundidad.DO2_val[profundidad.DO2_val.length - 1].value ? profundidad.DO2_val[profundidad.DO2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Salinidad */}
                <Grid item xs={6} >
                    <Salinidad
                        showSalinidad={showSalinidad}
                        toggleSalinidad={toggleSalinidad}
                        {...commonProps}
                        medicion={profundidad.sal_val[profundidad.sal_val.length - 1] && profundidad.sal_val[profundidad.sal_val.length - 1].value ? profundidad.sal_val[profundidad.sal_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Temperatura */}
                <Grid item xs={6} >
                    <Temperatura
                        showTemperatura={showTemperatura}
                        toggleTemperatura={toggleTemperatura}
                        {...commonProps}
                        medicion={profundidad.temp_val[profundidad.temp_val.length - 1] && profundidad.temp_val[profundidad.temp_val.length - 1].value ? profundidad.temp_val[profundidad.temp_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Prof_2_x4