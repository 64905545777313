import React from 'react'
import { Grid, Button, Typography, Box } from "@mui/material";
import TemperaturaIcon from "./img/termometro.png";

export const Temperatura = (props) => {
    
    const borderColor = 'rgba(255, 18, 3, 0.75)'
    const getColor = () => {
        return props.showTemperatura && ( props.medicion !== "N/D" ) ? 'rgb(203, 22, 6, 0.1)' : 'rgb(203, 22, 6, 0)';
    };

    return (

            <Button style={{ backgroundColor: getColor() }} onClick={props.toggleTemperatura} sx={{ border: `0.15vh solid ${borderColor}`, height:props.fullHeight, width:props.fullwidth }} disabled={props.medicion === 'N/D'} > 
                <Grid container spacing={0} alignItems="top" justifyContent="center"   >
                    {/* Card de oxígeno */}
                    <Grid item xs={6} >
                        <Box display='flex' direction="column" alignItems="center" justifyContent="center" width={props.width} height={props.height} >
                            <img src={TemperaturaIcon} alt="Icono" style={{marginLeft:props.marginLeft1, width: props.widthIMG, height: props.heightIMG, position: 'center' }} />
                            {!props.sinText && (
                                <Typography style={{ fontSize: props.fontSize, cursor: 'default !important', userSelect: 'none' }} color="white">
                                    {props.temperatura}
                                </Typography>
                            )}
                        </Box>
                    </Grid>

                    {/* Card de número */}
                    <Grid item xs={6} >
                        <Box style={{ width: props.width1, height: props.height1, backgroundColor: "transparent", marginLeft: props.marginLeft1, textTransform: 'none' }}>
                            <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '95%', cursor: 'default !important', userSelect: 'none' }}>
                                <Typography variant="h1" style={{ fontSize: props.fontSizeH1, fontWeight: 1000, color: 'white' }}>
                                    {props.medicion}
                                </Typography>
                                <Typography variant="h1" style={{ fontSize: props.fontSizeTypo1, fontWeight: 300, color: 'white' }}>
                                    °C
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Button>
    );
};



/* 

export const Temperatura = (props) => {
    return (
        <Grid container spacing={0} alignItems="top" justifyContent="center" >
            <Grid item xs={6}>
                <Button
                    style={{
                        minWidth: "1vw",
                        width: props.width,
                        height: props.height,
                        backgroundColor: 'transparent'// props.showTemperatura ? "rgb(65, 105, 225)" : "rgb(65, 105, 225,0.4)"
                    }}
                    onClick={props.toggleTemperatura}
                >
                    <Grid container direction="column" alignItems="center" justifyContent="center">
                        <img src={TemperaturaIcon} alt="Icono" style={{ width: props.widthIMG, height: props.heightIMG }} />
                        {!props.sinText && (
                            <Typography style={{ fontSize: props.fontSize, cursor: 'default !important', userSelect: 'none' }} color="white">
                                {props.temperatura}
                            </Typography>
                        )}
                    </Grid>
                </Button>
            </Grid>

            <Grid item xs={6} >
                <Card style={{ width: props.width1, height: props.height1, backgroundColor: "black", border: "0.1vh solid white", marginLeft: `${props.marginLeft1}` }}>
                    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '95%' , cursor: 'default !important', userSelect: 'none'}}>
                        <Typography variant="h1" style={{ fontSize: props.fontSizeH1, fontWeight: 1000 }}>
                            {props.medicion}
                        </Typography>
                        <Typography variant="h1" style={{ fontSize: props.fontSizeTypo1, fontWeight: 1000 }}>
                            °C
                        </Typography>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};



 */