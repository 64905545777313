import moment from 'moment';

const LOCAL_SERVER_URL = `${localStorage.getItem("serverName")}` ;            // Servidor ITG, accedido internamente
// const ITG_SERVER_URL_2 = "http://ambiental.itgchile.com:4023"     // Servidor ITG, accedido externamente
const LOCAL_IP = "http://localhost:4000"

const URL_TO_TEST = [LOCAL_SERVER_URL]

// Verifica cual dirección debe utilizar para comunicar con servidor
export async function _SERVER_URL() {
    // Número máximo de intentos
    const maxAttempts = 3;
    let attempts = 0;
    
    while (attempts < maxAttempts) {
        try {
            const controller = new AbortController();
            const signal = controller.signal;
            const timeout = setTimeout(() => {
                controller.abort();
            }, 5000);
    
            const URL_EMPRESA = LOCAL_SERVER_URL; // Modifica esta línea con la URL que deseas usar
            const ENDPOINT_TEST = 'check_server_status';
    
            ////console.log(URL_EMPRESA);
    
            const publicResponse = await fetch(`${URL_EMPRESA}/${ENDPOINT_TEST}`, { method: 'HEAD', signal, keepalive: false });
            clearTimeout(timeout);
    
            ////console.log((publicResponse && publicResponse.ok) ? 'IP Pública' : 'IP Local');
    
            return (publicResponse && publicResponse.ok) ? `${URL_EMPRESA}` : LOCAL_SERVER_URL;
    
        } catch (error) {
            console.error('Error al intentar conectarse:', error);
            attempts++;
            if (attempts < maxAttempts) {
                ////console.log(`Reintentando en 2 segundos (intentos restantes: ${maxAttempts - attempts})`);
                await new Promise(resolve => setTimeout(resolve, 2000));
            } else {
                ////console.log('Se alcanzó el número máximo de intentos. No se pudo establecer conexión.');
                return "Error en conexión";
            }
        }
    }
    
}

export const CLIENTNAME = (async () => {

    const LOCAL_STORAGE_KEY = 'clientName';

    // Intenta recuperar el valor de clientName de localStorage
    const storedClientName = localStorage.getItem(LOCAL_STORAGE_KEY);
    // ////console.log('Nombre de cliente cargado: ', storedClientName ? storedClientName : 'local')
    return storedClientName ? storedClientName : 'local'

})();   // Autoinvodaca!

// ! loggedIn -> False para local ; True para versión online


// -- API COMPLETA -- //
// ? Función para obtener datos de un centro, con todo las estaciones 
export async function fetchRemoteCenter(Region, Area, Centro, allDayData = false, loggedIn = true) {
    // ////console.log('%c OBTENIENDO CENTROS DE: ', 'color:green; font-size:20px', Region, Area, Centro)
    try {
        // Obtener la URL del servidor
        let SERVER_URL

        if (loggedIn) SERVER_URL = await LOCAL_SERVER_URL;
        else SERVER_URL = LOCAL_IP

        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return null;
        }
        // ////console.log('Conexion correcta: ', SERVER_URL)
        // Construir la URL completa
        const endpoint =  `/enviro/get_all_day_data/${Region}/${Area}/${Centro}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        // ////console.log('%c FULL URL: ', 'color:orange; font-size:20px', fullUrl)

        const response = await fetch(fullUrl);
        if (response.ok) {
            const data = await response.json();
            // ////console.log('data api', data)
            return data;
        } else {
            console.error(`Error en fetchCenter: ${response.status}`);
            return null;
        }
    } catch (error) {
        console.error(`Fetch failed in fetchCenter: ${error}`);
        return null;
    }
}


// ? Múltiples días
export async function fetchRemoteCenterDated(Region, Area, Centro, dateRange, loggedIn = true) {
    try {
        // Obtener la URL del servidor
        // Obtener la URL del servidor
        let SERVER_URL

        if (loggedIn) SERVER_URL = await LOCAL_SERVER_URL;
        else SERVER_URL = LOCAL_IP


        // Si no se pudo obtener la URL del servidor, retornar null
        if (!SERVER_URL || SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return null;
        }
        // ////console.log('Conexion correcta: ', SERVER_URL)
        const endpoint = `/enviro/get_multiple_days_data/${Region}/${Area}/${Centro}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        // ////console.log('fullUrl: ', fullUrl)

        // Realizar la solicitud POST
        const response = await fetch(fullUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateRange)  // Convertir el objeto a una cadena JSON
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error(`Error en fetchCenterDated: ${response.status}`);
            return null;
        }
    } catch (error) {
        console.error(`Fetch failed in fetchCenterDated: ${error}`);
        return null;
    }
}


// ? Obtener centros disponibles
export async function getAvailableCenters(loggedIn) {
    try {
        // if (!loggedIn) return [{ 'local': { 'local': ['local'] } }]

        let SERVER_URL
        // SERVER_URL = await _SERVER_URL;
        
        if (loggedIn) SERVER_URL = await _SERVER_URL;
        else SERVER_URL = LOCAL_IP
        
        ////console.log('SERVER_URL',SERVER_URL)
        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return {};
        }

        const endpoint = `/get_available_centers`;
        const fullUrl = `${SERVER_URL}${endpoint}`;

        const response = await fetch(fullUrl);
        if (response.ok) {
            const data = await response.json();

            // ////console.log('respuesta correcta centro: ', data)
            return data['centers'];
        } else {
            console.error(`Error en getAvailableCenters: ${response.status}`);
            return [{ 'local': { 'local': ['local'] } }]; //{};
        }

    } catch (error) {
        console.error(`Fetch failed in getAvailableCenters: ${error}`);
        return [{ 'local': { 'local': ['local'] } }]; //{};
    }


}



// -- API SIMPLIFICADA -- //
// ? Función para obtener datos de un centro, con todo las estaciones 
export async function fetchCenter(clientName, allDayData = false, loggedIn = true) {
    try {
        // Obtener la URL del servidor
        let SERVER_URL

        if (loggedIn) SERVER_URL = await _SERVER_URL;
        else SERVER_URL = LOCAL_IP

        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return null;
        }
        // ////console.log('Conexion correcta: ', SERVER_URL)
        // Construir la URL completa
        const endpoint = allDayData ? `/enviro/all_day_data/${clientName}` :  `/enviro/latest_data/${clientName}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;

        const response = await fetch(fullUrl);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error(`Error en fetchCenter: ${response.status}`);
            return null;
        }
    } catch (error) {
        console.error(`Fetch failed in fetchCenter: ${error}`);
        return null;
    }
}


// ? Múltiples días
export async function fetchCenterDated(clientName, dateRange, loggedIn = true) {
    try {
        // Obtener la URL del servidor
        // Obtener la URL del servidor
        let SERVER_URL

        if (loggedIn) SERVER_URL = await _SERVER_URL;
        else SERVER_URL = LOCAL_IP


        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return null;
        }
        // ////console.log('Conexion correcta: ', SERVER_URL)
        const endpoint = `/enviro/multiple_days_data/${clientName}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        ////console.log('fullUrl: ', fullUrl)
        // ////console.log('fullUrl: ',fullUrl)

        // Realizar la solicitud POST
        const response = await fetch(fullUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateRange)  // Convertir el objeto a una cadena JSON
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error(`Error en fetchCenterDated: ${response.status}`);
            return null;
        }
    } catch (error) {
        console.error(`Fetch failed in fetchCenterDated: ${error}`);
        return null;
    }
}



export async function fetchData(allDayData, firstData, loggedIn, maximum_data_requested, pauseRequest, requestedData, 
    setBatchData, setCenterData, setClientName, setFirstData, setRequestedData, setNoDataAvailable,
    fetchDatedData) {
    // const allDayData = !firstData

    if (!pauseRequest) {
        // Si se supera el máximo de datos, se solicita nuevamente los datos del día
        if (requestedData >= maximum_data_requested) {
            ////console.log('Liberando memoria...')
            const dateRange = {
                startDate: moment(new Date()).format('DD/MM/YYYY'),                     // Hoy
                endDate: moment(new Date()).format('DD/MM/YYYY'),                       // Hoy
            }
            fetchDatedData(dateRange)
        }
        // En caso contrario, se procede a solicitar el nuevo dato
        else {
            // Obtener credenciales del cliente
            let region, area, centro
            try {
                region = localStorage.getItem('Ambiental_ClientRegion')
                area = localStorage.getItem('Ambiental_ClientArea')
                centro = localStorage.getItem('Ambiental_ClientCentro')
            }
            catch {
                region = null
                area = null
                centro = null
            }

            let newCenterData

            // ////console.log('region, area, centro: ', region, area, centro)

            let useCompleteAPI
            if (centro && centro !== 'Cementerio' && centro !== 'local' && centro !== 'DEMO_CEMENTERIO') {
                useCompleteAPI = true
            }

            if (useCompleteAPI) {
                // Obtener datos desde servidor
                newCenterData = await fetchRemoteCenter(region, area, centro, allDayData, loggedIn);
                setClientName(await centro)
                // if (newCenterData) ////console.log('newCenterData de ', centro, ':', newCenterData['sensor_data'])
            }

            else {
                // Obtener credenciales por defecto
                let clientName
                clientName = localStorage.getItem('clientName') || 'local'
                // Caso particular DEMO
                if (region && area && centro && region === 'Loslagos' && area === 'Chiloe' && centro === 'Cementerio') clientName = 'DEMO_CEMENTERIO'
                // Obtener datos desde servidor
                newCenterData = await fetchCenter(clientName, allDayData, loggedIn);     // fetchRemoteCenter
                setClientName(await clientName)

                if (newCenterData) console.log('newCenterData de ', clientName, ':', newCenterData['sensor_data'])
                //console.log('allDayData: ', allDayData)
            }

            if (newCenterData) {
                setNoDataAvailable(false)
                setBatchData(newCenterData['batch_data'])
                setCenterData(newCenterData['sensor_data']);
                // Aumenta contador de datos solicitados
                setRequestedData(requestedData + 1)
                if (!firstData) setFirstData(true)
            }
            else { console.log('Error recibiendo datos'); setNoDataAvailable(true) }
        }
    }
    else{
        console.log('Solicitudes en pausa');
    }
};




export async function downloadReport(Region, Area, Centro, dateRange, loggedIn = true, setNotification_msg, setNotification_open, setNotification_status) {
    try {
        // Obtener la URL del servidor
        let SERVER_URL
        let endpoint
        let fullUrl

        // Definir url a solicitar
        if (loggedIn) {
            SERVER_URL = await LOCAL_SERVER_URL;
            // Si no se pudo obtener la URL del servidor, retornar null
            if (!SERVER_URL || SERVER_URL === "Error en conexión") {
                console.error("No se pudo establecer una conexión con el servidor.");
                setNotification_msg('No se pudo establecer una conexión con el servidor.');
                setNotification_open(true);
                setNotification_status('error')
                setTimeout(() => {
                    setNotification_open(false);
                }, 3000);
                return null;
            }
            // ////console.log('Conexion correcta: ', SERVER_URL)
            endpoint = `/enviro/generate_data_report/${Region}/${Area}/${Centro}`;
            fullUrl = `${SERVER_URL}${endpoint}`;
            // ////console.log('fullUrl: ', fullUrl)
        }

        else {
            SERVER_URL = LOCAL_IP
            let clientName = localStorage.getItem('clientName') || 'local'
            endpoint = `/enviro/generate_data_report/${clientName}/${clientName}/${clientName}`;
            fullUrl = `${SERVER_URL}${endpoint}`;
        }

        // Realizar la solicitud POST
        const response = await fetch(fullUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateRange)  // Convertir el objeto a una cadena JSON
        });

        // Solicitar reporte

        if (response.ok) {
                setNotification_open(false)
            if (loggedIn) {
                // Obtener la respuesta como un Blob
                const blob = await response.blob();

                // Crear un URL para el Blob
                const downloadUrl = window.URL.createObjectURL(blob);

                // Crear un enlace temporal para la descarga
                const a = document.createElement("a");
                a.href = downloadUrl;

                // Asignar nombre del reporte
                let reportName
                const startDateStr = dateRange.startDate.split('/').reverse().join('-')
                const endDateStr = dateRange.endDate.split('/').reverse().join('-')
                const startDate = new Date(startDateStr);
                const endDate = new Date(endDateStr);

                if (startDate.getTime() === endDate.getTime()) {
                    reportName = `Reporte_${Centro}_${startDateStr}.xlsx`
                } else {
                    // Si las fechas son diferentes, usa ambas fechas en el nombre del archivo
                    reportName = `Reporte_${Centro}_${startDateStr}_${endDateStr}.xlsx`
                }

                a.download = reportName;        // "report.xlsx"; // O asignar un nombre de archivo dinámicamente si es necesario
                document.body.appendChild(a);
                a.click();

                // Limpiar y liberar recursos
                window.URL.revokeObjectURL(downloadUrl);
                document.body.removeChild(a);

                return true; // Indicar que la descarga fue exitosa
            }
            else{
                // Reporte guardado localmente, solo retornar
                return true
            }
        } else {
            console.error(`Error en fetchCenterDated: ${response.status}`);
            return null;
        }

    } catch (error) {
        console.error(`Fetch failed in fetchCenterDated: ${error}`);
        setNotification_msg('Error en la solicitud de descarga.');
        setNotification_status('error')
        setNotification_open(true);
        setTimeout(() => {
            setNotification_open(false);
        }, 3000);
        return null;
    }
}

// -- API ALERTAS -- //
export async function fetchAlertas() {
    try {
        let SERVER_URL = LOCAL_SERVER_URL

        const Region = localStorage.getItem('Ambiental_ClientRegion')
        const Area = localStorage.getItem('Ambiental_ClientArea')
        const Centro = localStorage.getItem('Ambiental_ClientCentro')

        // ////console.log('SERVER_URL postUmbralesAlertas', SERVER_URL)

        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return {};
        }
        const endpoint = `/ENVIRO/alertas_activas/${Centro}/${Area}/${Region}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        ////console.log('fullUrl', fullUrl)

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        ////console.log('respuesta servidor para alertas', response)

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        // ////console.log('Respuesta del servidor:', result);
        
        // Retornar el resultado
        return result;
    } catch (error) {
        console.error('Error:', error);
        return {};  // Retornar un objeto vacío en caso de error
    }
};


// -- API CERRAR ALERTAS -- //

export async function obtenerUmbrales() {
    try {
      let SERVER_URL = await LOCAL_SERVER_URL;
      const Centro = localStorage.getItem('Ambiental_ClientCentro');
      const Area = localStorage.getItem('Ambiental_ClientArea');
      const Region = localStorage.getItem('Ambiental_ClientRegion');
  
      if (SERVER_URL === "Error en conexión") {
        console.error("No se pudo establecer una conexión con el servidor.");
        return {};
      }
  
      const endpoint = `/ENVIRO/obtener_umbrales_actuales/${Centro}/${Area}/${Region}`;
      const fullUrl = `${SERVER_URL}${endpoint}`;
      //console.log('fullUrl', fullUrl);
  
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      //console.log('respuesta servidor', response);
  
      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
  
      const result = await response.json();
      //console.log('Respuesta del servidor:', result);
  
      return result; // Asegúrate de retornar el resultado aquí
    } catch (error) {
      console.error('Error:', error);
      return {}; // Retorna un objeto vacío en caso de error
    }
  }


// ! -- API estado del centro deprecado -- //
export async function fetchStatusCenters( ) {
    try {
        let SERVER_URL = await LOCAL_SERVER_URL;
        const Region = localStorage.getItem('Ambiental_ClientRegion')
        const Area = localStorage.getItem('Ambiental_ClientArea')
        const Centro = localStorage.getItem('Ambiental_ClientCentro')
        // ////console.log('SERVER_URL fetchStatusCenters', SERVER_URL)

        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return {};
        }
        const endpoint = `/ENVIRO/estado_centro/${Centro}/${Area}/${Region}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;

        console.log('respuesta fullUrl', fullUrl)


        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // ////console.log('respuesta servidor', response)

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        // ////console.log('Respuesta del servidor:', result);
        
        // Retornar el resultado
        return result;
    } catch (error) {
        console.error('Error:', error);
        return {};  // Retornar un objeto vacío en caso de error
    }
};

// /<SERVICE>/consolidado_estados', methods=['GET']

