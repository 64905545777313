import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


import DateSelector from "./utils/DateSelector";




const ModalStyle = {
    position: 'absolute',
    marginTop: '28vh',
    marginLeft: '29vw',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#282828',
    borderRadius: '1vw',
    // border: '1px solid',
    width:'47vw',
    height:'49vh'
};




function ModalCalendario({ fetchDatedData, openModal, setOpenModal, pauseRequest, setPauseRequest, makeReport, translate, idioma, setNotification_open, setNotification_msg }) {
    

    return (
        <>
            {openModal && // Asegura que el componente se desmonte por completo
                (/* Modal Fechas */
                    < Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                    >
                        <Box style={ModalStyle} >
                            <DateSelector setNotification_open={setNotification_open} setNotification_msg={setNotification_msg}  translate={translate} idioma={idioma} fetchDatedData={fetchDatedData} closeModal={() => setOpenModal(false)} pauseRequest={pauseRequest} setPauseRequest={setPauseRequest} makeReport={makeReport} />
                        </Box>
                    </Modal >
                )
            }
        </>
    );
}

export default ModalCalendario;
