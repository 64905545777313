import React, { useState,useContext, useEffect } from 'react'
import { TextField, Grid, Button } from "@mui/material"
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import O2 from "./assets/img/oxigeno.png";
import DO2 from "./assets/img/saturacion.png"
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';


import BombaCommonInOperativa from './BombaCommonInOperativa.jsx';

const StyleTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  //   borderLeft: '0.01vh solid white',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#161616',
  //   border: '0.01vh solid white',
    fontSize:"2vh"
    
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40vw",
  height: "50vh",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  border: '2px solid #000',
  borderRadius: "3vh",
};

const BombaOperativa = ({controlType,bomba, translate, color, idx_bomba}) => {

  const [observacion, setObservacion] = useState(bomba?.nota?.value); // Estado para almacenar el color actual
 
   // Control
   const [buttonState2, setButtonState2] = useState(bomba.Control === "1" ? true : false);
   const [buttonColor2, setButtonColor2] = useState(bomba.Control === "1" ? 'primary' : 'secondary');
 

  const [value, setValue] = useState(1); // ERROR
  // funcion para color de "led" inyeccion de O2
  const getColorIny = () => {
      // Comprobamos si el valor es menor o igual a cero
      if (bomba.RLY !== '1') {
      // Si es menor o igual a cero, devolvemos el valor en rojo
      return '#FF000000';
      } else{
          // De lo contrario, devuelve un color verde claro
          return "green";
      }
  };

  // funcion para color de voltaje 
  const getColor = () => {
      // Comprobamos si el valor es menor o igual a cero
      if (bomba.Vo <= 0) {
      // Si es menor o igual a cero, devolvemos el valor en rojo
      return "#161616";
      } else{
          // De lo contrario, devuelve un color azul claro
          // return "green";
          return "#161616";
      }
  };


  useEffect(() => {
    setButtonState2(bomba.Control === "1" ? true : false);
    setButtonColor2(bomba.Control === "1" ? 'primary' : 'secondary');
  }, [bomba.Control]);


// 
// console.log('bomba', bomba)
// console.log('control', controlType)
  return (
    <Grid container justifyContent='center' alignContent='center' item xs={12}   style={{ marginLeft:"0vw", height:"15.5vh"}}>
      {/* Bomba */}
      <Grid container justifyContent='center' alignContent='center' item xs={12}>
          <Grid container item xs={12} style={{ border: 'solid 1px #ccc',height:'14vh', borderRadius:"1vh", backgroundColor:color, zIndex:1, maxWidth:"13vw"}}>
            <Grid container  justifyContent={'center'} alignContent={'center'} item xs={3} style={{ height:'13.8vh',  borderTopLeftRadius:'0.4vw',borderBottomLeftRadius:'0.4vw',  backgroundColor: getColor()}}>
                  {/* <Grid container item xs={12} style={{ height: '14vh', marginTop: "1vh", backgroundColor: "#161616", borderRadius: "1vh", border: isSelected ? '2px solid blue' : '0.1px solid #ccc' }}> */}
                      <Grid container justifyContent='center' alignContent='center' item xs={12} style={{ fontSize: "0.6vw", color: "white",  height:'5.5vh', marginTop:'-1vh'  }}>
                          {translate('mode')}
                          <Button
                              variant="outlined"
                              // color={bomba.SEL === "1" ? "primary" : "secondary"}
                              color={bomba.InSel === '1'  ? "primary" : "secondary"}
                              style={{
                                  backgroundColor: "rgb(255,255,255,0)",
                                  minWidth: '3vw',
                                  maxWidth: '3vw',
                                  height: "2.5vh"
                              }}
                          >
                              {/* controlType */}
                              { bomba.InSel === '1'   ? (
                                  <h1 style={{ fontSize: "0.5vw", marginTop: "1vh", marginLeft: "-0.1vw" }}>
                                      Auto
                                  </h1>
                              ) : (
                                  <h1 style={{  fontSize: "0.5vw", minWidth:'50vw', marginTop: "1vh", marginLeft: "0.1vw" }}>
                                      {translate('manual')}
                                  </h1>
                              )}
                          </Button>
                          
                      </Grid>
                      <Grid container justifyContent='center' alignContent='center' item xs={12} style={{ fontSize: "0.6vw", marginTop: "-0.5vh", color: "white",  height:'5.5vh' }}>
                        {translate('control')}
                          <Button
                              variant="outlined"
                              color={buttonColor2}
                              style={{
                                  backgroundColor: "rgb(255,255,255,0)", minWidth: '3vw', maxWidth: '3vw', height: "2.5vh"
                              }}>
                              {!buttonState2 ?
                                  <h1 style={{ fontSize: "0.5vw", marginTop: "1vh", marginLeft: "-0.1vw" }}>Auto </h1>
                                  :
                                  <h1 style={{ fontSize: "0.5vw", minWidth:'50vw', marginTop: "1vh", marginLeft: "0.1vw" }}>{translate('manual')}</h1>}
                          </Button>
                      </Grid>
            </Grid>
            {/*  */}
            <Grid container justifyContent={'center'} alignContent={'center'} item xs={9} style={{borderLeft:"solid 0.1vh",height:'13.9vh'}}>
               {/* Lado con 3 cuadrículas */}
            <StyleTooltip  title={`${translate('ultima_medicion_voltaje')} ${bomba.last_measure_vdf}`}  placement="top" classes={{ tooltip: 'custom-tooltip' }}>
                <Grid container item xs={5} style={{marginTop:'0.9vh'}}>
                    {/* Corriente y Voltaje */}
                    {value !== 1 ? (
                        <Grid container justifyContent="center" alignContent="center" severity="warning" color='' 
                                style={{color:"rgb(217, 192, 154)",borderLeft:"solid 0.1vh rgb(232, 152, 35)",
                                        marginLeft:"0vw", fontSize:"1.2vw", minWidth:"4vw", maxHeight:"4vh", minHeight:"4vh",marginBottom:"-1vh",
                                        backGroundColor:"rgb(101, 73, 28)"}}>
                            {translate('sensor_failure')}
                            {/* Falla Sensor */}
                        </Grid>
                    ):(
                    <Grid container justifyContent="center" alignContent="center" item xs={12} 
                        style={{  
                            fontSize:"1vw", color:"white",
                            backgroundColor: getColor() 
                            }}
                    >
                        {bomba.Vo.toFixed(2)}V 
                    </Grid>
                    )}
                    {/* Inyeccion */}
                    <Grid container justifyContent="center"  alignContent="center"item xs={12} 
                    style={{ borderTop: 'solid 0.1vh #ccc', fontSize:"1vw", color:"white", backgroundColor: getColor()  /*backgroundColor: '#161616' */}}>
                        {bomba.Io.toFixed(2)}A 
                    </Grid>
                {/* Informaciones */}
                    <Grid container justifyContent="center" alignContent="center" item xs={12} style={{ borderTop: 'solid 0.1vh #ccc', fontSize:"1vw", color:"white",  backgroundColor: getColor()  }}>
                    <RadioButtonUncheckedIcon
                        style={{
                        fontSize: '0.8vw',
                        marginRight: '0.1vw',
                        marginTop: '0.7vh',
                        backgroundColor: getColorIny(),
                        color: 'white',
                        borderRadius: '1vh',
                        }}
                    />
                        Iny O2
                    </Grid>
                </Grid>       
             </StyleTooltip>
            {/* Lado con 2 cuadrículas */}
            <StyleTooltip key={idx_bomba} title={`${translate('ultima_medicion_sensores')} ${bomba.last_measure_sensor}`}  placement="top" classes={{ tooltip: 'custom-tooltip' }}>
                { bomba.O2 < 0 ? (
                    <Grid container justifyContent="center" alignContent="center" severity="warning" color=''  item xs={7}
                            style={{color:"rgb(217, 192, 154)",borderLeft:"solid 0.01vh rgb(232, 152, 35)",borderTopRightRadius:"1vh", borderBottomRightRadius:"1vh", 
                                    marginLeft:"0vw", fontSize:"1vw", minWidth:"4vw", maxHeight:"10vh", minHeight:"10vh",
                                    backGroundColor:"rgb(101, 73, 28)"}}>
                        {/* Error */} {translate('sensor_failure')}
                    </Grid>
                ):(
                    <Grid container item xs={7} style={{ height:'10vh',marginTop:'0.9vh'}}>
                        <Grid container justifyContent="center" item xs={12} style={{ bborderLeft: 'solid .00.1vh #ccc', color:"white" }}>
                            <Grid container justifyContent="center" item xs={12} style={{borderTopRightRadius:"0.7vh", borderLeft: 'solid 0.1vh #ccc', color:"white",backgroundColor: bomba.O2_color /*getColorForO2(bomba.O2)*/}}>
                                <Grid  container justifyContent="center" alignContent="center" item xs={4}>
                                    <img
                                        src={O2}
                                        alt="Icono"
                                        style={{ width: '1.4vw', aspectRatio:1, marginLeft: "-0.3vw", marginTop: "0.4vh" }}
                                    />
                                </Grid>
                                <Grid container justifyContent="center" alignContent="center"  item xs={8}>
                                    <Grid style={{ fontSize:"1.2vw", marginLeft: "-0.5vw"}}>
                                        {bomba.O2.toFixed(1)}mg
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" item xs={12} style={{  color:"white" }}>
                        <Grid container justifyContent="center" item xs={12} style={{borderTop: 'solid 0.1vh #ccc',borderLeft: 'solid 0.1vh #ccc', color:"white", backgroundColor: bomba.SO2_color /*getColorForO2(bomba.O2)*/  }}> 
                            <Grid  container justifyContent="center" alignContent="center" item xs={4}>
                                <img
                                    src={DO2}
                                    alt="Icono"
                                    style={{ width: '1.4vw', aspectRatio:1, marginLeft: "-0.3vw", marginTop: "0.4vh", }}
                                />
                            </Grid>
                            <Grid container justifyContent="center" alignContent="center"  item xs={8}>
                                <Grid style={{ fontSize:"1.2vw", marginLeft: "-0.3vw",}}>
                                    {bomba.SO2.toFixed(1)}%
                                    {/* {typeof bomba.SO2 === 'number' ? bomba.SO2.toFixed(2) : 'N/A'} */}

                                </Grid> 
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>      
                )}
            </StyleTooltip>

            {/* nota y numero de Bomba */}
            <Grid container item xs={12} style={{marginBottom:"1vh", borderTop:'solid 0.1vh', overflow:'hidden', borderBottomRightRadius:'0.5vw', backgroundColor: getColor()}}>
              <Grid container justifyContent="center" alignContent={'center'} item xs={3} style={{ 
                      // border: 'solid 0.1vh #ccc', 
                      // borderBottomLeftRadius:"0.7vh", 
                      fontSize: "1.25vw", 
                      color: "white",
                      backgroundColor: '#009a9a',
                      height:'3.68vh' }}>
                  {bomba.alias}
              </Grid>
              <Grid  container justifyContent="center" alignContent="center"  item xs={8}  style={{ fontSize:'1vw',overflow:'auto'}}>
                  {/* <Grid container justifyContent={'center'} 
                      style={{ 
                        minWidth: "10vw",
                        maxWidth: "10vw", 
                        height: "3.5vh", 
                        // borderRadius: "1vh", 
                        backgroundColor: "#161616", 
                        color: "white", 
                        // marginLeft: "0.2vw" ,
                        fontSize:'1vw',
                        border:'solid 0.1vh',
                        overflow:'auto'
                      }} */}
                  {/* > */}
                    {observacion}
                  {/* </Grid> */}
              </Grid>
            </Grid>
                    
            </Grid>
          </Grid>
          
      </Grid>
    </Grid>
  )
}

export default BombaOperativa