import Prof_1_Normal from './VistaX1/Prof_1_x1';
import Prof_2_Normal from './VistaX1/Prof_2_x1';
import Prof_3_Normal from './VistaX1/Prof_3_x1';
// import Prof_4_Normal from './VistaX1/Prof_4_x1';
import Prof_1_x2 from './VistaX2/Prof_1_x2';
import Prof_2_x2 from './VistaX2/Prof_2_x2';
import Prof_3_x2 from './VistaX2/Prof_3_x2';
// import Prof_4_x2 from './VistaX2/Prof_4_x2';
import Prof_1_x4 from './VistaX4/Prof_1_x4';
import Prof_2_x4 from './VistaX4/Prof_2_x4';


// Mapeo de vistas y profundidades a componentes específicos
export const depthComponentMap = {
    'x1': {
        1: Prof_1_Normal, // Componente Prof_1_Normal para 1 profundidad 
        2: Prof_2_Normal, // Componente Prof_2_Normal para 2 profundidades 
        3: Prof_3_Normal, // Componente Prof_3_Normal para 3 profundidades 
    },
    'x2': {
        1: Prof_1_x2, // Componente Prof_1_x2 para 1 profundidad 
        2: Prof_2_x2, // Componente Prof_2_x2 para 2 profundidades 
        3: Prof_3_x2, // Componente Prof_3_x2 para 3 profundidades 
    },
    'x4': {
        1: Prof_1_x4, // Componente Prof_1_x4 para 1 profundidad 
        2: Prof_2_x4, // Componente Prof_2_x4 para 2 profundidades 
        3: Prof_2_x4, // No hay Prof_3_x4, así que utilizamos Prof_2_x4 en su lugar
    },
};
