import React, {useState} from 'react'
import { Grid } from '@mui/material'
import Oxigeno from '../Sensores/Oxigeno'
import Salinidad from '../Sensores/Salinidad'
import Saturacion from '../Sensores/Saturacion'
import { Temperatura } from '../Sensores/Temperatura'
// import Chart from '../Grafico/grafico'
import AmbientalChart from '../../Grafico/AmbientalGraph'
import Alertas from '../alertas'

const Prof_2_x2 = ({ alerta,translate, profundidad, showOxigeno, showSaturacion, showSalinidad, showTemperatura, toggleOxigeno, toggleSaturacion, toggleSalinidad, toggleTemperatura, title, pauseRequest, setPauseRequest, profundidadesPerPage, currentView  }) => {

    const commonProps = {
        fullwidth:'9.8vw',
        fullHeight:'7.5vh',
        // ajuste de la card
        width: '3.5vw',
        height: '6.0vh',
        //Tamaño de icono
        heightIMG: '4.5vh',
        widthIMG: '2.3vw',
        sinText: false,
        // ajuste de la posición del valor de oxígeno
        fontSizeH1: '2.9vh',
        fontSizeTypo1: '2.1vh',
        //tamaño card del valor de oxigeno
        width1: '5.5vw',
        height1: '6vh',
        // separación de la card de oxígeno y su valor
        marginLeft1: '-1vw',
    };
    
    const graphProps = {
        width       : "37vw",
        height      : "29vh",
        profundidad : profundidad,
        data1       : profundidad.O2_val? profundidad.O2_val : null,
        data2       : profundidad.DO2_val? profundidad.DO2_val : null,
        data3       : profundidad.sal_val? profundidad.sal_val : null,
        data4       : profundidad.temp_val? profundidad.temp_val : null,
        title       : title,
        // titleSize   : '40vw',
        // axisFontSize        : 14,
        axisFontSize     : window.innerHeight*0.015 , //"20vh",
        legendFontSize      : "1.5vh",
        showOxigeno           : showOxigeno,
        showSaturacion        : showSaturacion,
        showSalinidad         : showSalinidad,
        showTemperatura       : showTemperatura,
        toggleOxigeno         : toggleOxigeno,
        toggleSaturacion      : toggleSaturacion,
        toggleSalinidad       : toggleSalinidad,
        toggleTemperatura     : toggleTemperatura,
        // Activar Solicitudes de datos
        pauseRequest          : pauseRequest, 
        setPauseRequest       : setPauseRequest,
        profundidadesPerPage  : profundidadesPerPage,
        currentView           : currentView,
        translate              : translate

    }
    const initialAlerts = alerta?.alertasActivas || [];
    const [activeAlerts, setActiveAlerts] = useState(initialAlerts);
    return (
        <Grid container height='40vh' style={{ marginTop: "0.5vh" }}>
            <Grid container justifyContent="flex-start" alignContent={'center'} item xs={12} >
                <h1 style={{fontSize:window.innerHeight*0.019 , marginLeft:"0.5vw", marginTop:'0vh', height:'2vh'}}>
                    {profundidad.profundidad ? `${profundidad.profundidad}m` : ''}
                </h1>
            </Grid>
            <Alertas activeAlerts={activeAlerts} setActiveAlerts={setActiveAlerts} translate={translate}/>
            <Grid container justifyContent="center" item xs={8} height="1vh" >
                <Grid container item xs={12} style={{marginTop:'1.2vh', paddingLeft:'0.5vw'}} >
                    <AmbientalChart 
                        {...graphProps}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end"  item xs={4} >
                {/* Cards Oxigeno */}
                <Grid item xs={12} marginLeft={'6vw'} height={'6.5vh'} >
                    <Oxigeno
                        showOxigeno={showOxigeno}
                        toggleOxigeno={toggleOxigeno}
                        {...commonProps}
                        medicion={profundidad.O2_val[profundidad.O2_val.length - 1] && profundidad.O2_val[profundidad.O2_val.length - 1].value ? profundidad.O2_val[profundidad.O2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                 {/* Cards Saturacion */}
                 <Grid item xs={12} marginLeft={'6vw'} height={'6.5vh'}  >
                    <Saturacion
                        showSaturacion={showSaturacion}
                        toggleSaturacion={toggleSaturacion}
                        {...commonProps}
                        medicion={profundidad.DO2_val[profundidad.DO2_val.length - 1] && profundidad.DO2_val[profundidad.DO2_val.length - 1].value ? profundidad.DO2_val[profundidad.DO2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Salinidad */}
                <Grid item xs={12} marginLeft={'6vw'} height={'6.5vh'} >
                    <Salinidad
                        showSalinidad={showSalinidad}
                        toggleSalinidad={toggleSalinidad}
                        {...commonProps}
                        marginTopIMG= "-1vh"
                        medicion={profundidad.sal_val[profundidad.sal_val.length - 1] && profundidad.sal_val[profundidad.sal_val.length - 1].value ? profundidad.sal_val[profundidad.sal_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                {/* Cards Temperatura */}
                <Grid item xs={12} marginLeft={'6vw'} height={'6.5vh'} >
                    <Temperatura
                        showTemperatura={showTemperatura}
                        toggleTemperatura={toggleTemperatura}
                        {...commonProps}
                        medicion={profundidad.temp_val[profundidad.temp_val.length - 1] && profundidad.temp_val[profundidad.temp_val.length - 1].value ? profundidad.temp_val[profundidad.temp_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Prof_2_x2