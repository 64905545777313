import React, { useState, useEffect } from 'react';
import { InputBase, Button, Grid, Typography, Alert, IconButton, Menu,MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range';

import {
  addDays,
  addWeeks,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import jp from 'date-fns/locale/ja';
import zh from 'date-fns/locale/zh-CN';
import fr from 'date-fns/locale/fr';
import ar from 'date-fns/locale/ar-SA';
import ru from 'date-fns/locale/ru';
import pt from 'date-fns/locale/pt-BR';
import no from 'date-fns/locale/nb'; // Para noruego bokmål

import './utils/css/small.css'
import Nanoox from '../assets/img/APP-nanoox-1 (003).png'
import Enviro from '../assets/img/APP enviro.png'
import Scapp from '../assets/img/APP-scapp.png'
import PSS from '../assets/img/APP pss.png'
import Centro from '../components/Centro';
import { CrearCentro, ObtenerCentros, EditarCentro } from '../utils/apiServer';
import TranslateIcon from '@mui/icons-material/Translate';
import Logo from '../assets/img/ITG_BLANCO.png';
import Traductor from '../components/Traductor';

const StyledInput = styled(InputBase)(({ theme }) => ({
   fontSize:'2.5vh',
   width:'20vw',
   height:'5vh',
  'label + &': {
    marginTop: theme.spacing(3),
    fontSize:'2.5vh',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: '2vh',
    width:'20vw',
    height:'2.5vh',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#e84e0e',
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.main}33`,
    },
  },
}));


const serviceImages = {
  enviro: Enviro,
  nanoox: Nanoox,
  pss: PSS,
  scapp: Scapp,
};

const CreateCenters = ({translate ,handleClick ,openMenuIdioma ,handleCloseIdioma ,handleLanguageChange, idioma}) => {
  const [centros, setCentros] = useState([]);
  const [centroSeleccionado, setCentroSeleccionado] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [editar, setEditar] = useState(false); // Variable de estado para indicar si se está editando
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // //console.log('centroSeleccionado',centroSeleccionado)
  const input_ranges = [{
    ...defaultInputRanges[1],
    label: translate('dias_desde_hoy'),
  },
  ]

  const calendar_range = [
    {
        ...defaultStaticRanges[2],
        label: 'Esta Semana',
        range: () => ({
            startDate: addDays(startOfWeek(addWeeks(new Date(), 0)), +1),
            endDate: addDays(endOfWeek(addWeeks(new Date(), 0)), +1)
        }),
    },
    {
      ...defaultStaticRanges[4],
      label: 'Este Mes'
    },
    {
      ...defaultStaticRanges[5],
      label: translate('Este Año'), // Etiqueta para el rango de fechas del año actual
      range: () => ({
        startDate: new Date(new Date().getFullYear(), 0, 1), // Primer día del año actual
        endDate: new Date(new Date().getFullYear(), 11, 31), // Último día del año actual
      }),
    },
    {
      ...defaultStaticRanges[2],
      label: translate('Este Año desde Hoy'), // Etiqueta para el rango de fechas del próximo año
      range: () => ({
        startDate: addDays(new Date(), 0), // Día siguiente al día actual
        endDate: addDays(new Date(), 365), // Próximos 365 días a partir de la fecha actual

      }),
    },
    /* 
        {
        ...defaultStaticRanges[5],
        label: 'Mes Pasado'
    },
    {
        ...defaultStaticRanges[5],
        label: 'Mes Pasado'
    }, */
]

  const [nuevoCentro, setNuevoCentro] = useState({
    enviro: { init: null, fin: null },
    nanoox: { init: null, fin: null },
    pss: { init: null, fin: null },
    scapp: { init: null, fin: null }
  });

  const [centroInfo, setCentroInfo] = useState({
    centro: '',
    area: '',
    region: ''
  });

  const [dateRange, setDateRange] = useState({
    selection: {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  });

  useEffect(() => {
    setIsButtonDisabled(!(centroInfo.centro && centroInfo.area && centroInfo.region));
  }, [centroInfo]);

  const [openModal, setOpenModal] = useState({ enviro: false, nanoox: false, pss: false, scapp: false });

  const handleChange = (e) => {
    let { name, value } = e.target;
  
    // Convertir letras mayúsculas a minúsculas y eliminar espacios al inicio y final
    value = value.toLowerCase().trim();
  
    // Actualizar el estado permitiendo cadena vacía
    setCentroInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };
  

  const Exit = () => {
    window.location.href = '/home';
  };
  const cancelEdit = () =>{
    setEditar(false)
  }


  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setDateRange({ selection: { startDate, endDate, key: 'selection' } });
    setNuevoCentro((prev) => ({
      enviro: { init: startDate.toISOString().split('T')[0], fin: endDate.toISOString().split('T')[0] },
      nanoox: { init: startDate.toISOString().split('T')[0], fin: endDate.toISOString().split('T')[0] },
      pss: { init: startDate.toISOString().split('T')[0], fin: endDate.toISOString().split('T')[0] },
      scapp: { init: startDate.toISOString().split('T')[0], fin: endDate.toISOString().split('T')[0] },
    }));
  };

  const handleOpenModal = (service) => {
    setOpenModal((prev) => ({ ...prev, [service]: true }));
  };

  const handleCloseModal = (service) => {
    setOpenModal((prev) => ({ ...prev, [service]: false }));
  };

  const resetForm = () => {
    setCentroInfo({
      centro: '',
      area: '',
      region: ''
    });
    setNuevoCentro({
      enviro: { init: null, fin: null },
      nanoox: { init: null, fin: null },
      pss: { init: null, fin: null },
      scapp: { init: null, fin: null }
    });
    setDateRange({
      selection: {
        startDate: new Date(),
        endDate: addDays(new Date(), 1),
        key: 'selection'
      }
    });
  };

  const getImageStyle = (service) => {
    if (service === 'nanoox' || service === 'enviro') {
      return { border: 'solid 0.1vh #242424', borderRadius:'2vh', width: '6vw', aspectRatio:1 };
    }
    return {width: '6vw', aspectRatio:1};
  };

  const actualizarListaCentros = async () => {
    try {
        const usuariosObtenidos = await ObtenerCentros();
        setCentros(usuariosObtenidos);
    } catch (error) {
        console.error('Error al obtener centros:', error);
    }
};
  
  useEffect(() => {
    // Llamar a la función para obtener y mostrar la lista de usuarios al cargar la página
    actualizarListaCentros();
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const centro_id = `${centroInfo.centro}-${centroInfo.area}-${centroInfo.region}`;
      const newCentro = { centro_id, ...nuevoCentro };
      //console.log('Centro guardado:', [newCentro]); 
      await CrearCentro([newCentro],  setAlertMessage, setAlertSeverity,translate)
      setCentros((prev) => [...prev, newCentro]);
      resetForm();
      await actualizarListaCentros();

    } catch (error) {
      console.error('Error al crear usuario:', error);
    }
  };



  const handleEdit = (centro) => {
    setEditar(true);
    setCentroSeleccionado(centro.centro_id);
    setCentroInfo({
      centro: centro.centro_id.split('-')[0],
      area: centro.centro_id.split('-')[1],
      region: centro.centro_id.split('-')[2]
    });
    setNuevoCentro({
      enviro: { init: centro.enviro.init, fin: centro.enviro.fin },
      nanoox: { init: centro.nanoox.init, fin: centro.nanoox.fin },
      pss: { init: centro.pss.init, fin: centro.pss.fin },
      scapp: { init: centro.scapp.init, fin: centro.scapp.fin }
    });
    setDateRange({
      selection: {
        startDate: new Date(centro.enviro.init),
        endDate: new Date(centro.enviro.fin),
        key: 'selection'
      }
    });
  };

  const handleConfirmEdit = async (e) => {
    try {
      e.preventDefault();
      const updatedCentro = {
        // centro_id: centroSeleccionado,
        ...nuevoCentro
      };
      //console.log('Centro actualizado:', [updatedCentro]);
      await EditarCentro(updatedCentro, setAlertMessage, setAlertSeverity, translate, centroSeleccionado );
      setCentros((prev) =>
        prev.map((centro) =>
          centro.centro_id === centroSeleccionado ? updatedCentro : centro
        )
      );
      resetForm();
      setEditar(false);
      await actualizarListaCentros();
    } catch (error) {
      console.error('Error al actualizar centro:', error);
    }
  };

  const capitalizeCenter = (str) => {
    if (!str) return '';
    const trimmedStr = str.split('-')[0].trim();
    return trimmedStr.charAt(0).toUpperCase() + trimmedStr.slice(1);
  };

//console.log('idioma',idioma)
  return (
    <Grid container justifyContent='center'  style={{ cursor: 'default !important', userSelect: 'none', }}>
        <Grid container justifyContent="flex-start" item xs={6} style={{marginTop:'-10vh'}}>
          <IconButton onClick={Exit} edge="start" color="inherit" aria-label="menu">
            <img
              src={Logo}
              style={{
                paddingLeft: '0.5vw',
                height: '16vh',
                aspectRatio: '1.4',
                marginTop: '1vh',
              }}
            />
          </IconButton>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={6} style={{marginTop:'-10vh'}}>
          <IconButton onClick={handleClick} style={{marginRight:'1vw', height:'3vh', marginTop:'3vh'}}>
            <TranslateIcon style={{fontSize:'2vh', color:'#e84e0e'}}/>
          </IconButton>
          {/* Menú de idiomas */}
          <Traductor handleLanguageChange ={handleLanguageChange} openMenuIdioma ={openMenuIdioma} handleCloseIdioma ={handleCloseIdioma} />
        </Grid>
        <Grid container justifyContent='center' style={{ height:'82vh',  marginTop:'-8vh'}}>
          <Grid container justifyContent='center' style={{height:'5vh'}}>
            <Typography variant="h4" style={{fontSize:'3.5vh'}}>
              {!editar ? `${translate('create_center')}` : `${translate('edit_ciclo')} ${capitalizeCenter(String(centroSeleccionado))}`}
            </Typography>
          </Grid>
          {/* contenido */}
          <Grid container justifyContent='center' alignContent='center'  style={{height:'42vh'}}>
            {/* CENTRO, AREA Y REGION */}
            {!editar &&
              <Grid container justifyContent='center' alignContent='center' item xs={2}   >
                  <Grid container justifyContent='center' item xs={12} style={{width:'10vw', height:'10vh'}}>
                      <StyledInput
                        name="region"
                        placeholder={translate('region')}
                        value={centroInfo.region}
                        onChange={handleChange}
                        fullWidth
                      />
                  </Grid>

                  <Grid container justifyContent='center' item xs={12} style={{width:'10vw', height:'10vh'}}>
                      <StyledInput
                        name="area"
                        placeholder={translate('area')}
                        value={centroInfo.area}
                        onChange={handleChange}
                        fullWidth
                      />
                  </Grid>
                  
                  <Grid container justifyContent='center' item xs={12} style={{width:'10vw', height:'10vh'}}>
                      {/* <Typography variant="h6">Centro</Typography> */}
                      <StyledInput
                        placeholder={translate('centro')}
                        name="centro"
                        value={centroInfo.centro}
                        onChange={handleChange}
                        fullWidth
                      />
                  </Grid>
              </Grid>
            }
            {/* CALENDARIO */}
            <Grid container justifyContent='center' alignContent='center' item xs={6} style={{marginTop:'3vh', marginLeft:'3vw'}} >
              <Grid  container justifyContent='center' 
              style={{ cursor: 'default !important', userSelect: 'none', height:'40vh', width:'47vw',
               backgroundColor:'#282828', borderRadius:'1vh'}} >
                <DateRangePicker
                  locale={
                    idioma === 'en' ? en :
                    idioma === 'jp' ? jp :
                    idioma === 'zh' ? zh :
                    idioma === 'fr' ? fr :
                    idioma === 'ar' ? ar :
                    idioma === 'ru' ? ru :
                    idioma === 'pt' ? pt :
                    idioma === 'es' ? es :
                    idioma === 'no' ? no :
                    null
                  }
                  weekStartsOn={1}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  direction="horizontal"
                  ranges={[dateRange.selection]}
                  onChange={handleDateChange}
                  editableDateInputs={true}
                  staticRanges={calendar_range} // This hides the static ranges
                  inputRanges={[]} 
                  style={{ width: '47vw' }}
                />
              </Grid>
            </Grid>
            {/* SERVICIOS */}
            <Grid container justifyContent='center' alignContent='center' item xs={2} >
              {Object.keys(serviceImages).map((servicio) => (
                    <Grid container justifyContent='center' alignContent='center' item xs={6} key={servicio} 
                    style={{ marginBottom: '2vh' , maxWidth: '12vw', height: '11vh' }}>
                    <img src={serviceImages[servicio]} alt={servicio} style={getImageStyle(servicio)} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {/* boton crear centro */}
          <Grid container justifyContent='center'  style={{height:'5vh'}}>
            <Button disabled={isButtonDisabled} onClick={ !editar ? handleSubmit : handleConfirmEdit} variant="outlined" color="inherit" style={{fontSize:'0.9vw', height:'4vh', width:'9vw'}}>
              {editar ? translate('editar_centro') : translate('crear_centro') }
            </Button>
          </Grid>
          {/* centros creados */}
          <Grid container justifyContent='center'style={{height:'32vh'}}>
            <Centro editar={editar} cancelEdit={cancelEdit} translate={translate} centros={centros} actualizarListaCentros={actualizarListaCentros} centroSeleccionado={centroSeleccionado}  setCentroSeleccionado={setCentroSeleccionado} handleEdit={handleEdit} />
          </Grid>

        </Grid>
        {/* Alert para mostrar mensaje */}
          {alertMessage && (
            <Alert
              severity={alertSeverity}
              onClose={() => setAlertMessage(null)}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999, // Asegura que la alerta esté en la parte superior
                fontSize:'2vh',
                
              }}
            >
              {alertMessage}
            </Alert>
          )}
        
    </Grid>
  );
};

export default CreateCenters;