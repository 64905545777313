import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import Login from "./pages/Login";
import Home from "./pages/Home";
import { depthComponentMap } from './components/Estaciones/stationManager';
// import Report from "./pages/Report";
// import Reporteria from "./pages/reporteria";
// import Reporteria from "./pages/ReportLocal";
import AmbientalAppBar from "./components/AmbientalAppbar/AmbientalAppBar";
import { fetchData, fetchCenterDated, fetchRemoteCenterDated, downloadReport, fetchStatusCenters, fetchAlertas } from "./utils/Server_api";
import { fetchConsolidadoCenters } from "../../utils/apiServer";

import Notification from "./components/Notifier";
import { Grid } from "@mui/material";
import moment from 'moment';

import { transformData } from '../../utils/TransformarDataConsolidados';

const SWVersion = 'v20240530'

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#333', // Cambia este color al que desees
        },
    },
});


const MainEnviro = ({translate ,handleClick ,openMenuIdioma ,handleCloseIdioma ,handleLanguageChange, idioma, selectedRegion,setSelectedRegion, selectedArea, setSelectedArea}) => {
    
    const [consolidado_estados, setConsolidado_estados] = useState({});
    const [statusCenter,setStatusCenter] = useState('')
    const [alertas, setAlertas] = useState([]);
    // * ----------------------- Estados para la manipulación de datos de centros -----------------------
    //? Descripción:
    //? - centerData: Almacena los datos del centro actualmente seleccionado.
    // Estructura de datos: array de objetos, donde cada objeto representa una estacion.
    // propiedades: "Batería", "Nombre", "Profundidad_1", "Profundidad_2", "Profundidad_3" y "fecha" 
    // "Profundidad_1 , 2 y 3": representan diferentes niveles de profundidad, 
    // cada uno de los cuales contiene subpropiedades como "DO2", "O2", "Sal", "Temp" y "profundidad" con los correspondientes valores de datos y marcas de tiempo.
    const [centerData, setCenterData] = useState(null);
    //? - batch_data: Indica si se están procesando datos.
    // si es true indica que llega un dato si es false indica un conjuto de datos, del dia o por fecha 
    const [batch_data, setBatchData] = useState(false);
    //? - firstData: Indica si es la primera vez que se reciben datos.
    const [firstData, setFirstData] = useState(false);
    //? - localCenterData: Almacena los datos del centro actualmente seleccionado pero con la transformacion de los datos.
    // Estructura de datos: array de objetos, donde cada objeto representa una estacion. 
    // propiedades: "nombre", "profundidades", "battery"
    // "profundidades": array de objetos, cada objeto representa una profundidad, 
    // cada una de las cuales contiene subpropiedades como "DO2_val", "O2_val", "sal_val", "temp_val" y "profundidad" , 
    // donde profundidad es un string con el nivel de profundidad y los sensores corresponden a un array de objetos  con propiedades time: tipo string y value: numerico
    const [localCenterData, setLocalCenterData] = useState([]);
    //? - lastTime: Almacena el tiempo de la última actualización
    const [lastTime, setLastTime] = useState(0);
    // * ----------------------------- Estados para la gestión de la interfaz de usuario -----------------------------
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    //? - showAppbar: Indica si se debe mostrar la barra de aplicación.
    const [showAppbar, setShowAppbar] = useState(true);
    //? - lastPressedButtonIndex: Indice del boton seleccionado para cambiar el currentView
    const [lastPressedButtonIndex, setLastPressedButtonIndex] = useState(0)
    //? - currentPageStation: Mantiene la página actual de estación.
    const [currentPageStation, setcurrentPageStation] = useState(0);
    //? - currentView: Creación de un estado para mantener la vista actual  'x1' vista con una estacion 'x2' vista con dos estaciones 'x4' vista con cuatro estaciones
    const [currentView, setCurrentView] = useState('x1'); // Vista actual
    //? - itemsPerPage: Determina cuántos elementos 'Estaciones' se mostrarán por página según la vista actual
    const itemsPerPage = currentView === 'x1' ? 1 : currentView === 'x2' ? 2 : 4;
    //? - profundidadesPerPage: Determina cuántos elementos 'profundidades' se mostrarán por página según la vista actual.
    const [profundidadesPerPage, setProfundidadesPerPage] = useState(1);
    //? - clientName: Almacena el nombre del cliente 'Centro'
    const [clientName, setClientName] = useState('')
    // ? Calcular vista predeterminada óptima
    const [firstAppRender, setFirstAppRender] = useState(true)

    // * ----------------------------- Estados para el manejo de peticiones al servidor -----------------------------
    // Estados para manejar peticiones a servidor
    //? - server_update_time: Tiempo de actualización del servidor.
    const server_update_time = 3
    //? - maximum_data_requested: Cantidad máxima de datos solicitados.
    const maximum_data_requested = (60 / server_update_time) * 24 * 1
    //? - requestedData: Cantidad de datos solicitados actualmente.
    const [requestedData, setRequestedData] = useState(0)
    //? - pauseRequest: Indica si se deben pausar las solicitudes al servidor.
    const [pauseRequest, setPauseRequest] = useState(false)
    //? - noDataAvailable: Indica si no se reciben datos del servidor.
    const [noDataAvailable, setNoDataAvailable] = useState(false)
    // * ----------------------------- Estado para notificaciones -----------------------------
    //? - notificationOpen: Indica si la notificación está abierta.
    const [notificationOpen, setNotification_open] = useState(false)
    const [notification_msg, setNotification_msg] = useState('')
    const [notification_status, setNotification_status] = useState('success')



    // * ----------------------------- Estado para autenticación -----------------------------
    //? Descripción:
    // Se define un estado para el estado de autenticación.
    // - loggedIn: Indica si el usuario está autenticado , si es true esta en remoto, si es false esta en modo local.
    const loggedIn = true

    // -- Obtención de datos desde API -- //

    // ?  /* Solicitar datos al servidor conn rango de tiempo */
    const fetchDatedData = async (dateRange) => {

        // Obtener credenciales del cliente
        let region, area, centro
        try {
            region = localStorage.getItem('Ambiental_ClientRegion')
            area = localStorage.getItem('Ambiental_ClientArea')
            centro = localStorage.getItem('Ambiental_ClientCentro')
        }
        catch {
            region = null
            area = null
            centro = null
        }

        let newCenterData
        let useCompleteAPI

        if (region && area && centro) {
            // //console.log('centro: ', centro)
            if (centro !== 'Cementerio' && centro !== 'local' && centro !== 'DEMO_CEMENTERIO' && clientName !== 'DEMO_CEMENTERIO' && clientName !== 'local') {
                useCompleteAPI = true
            }
        }

        if (useCompleteAPI) {
            // Obtener datos desde el servidor
            newCenterData = await fetchRemoteCenterDated(region, area, centro, dateRange, loggedIn);
            // Actualizar estados locales
            setClientName(centro)
        }

        else {
            // Utilizar credenciales por defecto
            let clientName
            clientName = localStorage.getItem('clientName') || 'local' // localStorage.removeItem("clientName")
            // Caso particular DEMO
            if (region && area && centro && region === 'Loslagos' && area === 'Chiloe' && centro === 'Cementerio') clientName = 'DEMO_CEMENTERIO'
            newCenterData = await fetchCenterDated(clientName, dateRange, loggedIn);
            // Actualizar estados locales
            setClientName(clientName)
        }

        //console.log(dateRange)

        if (newCenterData) {
            if (noDataAvailable) setNoDataAvailable(false)
            // Actualizar estados locales
            setcurrentPageStation(0)
            setCenterData(null);
            setFirstData(false)
            setLocalCenterData([])
            setLastTime(0)

            setBatchData(newCenterData['batch_data'])
            setCenterData(newCenterData['sensor_data']);
            if (!firstData) setFirstData(true)
            //console.log('Seteado centerData: ', newCenterData['sensor_data'])
        }
        else {
            //console.log('Error recibiendo datos')
            if (!noDataAvailable) setNoDataAvailable(true)
        }
    };


    // -- Almacenar Credenciales del Cliente  -- //

    const updateClientCredentials = (Region, Area, Centro) => {

        setFirstData(false)
        setLocalCenterData([])
        setLastTime(0)
        setFirstAppRender(true) // Calcular vista predeterminada óptima

        // Sobreescribe el valor de clientName en localStorage
        localStorage.setItem('Ambiental_ClientRegion', Region);
        localStorage.setItem('Ambiental_ClientArea', Area);
        localStorage.setItem('Ambiental_ClientCentro', Centro);

        // Esperar 50ms antes de continuar
        setTimeout(() => {
            // Solicita datos para el nuevo cliente seleccionado
            fetchData(
                true,       // allDayData
                firstData,
                loggedIn,
                maximum_data_requested,
                pauseRequest,
                requestedData,
                setBatchData,
                setCenterData,
                setClientName,
                setFirstData,
                setRequestedData,
                setNoDataAvailable,
                fetchDatedData
            );
        }, 50);
    };


    // -- Descargar Reportes -- //

    const makeReport = async (dateRange) => {

        // Obtener credenciales del cliente
        let region, area, centro
        try {
            region = localStorage.getItem('Ambiental_ClientRegion')
            area = localStorage.getItem('Ambiental_ClientArea')
            centro = localStorage.getItem('Ambiental_ClientCentro')
        }
        catch {
            region = null
            area = null
            centro = null
        }

        let downloaded

        let useCompleteAPI
        //console.log('centro APP linea 105: ', centro)
        if (region && area && centro) {
            if (centro !== 'Cementerio' && centro !== 'local' && centro !== 'DEMO_CEMENTERIO' && clientName !== 'DEMO_CEMENTERIO' && clientName !== 'local') {
                useCompleteAPI = true
            }
        }

        //console.log('Solicitando reporte de: ', dateRange)
        if (useCompleteAPI) {
            // Obtener datos desde el servidor
            downloaded = await downloadReport(region, area, centro, dateRange, loggedIn,setNotification_msg, setNotification_open, setNotification_status);
        }

        else {
            // Utilizar credenciales por defecto
            let clientName
            clientName = localStorage.getItem('clientName') || 'local' // localStorage.removeItem("clientName")
            // Caso particular DEMO
            if (region && area && centro && region === 'Loslagos' && area === 'Chiloe' && centro === 'Cementerio') clientName = 'DEMO_CEMENTERIO'
            downloaded = await downloadReport(clientName, clientName, clientName, dateRange, loggedIn, setNotification_msg, setNotification_open, setNotification_status);
        }

        if (!downloaded) {
            //console.log('Error descargando reporte')
        if (!downloaded) {
        setNotification_msg('Error descargando el reporte.');
        setNotification_status('error')
        setNotification_open(true);
        setTimeout(() => {
            setNotification_open(false);
        }, 3000);

        }
        else {
            setNotification_msg('Reporte Generado');
            setNotification_open(true);
            setNotification_status('success')
            setTimeout(() => {
                setNotification_open(false);
            }, 3000);
        }

    };
}
;


    //*------------------------------ Funcion: handleProfundidadesPerPageChange -------------------------------
    //? Descripción: Mantiene el índice de la última estación presionada en la interfaz.
    const handleProfundidadesPerPageChange = (newPerPage) => {
        // Parámetros:
        // - newPerPage: Cantidad de profundidades por página.
        setProfundidadesPerPage(newPerPage);
    };

    //*------------------------------ Funcion: handleViewChange -----------------------------------------------
    //? Descripción: Cambia la vista actual y reinicia la página actual de la estación.
    const handleViewChange = (view, index) => {
        // Parámetros:
        // - view: Nueva vista a establecer ('x1', 'x2', 'x4').
        // - index: Índice del botón presionado.
        setCurrentView(view);
        setLastPressedButtonIndex(index);
        setcurrentPageStation(0)// Reiniciar a la primera página cuando cambia la vista
    };
//console.log('pauseRequest',pauseRequest)

    useEffect(() => {
        const fetchData = async () => {
            const PAGE ='ENVIRO' 
            const result = await fetchConsolidadoCenters(PAGE);
            setConsolidado_estados(result);
            // console.log('response: consolidado_estados', result)

        };

        // Fetch data initially
        fetchData();

        // Set interval to fetch data every 60 seconds
        const interval = setInterval(fetchData, 60000);

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, []);
    // ?  Solicitar continuamente datos al servidor   */
    useEffect(() => {
        // Obtener datos iniciales si pauseRequest es falso
        if (!pauseRequest) {
            fetchData(
                !firstData,
                firstData,
                loggedIn,
                maximum_data_requested,
                pauseRequest,
                requestedData,
                setBatchData,
                setCenterData,
                setClientName,
                setFirstData,
                setRequestedData,
                setNoDataAvailable,
                fetchDatedData
            );
        }
    
        // Definir el intervalo en el ámbito del useEffect
        let intervalId;
    
        if (!pauseRequest) {
            // Configurar intervalo para obtener datos cada 1 minuto si pauseRequest es falso
            intervalId = setInterval(() => {
                if (!pauseRequest) {
                    fetchData(
                        !firstData,
                        firstData,
                        loggedIn,
                        maximum_data_requested,
                        pauseRequest,
                        requestedData,
                        setBatchData,
                        setCenterData,
                        setClientName,
                        setFirstData,
                        setRequestedData,
                        setNoDataAvailable,
                        fetchDatedData
                    );
                }
            }, 180000); // 60000 ms = 1 minuto
        }
    
        // Limpiar intervalo cuando el componente se desmonte o cuando pauseRequest cambie
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    
    }, [pauseRequest]);

    // ? Definir cantidad de estaciones y profundidades mostradas, al primer render de la app
    useEffect(() => {
        // Si pauseRequest es verdadero, pausar las solicitudes al servidor
        if (pauseRequest === true) {
            return;
           }
        // Únicamente al primer renderizado de la App
        if (firstAppRender) {
            // Una vez que se recibieron datos
            if (firstData) {
                // Analizar estaciones recibidas
                const _cantidad_estaciones = localCenterData.length
                if (_cantidad_estaciones == 2) {
                    setLastPressedButtonIndex(1)
                    setCurrentView('x2')
                }
                else if (_cantidad_estaciones > 2) {
                    setLastPressedButtonIndex(2)
                    setCurrentView('x4')
                }

                // Analizar profundidades recibidas
                const maxProfundidades = localCenterData.reduce((max, estacion) => {
                    const profundidades = estacion.profundidades.length;
                    return profundidades > max ? profundidades : max;
                }, 0);

                // Actualizar setProfundidadesPerPage basado en maxProfundidades
                if (_cantidad_estaciones <= 2) {
                    if (maxProfundidades === 2) {
                        setProfundidadesPerPage(2);
                    } else if (maxProfundidades > 2) {
                        setProfundidadesPerPage(3);
                    }
                }
                else { // Vista x4, máximo 2 profundidades
                    if (maxProfundidades > 1) {
                        setProfundidadesPerPage(2);
                    }
                }
                setFirstAppRender(false)
            }

        }

    }, [localCenterData]);

    // useEffect para manejar las solicitudes de datos y el envío de la fecha de hoy si pauseRequest es false
    useEffect(() => {
        if (pauseRequest) {
            return;
        }
        let dateRange = {
            startDate: moment().format('DD/MM/YYYY'),  
            endDate: moment().format('DD/MM/YYYY') 
        }                     
        fetchDatedData(dateRange)
        //console.log( 'Rango de fechas app', dateRange )
    }, [pauseRequest]);

    //?  función para determinar si la página actual es la de login
    const isLoginPage = () => {
        return window.location.pathname === "/";
    };

    // useEffect(() => {
    //     const fetchData = async () => {
    //       const data = await fetchStatusCenters(loggedIn);
    //     //   console.log('data status',data)
    //       setStatusCenter(data);
    //     };
    
    //     fetchData(); // Llama la función al montar el componente
    
    //     const intervalId = setInterval(() => {
    //       fetchData();
    //     }, 6000); // 60000 ms = 1 minuto
    
    //     return () => clearInterval(intervalId); // Limpiar el intervalo cuando el componente se desmonte
    //   }, [statusCenter]);


      


          //*-------------------- alerta
    useEffect(() => {
        const fetchData = async () => {
            const loggedIn = true
            const data = await fetchAlertas(loggedIn);
            //console.log('data alerta ', data)
            if (Array.isArray(data)) {
                setAlertas(data);
            } else {
                // Manejar el caso en el que no se recibe un array
                //console.error('fetchAlertas no devolvió un array válido:', data);
            }
        };
    
        // Ejecutar la función inmediatamente al cargar el componente
        fetchData();
    
        // Configurar el intervalo para ejecutar la función cada minuto
        const interval = setInterval(fetchData, 30000);
    
        // Limpiar el intervalo al desmontar el componente
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Esto se ejecutará cada vez que 'alertas' se actualice
        // console.log('El estado de alertas ha cambiado:', alertas.length);
    
    }, [alertas]);


    // * ------- Definición de Props ------ //
    // ? Componente: AmbientalAppBar
    const AppbarProps = {
        // * estado centro
        statusCenter:statusCenter,
        // * alertas
        alertas:alertas,
        //* estado visibilidad
        showAppbar: showAppbar,
        // * Data
        localCenterData: localCenterData,
        fetchDatedData: fetchDatedData,
        // * View
        currentView: currentView,
        currentPageStation: currentPageStation,
        setcurrentPageStation: setcurrentPageStation,
        itemsPerPage: itemsPerPage,
        profundidadesPerPage: profundidadesPerPage,
        setProfundidadesPerPage: setProfundidadesPerPage,
        handleProfundidadesPerPageChange: handleProfundidadesPerPageChange,
        handleViewChange: handleViewChange,
        // * State Control
        clientName: clientName,
        loggedIn: loggedIn,
        pauseRequest: pauseRequest,
        setPauseRequest: setPauseRequest,
        // overwriteClientName: overwriteClientName,
        updateClientCredentials: updateClientCredentials,
        makeReport: makeReport,
        lastPressedButtonIndex: lastPressedButtonIndex,
        SWVersion: SWVersion,
        translate: translate,
        handleClick: handleClick,
        openMenuIdioma:openMenuIdioma,
        handleCloseIdioma:handleCloseIdioma,
        handleLanguageChange:handleLanguageChange,
        idioma:idioma,
        setNotification_open:setNotification_open,
        setNotification_msg:setNotification_msg,
        selectedRegion:selectedRegion,
        selectedArea:selectedArea,
        setSelectedRegion:setSelectedRegion,
        setSelectedArea:setSelectedArea,
        consolidado_estados:transformData(consolidado_estados),
        setStatusCenter:setStatusCenter
    };

    // ? Componente: Home
    const HomeProps = {
         // * alertas
         alertas:alertas,
         setAlertas:setAlertas,
        // * Data
        batch_data: batch_data,
        centerData: centerData,
        localCenterData: localCenterData,
        setLocalCenterData: setLocalCenterData,
        lastTime: lastTime,
        setLastTime: setLastTime,
        // * Pagination
        currentPageStation: currentPageStation,
        profundidadesPerPage: profundidadesPerPage,
        // * View
        currentView: currentView,
        itemsPerPage: itemsPerPage,
        depthComponentMap: depthComponentMap,
        noDataAvailable: noDataAvailable,
        // * State Control
        pauseRequest: pauseRequest,
        setPauseRequest: setPauseRequest,
        translate: translate,
        // setIsAuthenticated: setIsAuthenticated,
    };

    /* const LoginProps = {
        // * Data 
        batch_data : batch_data,
        centerData : centerData,
        localCenterData : localCenterData,
        setLocalCenterData : setLocalCenterData,
        lastTime : lastTime,
        setLastTime : setLastTime,
        // * Pagination
        currentPage : currentPage,
        setcurrentPage : setcurrentPage,
        currentPageDepth : currentPageDepth,
        setCurrentPageDepth : setCurrentPageDepth,
        profundidadesPerPage : profundidadesPerPage,
        // * View
        currentView : currentView,
        itemsPerPage : itemsPerPage,
        depthComponentMap : depthComponentMap,
        // * State Control
        // setIsAuthenticated : setIsAuthenticated,
    } */
    // //console.log(' %c CENTERDATA DESDE MAIN ENVIRO','color: red; fontSize:30px ',centerData)
    

  return (
    <Grid  style={{ cursor: 'default !important', userSelect: 'none', }}>
        {notificationOpen &&
            <Notification
                notification_open={notificationOpen} notification_time={3000} notification_severity={notification_status} notification_msg={notification_msg} setNotification_open={setNotification_open} />
        }
         <AmbientalAppBar {...AppbarProps} />
         
         {centerData &&
            <Home {...HomeProps} />
         }
    </Grid>

  )
}

export default MainEnviro