import React from 'react'
import { Grid, Button, Typography, Box } from "@mui/material";
import oxigeno from "./img/oxigeno.png";

const Oxigeno = (props) => {
    // console.log('props.showOxigeno',props.showOxigeno)
    const getColor = () => {
        return props.showOxigeno && ( props.medicion !== "N/D" ) ? 'rgb(5, 78, 138,0.1)' : 'rgb(5, 78, 138,0)';
    };
    return (
        <Button style={{ backgroundColor: getColor() }} onClick={props.toggleOxigeno} sx={{ border: "0.15vh solid  #054e8a ", height: props.fullHeight, width: props.fullwidth }} disabled={props.medicion === 'N/D'} > 
            <Grid container spacing={0} alignItems="top" justifyContent="center" >
                {/* Card de oxígeno */}
                <Grid item xs={6} >
                    <Box display='flex' direction="column" alignItems="center" justifyContent="center" width={props.width} height={props.height} >
                        <img src={oxigeno} alt="Icono" style={{marginLeft:props.marginLeft1, width: props.widthIMG, height: props.heightIMG, position: 'center' }} />
                        {!props.sinText && (
                            <Typography style={{ fontSize: props.fontSize, cursor: 'default !important', userSelect: 'none' }} color="white">
                                {props.oxigeno}
                            </Typography>
                        )}
                    </Box>
                </Grid>

                {/* Card de número */}
                <Grid item xs={6} >
                    <Box style={{ width: props.width1, height: props.height1, backgroundColor: "transparent", marginLeft: props.marginLeft1, textTransform: 'none',  }}>
                        <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '95%', cursor: 'default !important', userSelect: 'none' }}>
                            <Typography variant="h1" style={{ fontSize: props.fontSizeH1, fontWeight: 1000, color: 'white' }}>
                                {props.medicion}
                            </Typography>
                            <Typography variant="h1" style={{ fontSize: props.fontSizeTypo1, fontWeight: 200, color: 'white' }}>
                                mg/L
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Button>
    );
};

export default Oxigeno;


/* 
const Oxigeno = (props) => {
    return (
        <Grid container spacing={0} alignItems="top" justifyContent="center" >
            <Grid item xs={6}>
                <Button
                    style={{
                        minWidth: "1vw",
                        width: props.width,
                        height: props.height,
                        backgroundColor: props.showOxigeno ? "rgb(65, 105, 225)" : "rgb(65, 105, 225,0.4)"
                    }}
                    onClick={props.toggleOxigeno}
                >
                    <Grid container direction="column" alignItems="center" justifyContent="center">
                        <img src={oxigeno} alt="Icono" style={{ width: props.widthIMG, height: props.heightIMG }} />
                        {!props.sinText && (
                            <Typography style={{ fontSize: props.fontSize, cursor: 'default !important', userSelect: 'none' }} color="white">
                                {props.oxigeno}
                            </Typography>
                        )}
                    </Grid>
                </Button>
            </Grid>

            <Grid item xs={6} >
                <Card style={{ width: props.width1, height: props.height1, backgroundColor: "black", border: "0.1vh solid white", marginLeft: props.marginLeft1 }}>
                    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '95%' , cursor: 'default !important', userSelect: 'none'}}>
                        <Typography variant="h1" style={{ fontSize: props.fontSizeH1, fontWeight: 1000 }}>
                            {props.medicion}
                        </Typography>
                        <Typography variant="h1" style={{ fontSize: props.fontSizeTypo1, fontWeight: 1000 }}>
                            mg/L
                        </Typography>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};
*/