import React from 'react';
import { Grid, Paper, Modal, Button, Typography, Card , CardContent, Avatar, IconButton, Divider, Badge  } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#1e1928',
      // border:'solid 0.1vh white'
  
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#1e1928',
      // border:'solid 0.1vh white',
      minWidth:'12vw',
      maxWidth:'12vw',
      // minHeight:'4.9vh',
      // maxHeight:'4.9vh',
      fontSize:'0.75vw'
    },
  }));

  const style = {
    container: {
      border: 'solid 0.1vh red',
      borderRadius:'3vh',
      // borderTopRightRadius:'3vh',
      // marginRigth: '1vw',
      marginTop: '1vh',
      textAlign: 'center',
      width: '6vw',
      height:'9vh'
    },
    img: {
      // minHeight: '7vh',
    //   height: '9vh',
      // height: '9.5vh',
      borderRadius:'50vh',
      aspectRatio:1,
      width:'8vw',
      // marginLeft: '0.5vh',
      selected: {
        backgroundColor: 'rgb(255,255,255,0.1)', // Cambia el color de fondo para indicar la selección
      },
      // marginBottom: '5vh',
    },
    content: {
      // marginLeft: '1vw',
      border: 'solid 0.1vh ',
      width: '8vw',
      height: '5vh',
      textAlign: 'center',
      paddingTop: '0.2vh',
      fontSize: '3vh',
      borderBottomLeftRadius:'3vh',
      borderBottomRightRadius:'3vh',
      selected: {
        backgroundColor: 'rgb(255,255,255,0.1)', // Cambia el color de fondo para indicar la selección
      },
      img1: {
        height: '3vh',
      },
      img: {
        height: '2vh',
        marginTop: '-0.8vh',
      },
      imgScapp: {
        height: '2vh',
        marginTop: '0vh',
      },
      imgEnviro: {
        height: '3vh',
        marginTop: '-1.8vh',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'default !important', userSelect: 'none',
    },
    modalPaper: {
      // border: '0.01vh solid ',
      borderRadius:'2vh',
      textAlign: 'center',
      width: '25vw',
      height: '32vh',
      img:{
          height: '10vh',
          marginTop:'5vh'
      },
      content:{
          fontSize:'2.5vh',
          // marginTop:'2vh',
          // marginBottom:'3vh'
      },
      button:{
          fontSize:'2vh',
      }
    },
  };
  
  const severityMap = {
    "C": "rgb(211, 47, 47)",
    "A": "rgb(253, 183, 70)",
    "B": "rgb(0, 128, 0)"
  };
  
const ImageSection = ({ imgSrc, imgStyle, isSelected, handleClick, serviceStatus, translate }) => {
    // console.log('serviceStatus',serviceStatus)
    // console.log('serviceStatus',serviceStatus)
  
      // Obtiene los valores de conteo_a y conteo_c
    const conteoA = serviceStatus?.conteo_a || 0;
    const conteoC = serviceStatus?.conteo_c || 0;
  
    // Total de alertas
    const total_alertas = conteoA + conteoC;
  
    // const service_inactive = serviceStatus?.name === 'scapp' ? 0 : (serviceStatus?.name === 'pss' ? 0 : (total_alertas !== 0 ? total_alertas : ''))
    const service_inactive = serviceStatus?.name === 'scapp' ? 0 : (total_alertas !== 0 ? total_alertas : '')

  
    // const disabledStyle = serviceStatus?.name === 'scapp' ? { opacity: 0.5, filter: 'grayscale(100%)' } : {}; //solo para un service
    const disabledStyle = ['scapp', 'pss'].includes(serviceStatus?.name) ? { opacity: 0.5, filter: 'grayscale(100%)' } : (serviceStatus?.status_service) ? {}: { opacity: 0.5, filter: 'grayscale(100%)' };

  // console.log('serviceStatus',serviceStatus)
  
    return (
      <>
        <Grid justifyContent='center' alignContent='center'  >
        <Badge
            badgeContent={
              // service_inactive
              serviceStatus?.status_service ? (
              ['enviro', 'nanoox'].includes(serviceStatus?.name)  &&
                <Grid container justifyContent='center' alignContent='center' 
                  style={{ backgroundColor: severityMap[serviceStatus?.status],minWidth:'1.2vw', maxWidth:'5vw',aspectRatio:1, borderRadius:'50%'}}>
                    {service_inactive}
                </Grid>
              ):(
                ['enviro', 'nanoox',].includes(serviceStatus?.name)  &&
                  <Grid container justifyContent='center' alignContent='center' 
                    style={{ backgroundColor: 'transparent' ,minWidth:'1.2vw', maxWidth:'5vw',aspectRatio:1, borderRadius:'50%'}}>
                      {/* <NotInterestedIcon style={{fontSize:'1vw'}}/> */}
                  </Grid>
              )
  
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              marginLeft: '0vw',
              '& .MuiBadge-badge': {
                backgroundColor: 'transparent',//severityMap[serviceStatus?.status],
                maxHeight: '2.6vh',
                minHeight: '2.6vh',
                aspectRatio:1/1,
                // minWidth: '1.3vw',
                // maxWidth: '1.3vw',
                borderRadius:'100%',
                marginTop: '1vh',
                marginRight: '1vw',
                fontSize: '1.5vh',
              },
            }}
          >
            <Grid item xs={12} style={{marginLeft:'0.25vw', marginRight:'0.25vw'}}>
              <BootstrapTooltip 
                placement="top" 
                // title={`Alertas de Atención: ${conteoA} Alertas Críticas: ${conteoC}`}  
                title={
                  serviceStatus?.status_service ? (
                  ['enviro', 'nanoox'].includes(serviceStatus?.name)  &&
                    <Grid container>
                      {serviceStatus?.name === 'nanoox' ? (
                        <Grid container justifyContent='center' alignContent='center' item xs={12} style={{ textAlign: 'center' }}>
                          <p style={{marginTop:'1vh'}}> {translate('trabajando_alertas')} ... </p>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid container justifyContent='flex-start' item xs={10} style={{ color: '#d12d3a' }}>
                              {translate('alertas_criticas')}: 
                            </Grid>
                            <Grid container justifyContent='flex-end' item xs={2} style={{ color: '#d12d3a' }}>
                              {conteoC}
                            </Grid>
                            <Grid container justifyContent='flex-start' item xs={10} style={{ color: 'yellow' }}>
                              {translate('alertas_atencion')}: 
                            </Grid>
                            <Grid container justifyContent='flex-end' item xs={2} style={{ color: 'yellow' }}>
                              {conteoA} 
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ) :(
                    ['enviro', 'nanoox', 'pss'].includes(serviceStatus?.name)  &&
                    <Grid container>
                        <Grid container justifyContent='center' alignContent='center' item xs={12} style={{ textAlign: 'center' }}>
                          <p style={{marginTop:'1vh', fontSize:'0.75vw'}}> {translate('servicio_no_habilitado')} </p>
                        </Grid>
                    </Grid>
                  )
                } 
              >
                <IconButton style={{aspectRatio:1, width:'5.5vw',}}>
                  <img src={imgSrc} style={{ ...style.img, ...imgStyle, ...(isSelected && style.img.selected), ...disabledStyle }} alt="Section Image" onClick={()=> handleClick(serviceStatus)} />
                </IconButton>
              </BootstrapTooltip>
            </Grid>
          </Badge>
        </Grid>
      </>
    );
  }

export default ImageSection;