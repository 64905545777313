import React, {useState} from 'react'
import { Grid, Button, Modal, Typography, Divider } from '@mui/material'
import EastIcon from '@mui/icons-material/East';
import '../assets/css/Tabla.css'
import ModalTags from './utils/ModalTags';


const Tabla = ({problemas, rowsPerPage, page, setSelectedProblem}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
  
    // Formato para la hora
    const formatTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    };

    const handleOpenModal = (problem) => {
        setSelectedProblem(problem);
    };

    const handleOpenPopover = (event, tags) => {
        setAnchorEl(event.currentTarget);
        setSelectedTags(tags);
      };
    
    const handleClosePopover = () => {
    setAnchorEl(null);
    };

    // Calcular los datos mostrados en la página actual
    const displayedProblems = problemas.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const colorProblema = (tipo) =>{
        console.log(tipo)
        switch (tipo) {
            case '0':
                return '#97aab3'
            case '1':
                return '#5c7acc'
            case '2':
                return '#cca047'
            case '3':
                return '#cc7f47'
            case '4':
                return '#b55c45'
            case '5':
                return '#b14545'
            default:
                return ''
          }
    }

  return (
    <Grid container className="container-Tabla">
      {/* Encabezado de la tabla */}
      <Grid container className="header-container-tabla">
        <Grid container className="header-item-tabla" item xs={2}><h3>Tiempo</h3></Grid>
        <Grid container className="header-item-tabla" item xs={1}><h3>Equipo</h3></Grid>
        <Grid container className="header-item-tabla" item xs={5}><h3>Problema</h3></Grid>
        <Grid container className="header-item-tabla" item xs={1}><h3>Duración</h3></Grid>
        <Grid container className="header-item-tabla" item xs={2}><h3>Tags</h3></Grid>
        <Grid container className="header-item-tabla" item xs={1}><h3>Acciones</h3></Grid>
      </Grid> 
      
      {/* Filas de la tabla */}
        <Grid container className="row-container-tabla">
            {displayedProblems.map((problem) => (
                <Grid container key={problem.eventid} className="row-item">
                <Grid container className="row-item" item xs={2}>{formatTime(problem.clock)}</Grid>
                <Grid container className="row-item" item xs={1}>--</Grid>
                <Grid container className="row-item" style={{backgroundColor:colorProblema(problem.severity)}} item xs={5}>{problem.name}</Grid>
                <Grid container className="row-item" item xs={1}>--</Grid> {/* Aquí podrías calcular la duración */}
                <Grid container className="row-item" item xs={2}>
                    {/* {problem.tags.map(tag => `${tag.tag}: ${tag.value}`).join(', ')} */}
                    <Grid container>
                {problem?.tags.slice(0, 3).map((tag, index) => (
                  <Grid item xs={12} key={index}>
                    <ModalTags anchorEl={anchorEl} handleClose={handleClosePopover} tags={selectedTags} />
                  </Grid>
                ))}
                {problem?.tags.length > 3 ? (
                  <Grid item xs={12}>
                    <Button variant="text" className="button-tabla-tags" onClick={(event) => handleOpenPopover(event, problem.tags)}>
                      Ver más...
                    </Button>
                  </Grid>
                ):(
                    <Grid item xs={12}>
                      --
                    </Grid>
                )}
                    </Grid>
                </Grid> {/* Aquí podrías agregar tags si los hay */}
                <Grid container className="row-item" item xs={1}>
                    <Button variant="text" color='inherit'className="button-tabla" onClick={() => handleOpenModal(problem)}>
                    Ver 
                    {/* <EastIcon className="icon"/> */}
                    </Button>
                </Grid>
                </Grid>

            ))}
                {/* <TagsModal  /> */}
        </Grid>
    </Grid>
  )
}

export default Tabla