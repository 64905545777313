// import React, { useState } from 'react';
// import {
//   Modal,
//   Box,
//   Grid,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   TablePagination
// } from '@mui/material';
// import { styled } from '@mui/material/styles';


// const ModalHistorialAlertas = ({open, handleClose,alertas, translate}) => {

// const [page, setPage] = useState(0);
// const [rowsPerPage, setRowsPerPage] = useState(5);

// const handleChangePage = (event, newPage) => setPage(newPage);
//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

// const getSensorName = (sensor) => {
//     switch(sensor) {
//         case 'O2': return 'Oxígeno';
//         case 'DO2': return 'Saturación';
//         case 'Temp': return 'Temperatura';
//         case 'Sal': return 'Salinidad';
//         default: return sensor;
//     }
// };

// const getHeight = () => {
//     // Comprobamos si el valor es igual a C
//     if (rowsPerPage === 5) {
//     // Si es igual C, devolvemos el valor en rojo
//     return "50vh";
//     }else if (rowsPerPage === 10) {
//         // Si es igual A, devolvemos el valor en amarillo
//         return "70vh";
//     } else{
//         // De lo contrario, devuelve un color verde
//         return "90vh";
//     }
// };

// const getColor = (tipo) => {
//     // console.log('tipo', tipo)
//     // Comprobamos si el valor es igual a C
//     if (tipo === 'C') {
//     // Si es igual C, devolvemos el valor en rojo
//     return "#ac2929";
//     }else if (tipo === 'A') {
//         // Si es igual A, devolvemos el valor en amarillo
//         return "#cd953b";
//     } else{
//         // De lo contrario, devuelve un color verde
//         return "green";
//     }
// };

// const getColorSensor = (tipo) => {
//   // console.log('tipo', tipo)
//   // Comprobamos si el valor es igual a C
//   if (tipo === 'Oxígeno') {
//   // Si es igual C, devolvemos el valor en rojo
//   // return "#0269be";
//   return "rgb(5, 78, 138,0.1)";
//   }else if (tipo === 'Saturación') {
//       // Si es igual A, devolvemos el valor en amarillo
//       // return "#02a26c";
//       return "rgb(5, 118, 80,0.1)";
//   }else if (tipo === 'Salinidad') {
//     // Si es igual A, devolvemos el valor en amarillo
//     // return "#a97716";
//     return "rgb(174, 122, 21, 0.1)";
//   } else{
//       // De lo contrario, devuelve un color verde
//       // return "#cc1106";
//       return "rgb(203, 22, 6, 0.1)";
//   }
// };

// const getBorderColorSensor = (tipo) => {
//   // console.log('tipo', tipo)
//   // Comprobamos si el valor es igual a C
//   if (tipo === 'Oxígeno') {
//   // Si es igual C, devolvemos el valor en rojo
//   return "#054e8a";
//   }else if (tipo === 'Saturación') {
//       // Si es igual A, devolvemos el valor en amarillo
//       return "#057650";
//   }else if (tipo === 'Salinidad') {
//     // Si es igual A, devolvemos el valor en amarillo
//     return "#ae7a15";
//   } else{
//       // De lo contrario, devuelve un color verde
//       return "rgba(255, 18, 3, 0.75)";
//   }
// };

// const getAlertState = (alerta) => {
//     // return alerta === 'C' ? 'Crítica' : 'Atención';
//     return alerta === 'C' ? translate('critica') : ( alerta === 'A' ? translate('atencion') : translate('buena'));
// };

// const StyledTablePagination = styled(TablePagination)({
//     '& .MuiTablePagination-toolbar': {
//       fontSize: '1.5vh', // Ajusta este valor según sea necesario
//     },
//     '& .MuiTablePagination-selectLabel':{
//       fontSize: '1.5vh'
//     },
//     '& .MuiTablePagination-displayedRows':{
//       fontSize: '1.5vh'
//     },
//     '& .css-12ituji-MuiSvgIcon-root-MuiSelect-icon':{
//       fontSize: '2vh'
//     },
//     '& .css-b57xgn-MuiButtonBase-root-MuiIconButton-root.Mui-disabled':{
//       fontSize: '2vh'
//     }
//   });

//   const StyledTableRow = styled(TableRow)({
//     maxHeight: '1vh', // Ajusta este valor según sea necesario
//   });

//   const StyledTableCell = styled(TableCell)({
//     fontSize: '1.5vh', // Tamaño de fuente para las celdas
//     borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde inferior para las celdas
//     padding: '10px', // Ajusta el padding interno de las celdas
    
//   });

//   return (
//     <Grid container justifyContent='center' style={{cursor: 'default !important', userSelect: 'none',}}>
//       {/* <Button variant="contained" color="primary" onClick={handleOpen}>
//         Mostrar Alertas
//       </Button> */}
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//         style={{ cursor: 'default !important', userSelect: 'none',}}

//       >
//         <Grid container justifyContent='center' alignContent='center'
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             width: '50vw',
//             // height: getHeight(),
//             bgcolor: 'background.paper',
//             // border: '2px solid #000',
//             borderRadius:'2vh',
//             GridShadow: 24,
//             p: 4,
//           }}
//         >
//             <Grid container justifyContent='center'>
//                 <Typography id="modal-title" variant="h6" component="h2" style={{fontSize:'3vh', marginBottom:'1vh', }}>
//                     {translate('alertas_activas')}: {alertas.length}
//                 </Typography>
//             </Grid>
//             <Grid container justifyContent='center'>
//                 <TableContainer component={Paper}>
//                   <Table aria-label="alert table">
//                       <TableHead>
//                       <StyledTableRow  style={{}} >
//                           <StyledTableCell style={{textAlign:'center',color:'#cc1106', borderRight:'solid 0.1vh'}} >{translate('status_alertas')}</StyledTableCell>
//                           <StyledTableCell style={{textAlign:'center',borderRight:'solid 0.1vh'}} >{translate('fecha')}</StyledTableCell>
//                           <StyledTableCell style={{textAlign:'center',borderRight:'solid 0.1vh'}} >{translate('valor_sensor')}</StyledTableCell>
//                           <StyledTableCell style={{textAlign:'center',borderRight:'solid 0.1vh'}} >{translate('estacion')}</StyledTableCell>
//                           <StyledTableCell style={{textAlign:'center',borderRight:'solid 0.1vh'}} >{translate('profundidad')}</StyledTableCell>
//                           <StyledTableCell style={{textAlign:'center'}} >{translate('sensor')}</StyledTableCell>
//                       </StyledTableRow>
//                   </TableHead>
//                   <TableBody >
//                       {alertas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((alerta, index) => {
//                       const [estacion, profundidad, sensor] = alerta.variable.split('-');
//                       return (
//                           <StyledTableRow key={index}>
//                               <StyledTableCell style={{ display: 'flex',justifyContent: 'center',  textAlign:'center', color:'#cc1106', borderRight:'solid 0.1vh'}} > <Box style={{ width:'4vw',textAlign:'center', fontSize: '1.5vh',color:'white', borderRadius:'0.5vh', backgroundColor:getColor(alerta.alerta) }}> {getAlertState(alerta.alerta)} </Box> </StyledTableCell>
//                               <StyledTableCell style={{textAlign:'center', borderRight:'solid 0.1vh'}} >{alerta.fecha}</StyledTableCell>
//                               <StyledTableCell style={{textAlign:'center', borderRight:'solid 0.1vh'}} >{alerta.valor.toFixed(1)}</StyledTableCell>
//                               <StyledTableCell style={{textAlign:'center', borderRight:'solid 0.1vh'}} >{estacion}</StyledTableCell>
//                               <StyledTableCell style={{textAlign:'center', borderRight:'solid 0.1vh'}} >{ profundidad.replace(/_/g, '  ')}</StyledTableCell>
//                               {/* <StyledTableCell >{getSensorName(sensor)}</StyledTableCell> */}
//                               <StyledTableCell style={{ textAlign: 'center', display: 'flex',justifyContent: 'center', }} ><Box style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', width:'5.5vw',textAlign:'center', fontSize: '1.5vh', borderRadius:'0.5vh',border:`0.15vh solid ${getBorderColorSensor(getSensorName(sensor))}`, backgroundColor:getColorSensor(getSensorName(sensor)) }}> {getSensorName(sensor)} </Box></StyledTableCell>

//                           </StyledTableRow>
//                       );
//                       })}
//                   </TableBody>
//                   </Table>
//                 </TableContainer>
//             </Grid>
//           <Grid container justifyContent='center'>
//             <StyledTablePagination
//                 rowsPerPageOptions={[5, 10, 15]}
//                 component="div"
//                 count={alertas.length}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//                 labelRowsPerPage={translate("filas_paginas")}
//                 style={{fontSize:'1.5vh'}}
//             />
//           </Grid>
          
//           <Grid container  justifyContent="center">
//             <Button variant="outlined" color='inherit' style={{fontSize:'1.5vh'}}  onClick={handleClose}>{translate('cerrar')}</Button>
//           </Grid>
//         </Grid>
//       </Modal>
//     </Grid>
//   );
// };

// export default ModalHistorialAlertas;
import React, { useState } from 'react';
import { Modal, Box, Grid, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const StyledPagination = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1rem 0',
  fontSize: '1vw',
});

const StyledRow = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4vh', // Ajusta este valor según sea necesario
  maxHeight: '4vh',
  height: '4vh',
  borderBottom: '0.1vh solid rgba(224, 224, 224, 1)',
});

const StyledCell = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  height: '4vh', // Define una altura fija para las celdas
  fontSize: '0.7vw',
  fontWeight:2000
});

const ModalHistorialAlertas = ({ open, handleClose, alertas, translate }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAlertState = (alerta) => {
    return alerta === 'C' ? translate('critica') : (alerta === 'A' ? translate('atencion') : translate('buena'));
  };

  const getColor = (tipo) => {
    if (tipo === 'C') {
      return "#ac2929";
    } else if (tipo === 'A') {
      return "#cd953b";
    } else {
      return "green";
    }
  };

  const getSensorName = (sensor) => {
    switch (sensor) {
      case 'O2': return translate('oxigeno');
      case 'DO2': return translate('saturacion');
      case 'Temp': return translate('temperatura');
      case 'Sal': return translate('salinidad');
      default: return sensor;
    }
  };

  const getColorSensor = (tipo) => {
  // console.log('tipo', tipo)
  // Comprobamos si el valor es igual a C
  if (tipo ===  translate('oxigeno')) {
  // Si es igual C, devolvemos el valor en rojo
  // return "#0269be";
  return "rgb(5, 78, 138,0.1)";
  }else if (tipo === translate('saturacion')) {
      // Si es igual A, devolvemos el valor en amarillo
      // return "#02a26c";
      return "rgb(5, 118, 80,0.1)";
  }else if (tipo === translate('salinidad')) {
    // Si es igual A, devolvemos el valor en amarillo
    // return "#a97716";
    return "rgb(174, 122, 21, 0.1)";
  } else{
      // De lo contrario, devuelve un color verde
      // return "#cc1106";
      return "rgb(203, 22, 6, 0.1)";
  }
};

const getBorderColorSensor = (tipo) => {
  // console.log('tipo', tipo)
  // Comprobamos si el valor es igual a C
  if (tipo ===  translate('oxigeno')) {
  // Si es igual C, devolvemos el valor en rojo
  return "#054e8a";
  }else if (tipo === translate('saturacion')) {
      // Si es igual A, devolvemos el valor en amarillo
      return "#057650";
  }else if (tipo === translate('salinidad')) {
    // Si es igual A, devolvemos el valor en amarillo
    return "#ae7a15";
  } else{
      // De lo contrario, devuelve un color verde
      return "rgba(255, 18, 3, 0.75)";
  }
};


  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const totalRows = alertas.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <Grid container justifyContent='center' style={{ cursor: 'default !important', userSelect: 'none' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{ cursor: 'default !important', userSelect: 'none' }}
      >
        <Grid container justifyContent='center' alignContent='center'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            bgcolor: 'background.paper',
            borderRadius: '2vh',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid container justifyContent='center'>
            <Typography id="modal-title" variant="h6" component="h2" style={{ fontSize: '3vh', marginBottom: '1vh' }}>
              {translate('alertas_activas')}: {alertas.length}
            </Typography>
          </Grid>
          <Grid container justifyContent='center'>
            <Paper style={{width:'50vw'}}>
              <StyledRow container>
                <StyledCell item xs={2}>{translate('status_alertas')}</StyledCell>
                <StyledCell item xs={2}>{translate('fecha')}</StyledCell>
                <StyledCell item xs={2}>{translate('valor_sensor')}</StyledCell>
                <StyledCell item xs={2}>{translate('estacion')}</StyledCell>
                <StyledCell item xs={2}>{translate('profundidad')}</StyledCell>
                <StyledCell item xs={2}>{translate('sensor')}</StyledCell>
              </StyledRow>
              {alertas.slice(startIndex, endIndex).map((alerta, index) => {
                const [estacion, profundidad, sensor] = alerta.variable.split('-');
                return (
                  <StyledRow container key={index} style={{ /*backgroundColor: index % 2 === 0 ? '#3b3b3b' : '#5b5b5b' */}}>
                    <StyledCell item xs={2}>
                      <Box style={{ minWidth: '4vw', textAlign: 'center', fontSize: '1.5vh', color: 'white', borderRadius: '0.5vh', backgroundColor: getColor(alerta.alerta) }}>
                        {getAlertState(alerta.alerta)}
                      </Box>
                    </StyledCell>
                    <StyledCell item xs={2}>{alerta.fecha}</StyledCell>
                    <StyledCell item xs={2}>{alerta.valor.toFixed(1)}</StyledCell>
                    <StyledCell item xs={2}>{estacion}</StyledCell>
                    <StyledCell item xs={2}>{profundidad.replace(/_/g, '  ')}</StyledCell>
                    <StyledCell item xs={2}>
                      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5.5vw', textAlign: 'center', fontSize: '1.5vh', borderRadius: '0.5vh', border: `0.15vh solid ${getBorderColorSensor(getSensorName(sensor))}`, backgroundColor: getColorSensor(getSensorName(sensor)) }}>
                        {getSensorName(sensor)}
                      </Box>
                    </StyledCell>
                  </StyledRow>
                );
              })}
            </Paper>
          </Grid>
          <StyledPagination>
            <Button style={{ fontSize: '1.5vh' }} onClick={() => handleChangePage(page - 1)} disabled={page === 0}>
              <ArrowBackIosIcon style={{fontSize:'1vw'}}/>
            </Button>
            <Typography variant="body1" style={{ margin: '0 1rem', fontSize: '0.75vw' }}>
               {page + 1} of {totalPages}
            </Typography>
            <Button style={{ fontSize: '1.5vh' }} onClick={() => handleChangePage(page + 1)} disabled={page >= totalPages - 1}>
              <ArrowForwardIosIcon style={{fontSize:'1vw'}}/>
            </Button>
            <Typography variant="body1" style={{ margin: '0 1rem', fontSize: '0.75vw' }}>
              {translate("filas_paginas")}
            </Typography>
            
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage} style={{ marginLeft: '1rem', fontSize: '0.75vw' }}>
              <option style={{ fontSize: '0.75vw' }} value={5}>5</option>
              <option style={{ fontSize: '0.75vw' }} value={10}>10</option>
              <option style={{ fontSize: '0.75vw' }} value={15}>15</option>
            </select>
          </StyledPagination>
          <Grid container justifyContent="center">
            <Button variant="outlined" color='inherit' style={{ fontSize: '0.75vw', width:'4.5vw', height:'3.5vh' }} onClick={handleClose}>{translate('cerrar')}</Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default ModalHistorialAlertas;
