import React, { useState, useEffect } from "react";

import {
    // TextField,
    Button,
    Box,
    Grid,
    Checkbox,
    FormControlLabel,
    IconButton,
    Tooltip
} from "@mui/material";

import styled from '@emotion/styled';
import DownloadIcon from '@mui/icons-material/Download';

/* React date range */
import moment from 'moment';
import { DateRangePicker, defaultInputRanges, defaultStaticRanges } from "react-date-range";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import jp from 'date-fns/locale/ja';
import zh from 'date-fns/locale/zh-CN';
import fr from 'date-fns/locale/fr';
import ar from 'date-fns/locale/ar-SA';
import ru from 'date-fns/locale/ru';
import pt from 'date-fns/locale/pt-BR';
import no from 'date-fns/locale/nb'; // Para noruego bokmål
import {
    addDays,
    addWeeks,
    startOfWeek,
    endOfWeek,
} from "date-fns";
/* Estilos */
import './css/small_react-date-range_default.css'
import './css/small_react-date-range_styles.css'



const StyledButton = styled(Button)`
  width: 5.5vw;
  height: 5vh;
  padding: 0;
  margin-top: -1vh;
  margin-left: 0;
  font-size: 1.5vh;
  line-height: 2;
  border-radius: 1vh;
  background-color: #24305e;
  color: #bfbfbf;
`;
const StyledButtonReport = styled(Button)`
  width: 5.5vw;
  height: 5vh;
  padding: 0;
  margin-top: 5vh;
  margin-left: 0;
  font-size: 1.5vh;
  line-height: 2;
  border-radius: 1vh;
  background-color: #24305e;
  color: #bfbfbf;
`;





function DateSelector({ fetchDatedData, closeModal, pauseRequest, setPauseRequest, makeReport, translate, idioma, setNotification_open, setNotification_msg }) {

    
    /* Date Range */
    // Del calendario
    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -7),       // Última semana
            endDate: new Date(),                      // Hoy
            key: 'selection'
        }
    ]);

    // Entendible por personas y BE
    const [dateRange, setDateRange] = useState({
        startDate: addDays(new Date(), -7),         // Última semana
        endDate: new Date(),                       // Hoy
        conglomeratedData: false,                      
    });

    // Función para actualizar la fecha de inicio y la fecha de fin
    const updateDateRange = (newStartDate, newEndDate) => {
        setDateRange({
            startDate: newStartDate,
            endDate: newEndDate,
        });
    };

    // Manejador para el cambio del checkbox
    const handleCheckboxChange = (event) => {
        setDateRange(prevState => ({
            ...prevState,
            conglomeratedData: event.target.checked,
        }));
    };

    // Actualizar fechas para BE al cambiar rango en calendario
    useEffect(() => {
        if (state) {
            const start_date = moment(state[0].startDate).format('DD/MM/YYYY')
            const end_date = moment(state[0].endDate).format('DD/MM/YYYY')
            updateDateRange(start_date, end_date)
        }

    }, [state]
    );

    const calendar_ranges = [
        {
            ...defaultStaticRanges[0],
            label: translate('hoy')
        },
        {
            ...defaultStaticRanges[1],
            label: translate('ayer')
        },
        {
            ...defaultStaticRanges[2],
            label: translate('esta_semana'),
            range: () => ({
                startDate: addDays(startOfWeek(addWeeks(new Date(), 0)), +1),
                endDate: addDays(endOfWeek(addWeeks(new Date(), 0)), +1)
            }),
        },
        {
            ...defaultStaticRanges[3],
            label: translate('semana_pasada'),
            range: () => ({
                startDate: addDays(startOfWeek(addWeeks(new Date(), -1)), +1),
                endDate: addDays(endOfWeek(addWeeks(new Date(), -1)), +1)
            }),
        },
        {
            ...defaultStaticRanges[5],
            label: translate('mes_pasado'),
        },
    ]
    
    const input_ranges = [{
        ...defaultInputRanges[0],
        label: translate('dias_hasta_hoy'),
    },
    ]


    const buttonClick = () => {
        // console.log('Solicitando datos...')
        
        // Obtener la fecha actual
        const currentDate = moment();

        /* Se utiliza formato DD/MM/AAA , HH:MM:SS */
        console.log({ 'Rango de fechas': dateRange })
        fetchDatedData(dateRange)

        // Determinar si se debe pausar la solicitud basándose en si endDate es anterior a la fecha actual
        const shouldPause = moment(state[0].endDate).isBefore(currentDate, 'day');
        console.log('pausar request: ', shouldPause)
        // Actualizar el estado de pauseRequest basándose en la condición anterior
        if (pauseRequest !== shouldPause) {
            setPauseRequest(shouldPause);
        }
        // setPauseRequest(true);
        // Se genera el reporte solo si el checkbox está seleccionado y se presiona el botón "Aceptar"
        if (dateRange.generateReport) {
            makeReport(dateRange);
        }

        closeModal()

    }

    const handleReport = () => {
        makeReport(dateRange)
        closeModal()
        setNotification_msg('Generando Reporte ...')
        setNotification_open(true)

    }


    return (
        <Box
        style={{ cursor: 'default !important', userSelect: 'none', }}
            backgroundColor='transparent'
            // marginLeft='5vw'
            // display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={'2vh'}
        // border='1px solid white'
        >
            <Grid container align='center' >
                {/* Calendario */}
                <Grid item xs={12} >
                    <DateRangePicker
                        /* https://github.com/hypeserver/react-date-range */
                        /* Comienza semana el lunes */
                        locale={
                            idioma === 'en' ? en :
                            idioma === 'jp' ? jp :
                            idioma === 'zh' ? zh :
                            idioma === 'fr' ? fr :
                            idioma === 'ar' ? ar :
                            idioma === 'ru' ? ru :
                            idioma === 'pt' ? pt :
                            idioma === 'es' ? es :
                            idioma === 'no' ? no :
                            null
                        }
                        weekStartsOn={1}
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        inputRanges={input_ranges}
                        staticRanges={calendar_ranges}
                        fixedHeight={true}
                        style={{ 'width': '47vw',  }}
                    />

                    {/* Botones */}
                    <Grid container justifyContent='center'  alignContent='center'  style={{ height:'6vh',width:'47vw', zIndex:1, marginLeft: '0vw', backgroundColor:'#282828', borderBottomLeftRadius:'2vh', borderBottomRightRadius:'2vh'  }} >
                        
                       

                         {/* Área Generar Reporte */}
                        {/* Checkbox de reporte Conglomerado */}
                        <Grid container justifyContent='center' alignContent='center' item xs={3} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dateRange.conglomeratedData}
                                        onChange={handleCheckboxChange}
                                        name="conglomeratedData"
                                        // color="primary"
                                        style={{color:'#e84e0e',}}
                                    />
                                }
                                label={
                                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1.4vh', color: 'rgba(180, 180, 180, 1)' }}>
                                        {/* <span>Reporte</span> */}
                                        <span>{translate('conglomerado')}</span>
                                    </div>
                                }
                            />
                        </Grid>

                        {/* Botón Generar reporte */}
                        <Grid container justifyContent='center' alignContent='center'  item xs={3} 
                        >
                            <Tooltip title="Generar Reporte">
                                <IconButton variant="contained" style={{color:'#e84e0e', height:'4vh',width:'2vw', }}
                                    onClick={() => handleReport()} disabled={!dateRange}
                                >
                                    <DownloadIcon style={{ fontSize: '2.5vh' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                         {/* Botón Obtener datos*/}
                         <Grid container justifyContent='center' alignContent='center'  item xs={6} >
                            <StyledButton variant="contained" style={{backgroundColor:'#e84e0e',height:'4vh',width:'4.5vw', fontSize:'0.75vw'}}
                                onClick={() => buttonClick()}
                                disabled={!dateRange}
                            >
                                {translate('aceptar')}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
}

export default DateSelector;