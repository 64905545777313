import React, { useEffect, useRef } from 'react';
import { Box, Grid, IconButton } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import 'chartjs-adapter-moment';
import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

import { format } from 'date-fns';

ChartJS.register(zoomPlugin);
ChartJS.register(...registerables);

const chartStyles = {
    battery: {
        borderColor:        'rgba(191, 91, 208, 0.9)',
        backgroundColor:    'rgba(171, 71, 188, 0.20)',
    },
};


// ? Función para crear configuración del gráfico
function createChartConfig(data1, minMaxBattery, axisFontSize = 15, legendFontSize = 14, lineType = 'area') {
    return {
        type: 'line',
        data: {
            datasets: [
                createDatasetConfig('Batería', data1, chartStyles.battery, lineType , 'yBattery'),
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false, /* Gráfico rectangular en lugar de cuadrado */
            interaction: {
                intersect: false,       /* Permite tooltip móvil */
                mode: 'index',          /* Permite tooltip móvil */
            },
            scales: {   /* x and y axis config */
                x: {
                    type: 'time',
                    time: {
                        parser: 'DD-MM-YYYY HH:mm:ss',   // Formato de la fecha proporcionada por tu API
                    },
                    ticks: {
                        maxTicksLimit: 8,  // Fuerza a que siempre se muestren 8 ticks
                        callback: function (val, index) {
                            if (this.getLabelForValue(val)) {
                                const currentDate = new Date(this.getLabelForValue(val));
                                // return format(currentDate, 'dd MMM, \nHH:mm');
                                // Dividir la fecha en dos líneas
                                return [format(currentDate, 'HH:mm'), format(currentDate, 'dd MMM')];
                            }
                        }
                    },
                    // title: { display: true, text: 'Fecha' }  // Formato de título del eje
                },
                
                yBattery: createYAxisConfig('yBattery', 'left', 'Batería', chartStyles.battery, minMaxBattery, axisFontSize),
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        font: {
                            size: legendFontSize,
                        }
                    }
                },
                zoom: {
                    pan: {
                        enabled: true,      // Habilita el desplazamiento
                        mode: 'x',          // Desplaza en el eje x
                        threshold: 10,      // Umbral mínimo de píxeles para empezar a desplazar
                    },
                    zoom: {
                        wheel: {
                            enabled: true,  // Deshabilita el zoom con la rueda del ratón
                        },
                        pinch: {
                            enabled: false,  // Deshabilita el zoom con gesto de pellizco
                        },
                        mode: 'x',           // Permite el zoom en el eje x
                    }
                }
            },

            //   hover: {
            // mode: 'nearest',
            // intersect: true
            //   },
            // ... otras opciones que puedan ser necesarias
        }
    }
}


// ? Función para crear una configuración común de los ejes Y
function createYAxisConfig(id, position, titleText, style, minMax, axisFontSize) {
    return {
        type: 'linear',
        display: true,
        position: position,
        id: id,
        grid: {
            drawOnChartArea: position === 'left' ? true : false, // Solo cuadrícula para el eje izquierdo
        },
        title: {
            display: true,
            text: titleText,
            color: style.borderColor,
            font: {
                size: axisFontSize
            }
        },
        min: minMax.min,
        max: minMax.max,
        ticks: {
            color: style.borderColor,
            font: {
                size: axisFontSize - 2
            }
        },
    };
}

// ? Función para crear la configuración de los datasets
function createDatasetConfig(label, data, style, lineType , yAxisID) {
    return {
        label: label,
        data: data.map(d => ({ x: d.time, y: d.value })),
        ...style,
        fill: lineType === 'area' ? true : false,
        tension: 0.2,
        pointRadius: 0,
        yAxisID: yAxisID,
    };
}

// ? Función para obtener el rango mínimo y máximo de los ejes
const getMinMax = (data, range, offset) => {
    if (Array.isArray(data) && data.length > 0) {
        const values = data.map(d => d.value);
        const minVal = Math.min(...values);
        const maxVal = Math.max(...values);
        return {
            min: minVal - (minVal * range) - offset,
            max: maxVal + (minVal * range) - offset
        }
    }
    else {
        return {
            min: 0,
            max: 10
        }
    }
};




const BatteryChart = (props) => {
    const { data1, width, height, axisFontSize, legendFontSize } = props
    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    const [data1Len, setData1Len] = React.useState(props.data1 ? props.data1.length : 0);


    // Esta función se encarga de actualizar los datos del gráfico
    const updateChartData = (chart, newData, datasetIndex) => {

        // ! Original - deprecado
        chart.data.datasets[datasetIndex].data = newData.map(d => ({ x: d.time, y: d.value }));
        chart.update();
    };

    // Función para actualizar la configuración del eje Y
    const updateYAxisConfig = (chart, axisId, newLimits) => {
        if (chart.options.scales[axisId]) {
            chart.options.scales[axisId].min = newLimits.min;
            chart.options.scales[axisId].max = newLimits.max;
        }
    };

    /* Creación del gráfico al montar componente */
    useEffect(() => {

        if (!chartRef.current && canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');

            // Calcular los nuevos límites para cada eje
            const minMaxBattery = {
                                        min: 0,
                                        max: 100
                                    }

            // Configuración inicial del gráfico

            const chartConfig = createChartConfig(data1, minMaxBattery, axisFontSize, legendFontSize, props.lineType);

            // Crear el gráfico
            chartRef.current = new ChartJS(ctx, chartConfig);

            // Asignar el yAxisID a cada dataset
            chartConfig.data.datasets[0].yAxisID = 'yBattery';

        }

        return () => {
            // Vacío, no es necesario hacer nada cuando los datos cambian
        };
    }, []);


    // Manejo de la actualización de los datos del gráfico
    useEffect(() => {
        if (chartRef.current && data1Len !== props.data1.length) {
            setData1Len(props.data1.length)
            console.log('gráfico data1 actualizado a largo:', props.data1.length)
            // Actualizar los datos del gráfico
            updateChartData(chartRef.current, props.data1, 0);

            // Calcular los nuevos límites para cada eje // ? Innecesario en batería
            // const newMinMaxOxigeno = getMinMax(props.data1, 0.3, 0);

            // Actualizar la configuración de los ejes Y
            // updateYAxisConfig(chartRef.current, 'yBattery', newMinMaxOxigeno);

            // Actualizar el gráfico para reflejar los cambios
            chartRef.current.update();
        }
    }, [props]);/* [data1Len, props]); */

    const handleHomeClick = () => {
        const chart = chartRef.current;
        if (chart) {
            chart.resetZoom();
        }
    };

    return (
        <Grid  >
            <Box display='flex' >

                <Grid container justifyContent="center">
                    {/* Otros botones e interacciones pueden ir aquí */}
                    <Grid container justifyContent="center" style={{ width, height }}>
                        <canvas ref={canvasRef} />
                    </Grid>
                </Grid>


            </Box>
            <Box height='auto' style={{ /* marginLeft: '-97%', top: '100%'  */ marginTop: '-4vh', marginLeft: '1vw' }} >
                <IconButton onClick={handleHomeClick} >
                    <HomeIcon />
                </IconButton>
            </Box>

        </Grid>
    );
};

export default BatteryChart;
