export function  transformData(consolidado_estados) {
    const { areas, centros, regiones } = consolidado_estados;
    const transformedData = [];
  
    // Recorrer las regiones
    for (const regionName in regiones) {
        const region = {
            name: regionName,
            statusRegions: regiones[regionName],
            areas: []
        };
  
        // Recorrer las áreas
        for (const areaName in areas) {
            const [regionPrefix, areaPrefix] = areaName.split('-');
  
            if (regionPrefix === regionName) {
                const area = {
                    name: areaPrefix,
                    statusArea: areas[areaName],
                    centers: []
                };
  
                // Recorrer los centros
                for (const centerName in centros[areaName]) {
                    const [centerPrefix] = centerName.split('-');
  
                    const center = {
                        name: centerPrefix,
                        statusCenter: centros[areaName][centerName]
                    };
  
                    area.centers.push(center);
                }
  
                region.areas.push(area);
            }
        }
  
        transformedData.push(region);
    }
  
    return transformedData;
  }