import React from 'react'
import { Grid } from '@mui/material';

const DrawerFooter = ({stylep175, translate, SWVersion}) => {
  return (
        <Grid container alignContent='center' style={{height:'10vh', marginTop:'80vh', position: 'absolute', padding: '1vh', borderTop: '1px solid #2f2f2f', }}>
            {/* Contenido del pie de página */}
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}> {translate('email_label')} informaciones@itgchile.com</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}> {translate('phone_label')} +56 (65) 22 52 881</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}> {translate('phone_label')} +56 (65) 22 50 080</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.8vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175,height:'1.3vh'}}>ITG Chile</p>
            </Grid>
            <Grid container justifyContent='center' alignContent='center' style={{ height:'1.5vh'}}>
                <p style={{fontWeight:200, fontSize: stylep175, height:'1.3vh', color:'rgba(255,255,255)'}}>{SWVersion}</p>
            </Grid>
        </Grid>
   )
}

export default DrawerFooter