import React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

// Estilos básicos para el componente
const styles = {
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1vh',
        marginLeft:'-1.5vw'
    },
    pageItem: {
        minWidth: '4vh', // Asegura un tamaño mínimo
        padding: '0.5vh', // Ajusta el padding en lugar de width/height para tamaño dinámico
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        fontSize: '2vh', // Tamaño de letra responsive
    },
    selectedPageItem: {
        border: '2px solid white',
        backgroundColor:'rgba(255,255,255,0.08)'
    },
    ellipsis: {
        cursor: 'default',
    },
    disabledArrow: {
        color: 'grey',
        cursor: 'default',
    },
};

const AmbientalPagination = ({ currentPage, totalPages, onChange, visiblePages = 1 }) => {
    // Función para generar las páginas intermedias
    const middlePages = () => {
        let pages = [];
        let startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 2);
        let endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);

        if (endPage - startPage < visiblePages - 1) {
            startPage = Math.max(endPage - visiblePages + 1, 2);
        }

        if (startPage > 2) {
            pages.push('...');
        }
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        if (endPage < totalPages - 1) {
            pages.push('...');
        }

        return pages;
    };

    // Determina si la flecha hacia atrás debe estar deshabilitada
    const isBackwardDisabled = currentPage === 1;
    // Determina si la flecha hacia adelante debe estar deshabilitada
    const isForwardDisabled = currentPage === totalPages;

    return (
        <div style={styles.paginationContainer}>
            <div
                style={{ ...styles.pageItem, ...(isBackwardDisabled ? styles.disabledArrow : {}) }}
                onClick={() => onChange('', Math.max(currentPage - 1, 1))}
                title="Estación anterior"
            >
                <ArrowBackIosNewRoundedIcon style={{ fontSize:'2vh' }} />
            </div>
            {/* Página inicial */}
            <div
                style={{ ...styles.pageItem, ...(currentPage === 1 ? styles.selectedPageItem : {}) }}
                onClick={() => onChange('', 1)}
            >
                1
            </div>

            {/* Páginas centrales */}
            {middlePages().map((page, index) => (
                <div
                    key={index}
                    style={{ ...styles.pageItem, ...(page === currentPage ? styles.selectedPageItem : {}), ...(page === '...' ? styles.ellipsis : {}) }}
                    onClick={() => page !== '...' && onChange('', page)}
                >
                    {page}
                </div>
            ))}

            {/* Página final */}
            <div
                style={{ ...styles.pageItem, ...(currentPage === totalPages ? styles.selectedPageItem : {}) }}
                onClick={() => onChange('', totalPages)}
            >
                {totalPages}
            </div>

            <div
                style={{ ...styles.pageItem, ...(isForwardDisabled ? styles.disabledArrow : {}) }}
                onClick={() => onChange('', Math.min(currentPage + 1, totalPages))}
                title="Estación siguiente"
            >
                <ArrowForwardIosRoundedIcon style={{ fontSize:'2vh' }} />
            </div>
        </div>
    );
};

export default AmbientalPagination;
