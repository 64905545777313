import React,{useState} from 'react'
import { Grid} from '@mui/material'
import { styled } from '@mui/material/styles';

import BombaStatusPS from '../PS/BombaStatusPS'
import BombaStatusVDF from '../VDF/BombaStatusVDF'
import DetalleCan from './DetalleCan';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import './utils/css/Main_Bombas.css'

const StyleTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    //   borderLeft: '0.01vh solid white',

    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#161616',
    //   border: '0.01vh solid white',
      fontSize:"0.5vw"
      
    },
  }));

const Main_Bombas = ({translate, modulo, modulosPorPagina,controlType}) => {
  
  // const [controlType, setControlType] = useState('PS'); // Estado para controlar la vista para //* -- VARIADOR DE FRECUENCIA -- || -- PARTIDOR SUAVE --

  return (
    <Grid container  spacing={1} width={"87.5vw"}  style={{}}>
      {modulo && modulo.cans && modulo.cans.map((can, idx) => {
      
      // console.log('%c Can:', 'color: rose; font-size:20px', can);

        return(
          <Grid container justifyContent="center" alignContent='center' item xs={4} key={idx} className='contenido_can'>
            <Grid container justifyContent="center" alignContent='center' style={{marginTop:'0vh', height:'32vh'}}>
              {can.bombas.map((bomba,idx_bomba) => (
                <Grid container justifyContent="center" item xs={6} key ={idx_bomba} style={{ height:'15.5vh'}}>
                  {controlType === 'PS' ?
                    (
                      <>
                        <BombaStatusPS  
                          modulo={modulo}
                          bomba={bomba} 
                          // color={can ? (can.estado?'#161616':'red'):'#161616'} 
                          color={'#161616'} 
                          translate={translate}
                          idx_bomba={idx_bomba}
                          controlType={controlType} 
                        />
                        
                      </>
                    ):(
                      <>
                      <BombaStatusVDF 
                        modulo={modulo}
                        bomba={bomba} 
                        color={'#161616'} 
                        // color={can ? (can.estado?'#161616':'red'):'#161616'} 
                        translate={translate}
                        idx_bomba={idx_bomba}
                        controlType={controlType} 
                        
                      />
                      </>
                    )
                  }  
                </Grid>
                
              ))}
            </Grid>
            <Grid container justifyContent="center" item xs={12}
                style={{ marginTop: "1.5vh", }}>
                    <DetalleCan
                        StyleTooltip={StyleTooltip}
                        idx={idx}
                        can={can}
                        translate={translate}
                    />
            </Grid>
          </Grid>
        )
      })}
   </Grid>
  )
}

export default Main_Bombas