import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel, EffectCoverflow, Pagination , Navigation } from 'swiper/modules';
import { Grid,  Paper, IconButton, Typography, Divider, Button,  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './utils/scroll.css'
import ENVIRO from '../../../assets/img/APP enviro.png'
import NANOOX from '../../../assets/img/APP-nanoox-1 (003).png'
import SCAPP from '../../../assets/img/APP-scapp.png'
import PSS from '../../../assets/img/APP pss.png'


// const CardReggions = ({
//     user,
//     remove,
//     translate, 
//     capitalize,
//     handleCenterDelete}) => {
//     const [expandedRegion, setExpandedRegion] = useState(null);
//     const [hoveredArea, setHoveredArea] = useState(null);

//     // const handleExpand = (regionIndex) => {
//     //     setExpandedRegion(expandedRegion === regionIndex ? null : regionIndex);
//     // };

//     const handleMouseEnter = (regionIndex, areaIndex) => {
//         setHoveredArea({ regionIndex, areaIndex });
//     };

//     const handleMouseLeave = () => {
//         setHoveredArea(null);
//     };

//     // console.log('user',user)
//   return (
//     <Grid container justifyContent="center" item xs={12} style={{ marginTop: "3vh", zIndex:0 }}>
//       <Swiper
//         effect={'coverflow'}
//         grabCursor={true}
//         centeredSlides={true}
//         // slidesPerView={'auto'}
//         slidesPerView={3} 
//         coverflowEffect={{
//           rotate: 50,
//           stretch: 0,
//           depth: 100,
//           modifier: 1,
//           slideShadows: true,
//         }}
//         pagination={true}
//         modules={[EffectCoverflow, Pagination]}
//         className="mySwiper"
//       >
//         {user && user.permisos && Object?.keys(user.permisos).map((region, regionIndex) => {
//           // const isExpanded = expandedRegion === regionIndex;
//           return (
//             <SwiperSlide key={regionIndex}>
//               <Paper
//                 elevation={3}
//                 style={{
//                     // border: 'solid',
//                   // width: isExpanded ? '15vw' : '5vw',
//                   // height: isExpanded ? '25vh' : '10vh',
//                   width: '30vw' ,
//                   height: '45vh' ,
//                   borderRadius: '2vh' ,
//                   cursor: 'pointer',
//                   overflow: 'hidden',
//                   transition: 'all 0.3s ease',
//                   marginRight:'1vw',
//                   backgroundColor:'#282828'
//                 }}
                
//               >
//                 <Grid container direction="center" alignItems="center" style={{  height: '5vh',  }}>
//                   <Grid container justifyContent="center" alignItems="center" item xs={10} style={{ height: '4vh', width: '100%' }}>
//                     <Typography  style={{ fontSize: '2.5vh' , marginTop: '-0.1vh', marginLeft: '3vw', textAlign: 'center' }}>
//                       {capitalize(String(region))}
//                     </Typography >
                      
//                   </Grid>
//                   <Grid container justifyContent="center" alignItems="center" item xs={2} >
//                     <IconButton
//                       onClick={(e) => { e.stopPropagation(); remove(regionIndex); }}
//                       variant="outlined"
//                       size="small"
//                     >
//                       <DeleteIcon style={{ fontSize: '2vh' }} />
//                     </IconButton>
//                   </Grid>
//                 </Grid>
//                 <Swiper
//                   watchSlidesProgress={true} 
//                   direction={'vertical'}
//                   scrollbar={{ draggable: true }}
//                   mousewheel={true}
//                   slidesPerView={2} // cantidad de ususraios visibles
//                   modules={[Pagination, Navigation, FreeMode, Scrollbar, Mousewheel]}
//                   // className="mySwiper"
//                   style={{ maxHeight:'40vh'}}
//                 >
//                   {Object?.keys(user?.permisos[region]).map((area, areaIndex) => {
//                     const colorArea = areaIndex % 2 === 0 ? '#0f0f0f' : '#212121';
//                     // const colorArea =  '#0f0f0f' // Tamaño personalizado 363636 boton:6a6a6a
//                     // console.log('area.CENTRO',areaData.CENTRO)
//                     return (
//                       <SwiperSlide key={areaIndex} >
//                         <Grid style={{ backgroundColor: colorArea, height:'20vh'}} container justifyContent='center' key={areaIndex}>
//                           <Typography variant="subtitle1" style={{fontSize:'2vh'}}>
//                             {/* {translate('area')}:  */}
//                             {capitalize(String(area))}
//                           </Typography>
//                             <Grid container justifyContent='center' alignContent='center'marginTop='-2.5vh' >
                             
//                                   {Object?.keys(user?.permisos[region][area]).map((center, centerIndex) => {
//                                     const parts = center.split('-');
//                                     return(
//                                       <Grid item xs={5} marginTop={'1vh'} key={centerIndex}  style={{height:'8vh', backgroundColor:'#363636', borderRadius:'2vh',  }}> 
//                                         <Grid container justifyContent='center' item xs={11} style={{fontSize:'2vh'}}>
//                                           {capitalize(String(parts[0]))}
//                                           <IconButton onClick={handleCenterDelete(regionIndex, areaIndex, centerIndex)} sx={{ position: 'absolute', marginTop: '-0.5vh', marginLeft: '6vw', }} >
//                                             <DeleteIcon style={{fontSize:'2.2vh'}} />
//                                           </IconButton>
//                                         </Grid>
//                                         <Grid container justifyContent='center' item xs={11} style={{fontSize:'2vh'}}>
//                                           {user.permisos[region][area][center].enviro.status && (
//                                             <img src={ENVIRO} alt="Enviro" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
//                                           )}
//                                           {user.permisos[region][area][center].nanoox.status && (
//                                             <img src={NANOOX} alt="Nanoox" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
//                                           )}
//                                           {user.permisos[region][area][center].scapp.status && (
//                                             <img src={SCAPP} alt="Scapp" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
//                                           )}
//                                            {user.permisos[region][area][center].scapp.status && (
//                                             <img src={PSS} alt="PSS" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
//                                           )}
//                                         </Grid>
//                                       </Grid> 
//                                     )
//                                   })}     
//                             </Grid>
//                           <Button
//                             onClick={(e) => { e.stopPropagation(); remove(regionIndex, areaIndex); }}
//                             variant="outlined"
//                             color="inherit"
//                             size="small"
//                             style={{fontSize:'1.4vh', marginTop:'1.5vh', marginBottom:'1vh', height:'4vh'}}
//                           >
//                             {translate('borrar_area')}
//                           </Button>
//                         </Grid>
//                       </SwiperSlide>
//                     )
//                   })}
//                 </Swiper>
//               </Paper>
//             </SwiperSlide>
//           );
//         })}
//       </Swiper>
//     </Grid>
//   )
// }

// export default CardReggions
const CardReggions = ({
  user,
  remove,
  translate, 
  capitalize,
  handleCenterDelete
}) => {
  const [expandedRegion, setExpandedRegion] = useState(null);
  const [hoveredArea, setHoveredArea] = useState(null);

  const handleMouseEnter = (regionName, areaIndex) => {
      setHoveredArea({ regionName, areaIndex });
  };

  const handleMouseLeave = () => {
      setHoveredArea(null);
  };

  return (
      <Grid container justifyContent="center" item xs={12} style={{ marginTop: "3vh", zIndex: 0 }}>
          <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={3}
              coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
              }}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
          >
              {user && user.permisos && Object.keys(user.permisos).map((region, regionIndex) => {
                  return (
                      <SwiperSlide key={regionIndex}>
                          <Paper
                              elevation={3}
                              style={{
                                  width: '30vw',
                                  height: '45vh',
                                  borderRadius: '2vh',
                                  cursor: 'pointer',
                                  overflow: 'hidden',
                                  transition: 'all 0.3s ease',
                                  marginRight: '1vw',
                                  backgroundColor: '#282828'
                              }}
                          >
                              <Grid container direction="center" alignItems="center" style={{ height: '5vh' }}>
                                  <Grid container justifyContent="center" alignItems="center" item xs={10} style={{ height: '4vh', width: '100%' }}>
                                      <Typography style={{ fontSize: '2.5vh', marginTop: '-0.1vh', marginLeft: '3vw', textAlign: 'center' }}>
                                          {capitalize(String(region))}
                                      </Typography>
                                  </Grid>
                                  <Grid container justifyContent="center" alignItems="center" item xs={2}>
                                      <IconButton
                                          onClick={(e) => { e.stopPropagation(); remove(region, null, null); }}
                                          variant="outlined"
                                          size="small"
                                      >
                                          <DeleteIcon style={{ fontSize: '2vh' }} />
                                      </IconButton>
                                  </Grid>
                              </Grid>
                              <Swiper
                                  watchSlidesProgress={true}
                                  direction={'vertical'}
                                  scrollbar={{ draggable: true }}
                                  mousewheel={true}
                                  slidesPerView={2}
                                  modules={[Pagination, Navigation, FreeMode, Scrollbar, Mousewheel]}
                                  style={{ maxHeight: '40vh' }}
                              >
                                  {Object.keys(user.permisos[region]).map((area, areaIndex) => {
                                      const colorArea = areaIndex % 2 === 0 ? '#0f0f0f' : '#212121';
                                      return (
                                          <SwiperSlide key={areaIndex}>
                                              <Grid style={{ backgroundColor: colorArea, height: '20vh' }} container justifyContent='center'>
                                                  <Typography variant="subtitle1" style={{ fontSize: '2vh' }}>
                                                      {capitalize(String(area))}
                                                  </Typography>
                                                  <Grid container justifyContent='center' alignContent='center' marginTop='-2.5vh'>
                                                      {Object.keys(user.permisos[region][area]).map((center, centerIndex) => {
                                                          const parts = center.split('-');
                                                          return (
                                                              <Grid item xs={5} marginTop={'1vh'} key={centerIndex} style={{ height: '8vh', backgroundColor: '#363636', borderRadius: '2vh' }}>
                                                                  <Grid container justifyContent='center' item xs={11} style={{ fontSize: '2vh' }}>
                                                                      {capitalize(String(parts[0]))}
                                                                      <IconButton onClick={(e) => { e.stopPropagation(); handleCenterDelete(region, area, center); }} sx={{ position: 'absolute', marginTop: '-0.5vh', marginLeft: '6vw' }}>
                                                                          <DeleteIcon style={{ fontSize: '2.2vh' }} />
                                                                      </IconButton>
                                                                  </Grid>
                                                                  <Grid container justifyContent='center' item xs={11} style={{ fontSize: '2vh' }}>
                                                                      {user.permisos[region][area][center].enviro.status && (
                                                                          <img src={ENVIRO} alt="Enviro" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
                                                                      )}
                                                                      {user.permisos[region][area][center].nanoox.status && (
                                                                          <img src={NANOOX} alt="Nanoox" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
                                                                      )}
                                                                      {user.permisos[region][area][center].scapp.status && (
                                                                          <img src={SCAPP} alt="Scapp" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
                                                                      )}
                                                                      {user.permisos[region][area][center].pss.status && (
                                                                          <img src={PSS} alt="PSS" style={{ width: '2vw', height: 'auto', margin: '0.3vw' }} />
                                                                      )}
                                                                  </Grid>
                                                              </Grid>
                                                          );
                                                      })}
                                                  </Grid>
                                                  <Button
                                                      onClick={(e) => { e.stopPropagation(); remove(region, area, null); }}
                                                      variant="outlined"
                                                      color="inherit"
                                                      size="small"
                                                      style={{ fontSize: '1.4vh', marginTop: '1.5vh', marginBottom: '1vh', height: '4vh' }}
                                                  >
                                                      {translate('borrar_area')}
                                                  </Button>
                                              </Grid>
                                          </SwiperSlide>
                                      );
                                  })}
                              </Swiper>
                          </Paper>
                      </SwiperSlide>
                  );
              })}
          </Swiper>
      </Grid>
  );
};

export default CardReggions;
