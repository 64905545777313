import React from 'react'
import { Grid,Paper } from '@mui/material'
import ReportOffIcon from '@mui/icons-material/ReportOff';

const BombaNoData = ({message}) => {
  return (
               
    <Grid container justifyContent="center" alignItems="center" alignContent={'center'} style={{ color: "white"}}>
        <Paper style={{height: "14vh", maxWidth:"13vw", minWidth:'13vw', marginLeft:'0vw', backgroundColor:"#07131a", border:"solid 0.01vh #254d5f"}}>
            {/* //920800 */}
            <Grid container justifyContent="center" alignItems="center" alignContent={'center'}>
                <Grid container justifyContent="center" alignItems="center" alignContent={'center'} item xs={12} height='5vh' >
                    <h4 style={{fontSize:"1vw", color: "#455761",textAlign:"center"  }}>
                        {message}
                    </h4>
                </Grid>
                <Grid container justifyContent="center" alignItems="center" alignContent={'center'} item xs={12} height='5vh'  style={{textAlign:"center", }}>
                    <ReportOffIcon style={{fontSize:"3vw", color: "#455761",}} />
                </Grid>
            </Grid>
        </Paper>
    </Grid>
  )
}

export default BombaNoData