import * as React from "react";
import {
    Box,
} from "@mui/material";

/* Notificaciones */
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';





const Alert = React.forwardRef(function Alert(props, ref,) {
    return <MuiAlert
        elevation={6} ref={ref} variant="filled" {...props}
        style={{
            width: "20vw", margin: "1vh", marginTop: "-170vh", marginLeft: "40vw", fontSize: '1.7vh', alignContent: 'center',
            justifyContent: "center", cursor: 'default !important', userSelect: 'none'
        }} />;
});



const Notification = ({ notification_open, notification_time, notification_severity, notification_msg, setNotification_open }) => {



    /* Notificaciones generales*/

    // const [notification_open, setNotification_open] = React.useState(false)
    // const [notification_msg, setNotification_msg] = React.useState('')                      // ? Mensaje de notificación
    // const [notification_severity, setNotification_severity] = React.useState('success')     // ? info ; warning ; success ; error
    // const [notification_time, setNotification_time] = React.useState(1500)                    // ? Tiempo en milisegundos de notificación


    const handleCloseNotification = () => {
        setNotification_open(false)
    };



    return (

        <div style={{ height: '0vh', width: '100vw' }}>

            {/* Notificaciones */}
            {/* Cargando camaras... */}
            <Box paddingLeft="13.3vw" paddingBottom="1.5vh"
                justifyContent="center"
                alignItems="center"
                minWidth="20vw">

                {/* Notificacion generica */}
                <Snackbar open={notification_open} /*autoHideDuration={notification_time}*/ onClose={handleCloseNotification} >
                    <Alert severity={notification_severity} >
                        {notification_msg}
                    </Alert>
                </Snackbar>
            </Box>

        </div>
    );
};

export default Notification