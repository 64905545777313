// // PieChart.js
// import React from 'react';
// import { Grid } from '@mui/material';
// import { Pie } from 'react-chartjs-2';
// import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

// ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);



// const PieChart = ({problemas}) => {

//     const severityLabels = {
//         0: 'No Clasificado',
//         1: 'Información',
//         2: 'Advertencia',
//         3: 'Media',
//         4: 'Alta',
//         5: 'Muy Alta',
//     };
    
//     const severityColors = {
//         0: '#97AAB3',
//         1: '#5C7ACC',
//         2: '#CCA047',
//         3: '#CC7F47',
//         4: '#B55C45',
//         5: '#B14545',
//     };
    
//     const groupBySeverity = (problems) => {
//         return problems.reduce((acc, problem) => {
//             const severity = problem.severity;
//             acc[severity] = (acc[severity] || 0) + 1;
//             return acc;
//         }, {});
//     };
    
//     const severityCounts = groupBySeverity(problemas);
//     const labels = Object.keys(severityCounts).map(severity => severityLabels[severity] || 'Desconocido');
//     const data = Object.values(severityCounts);
//     const colors = Object.keys(severityCounts).map(severity => severityColors[severity] || '#D3D3D3');
    
//     const chartData = {
//         labels: labels,
//         datasets: [
//             {
//                 data: data,
//                 backgroundColor: colors,
//                 borderWidth: 0, // Eliminar el borde
//                 hoverBackgroundColor: colors.map(color => color + '80'), // Agregar opacidad al pasar el cursor
//             },
//         ],
//     };
// return(
//     <Grid>
//         <h2>Problemas por Severidad</h2>
//         <Pie data={chartData} />
//     </Grid>
// )};

// export default PieChart;
// PieChart.js
// PieChart.js
// PieChart.js

import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { Chart, Title, Tooltip, Legend, ArcElement } from 'chart.js';

Chart.register(Title, Tooltip, Legend, ArcElement);

const PieChart = ({ problemas }) => {
    const chartRef = useRef(null);

    const severityLabels = {
        0: 'No Clasificado',
        1: 'Información',
        2: 'Advertencia',
        3: 'Media',
        4: 'Alta',
        5: 'Muy Alta',
    };

    const severityColors = {
        0: '#97AAB3',
        1: '#5C7ACC',
        2: '#CCA047',
        3: '#CC7F47',
        4: '#B55C45',
        5: '#B14545',
    };

    const groupBySeverity = (problems) => {
        return problems.reduce((acc, problem) => {
            const severity = problem.severity;
            acc[severity] = (acc[severity] || 0) + 1;
            return acc;
        }, {});
    };

    const severityCounts = groupBySeverity(problemas);
    const labels = Object.keys(severityCounts).map(severity => severityLabels[severity] || 'Desconocido');
    const data = Object.values(severityCounts);
    const colors = Object.keys(severityCounts).map(severity => severityColors[severity] || '#D3D3D3');

    useEffect(() => {
        const chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: colors,
                    borderWidth: 0,
                    hoverBackgroundColor: colors.map(color => color + '80'),
                    hoverOffset: 30,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    position: 'left',
                    labels: {
                        font: {
                            size: '20vw',
                        },
                        boxWidth: 20,
                        borderRadius:1 ,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.label || '';
                            if (label) {
                                label += ': ';
                            }
                            label += context.raw;
                            return label;
                        },
                    },
                },
            },
        };

        if (chartRef.current) {
            new Chart(chartRef.current, {
                type: 'pie',
                data: chartData,
                options: options,
            });
        }

        // Cleanup the chart instance on unmount
        return () => {
            if (chartRef.current) {
                Chart.getChart(chartRef.current)?.destroy();
            }
        };
    }, [problemas, labels, data, colors]);

    return (
        <Grid container justifyContent={'center'} >
            <Grid container justifyContent={'center'} alignContent={'center'} style={{height:'2vh', marginTop:'4vh'}}> 
                <h2 style={{ fontSize:'1.5vw'}}>Problemas por Gravedad</h2>
            </Grid>
            <Grid container justifyContent={'center'} style={{ zIndex: 999, overflow: 'auto', marginTop:'-0vh', height:'41vh' }}>
                    <canvas ref={chartRef} style={{ minHeight:'40vh', minWidth:'25vw', maxHeight:'40vh', maxWidth:'25vw', marginLeft:'-1vw'}}></canvas>
            </Grid>
        </Grid>
    );
};

export default PieChart;
