// const LOCAL_SERVER_URL = "http://192.168.20.95:5501";
// const LOCAL_SERVER_URL = " http://192.168.0.16:3000";
const LOCAL_SERVER_URL =`${localStorage.getItem("serverName")}` //"http://192.168.20.145:4023";

// //console.log('%cLOCAL_SERVER_URL:', 'color: green', LOCAL_SERVER_URL);


export async function _SERVER_URL() {
    // Número máximo de intentos
    const maxAttempts = 3;
    let attempts = 0;
    
    while (attempts < maxAttempts) {
        try {
            const controller = new AbortController();
            const signal = controller.signal;
            const timeout = setTimeout(() => {
                controller.abort();
            }, 5000);
    
            const URL_EMPRESA = LOCAL_SERVER_URL; // Modifica esta línea con la URL que deseas usar
            const ENDPOINT_TEST = 'check_server_status';
    
            //console.log(URL_EMPRESA);
    
            const publicResponse = await fetch(`${URL_EMPRESA}/${ENDPOINT_TEST}`, { method: 'HEAD', signal, keepalive: false });
            clearTimeout(timeout);
    
            //console.log((publicResponse && publicResponse.ok) ? 'IP Pública' : 'IP Local');
    
            return (publicResponse && publicResponse.ok) ? `${URL_EMPRESA}` : LOCAL_SERVER_URL;
    
        } catch (error) {
            console.error('Error al intentar conectarse:', error);
            attempts++;
            if (attempts < maxAttempts) {
                //console.log(`Reintentando en 2 segundos (intentos restantes: ${maxAttempts - attempts})`);
                await new Promise(resolve => setTimeout(resolve, 2000));
            } else {
                //console.log('Se alcanzó el número máximo de intentos. No se pudo establecer conexión.');
                return "Error en conexión";
            }
        }
    }
    
}

// METODO DE LOGIN
export async function LoginUsuario({ input_empresa, input_username, input_password, input_email }) {
    //console.log("entro en la funcion del login", "empresa: ", input_empresa, "/", "usuario: ", input_username, "/", "contraseña: ", input_password);
  
    let SERVER_URL = await _SERVER_URL;
  
    let username = input_username;
    let password = input_password;
  
    try {
      //console.log('solicitando login en ', `${SERVER_URL}/login`);
      const response = await fetch(`${SERVER_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        keepalive: false, // Asegura cerrar la conexión después de que se haya completado la solicitud
      });
  
      const data = await response.json();
      //console.log("Respuesta del servidor:", data);
  
      if (!response.ok) {
        throw new Error(data.mensaje || "Error al iniciar sesión");
      }
  
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
  
      return true;
    } catch (error) {
      console.error("Hubo un error al iniciar sesión:", error);
  
      // Puedes agregar lógica para reintentar el login si es necesario
  
      return false;
    }
  }

//METODO DE refreshToken
export async function refreshToken() {
    let SERVER_URL = await _SERVER_URL;
    const refreshToken = localStorage.getItem("refresh_token");
    try {
      const response = await fetch(`${SERVER_URL}/token/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refresh_token: refreshToken
        }),
        keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
      });
      const data = await response.json();
      if (data.access_token) {
        localStorage.setItem("access_token", data.access_token);
        //console.log('token refrescado exitosamente');
        return data.access_token;
      } else {
        // Si hay un error al refrescar el token, elimina cualquier token que pueda estar en localStorage
        //console.log('Eliminando credenciales');
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("empresa");
        throw new Error(data.message || "Error al refrescar el token");
      }
    } catch (error) {
      console.error("Hubo un error al refrescar el token:", error);
      return null;
    }
  }

// METODO DE CERRAR SESION
export function cerrarSesion(setIsAuthenticated) {
try {
    //console.log('Eliminando credenciales');
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("empresa");
    setIsAuthenticated(false);  // Aquí se actualiza el estado.
    return true;
} catch (error) {
    console.error("Error cerrando sesión:", error);
    return null;
}
}

// METODO DE CONSULTA DE DATOS get_centers
export async function get_center() {
    //console.log("obteniendo zonas  usuario")

    let SERVER_URL = await _SERVER_URL;

    let access_token = localStorage.getItem('access_token');
    //    localStorage.setItem('refresh_token', data.refresh_token);
    let data

    try {
        const response = await fetch(`${SERVER_URL}/get_centers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },

            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        data = await response.json();

        //console.log(data)

        // Si la respuesta no es exitosa, lanza un error
        if (response.status === 401) {
            access_token = await refreshToken();
            if (access_token) {
                const response = await fetch(`${SERVER_URL}/get_centers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`
                    },
        
                    keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
                });
        
                data = await response.json();
        }}

        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando  acceder al centro");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al acceder al centro:", error);
        throw error;
    }
}

// METODO DE CONSULTA DE DATOS DEL CENTRO  get_data
export async function get_data(region, area, centro, service) {
    //console.log("obteniendo zonas  usuario")

    let SERVER_URL = await LOCAL_SERVER_URL;
    //console.log("SERVER_URL",SERVER_URL)

    //    localStorage.setItem('refresh_token', data.refresh_token);
    let data

    try {
        const response = await fetch(`${SERVER_URL}/nanoox/get_data/${region}/${area}/${centro}`, {
        // const response = await fetch(`${SERVER_URL}/get_data/${"Aysen"}/${"Melinka"}/${"Cacere0s"}`, {

            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                
            },

            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        data = await response.json();

        // console.log('data',data)
        // console.log(response)


        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando  acceder al centro");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al acceder al centro:", error);
        throw error;
    }
}



//  //? generar Reportes
// export async function downloadReport(endpoint, dateRange, reportType, loggedIn=true,  setNotification_open, setNotification_msg, setNotification_status) {
//     try {
//         // Obtener la URL del servidor
//         const SERVER_URL = await LOCAL_SERVER_URL;
//         let Centro = localStorage.getItem('CENTRO');


//         if (!SERVER_URL || SERVER_URL === "Error en conexión") {
//             setNotification_msg("No se pudo establecer una conexión con el servidor.");
//             setNotification_open(true);
//             setNotification_status('error')
//             return {
//                 error: true,
//                 message: "No se pudo establecer una conexión con el servidor."
//             };
//         }

//         // Definir la URL completa
//         const fullUrl = `${SERVER_URL}${endpoint}`;

//         // Realizar la solicitud POST
//         const response = await fetch(fullUrl, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(dateRange)
//         });
//         //console.log('respuesta del servidor', response)

//         if (!response.ok) {
//             setNotification_msg(`Error al generar el reporte`);
//             setNotification_open(true);
//             setNotification_status('error')
//             return {
//                 error: true,
//                 message: `Error en la solicitud: ${response.status}`
//             };
//         }

//         // Manejar la respuesta...
//         // return {
//         //     // error: false,
//         //     // data: await response.blob()
            
//         // };
//         if (response.ok) {
//             if (loggedIn) {
//                 // Obtener la respuesta como un Blob
//                 const blob = await response.blob();

//                 // Crear un URL para el Blob
//                 const downloadUrl = window.URL.createObjectURL(blob);

//                 // Crear un enlace temporal para la descarga
//                 const a = document.createElement("a");
//                 a.href = downloadUrl;

//                 // Asignar nombre del reporte
//                 let reportName
//                 const startDateStr = dateRange.startDate.split('/').reverse().join('-')
//                 const endDateStr = dateRange.endDate.split('/').reverse().join('-')
//                 const startDate = new Date(startDateStr);
//                 const endDate = new Date(endDateStr);
//                 //console.log('reportType',reportType)

//                 if (reportType === 'reporteSensores' ){

//                     if (startDate.getTime() === endDate.getTime()) {
//                         reportName = `Nanoox ${Centro} ${startDateStr}.xlsx`
//                     } else {
//                         // Si las fechas son diferentes, usa ambas fechas en el nombre del archivo
//                         reportName = `Nanoox ${Centro} ${startDateStr}_${endDateStr}.xlsx`
//                     }
//                 }  

//                 if (reportType === 'reporteHistorialControl' ){

//                     if (startDate.getTime() === endDate.getTime()) {
//                         reportName = `Nanoox Historial de Control ${Centro} ${startDateStr}.xlsx`
//                     } else {
//                         // Si las fechas son diferentes, usa ambas fechas en el nombre del archivo
//                         reportName = `Nanoox Historial de Control ${Centro} ${startDateStr}_${endDateStr}.xlsx`
//                     }
//                 }

//                 a.download = reportName;        // "report.xlsx"; // O asignar un nombre de archivo dinámicamente si es necesario
//                 document.body.appendChild(a);
//                 a.click();

//                 // Limpiar y liberar recursos
//                 window.URL.revokeObjectURL(downloadUrl);
//                 document.body.removeChild(a);

//                 return true; // Indicar que la descarga fue exitosa
//             }
//             else{
//                 // Reporte guardado localmente, solo retornar
//                 return true
//             }
//         } else {
//             console.error(`Error en fetchCenterDated: ${response.status}`);
//             return null;
//         }
//     } catch (error) {
//         setNotification_msg(`Fetch failed in downloadReport: ${error}`);
//         setNotification_open(true);
//         setNotification_status('error')

//         return {
//             error: true,
//             message: `Fetch failed in downloadReport: ${error}`
//         };
//     }
// }
export async function downloadReport(endpoint, dateRange, reportType, setNotification_open, setNotification_msg, setNotification_status) {
    try {
        const SERVER_URL = await LOCAL_SERVER_URL;
        let Centro = localStorage.getItem('CENTRO');

        const fullUrl = `${SERVER_URL}${endpoint}`;

        const response = await fetch(fullUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateRange)
        });

        if (!response.ok) {
            setNotification_msg(`Error al generar el reporte: ${response.status}`);
            setNotification_open(true);
            setNotification_status('error');
            return { error: true, message: `Error en la solicitud: ${response.status}` };
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;

        let reportName;
        const startDateStr = dateRange.startDate.split('/').reverse().join('-');
        const endDateStr = dateRange.endDate.split('/').reverse().join('-');
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        if (reportType === 'reporteSensores') {
            reportName = startDate.getTime() === endDate.getTime() ? `Nanoox ${Centro} ${startDateStr}.xlsx` : `Nanoox ${Centro} ${startDateStr}_${endDateStr}.xlsx`;
        } else if (reportType === 'reporteHistorialControl') {
            reportName = startDate.getTime() === endDate.getTime() ? `Nanoox Historial de Control ${Centro} ${startDateStr}.xlsx` : `Nanoox Historial de Control ${Centro} ${startDateStr}_${endDateStr}.xlsx`;
        }

        a.download = reportName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);

        return { error: false };
    } catch (error) {
        setNotification_msg(`Fetch failed in downloadReport: ${error}`);
        setNotification_open(true);
        setNotification_status('error');
        return { error: true, message: `Fetch failed in downloadReport : ${error}` };
    }
}


export async function fetchAlertas() {
    try {
        let SERVER_URL = LOCAL_SERVER_URL

        const Region = localStorage.getItem('REGION')
        const Area = localStorage.getItem('AREA')
        const Centro = localStorage.getItem('CENTRO')

        // ////console.log('SERVER_URL postUmbralesAlertas', SERVER_URL)

        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return {};
        }
        const endpoint = `/NANOOX/alertas_activas/${Centro}/${Area}/${Region}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        ////console.log('fullUrl', fullUrl)

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });


        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        
        // Retornar el resultado
        return result;
    } catch (error) {
        console.error('Error:', error);
        return {};  // Retornar un objeto vacío en caso de error
    }
};

export async function fetchFallas() {
    try {
        let SERVER_URL = LOCAL_SERVER_URL

        const Region = localStorage.getItem('REGION')
        const Area = localStorage.getItem('AREA')
        const Centro = localStorage.getItem('CENTRO')

        // ////console.log('SERVER_URL postUmbralesAlertas', SERVER_URL)

        // Si no se pudo obtener la URL del servidor, retornar null
        if (SERVER_URL === "Error en conexión") {
            console.error("No se pudo establecer una conexión con el servidor.");
            return {};
        }
        const endpoint = `/NANOOX/fallas_activas/${Centro}/${Area}/${Region}`;
        const fullUrl = `${SERVER_URL}${endpoint}`;
        ////console.log('fullUrl', fullUrl)

        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // console.log('respuesta servidor para alertas', response)

        if (!response.ok) {
            throw new Error('Error en la solicitud');
        }

        const result = await response.json();
        // console.log('resultado del servidor para Fallas:', result);
        
        // Retornar el resultado
        return result;
    } catch (error) {
        console.error('Error:', error);
        return {};  // Retornar un objeto vacío en caso de error
    }
};


export async function tipo_control() {
try {
    let SERVER_URL = LOCAL_SERVER_URL

    const Region = localStorage.getItem('REGION')
    const Area = localStorage.getItem('AREA')
    const Centro = localStorage.getItem('CENTRO')


    if (SERVER_URL === "Error en conexión") {
    console.error("No se pudo establecer una conexión con el servidor.");
    return {};
    }

    const endpoint = `/NANOOX/tipo_control/${Centro}/${Area}/${Region}`;
    const fullUrl = `${SERVER_URL}${endpoint}`;
    //console.log('fullUrl', fullUrl);

    const response = await fetch(fullUrl, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    },
    });

    //console.log('respuesta servidor', response);

    if (!response.ok) {
    throw new Error('Error en la solicitud');
    }

    const result = await response.json();
//   console.log('Respuesta del servidor:', result);

    return result; // Asegúrate de retornar el resultado aquí
} catch (error) {
    console.error('Error:', error);
    return {}; // Retorna un objeto vacío en caso de error
}
}

 
// ? FUNCIONES USUARIOS

//METODO CREACION DE USUARIO
export async function CrearUsuario() {
    //console.log("creando usuario")

    let SERVER_URL = await _SERVER_URL;



    try {
        const response = await fetch(`${SERVER_URL}/create_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                "username": "fonchi",
                "password": "11111",
                "cargo": "senior",
                "accesos": {
                    "Region2": {
                      "Area2": [
                        "Centro4",
                        "Centro5"
                      ]
                    },
                    "Region3": {
                      "Area3": [
                        "Centro6"
                      ]
                    }
                  },
                
                "correo": "fonchi@itgchile.com"
            }),
            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        //console.log(data)

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error crear nuevo usuario");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al crear nuevo usuario:", error);
        throw error;
    }
}

//METODO PARA EDICION/ACTUALIZACION DE USUARIO
export async function EditarUsuario() {
    //console.log("editando  usuario")

    let SERVER_URL = await _SERVER_URL;



    try {
        const response = await fetch(`${SERVER_URL}/edit_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                "username": "richi",
                "password": "eeeeeel",
                "cargo": "wena richi",
                "accesos": {
                    "regionx": "los cracks"
                }
                ,
                "correo": "nuevo_correo@example.com"
            }),
            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        //console.log(data)

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando actualizar  usuario");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al actualizar nuevo usuario:", error);
        throw error;
    }
}

//METODO PARA ELIMINAR USUARIO
export async function EliminarUsuario() {
    //console.log("eliminando  usuario")

    let SERVER_URL = await _SERVER_URL;


    try {
        const response = await fetch(`${SERVER_URL}/delete_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                "username": "fonchi"

            }),

            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        //console.log(data)

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando eliminar  usuario");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al eliminar  usuario:", error);
        throw error;
    }
}

//METODO PARA ELIMINAR USUARIO
export async function ObtenerZonas() {
    //console.log("obteniendo zonas  usuario")

    let SERVER_URL = await _SERVER_URL;


    try {
        const response = await fetch(`${SERVER_URL}/obtener_usuarios`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                

            }),

            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });

        const data = await response.json();

        //console.log(data)

        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando eliminar  usuario");
        }

        return data;


    } catch (error) {
        console.error("Hubo un error al eliminar  usuario:", error);
        throw error;
    }
}

//METODO PARA ELIMINAR USUARIO
export async function StartVdf() {
    //console.log("iniciando vdf")
  
    let SERVER_URL = await _SERVER_URL;
  
  
    try {
        const response = await fetch(`${SERVER_URL}/ControlVdf`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
  
              "VdfName": "VA1",
             
              "action": true
          }),
    
  
            keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
        });
  
        const data = await response.json();
  
        //console.log(data)
  
        // Si la respuesta no es exitosa, lanza un error
        if (!response.ok) {
            throw new Error(data.mensaje || "Error intentando eliminar  usuario");
        }
  
        return data;
  
  
    } catch (error) {
        console.error("Hubo un error al eliminar  usuario:", error);
        throw error;
    }
  }
    
//METODO PARA ELIMINAR USUARIO
export async function StopVdf() {
//console.log("deteniendo vdf")

let SERVER_URL = await _SERVER_URL;


try {
    const response = await fetch(`${SERVER_URL}/ControlVdf`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            "VdfName": "VA1",
            
            "action": false
        }),


        keepalive: false  // Asegura cerrar la conexión después de que se haya completado la solicitud
    });

    const data = await response.json();

    //console.log(data)

    // Si la respuesta no es exitosa, lanza un error
    if (!response.ok) {
        throw new Error(data.mensaje || "Error intentando eliminar  usuario");
    }

    return data;


} catch (error) {
    console.error("Hubo un error al eliminar  usuario:", error);
    throw error;
}
}


