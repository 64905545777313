import React, { useState, useEffect, useRef  } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid, Drawer, IconButton, Avatar, CssBaseline } from "@mui/material"
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PublicIcon from '@mui/icons-material/Public';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import '../../assets/css/drawer.css'
const drawerWidth = '15vw';
const drawerHeight = '87vh';


async function sha256(message) {
    const msgBuffer = new TextEncoder("utf-8").encode(message);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => ("00" + b.toString(16)).slice(-2)).join("");
    return hashHex;
  }

export default function PersistentDrawerLeft({ 
    consolidado_estados,
    severityMap,
    cargo,
    handleUsersItemClick,
    handleCentersItemClick,
    handleExit,
    open,
    setOpen,
    updateClientCredentials,
    translate,
    SWVersion,
    selectedRegion, setSelectedRegion, selectedArea, setSelectedArea,
    capitalize
 }) {
    //* ----------------------------- Estado del componente -----------------------------
    // const [centersData, setCentersData ] = React.useState( [] )
    const [showRegions, setShowRegions] = useState(true);
    const [showAreas, setShowAreas] = useState(true);
    const drawerRef = useRef(null);
    const [posicionArea, setPosicionArea] = useState(null)

    const containerRef = useRef(null);
    const [isOverflow, setIsOverflow] = useState(false);
    //  *
    // const [usuarioLogeado, setusuarioLogeado] = useState("");
    const [matrix, setMatrix] = useState(Array(5).fill(Array(5).fill(0)));
    const canvasRef = useRef(null);
    //  * 

    const [showOptions, setShowOptions] = useState(false);
    const usuarioLogeado = localStorage.getItem('username')
    const [bgColor, setBgColor] = useState(true); // Color inicial del fondo

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    // Función para cambiar el color de fondo al hacer clic
    const handleClick = () => {
        setBgColor(!bgColor);
        toggleOptions();
    };



    // *  -----------------------------  Función: handleRegionClick ------------------------------------------------
    //? Descripción: 
    //? Función utilizada para poder seleccionar Regiones
    //? Parámetros:
    // - region : objeto con atributo 'clientId' que corresponde al indice del cliente 'name' que corresponde a la region seleccionada
    //            y un atributo 'area' que es un array de objetos de las areas de la region seleccionada, objeto que corresponde al que se desbribe en los parametros de handleAreaClick 
    const handleRegionClick = (region) => {
        // console.log('region',region)
        setSelectedRegion(region);
    };

    // *  -----------------------------  Función: handleAreaClick ------------------------------------------------
    //? Descripción: 
    //? Función utilizada para poder seleccionar areas
    //? Parámetros:
    // - area : objeto con atributo 'name' que corresponde al area seleccionada y un atributo 'centers' que es un array de los centros de esa area
    const handleAreaClick = (area) => {
        setSelectedArea(area.name);
        // setCentro(area.centers)
        // console.log('area center seleccionada',area.centers)
        // console.log('areas seleccionada',area)
    };

//  * --------------------------------------- useEffect: para cerrar el drawer al presionar fuera del cajon
    useEffect(() => {
    // Función para cerrar el Drawer cuando se hace clic fuera de él
    const handleOutsideClick = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setOpen(false);
        }
    };

    // Agregar el event listener cuando el Drawer está abierto
    if (open) {
        document.addEventListener('mousedown', handleOutsideClick);
    } else {
        // Remover el event listener cuando el Drawer está cerrado
        document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
    };
    }, [open]);

    const getInitial = (name) => {
        return name ? name.charAt(0) : '';
    };

    const generarColorAvatar = (username) => {
        // Generar un valor hash a partir del nombre de usuario
        let hash = 0;
        for (let i = 0; i < username.length; i++) {
            hash = username.charCodeAt(i) + ((hash << 5) - hash);
        }
        // Convertir el hash a un color hexadecimal
        const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
        // Añadir ceros a la izquierda si es necesario
        return "#" + "00000".substring(0, 6 - color.length) + color;
    };

    // //* funcion
    useEffect(() => {
    const generateMatrix = async () => {
      let m = Array(5).fill().map(() => Array(5).fill(0));
      let hash = (await sha256(usuarioLogeado)).substr(0, m.length * m[0].length);
      
      for (let i = 0; i < m.length; i++) {
        for (let j = 0; j < m[i].length; j++) {
          let n = parseInt(hash.substr(i * j + j, 1), 16);
          m[i][j] = n > 7 ? 0 : 1;
        }
      }

      for (let i = 0; i < m.length; i++) {
        for (let j = Math.round(m[i].length / 2), k = 2; j < m[i].length; j++, k += 2) {
          m[i][j] = m[i][j - k];
        }
      }

      setMatrix(m);
    };

    if (usuarioLogeado) {
      generateMatrix();
    }
    }, [usuarioLogeado]);

    useEffect(() => {
        const ctx = canvasRef.current?.getContext("2d");

        if (!ctx) return; // Si canvasRef no está asignado, salir

        const clear = () => {
        ctx.fillStyle = "#000";
        ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        };

        const drawMatrix = () => {
        clear();

        let r = Math.floor(Math.random() * 128 + 128);
        let g = Math.floor(Math.random() * 128 + 128);
        let b = Math.floor(Math.random() * 128 + 128);

        ctx.fillStyle = `rgba(${r}, ${g}, ${b}, 1)`;

        for (let i = 0; i < matrix.length; i++) {
            for (let j = 0; j < matrix[i].length; j++) {
            if (matrix[i][j] === 1) {
                ctx.fillRect(
                j * (canvasRef.current.width / 5),
                i * (canvasRef.current.height / 5),
                canvasRef.current.width / 5,
                canvasRef.current.height / 5
                );
            }
            }
        }
        };

        drawMatrix();
    }, [matrix]);

    useEffect(() => {
        const handleResize = () => {
        if (containerRef.current) {
            const containerHeight = containerRef.current.clientHeight;
            setIsOverflow(containerHeight > window.innerHeight * 0.76);
        }
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize); // Check on resize

        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized && consolidado_estados.length > 0) {
            const firstRegion = consolidado_estados[0];
            setSelectedRegion(firstRegion);
            localStorage.setItem('REGION', firstRegion.name);

            if (firstRegion.areas.length > 0) {
                const firstArea = firstRegion.areas[0];
                setSelectedArea(firstArea.name);
                localStorage.setItem('AREA', firstArea.name);
            }
            setInitialized(true);
        }
    }, [consolidado_estados, initialized]);


    //* Componente personalizado para el pie de página del Drawer
    const DrawerFooter = () => {
        return (
        <Grid container alignContent='center' style={{height:'10vh', marginTop:'77vh', position: 'absolute', padding: '1vh', borderTop: '1px solid #2f2f2f', }}>
            {/* Contenido del pie de página */}
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.7vh'}}>
                <p style={{fontWeight:200, fontSize: '0.7vw',height:'1.3vh'}}> {translate('email_label')} informaciones@itgchile.com</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.7vh'}}>
                <p style={{fontWeight:200, fontSize: '0.7vw',height:'1.3vh'}}> {translate('phone_label')} +56 (65) 22 52 881</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.7vh'}}>
                <p style={{fontWeight:200, fontSize: '0.7vw',height:'1.3vh'}}> {translate('phone_label')} +56 (65) 22 50 080</p>
            </Grid>
            <Grid container justifyContent='flex-start' alignContent='center' style={{ height:'1.7vh'}}>
                <p style={{fontWeight:200, fontSize: '0.7vw',height:'1.3vh'}}>ITG Chile</p>
            </Grid>
            <Grid container justifyContent='center' alignContent='center' style={{ height:'1.7vh'}}>
                <p style={{fontWeight:200, fontSize: '0.7vw', height:'1.3vh', color:'rgba(255,255,255)'}}>{SWVersion}</p>
            </Grid>
        </Grid>
        );
    };
      
    const styleFont='1vw'
    const stylefontSize='1vw'

    return (
        <Grid container justifyContent="center" item xs={1} sx={{ display: "flex" }} style={{ cursor: 'default !important', userSelect: 'none' }}  
        >
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    // height:drawerHeight,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        height:drawerHeight,
                        marginTop:'11vh',
                        marginLeft:'1.5vw',
                        borderRadius:'2vh',
                        border: 0,
                        background:'linear-gradient(#393939, #1a1a1a)',
                        display: 'flex',
                        // backgroundColor:'#404048'
                        // boxSizing: "border-box",
                        // border:'solid 0.01vh '

                    },
                    // border:'solid 0.1vh'
                    borderRadius:'2vh',
                }}
                
                variant="persistent"
                anchor="left"
                open={open}
            >

                <Grid ref={containerRef} container style={{overflowY: isOverflow ? 'auto' : 'hidden',maxHeight:'76vh' }}  >
                    {/* Texto para los usuarios*/}
                    <Grid container justifyContent='center' alignContent='center' alignItems='center' style={{ height:'7vh', backgroundColor:!bgColor ? '#252525': '#252525', }}>
                        <Grid container justifyContent='center' alignContent='center' alignItems='center' onClick={handleClick} item xs={11} 
                        sx={{ height: '5vh', width:'12vw', marginBottom:'1.5vh', marginTop:'1.5vh', backgroundColor: !bgColor ? '#252525': 'transparent', borderRadius:'1vh' }}>
                            <Grid  item xs={2} alignContent='center' /*className="circle-container"*/>
                                <Avatar sx={{  bgcolor: generarColorAvatar(usuarioLogeado) ,marginRight: '0.5vw',  fontSize:'2.5vh', width:'3vh', height:'3vh'  }}>
                                    {getInitial(usuarioLogeado)}
                                </Avatar>
                                {/* <canvas ref={canvasRef} width={'40vw'} height={'40vh'} ></canvas> */}
                            </Grid>
                            {/* <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}></p> */}
                            <Grid container justifyContent='center' item xs={6}  alignContent='center' >
                                <p style={{ color: 'white', fontSize: '1.5vw', marginLeft: '0.4vw' }}>
                                    {usuarioLogeado}
                                </p>
                            </Grid>
                            <Grid container justifyContent='center' item xs={2}  alignContent='center' textAlign='initial'>
                                <IconButton style={{ color: 'white' }} onClick={toggleOptions}>
                                        {showOptions ? <ExpandLessIcon style={{ fontSize: styleFont }} /> : <ExpandMoreIcon style={{ fontSize: styleFont }} />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showOptions && (
                        <>
                            {cargo !== 'Operario' && (
                                <Grid container onClick={handleUsersItemClick} style={{backgroundColor:!bgColor ? '#252525': 'transparent',}}>
                                        <Grid container justifyContent='center'  >
                                                {/* boton para selecicionar regiones 3f3f3f */}
                                                <Grid container justifyContent='center' item xs={11}
                                                    sx={{
                                                        height: '5vh',
                                                        width:'12vw',
                                                        borderRadius:'1vh',
                                                        justifyContent: 'initial',
                                                        px: 1,
                                                        fontSize: "1.5vh", 
                                                        // border:'solid 0.1vh'
                                                        '&:hover': {
                                                            backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                                        },
                                                    }}
                                                >
                                                    {/* icono de las Gestionar usuarios */}
                                                    <Grid item xs={2} alignContent='center'>
                                                        <PersonIcon style={{fontSize:styleFont}} />
                                                    </Grid>
                                                    {/* nombre de las Gestionar usuarios */} 
                                                    <Grid item xs={8}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                        <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}> {translate('gestionar_usuarios')} </p>
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                </Grid>
                            )}
                            {cargo === 'Superusuario' && (
                                <Grid onClick={handleCentersItemClick} container style={{backgroundColor:!bgColor ? '#252525': 'transparent',}}>
                                        <Grid container justifyContent='center'  >
                                                {/* boton para selecicionar ciclos */}
                                                <Grid container justifyContent='center' item xs={11}
                                                    sx={{
                                                        height: '5vh',
                                                        width:'12vw',
                                                        borderRadius:'1vh',
                                                        justifyContent: 'initial',
                                                        px: 1,
                                                        fontSize: "1.5vh", 
                                                        '&:hover': {
                                                            backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                                        },
                                                    }}
                                                >
                                                    {/* icono de las regiones */}
                                                    <Grid item xs={2} alignContent='center'>
                                                        <BusinessIcon style={{fontSize:styleFont}} />
                                                    </Grid>
                                                    {/* gestionar ciclos */} 
                                                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                        <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}> {translate('gestionar_ciclos')} </p>
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                </Grid>
                            )}
                            <Grid onClick={handleExit} container style={{  borderBottom: '0.1vh solid #3f3f3f',height: '6vh',backgroundColor:!bgColor ? '#252525': 'transparent',}}>
                                    <Grid container justifyContent='center'  >
                                            {/* boton cerrar sesion */}
                                            <Grid container justifyContent='center' item xs={11}
                                                sx={{
                                                    height: '5vh',
                                                    width:'12vw',
                                                    borderRadius:'1vh',
                                                    justifyContent: 'initial',
                                                    px: 1,
                                                    fontSize: "1.5vh", 
                                                    '&:hover': {
                                                        backgroundColor: '#3f3f3f', // puedes cambiar el color y grosor del borde a tu gusto
                                                    },
                                                }}
                                            >
                                                {/* icono de las regiones */}
                                                <Grid item xs={2} alignContent='center'>
                                                    <LogoutIcon style={{fontSize:styleFont}} />
                                                </Grid>
                                                {/* nombre de las regiones */} 
                                                <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                    <p sx={{ opacity: open ? 1 : 0, }} style={{fontSize: '0.7vw', }}>  {translate('cerrar_sesion')}     </p>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                            </Grid>
                        </>
                    )}
                    {/* Texto para las Regiones y boton para ocultar las regiones */}
                    <Grid container justifyContent='center' >
                        {/* <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}></p> */}
                        <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}>
                            {translate('regions')}
                            <IconButton
                                onClick={() => setShowRegions(!showRegions)}
                                disabled={selectedRegion===null}
                                style={{ color: 'white' }}
                            >
                                {showRegions ? <ExpandLessIcon  style={{fontSize:styleFont}}  /> : <ExpandMoreIcon  style={{fontSize:styleFont}} />}
                            </IconButton>
                        </p>
                    </Grid>
                    {/* Region seleccionada al ocultar las demas regiones */}
                    {showRegions === false &&
            
                        <Grid container justifyContent='center'  >
                            {/* boton para selecicionar regiones */}
                            <Grid container justifyContent='center' item xs={11}
                                sx={{
                                    height: '5vh',
                                    width:'12vw',
                                    borderRadius:'1vh',
                                    justifyContent: 'initial',
                                    px: 1,
                                    fontSize: "1.5vh", // Ajuste del tamaño del texto para centros
                                    backgroundColor:  'rgb(232, 78, 14,0.6)',
                                }}
                            >
                                {/* icono de las regiones */}
                                <Grid container justifyContent='center' item xs={2} alignContent='center' style={{backgroundColor: severityMap[selectedRegion.statusRegions], height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh' }}>
                                    <PublicIcon style={{fontSize:styleFont}} />
                                </Grid>
                                {/* nombre de las regiones */} 
                                <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                    <p sx={{ opacity: open ? 1 : 0, fontSize: '1.2vh', }}>{selectedRegion && capitalize(selectedRegion.name)}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    }
                    {/* mapeo de Regiones */}
                    {showRegions &&
                        consolidado_estados?.map((region, index) => (
                            <Grid container key={index} style={{ marginTop:'1vh'}} >
                                    <Grid container justifyContent='center'  >
                                            {/* boton para selecicionar regiones */}
                                            {/* <Badge badgeContent={ <PublicIcon style={{fontSize:styleFont}} />}
                                                anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                                }}
                                                sx={{marginLeft:'0vw','& .MuiBadge-badge': { backgroundColor: severityMap[region.statusRegions], height:'3vh', width:'1.5vw', marginTop:'0.8vh', marginRight:'0.1vw', fontSize:'1.8vh' }  }}
                                                > */}
                                                <Grid container justifyContent='center'   item xs={11}
                                                    sx={{
                                                        height: '5vh',
                                                        width:'12vw',
                                                        borderRadius:'1vh',
                                                        justifyContent: 'initial',
                                                        px: 1,
                                                        fontSize: "1.5vh", // Ajuste del tamaño del texto para centros
                                                        '&:hover': {
                                                            backgroundColor: 'rgb(255, 120, 60)', // puedes cambiar el color y grosor del borde a tu gusto
                                                        },
                                                        backgroundColor:  (selectedRegion && selectedRegion.clientId ===  region.clientId) && (selectedRegion && selectedRegion.name ===  region.name) ? 'rgb(232, 78, 14,0.6)' : '#3f3f3f',
                                                    }}
                                                    // onClick={() => handleRegionClick(region)}
                                                    onClick={() => {
                                                        handleRegionClick(region);
                                                        localStorage.setItem('REGION', region.name);
                                                    }}
                                                >
                                                    {/* icono de las regiones */}
                                                    <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[region.statusRegions] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                                                        <PublicIcon style={{fontSize:styleFont}} />
                                                    </Grid>
                                                    {/* nombre de las regiones */} 
                                                    <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                        <p sx={{ opacity: open ? 1 : 0, color: selectedRegion === region && 'white', fontSize: '1.2vh', }}>{capitalize(region.name)}</p>
                                                    </Grid>
                                                </Grid>
                                            {/* </Badge> */}
                                    </Grid>
                            </Grid>
                        ))
                    }
                    {/* Texto para las áreas y boton para ocultar las areas */}
                    <Grid container justifyContent='center' marginBottom='-2vh' >
                        {/* <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}></p> */}
                        <p style={{ color: 'white', fontSize: '1.8vh', marginLeft: '0.4vw' }}>
                            {translate('areas')}
                            <IconButton
                                onClick={() => setShowAreas(!showAreas)}
                                disabled={selectedArea===null}
                                style={{ color: 'white' }}
                            >
                                {showAreas ? <ExpandLessIcon  style={{fontSize:styleFont}}  /> : < ExpandMoreIcon  style={{fontSize:styleFont}} />}
                            </IconButton>
                        </p>
                    </Grid>
                    {/* Area seleccionada al ocultar las demas areas */}
                    {showAreas === false &&
                        <Grid container justifyContent='center'>
                            {/* boton para seleccionar areas */}
                            <Grid container justifyContent='center' item xs={11}
                                sx={{
                                    height: '5vh',
                                    width:'12vw',
                                    borderRadius:'1vh',
                                    justifyContent: 'initial',
                                    px: 1,
                                    fontSize: "1.5vh",
                                    backgroundColor: 'rgb(255, 120, 60, 0.6)',
                                    marginTop:'0.5vh',
                                }}
                            >
                                {/* icono de la areas */}
                                
                                            
                                <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[selectedRegion?.areas?.[posicionArea].statusArea] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>       
                                    <TravelExploreIcon  style={{fontSize:styleFont}}/>
                                </Grid>
                                {/* nombre de la area seleccionada */}
                                <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                <p sx={{ opacity: open ? 1 : 0, fontSize: '1.2vh', }}>{capitalize(selectedArea)}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* mapeo de Areas */}
                    <Grid container >
                        { showAreas && selectedRegion && selectedRegion.areas.map((area, index) => (
                                <Grid  key={index} container justifyContent='center' style={{marginTop:'1vh'}}>
                                        {/* boton para seleccionar areas */}
                                        {/* <Badge badgeContent={ <TravelExploreIcon  style={{fontSize:styleFont}}/>} 
                                                anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                                }}
                                                sx={{marginLeft:'0vw', '& .MuiBadge-badge': 
                                                { backgroundColor: severityMap[area.statusArea], height:'3vh', width:'1.5vw', 
                                                marginTop:'0.8vh', marginRight:'0.1vw', fontSize:'1.8vh' }  }}> */}
                                            <Grid container justifyContent='center' item xs={11}
                                                sx={{
                                                    height: '5vh',
                                                    width:'12vw',
                                                    borderRadius:'1vh',
                                                    justifyContent: 'initial',
                                                    px: 1,
                                                    fontSize: "1.5vh",
                                                    '&:hover': {
                                                        backgroundColor: 'rgb(247, 130, 78)', // puedes cambiar el color y grosor del borde a tu gusto
                                                    },
                                                    backgroundColor: selectedArea === area.name ? 'rgb(255, 120, 60, 0.6)' : '#3f3f3f',
                                                    // marginTop:'1vh',
                                                }}
                                                // onClick={() => handleAreaClick(area)}
                                                onClick={() => {handleAreaClick(area);
                                                    localStorage.setItem('AREA', area.name);
                                                    setPosicionArea(index)
                                                    }}
                                            >
                                            
                                            <Grid container item xs={2} justifyContent='center' alignContent='center'  style={{ backgroundColor: severityMap[area.statusArea] , height:'4vh', minWidth:'2vw',maxWidth:'2vw', marginTop:'0.5vh', borderRadius:'1vh'}}>
                                                <TravelExploreIcon  style={{fontSize:styleFont}}/>
                                            </Grid>
                                            <Grid item xs={7}  alignContent='center' textAlign='initial' marginLeft='1vw'>
                                                <p sx={{ opacity: open ? 1 : 0, color: selectedArea === area.name && 'white', fontSize: '1.2vh',  }}>{capitalize(area.name)}</p>
                                            </Grid>
                                            </Grid>
                                        {/* </Badge> */}
                                    {/* <Divider style={{ color: "white", backgroundColor: "#2f2f2f", height: "0.01vh", width:'14.9vw' }} /> */}
                                </Grid>
                        ))}
                    </Grid>
                    {/* Texto para los centros */}
                    
                </Grid>
                <DrawerFooter /> {/*pie de pagina */}
            </Drawer>
        </Grid>
    );
}
