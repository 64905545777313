import React from 'react';
import { AppBar, Box, MenuItem, Menu,  Button, IconButton, Grid, /* Pagination, */ Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import AssessmentIcon from '@mui/icons-material/Assessment';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ReplyIcon from '@mui/icons-material/Reply';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Drawer from './Drawer'
import ModalCalendario from "../ModalComponents/ModalCalendario"
import ModalHistorialAlertas from '../ModalComponents/ModalHistorialAlertas'
import AmbientalPagination from './AmbientalPagination'
import Logo from './img/LOGO.png'
import LogoEnviro from './img/logo ENVIRO blanco sin fondo.png'
import TranslateIcon from '@mui/icons-material/Translate';
import Traductor from '../../../../components/Traductor';



function capitalize(value) {
    // Divide la cadena en palabras
    let words = value.toLowerCase().split(' ');

    // Capitaliza la primera letra de cada palabra
    let capitalizedWords = words.map(word => {
        // Capitaliza la primera letra de la palabra
        let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalized;
    });

    // Une las palabras nuevamente en una cadena
    let capitalized = capitalizedWords.join(' ');

    // Si la cadena comienza con 'De', agrega un espacio después de 'De'
    if (capitalized.startsWith('Los')) {
        capitalized = capitalized.replace('Los', 'Los ');
    }

    // Si la cadena comienza con 'Puerto', agrega un espacio después de 'Puerto'
    if (capitalized.startsWith('Puerto')) {
        capitalized = capitalized.replace('Puerto', 'Puerto ');
    }

    return capitalized;
}



function AmbientalAppBar({
    
    statusCenter,    //estado centro
    alertas,            //  alertas
    makeReport,        // Función para generar reportes.
    setNotification_open, //Estado para abrir alerta cuando se generara el reporte
    setNotification_msg, // para setear e mensaje de generando reporte
    localCenterData,   // Datos locales del centro.
    fetchDatedData,    // Función para obtener datos con fecha.
    lastPressedButtonIndex, // Índice del último botón presionado.
    currentView,            // Vista actual ('x1', 'x2', 'x4').
    currentPageStation,     // Página actual de la estación.
    setcurrentPageStation,  // Función para establecer la página actual de la estación.
    itemsPerPage,           // Cantidad de elementos 'estaciones' por página.
    profundidadesPerPage,   // Profundidades por página.
    setProfundidadesPerPage,// Función para establecer la cantidad de profundidades por página.
    handleProfundidadesPerPageChange, // Función para manejar el cambio en la cantidad de profundidades por página.
    handleViewChange, // Función para cambiar la vista actual.
    clientName,       // Nombre del cliente 'centro actual'.
    loggedIn,         // Estado de inicio de sesión
    pauseRequest,     // Estado de pausa de solicitudes.
    setPauseRequest,  // Función para establecer el estado de pausa de solicitudes.
    updateClientCredentials,  // Función para actualizar las credenciales del cliente
    SWVersion,        // string, indica version del software
    translate ,
    handleClick ,
    openMenuIdioma ,
    handleCloseIdioma ,
    handleLanguageChange, 
    idioma,
    selectedRegion, setSelectedRegion,
    selectedArea, setSelectedArea,
    consolidado_estados,
    setStatusCenter
}) {
    //* ------------------------ Estados Iniciales Appbar ----------------------------------------------
    const [openModal, setOpenModal] = React.useState(false)
    const [open, setOpen] = React.useState(true); //? Drawer
    const [centerName, setcenterName] = React.useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false)
    const [logoHoverStates, setLogoHoverStates] = React.useState(false); 

    const [openHistorialAlerta, setOpenHistorialAlerta] = React.useState(false);

    const handleOpenHistorialAlerta = () => setOpenHistorialAlerta(true);
    const handleCloseHistorialAlerta = () => setOpenHistorialAlerta(false);

    //* ------------------------ Constante: pages ----------------------------------------------
    //? Descripción: Define las opciones de páginas y sus vistas correspondientes.
    // Tipo de Dato: Array de objetos
    const pages = [
        { label: `1 ${translate('estacion')}`, view: 'x1' },
        { label: `2 ${translate('estaciones')}`, view: 'x2' },
        { label: `4 ${translate('estaciones')}`, view: 'x4' }
    ];

    //* ------------------------ Estado: maxElementCountDepth ----------------------------------------------
    //? Descripción: Calcula el número máximo de profundidades entre todas las estaciones en localCenterData.
    // Tipo de Dato: Número
    const maxElementCountDepth = localCenterData ? localCenterData.reduce((max, item) => {
        const profundidadLength = item.profundidades.length;
        return profundidadLength > max ? profundidadLength : max;
    }, 0) : 1;

    //* ------------------------ Estado: totalViews ----------------------------------------------
    //? Calcula el número total de vistas disponibles.
    // * Salto en bloques:
    // const totalViews = localCenterData && localCenterData.length > 0 ? Math.ceil(localCenterData.length / itemsPerPage) : 0;
    // * Salto de "uno en uno"
    const totalViews = localCenterData && localCenterData.length > 1 ? Math.ceil(localCenterData.length  / itemsPerPage) : 0; // Math.ceil((localCenterData.length - (itemsPerPage - 1) ) / itemsPerPage)

    //* ------------------------ Función: handleMenu ------------------------------------------------------
    //? Descripción: Maneja la apertura del menú de usuario.
    // Parámetros:
    // - event: Evento del menú.
    const handleMenu = () => {
        // setAnchorEl(event.currentTarget);
        setOpenMenu(true)
    };
    //* ------------------------ Función: handleCloseMenu ------------------------------------------------
    //? Descripción: Maneja el cierre del menú de usuario y realiza redirección.
    const handleCloseMenu = () => {
        setOpenMenu(false)
        window.location.href = "/"; // Cambia "/Añadir" por la ruta que deseas redirigir
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("empresa");
    };
    //* ------------------------ Función: handleCloseMenu2 -----------------------------------------------
    //? Descripción: Maneja el cierre del menú de usuario sin redirección.
    const handleCloseMenu2 = () => {
        setOpenMenu(false)
    };
    //* ------------------------ Función: handleReporteria ------------------------------------------------
    //? Descripción: Redirige a la página de reportería al hacer clic en el menú de usuario.
    const handleReporteria = () => {
        window.location.href = "/Report";
    };
    //* ------------------------ Función: handleHome ------------------------------------------------------
    //? Descripción: Redirige a la página de inicio al hacer clic en el menú de usuario.
    const handleHome = () => {
        window.location.href = "/home";
    };

    // -- Drawer Región - Área - Centro -- //
    //* ------------------------ Función: handleDrawerOpen ------------------------------------------------
    //? Descripción: Abre el drawer de Región - Área - Centro.
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    //* ------------------------ Función: handleDrawerClose -----------------------------------------------
    //? Descripción: Cierra el drawer de Región - Área - Centro.
    const handleDrawerClose = () => {
        setOpen(false);
    };

    //* ------------------------ Función: handlePageChange ------------------------------------------------
    //? Descripción: Maneja el cambio de página en la aplicación.
    // Parámetros:
    // - event: Evento del cambio de página.
    // - newPage: Nueva página seleccionada.
    const handlePageChange = (event, newPage) => {
        setcurrentPageStation(newPage - 1); // Restamos 1 ya que el componente de paginación usa índices basados en 1
    };

    // * --------------------------- HandleUser -------------------------------------------
    const handleUser = () =>{
        //console.log('hola mubndo')
    }

    //  * --------------------------handle MouseEnter
    const handleLogoMouseEnter = () => {
        setOpen(true); 
    };
    
    const handleLogoMouseLeave = () => {
        setOpen(false); 
    };
    

    //* ------------------------ Efecto: setcenterName ----------------------------------------------
    //? Descripción: Actualiza el estado centerName cuando el cliente cambia.
    React.useEffect(() => {
        setcenterName(clientName);
    }, [clientName]);

    //* ------------------------ Efecto: setProfundidadesPerPage ----------------------------------------------
    //? Descripción: Establece la profundidad por página en 2 si la vista cambia a 'x4'.
    //? Si profundidadesPerPage es mayor que 2, lo ajusta a 2.
    React.useEffect(() => {
        if (currentView === 'x4') {
            if (profundidadesPerPage > 2) {
                setProfundidadesPerPage(2);
            }
        }
        // Puedes descomentar el bloque "else" si se desea manejar la profundidad actual en otras vistas.
        // else {
        //     setProfundidadesPerPage(profundidadesPerPage);
        // }
    }, [currentView]);

    
    const getColor = () => {
        // Comprobamos si el valor es igual a C
        if (statusCenter === 'C') {
        // Si es igual C, devolvemos el valor en rojo
        return "red";
        }else if (statusCenter === 'A') {
            // Si es igual A, devolvemos el valor en amarillo
            return "yellow";
        } else{
            // De lo contrario, devuelve un color verde
            return "green";
        }
    };
    return (
        <AppBar style={{ cursor: 'default !important', userSelect: 'none', height: "7vh", }}>
            <Grid container justifyContent='center' alignContent='center' >
                {/* Logo ITG */}
                <Grid container justifyContent='flex-start'  alignContent='center' item xs={1}  >
                    <Grid item xs={6}>
                        <Box style={{ marginTop: '0vh', width: '3vw', height: "6vh", marginLeft: '1vw', }} 
                        onMouseEnter={() => handleLogoMouseEnter()}
                        onMouseLeave={() => handleLogoMouseLeave()} >
                            <img src={Logo} style={{ paddingLeft: '0.5vw', /* width: '4.2vw', */ height: "6vh", aspectRatio: '1.2', paddingTop: '1vh' }}
                                onClick={handleDrawerOpen} alt={''} />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton onClick={() => setOpenModal(!openModal)} style={{  width: "2.8vw", height: "6vh", marginLeft: '2.5vw', marginTop: '0.7vh' }} >
                            <CalendarMonthIcon style={{ fontSize:'3vh', color: "whitesmoke" }} />
                        </IconButton>
                    </Grid>
                </Grid>

                {/* Botones Estaciones */}
                <Grid container justifyContent='flex-end' alignContent='center' item xs={3} style={{ alignItems: 'center'}}>
                    {pages.map((button, index) => {
                        const [number, text] = button.label.split(' ');
                        return(
                            <Button key={index}
                                color="inherit"
                                variant='outlined'
                                onClick={() => handleViewChange(button.view, index)}
                                style={{
                                    fontSize: "1.5vh", minWidth: '6.5vw', maxWidth: '6.5vw', borderRadius: '1vh',
                                    height: "5vh", marginLeft: "0.5vw", lineHeight: "2.1vh",
                                    backgroundColor: lastPressedButtonIndex === index ? "rgb(81, 113, 140,0.5)" : "transparent",
                                    // border:'solid 0.1vh'
                                }}>
                                {/* {button.label} */}
                                <Grid container justifyContent='center' alignContent='center'>
                                    <Grid container justifyContent='center'>
                                        <Typography variant="body2" style={{  fontSize: "0.75vw", }}>{number}</Typography>
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <Typography variant="body2" style={{  fontSize: "0.75vw", }}>{text}</Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        )
                    })}
                </Grid>

                {/* Cambio entre estaciones */}
                <Grid container justifyContent='center'  item xs={4}  style={{ maxHeight: '7.5vh', alignItems: 'center',  justifyContent: 'left', }} >
                    <Grid container justifyContent='flex-end' item xs={2} >
                        {/* <div style={{ fontSize: '2vh' }} > */}
                            {centerName && (
                                <Grid container item xs={1}
                                    style={{
                                        alignItems: 'center', // Alinea los elementos hijos verticalmente en el centro
                                        justifyContent: 'center', // Alinea los elementos hijos horizontalmente
                                    }}  >
                                    <div>
                                        <Typography style={{ fontSize: '1.5vh', fontWeight: 100, textAlign:'center' }} >
                                            {translate('centro')}:
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ fontSize: '2vh', fontWeight: 700 , textAlign:'center'}} >
                                        {capitalize(centerName)}
                                        </Typography>
                                    </div>
                                </Grid>
                            )}
                        {/* </div> */}
                    </Grid>
                    <Grid item xs={8}>
                        {totalViews > 1 &&
                            <AmbientalPagination
                                currentPage={currentPageStation + 1} // Sumamos 1 ya que el componente de paginación usa índices basados en 1
                                totalPages={totalViews}
                                onChange={handlePageChange}
                            />
                        }
                    </Grid>
                    <Grid container  justifyContent='center' alignContent='center' item xs={2} style={{display:'flex', height:'4vh', border:'solid 0.1vh ', borderColor:getColor() ,borderRadius:'1vh'}}>
                        <Button onClick={handleOpenHistorialAlerta} style={{borderRadius:'1vh', height:'4vh', color:getColor()}}>
                            <Grid container justifyContent='center'>
                                <Grid continer  justifyContent='center'  alignContent='center' alignItems='center' display={'flex'} >
                                    <p  style={{fontSize:'0.8vw', color:getColor(), marginRight:'0.5vw'}} > {translate('status')} </p> 
                                    <Box  style={{height:'1vh', aspectRatio: 1 , borderRadius:'50%', backgroundColor: getColor()}}>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    
                </Grid>

                

                <Grid  container justifyContent='center' alignContent='center' item xs={4}  >
                    <Grid container item xs={2}>
                        <IconButton  onClick={handleClick} style={{marginLeft:'3vw'}}>
                            <TranslateIcon style={{fontSize:'2.2vh', color:'#e84e0e'}}/>
                        </IconButton>
                        {/* Menú de idiomas */}
                        <Traductor handleLanguageChange ={handleLanguageChange} openMenuIdioma ={openMenuIdioma} handleCloseIdioma ={handleCloseIdioma} />
                    </Grid>
                    <Grid container justifyContent='center' alignContent='center'  item xs={3}>
                        <Button variant='text' 
                            color='inherit'
                            onClick={() => handleProfundidadesPerPageChange(profundidadesPerPage - 1)} disabled={profundidadesPerPage === 1}
                            style={{ fontSize: "0.8vw",  height: "5vh",  }}>
                            {translate('quitar_profundidad')}
                        </Button>
                    </Grid>

                    <Grid container justifyContent='center' alignContent='center'  item xs={3}>
                        <Button variant='text'
                            color='inherit'
                            onClick={() => handleProfundidadesPerPageChange(profundidadesPerPage + 1)}
                            disabled={currentView === 'x4' ? profundidadesPerPage > 1 : profundidadesPerPage >= maxElementCountDepth}
                            style={{ fontSize: "0.8vw",  height: "5vh" }}>
                            {translate('agregar_profundidad')}
                        </Button>
                    </Grid>
                    {/* logo enviro */}
                    {/* Menu Usuario */}
                    <Grid container justifyContent='center' alignContent='center' item xs={4}>
                        <Grid container justifyContent='center' alignContent='center'>
                            
                                    {/* <AccountCircle style={{ fontSize: "3vh" }} /> */}
                                    {/* <img src={LogoEnviro}/> */}
                                    <img src={LogoEnviro} style={{ marginLeft: '-0.5vw', /* width: '4.2vw', */ width: "8.5vw", aspectRatio: 8/2, marginTop: '0vh' }}
                                    alt={''} />
                                
                
                                

                        </Grid>
                    </Grid>
                    </Grid>

                {/* Modal Fechas */}
                <ModalCalendario setNotification_open={setNotification_open} setNotification_msg={setNotification_msg} translate={translate} idioma={idioma} fetchDatedData={fetchDatedData} openModal={openModal} setOpenModal={setOpenModal} pauseRequest={pauseRequest} setPauseRequest={setPauseRequest} makeReport={makeReport} />
                <ModalHistorialAlertas translate={translate} alertas={alertas} open={openHistorialAlerta} handleClose={handleCloseHistorialAlerta}/>

                {/* Cajon lateral para seleccionar la region area y centro  */}
                <Drawer setStatusCenter={setStatusCenter} consolidado_estados={consolidado_estados} selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion} setSelectedArea={setSelectedArea} selectedArea={selectedArea} translate={translate} capitalize={capitalize} handleLogoMouseEnter={handleLogoMouseEnter} handleLogoMouseLeave={handleLogoMouseLeave} handleDrawerClose={handleDrawerClose} open={open} setOpen={setOpen} updateClientCredentials={updateClientCredentials} clientName={clientName} loggedIn={loggedIn} SWVersion={SWVersion} />

                {/* </Toolbar> */}
            </Grid>
        </AppBar>
    );
}

export default AmbientalAppBar;
