import React, { useState } from 'react';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/Logout';
import Traductor from '../Traductor';
import Logo from '../../assets/img/ITG_BLANCO.png';
import LogoITGSM from '../../assets/img/ITGSM.png';
import Pss from '../../assets/img/APP pss.png'
// import Fondo from '../../assets/img/image.png'

import PersistentDrawerLeft from './Drawer';

import { transformData } from '../../utils/TransformarDataConsolidados';

// function  transformData(consolidado_estados) {
//   const { areas, centros, regiones } = consolidado_estados;
//   const transformedData = [];

//   // Recorrer las regiones
//   for (const regionName in regiones) {
//       const region = {
//           name: regionName,
//           statusRegions: regiones[regionName],
//           areas: []
//       };

//       // Recorrer las áreas
//       for (const areaName in areas) {
//           const [regionPrefix, areaPrefix] = areaName.split('-');

//           if (regionPrefix === regionName) {
//               const area = {
//                   name: areaPrefix,
//                   statusArea: areas[areaName],
//                   centers: []
//               };

//               // Recorrer los centros
//               for (const centerName in centros[areaName]) {
//                   const [centerPrefix] = centerName.split('-');

//                   const center = {
//                       name: centerPrefix,
//                       statusCenter: centros[areaName][centerName]
//                   };

//                   area.centers.push(center);
//               }

//               region.areas.push(area);
//           }
//       }

//       transformedData.push(region);
//   }

//   return transformedData;
// }

export default function ControlManagerAppbar({ open, setOpen, SWVersion,consolidado_estados, severityMap, cargo, handleClick, openMenuIdioma, handleCloseIdioma, handleLanguageChange,translate,selectedRegion, setSelectedRegion, selectedArea, setSelectedArea, capitalize }) {
  const [openConfigMenu, setOpenConfigMenu] = useState(null);
  // const [open, setOpen] = useState(true); //drawer

  // const handleOpen = {}
  // const handleClose = {}

  const handleConfigMenuOpen = (event) => {
    setOpenConfigMenu(event.currentTarget);
  };

  const handleConfigMenuClose = () => {
    setOpenConfigMenu(null);
  };

  const handleUsersItemClick = () => {
    window.location.href = '/Registro'; // Redireccionar a la ruta de Usuarios
  };

  const handleCentersItemClick = () => {
    window.location.href = '/CreateCenters'; // Redireccionar a la ruta de Centros
  };

  const handleExit = () => {
    window.location.href = '/'; // 
  };

  // console.log('consolidado_estados',consolidado_estados)
  // console.log('transformData',transformData(consolidado_estados))


  return (
    <Grid container justifyContent='center' alignContent='center' style={{  height:'9vh', backgroundColor:'#121212' }}>
      <Grid container justifyContent="flex-start" item xs={8} >
        <IconButton onClick={()=>{setOpen(!open)}}> 
          <img src={Logo} style={{ marginLeft: '1.5vw', height: "6vh", aspectRatio: '1.4'}} alt="Logo" />
        </IconButton>
      </Grid>
      <Grid container justifyContent="flex-end" alignContent='center' item xs={2} >
        <IconButton onClick={handleClick} style={{ marginRight: '1vw', height: '3vh' }}>
          <TranslateIcon style={{ fontSize: '3vh', color: '#e84e0e' }} />
        </IconButton>
        {/* Language Menu */}
        <Traductor handleLanguageChange ={handleLanguageChange} openMenuIdioma ={openMenuIdioma} handleCloseIdioma ={handleCloseIdioma} />
      </Grid>
      <Grid container justifyContent="flex-end" alignContent='center' item xs={2} 
   style={{
        fontSize:'2vw', 
        // fontFamily:'Roboto, sans-serif', 
        // fontWeight:'lighter',
        }}>

        {/* <IconButton  style={{ marginLeft: '1vw',  width: "5.2vw", aspectRatio: '1'}}> 
          <img src={Pss} style={{ height: "7vh", aspectRatio: '1', borderRadius:'50%'}} alt="Logo" />
        </IconButton> */}

        {/* <IconButton style={{  width: "5.2vw", aspectRatio: '1.2'}} >  */}
          <img src={LogoITGSM} style={{  width: "5.2vw", aspectRatio: '1.2'}} alt="Logo" />
        {/* </IconButton> */}
       
      </Grid>
      <PersistentDrawerLeft  
        SWVersion={SWVersion}
        consolidado_estados={transformData(consolidado_estados)} 
        severityMap={severityMap} 
        cargo={cargo}
        handleUsersItemClick={handleUsersItemClick}
        handleCentersItemClick={handleCentersItemClick}
        handleExit={handleExit}
        open={open} 
        translate={translate} 
        selectedRegion={selectedRegion} 
        setSelectedRegion={setSelectedRegion} 
        selectedArea={selectedArea} 
        setSelectedArea={setSelectedArea}
        capitalize={capitalize}/>
    </Grid>
  );
}
