import React from 'react';
import {Grid,IconButton,Typography, Box } from '@mui/material';
import LogoNaranjo from '../assets/img/ITG-Logotipo-SobreNegro.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModalCalendario from './modal/ModalCalendario';
import TranslateIcon from '@mui/icons-material/Translate';
import Traductor from '../../../../components/Traductor';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import TemporaryDrawer from './TemporaryDrawer'; 
import Pss from '../../components/assets/img/logo pss 1 blanco sin fondo.png'

export function MyAppBar({
    nameCentro,
    translate ,
    handleClick ,
    openMenuIdioma ,
    handleCloseIdioma ,
    handleLanguageChange, 
    idioma ,
    notificationOpen, setNotification_open, setNotification_msg, setNotification_status,
    setNameCentro,
    SWVersion,
    centroSeleccionado,
    regionSeleccionada,
    handleCentroClick,
    areaSeleccionada,
    handleAreaClick,
    theme,
    handleRegionClick,
    capitalize,
    consolidado_estados,
    selectedRegion, setSelectedRegion,
    selectedArea, setSelectedArea,
      }) {

  const [openModal, setOpenModal] = React.useState(false)//CALENDARIO
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  //  * --------------------------handle MouseEnter
  const handleLogoMouseEnter = () => {
    setOpen(true); 
  };
    
  const handleLogoMouseLeave = () => {
      setOpen(false); 
  };


  const MyDrawerProps = {
    SWVersion: SWVersion,
    theme: theme,
    handleAreaClick: handleAreaClick,
    handleRegionClick: handleRegionClick,
    areaSeleccionada: areaSeleccionada,
    handleCentroClick: handleCentroClick,
    regionSeleccionada: regionSeleccionada,
    centroSeleccionado: centroSeleccionado,
    setNameCentro: setNameCentro,
    translate: translate,
    capitalize:capitalize,
    consolidado_estados:consolidado_estados,
    selectedRegion:selectedRegion, 
    setSelectedRegion:setSelectedRegion,
    selectedArea:selectedArea, 
    setSelectedArea:setSelectedArea,

    open:open,
    setOpen:setOpen,
    toggleDrawer:toggleDrawer,
    handleLogoMouseEnter:handleLogoMouseEnter, 
    handleLogoMouseLeave:handleLogoMouseLeave,
  } 
  return (
      <Grid container justifyContent={'center'} style={{height:'7vh'}}>
            <Grid container justifyContent='center' alignContent='center'  alignItems={'center'} item xs={1} style={{height:'7vh'}}>
              <IconButton
                color="inherit"
                onClick={toggleDrawer(true)}
                edge="start"
                onMouseEnter={() => handleLogoMouseEnter()}
                onMouseLeave={() => handleLogoMouseLeave()}
                // style={{position: 'fixed',}}
                style={{ width: "3.4vw", aspectRatio: '1.2' }}
              >
                <img
                    src={LogoNaranjo}
                    alt="Icono"
                    style={{ width: "3.4vw", aspectRatio: '1.2',  color:"white" }}
                />
              </IconButton> 
            </Grid>
             {/* Botón Calendario */}
            <Grid container justifyContent='flex-start' alignContent='center' item xs={1} style={{}} >
              <IconButton onClick={() => setOpenModal(!openModal)} style={{ width: "2.8vw", height: "6vh" }} >
                <CalendarMonthIcon style={{fontSize:"1.75vw",color: "whitesmoke", /* position: 'fixed', top: '1.3vh', left: '10vw' */ }} />
              </IconButton>
              <ModalCalendario openModal={openModal} setOpenModal={setOpenModal} idioma={idioma} translate={translate} notificationOpen={notificationOpen} setNotification_open={setNotification_open} setNotification_msg={setNotification_msg} setNotification_status={setNotification_status} />
            </Grid>
            <Grid container justifyContent='center' alignContent='center' item xs={4} style={{}}>
              {nameCentro && (
                        <Grid container  style={{ alignItems: 'center', justifyContent: 'center' }}>
                          <Grid container justifyContent='center'>
                              <Typography style={{ fontSize: '0.75vw', fontWeight: 100 }}>{translate('accessed_center')}</Typography>
                          </Grid>
                          <Grid container justifyContent='center'>
                              <Typography style={{ fontSize: '1vw', fontWeight: 700 }}>{capitalize(nameCentro)}</Typography>
                          </Grid>
                        </Grid>
              )}
            </Grid>
            
            {/* Nivel de Funcionamiento */}
            <Grid container justifyContent='center' alignContent='center' item xs={3} style={{}}>
              {/* <Box 
                display="flex" 
                alignItems="center" 
                width="100%" 
                minHeight="5vh" 
                borderRadius="4px" 
                overflow="hidden"
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.2)"
              > */}
                {/* Barra de Funcionamiento */}
                <Box 
                  display="flex" 
                  justifyContent="center" 
                  alignItems="center" 
                  width={`${60}%`} 
                  height='3.5vh'
                  bgcolor="#00bf63" //00bf63 //008000
                  color="white"
                >
                  <Typography variant="body2" style={{fontSize:'0.8vw'}}>
                    Funcionamiento {60}%
                  </Typography>
                </Box>
                {/* Barra de Fallas */}
                <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                width={`${40}%`} 
                height='3.5vh'
                bgcolor="#b14645" //#b14645 //ff0000
                color="white"
              >
                  <Typography variant="body2" style={{fontSize:'0.8vw'}}>
                    Fallas {40}%
                  </Typography>
                </Box>
              {/* </Box> */}
            </Grid>
            
            <Grid container justifyContent='center' alignContent='center' item xs={1} style={{}}>
                <IconButton  onClick={handleClick} style={{marginRight:'1vw'}}>
                  <TranslateIcon style={{fontSize:'1.1vw', color:'#e84e0e'}}/>
                </IconButton>
                {/* Menú de idiomas */}
                <Traductor handleLanguageChange ={handleLanguageChange} openMenuIdioma ={openMenuIdioma} handleCloseIdioma ={handleCloseIdioma} />
            </Grid>
            <Grid container justifyContent='flex-end' alignContent='center' item xs={2} style={{height:'7vh'}}>
            <IconButton
                color="inherit"
                onClick={() => {window.location.href='/PSS'}}
                // style={{position: 'fixed',}}
                style={{ width: "8vw", aspectRatio: '1.6' }}
              >
                <img
                  src={Pss}
                  alt="Icono"
                  style={{ width: "8vw", aspectRatio: '1.6',  color:"white" }}
                />  
              </IconButton> 
            </Grid>
            {/* Drawer */}
            <TemporaryDrawer  {...MyDrawerProps} />
      </Grid>
  );
}
