// src/components/SharedComponents/BombaStatusMessage.jsx
import React, { useState,useContext, useEffect } from 'react'
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import BombaCommonInStatus from './BombaCommonInStatus.jsx';
import './assets/css/BombaCommonStatus.css';


const StyleTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    //   borderLeft: '0.01vh solid white',

    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#161616',
    //   border: '0.01vh solid white',
      fontSize:"1vh"
      
    },
  }));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40vw",
    height: "50vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    border: '2px solid #000',
    borderRadius: "3vh",
};


const BombaCommonStatus = ({ message, modulo, bomba,translate, configuracionUmbrales, color }) => {

    const [isWriting, setIsWriting] = useState(false);
    // modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Modoo
    const [autoMode, setAutoMode] = useState(false);

    // Control
    const [buttonState2, setButtonState2] = useState(bomba.Control === "1" ? true : false);
    const [buttonColor2, setButtonColor2] = useState(bomba.Control === "1" ? 'inherit' : 'error');

    const handleButtonClickState = (button) => {
        if (button === 2) {
            setButtonState2(!buttonState2); // Cambiar el estado del segundo botón al hacer clic
            setButtonColor2(prevState => prevState === 'primary' ? 'inherit' : 'error');

        }

    };

    useEffect(() => {
        // Este efecto se ejecutará cuando bomba.SEL cambie
        if (bomba.InSel === "1") {
        setAutoMode(true);
        } else {
        setAutoMode(false);
        }
    }, [bomba.InSel]); // Se ejecutará cada vez que bomba.SEL cambie

    useEffect(() => {
        setButtonState2(bomba.Control === "1" ? true : false);
        setButtonColor2(bomba.Control === "1" ? 'primary' : 'secondary');
    }, [bomba.Control]);

  return (
    <Grid container justifyContent="center" className="bomba-common-status">
        <Grid container justifyContent={'center'} item xs={12}>
            <BombaCommonInStatus 
                StyleTooltip={StyleTooltip} 
                bomba = {bomba}  
                color={color} 
                configuracionUmbrales={configuracionUmbrales}
                message={message}
                translate={translate}
            />
        </Grid>
    </Grid>
  );
};

export default BombaCommonStatus;
