import React from 'react'
import { Grid } from '@mui/material'
import { MyAppBar } from '../components/Layouts/MyAppBar'
import Tabla from '../components/SLA/Tabla'
import Grafico from '../components/SLA/Grafico'
import ProblemasTabla from '../components/SLA/Pruebas'

const SLA = ({translate}) => {

    const AppBarProps = {
        translate: translate,
      } 
  return (
    <Grid container justifyContent={'center'}>
        <Grid container justifyContent={'center'} style={{height:'7vh', backgroundColor:'#171717'}}>
            <MyAppBar  {...AppBarProps}/>
        </Grid>
        {/* <Grid container justifyContent={'center'}  style={{height:'46.5vh', width:'98vw'}}>
            <Tabla/>
        </Grid>
        <Grid container justifyContent={'center'}  style={{height:'46.5vh', width:'98vw' }}>
            <Grafico/>
        </Grid> */}
        <Grid container justifyContent={'center'}  style={{height:'93vh', width:'98vw'}}>
            <ProblemasTabla/>
        </Grid>
    </Grid>
  )
}

export default SLA