import React from 'react';
import { Grid } from '@mui/material';


const Alertas = ({alertaSeleccionada, translate}) => {
    const getSensorName = (sensor) => {
    switch(sensor) {
    case 'O2': return   translate('oxigeno');
    case 'DO2': return  translate('saturacion');
    case 'Vo': return   translate('voltaje');
    case 'Io': return   translate('corriente');
    case 'Press': return translate('presion');
    default: return sensor;
    }
    };

    const getSensorUnit = (sensor) => {
    switch(sensor) {
    case 'O2': return '[ mg/L ]';
    case 'DO2': return '[ V ]';
    case 'Vo': return '[ A ]';
    case 'Io': return '[ % ]';
    case 'Press': return '[ bar ]';
    default: return sensor;
    }
    };

    const getSensorTipo = (rango) => {
    switch(rango) {
    case 0: return translate('dentro_umbral');
    case 1: return translate('sobre_umbral');
    case -1: return translate('bajo_umbral');
    default: return rango;
    }
    };

    const getBackgroundColor = (rango) => {
    switch (rango) {
    case -1:
    case 1:
    return '#ac2929';
    case 0:
    return 'green';
    default:
    return 'transparent';
    }
    };

    const [año, mes, dia, hora ] = alertaSeleccionada?.fecha?.split('-') ? alertaSeleccionada?.fecha?.split('-') : '';
    const [modulo, jaula, bomba,can,sensor] = alertaSeleccionada?.variable?.split('-') ? alertaSeleccionada?.variable?.split('-') : '';

    if (!alertaSeleccionada) {
        return (
        <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} 
            style={{backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000', fontSize:'1vw', color:'#ffffff', marginBottom:'1vh',  height:'6vh'}}>
            <strong>{translate('no_alerta_seleccionada')}</strong>
        </Grid>
        ) //<p>No hay alerta seleccionada</p>;
    }
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} style={{backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000'}}>
      <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={9} style={{fontSize:'1vw', color:'#ffffff', marginBottom:'1vh',  height:'6vh'}}>
        <strong>{translate('detalles_alerta')}</strong>
        {/* Detalles de la Alerta Seleccionada */}
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('fecha')}:
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {dia} - {mes} - {año}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('hora')}:
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {hora}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('modulo')}:
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {modulo}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('jaula')}:
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {jaula}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('Sensor')}:
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {getSensorName(sensor)}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('valor')}:
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {alertaSeleccionada.valor.toFixed(1)} {getSensorUnit(sensor)}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          {translate('tipo')}:
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh',}}>
          <Grid container justifyContent={'center'} style={{borderRadius:'0.2vw',backgroundColor:getBackgroundColor(alertaSeleccionada.rango)}}>
            {getSensorTipo(alertaSeleccionada.rango)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Alertas