import React from 'react';
import Modal from '@mui/material/Modal';
import { Box, Typography } from '@mui/material';
import BatteryChart from '../Grafico/BatteryGraph'



const ModalStyleNombreCentro = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    height: '65vh',
    backgroundColor: 'rgba(10,10,10,1)',
    borderRadius: '1vw',
    border: "0.2vh solid rgba(171, 71, 188)"

};



function ModalBattery({ batteryData, clientName, openModalBattery, handleCloseBattery ,translate}) {

    if (typeof openModalBattery === 'undefined') {
        return null;
    }
    
    /* Nombre del centro */
    const stationName = clientName


    return (

        <Modal
            open={openModalBattery}
            onClose={handleCloseBattery}
            
        >
            <Box sx={{ padding: '2vh' }} style={ModalStyleNombreCentro}  >
                <Typography textAlign={'center'} fontSize='1.2vw' sx={{ cursor: 'default !important', userSelect: 'none' }} >
                    {stationName}
                </Typography>

                <BatteryChart
                    data1={batteryData}
                    width={"84vw"}
                    height={"58vh"}
                />

            </Box>
        </Modal>
    );
}

export default ModalBattery;