import React, { useState, useEffect } from "react";
import { DrawerHeader } from './utils/funciones_AppBar_Drawer';
import Main from './component/layout/Main';
import { Grid } from '@mui/material';
import Notification from './component/Notifier';
import { fetchConsolidadoCenters } from "../../utils/apiServer";
import { transformData } from '../../utils/TransformarDataConsolidados';

const  SWVersion = 'v20240530'

function capitalize(value) {
  if (!value) return '';

  // Divide la cadena en palabras
  let words = value.toLowerCase().split(' ');

  // Capitaliza la primera letra de cada palabra y divide 'Los' y 'Puerto' si están dentro de una palabra más grande
  let capitalizedWords = words.map(word => {
    // Reemplaza 'Los' y 'Puerto' en cualquier posición dentro de una palabra
    word = word.replace(/los/gi, 'Los ').replace(/puerto/gi, 'Puerto ');

    // Capitaliza la primera letra de cada palabra resultante
    return word.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  });

  // Une las palabras nuevamente en una cadena
  let capitalized = capitalizedWords.join(' ');

  return capitalized.trim(); // Remover cualquier espacio adicional accidental
}

const MainNanoox = ({translate ,handleClick ,openMenuIdioma ,handleCloseIdioma ,handleLanguageChange, idioma,selectedRegion,setSelectedRegion, selectedArea, setSelectedArea }) => {
  const [consolidado_estados, setConsolidado_estados] = useState({});
  const [open, setOpen] = useState(true);
    // * ----------------------------- Estado para notificaciones -----------------------------
  //? - notificationOpen: Indica si la notificación está abierta.
  const [notificationOpen, setNotification_open] = useState(false)
  const [notification_msg, setNotification_msg] = useState('')
  const [notification_status, setNotification_status] = useState('success')



  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
        const PAGE ='NANOOX' 
        const result = await fetchConsolidadoCenters(PAGE);
        setConsolidado_estados(result);
        // console.log('response: consolidado_estados', result)

    };

    // Fetch data initially
    fetchData();

    // Set interval to fetch data every 60 seconds
    const interval = setInterval(fetchData, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
}, []);


  const NanooxProps = {
    SWVersion: SWVersion,
    open: open,
    handleDrawer: handleDrawer,
    DrawerHeader: DrawerHeader,
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    idioma:idioma,
    notificationOpen:notificationOpen,
    setNotification_open:setNotification_open,
    setNotification_msg:setNotification_msg,
    setNotification_status:setNotification_status,
    selectedRegion:selectedRegion,
    selectedArea:selectedArea,
    setSelectedRegion:setSelectedRegion,
    setSelectedArea:setSelectedArea,
    consolidado_estados:transformData(consolidado_estados),
    capitalize:capitalize
  } 

  return (
    <Grid container justifyContent={'center'}  style={{ cursor: 'default !important', userSelect: 'none', }}> 
      {notificationOpen &&
        <Notification
            notification_open={notificationOpen} notification_time={3000} notification_severity={notification_status} notification_msg={notification_msg} setNotification_open={setNotification_open} />
      }
      <Main {...NanooxProps}/>

    </Grid>

  )
}

export default MainNanoox