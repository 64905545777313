import React from 'react';
import { Grid, Button, Box, Typography } from "@mui/material";
import imgSalinidad from "./img/rocas.png";

const Salinidad = ({
    // Valor de sensor
    medicion,
    // Funcionalidad del Botón
    showSalinidad, toggleSalinidad,
    // Tamaños generales
    fullHeight, fullwidth,
    // Ajuste de la card de oxígeno
    width, height,
    // Tamaño card del valor de oxigeno
    width1, height1,
    // Tamaño de icono
    widthIMG, heightIMG,
    // Separación de la card de oxígeno y su valor
    marginLeft1,
    // Ajuste de la posición del valor de oxígeno
    fontSizeH1, fontSizeTypo1,
}) => {

    const getColor = () => {
        return showSalinidad && ( medicion !== "N/D" ) ? 'rgb(174, 122, 21, 0.1)' : 'rgb(174, 122, 21, 0)';
    };

    return (
        <Button style={{ backgroundColor: getColor() }} onClick={toggleSalinidad} sx={{ border: `0.15vh solid ${medicion!== "N/D" ? '#ae7a15' : '#ae7a15'}`, height: fullHeight, width: fullwidth }} disabled={medicion === 'N/D'} > 
            <Grid container spacing={0} alignItems="top" justifyContent="center" >
                {/* Card de oxígeno */}
                <Grid item xs={6} >
                    <Box display='flex' direction="column" alignItems="center" justifyContent="center" width={width} height={height} /* style={{ filter: `${medicion!== "N/D" ? '' : 'brightness(0.6)' }` }} */ >
                        <img src={imgSalinidad} alt="Icono" style={{ width: widthIMG, height: heightIMG, position: 'center', marginLeft: marginLeft1 }} />
                    </Box>
                </Grid>

                {/* Card de número */}
                <Grid item xs={6} >
                    <Box style={{ width: width1, height: height1, backgroundColor: "transparent", marginLeft: marginLeft1, textTransform: 'none' }} >
                        <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '95%', cursor: 'default !important', userSelect: 'none' }}>
                            <Typography variant="h1" style={{ fontSize: fontSizeH1, fontWeight: 1000, color: 'white'/* `${medicion!== "N/D" ? 'white' : 'rgba(255,255,255,0.5)'}` */ }} >
                                {medicion}
                            </Typography>
                            <Typography variant="h1" style={{ fontSize: fontSizeTypo1, fontWeight: 300, color: 'white'/* `${medicion!== "N/D" ? 'white' : 'rgba(255,255,255,0.5)'}` */ }} >
                                ppt
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Button>
    );
};

export default Salinidad;


/* 

const Salinidad = ({
    showSalinidad,
    toggleSalinidad,
    fullHeight,
    fullwidth,
    width,
    height,
    widthIMG,
    heightIMG,
    marginLeft1,
    medicion,
    fontSizeH1,
    fontSizeTypo1,
    width1,
    height1
}) => {

    const getColor = () => {
        return showSalinidad && ( medicion !== "N/D" ) ? 'rgb(174, 122, 21, 0.1)' : 'rgb(174, 122, 21, 0)';
    };

    return (
        <Button style={{ backgroundColor: getColor() }} onClick={toggleSalinidad} sx={{ border: `0.15vh solid ${medicion!== "N/D" ? '#ae7a15' : '#ae7a15'}`, height: fullHeight, width: fullwidth }} disabled={medicion === 'N/D'} >
        <Grid container spacing={0} alignItems="top" justifyContent="center" >

        <Grid item xs={6} >
            <Box display='flex' direction="column" alignItems="center" justifyContent="center" width={width} height={height} style={{ filter: `${medicion!== "N/D" ? '' : 'brightness(0.5)' }` }} >
                <img src={imgSalinidad} alt="Icono" style={{ width: widthIMG, height: heightIMG, position: 'center', marginLeft: marginLeft1 }} />
            </Box>
        </Grid>

        <Grid item xs={6} >
            <Box style={{ width: width1, height: height1, backgroundColor: "transparent", marginLeft: marginLeft1, textTransform: 'none' }} >
                <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '95%', cursor: 'default !important', userSelect: 'none' }}>
                    <Typography variant="h1" style={{ fontSize: fontSizeH1, fontWeight: 1000, color: `${medicion!== "N/D" ? 'white' : '#495057'}` }}>
                        {medicion}
                    </Typography>
                    <Typography variant="h1" style={{ fontSize: fontSizeTypo1, fontWeight: 300, color: `${medicion!== "N/D" ? 'white' : '#495057'}` }}>
                        ppt
                    </Typography>
                </Grid>
            </Box>
        </Grid>
    </Grid>
</Button>
);
};

*/