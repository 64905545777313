import React, {useState} from 'react'
import { Grid } from '@mui/material'
import Oxigeno from '../Sensores/Oxigeno'
import Salinidad from '../Sensores/Salinidad'
import Saturacion from '../Sensores/Saturacion'
import { Temperatura } from '../Sensores/Temperatura'
// import Chart from '../Grafico/grafico'
import AmbientalChart from '../../Grafico/AmbientalGraph'
import Alertas from '../alertas'


const Prof_2_Normal = (
    {/* Graficos */alerta,
        showOxigeno, showSaturacion, showSalinidad, showTemperatura, profundidad,translate,
        toggleOxigeno, toggleSaturacion, toggleSalinidad, toggleTemperatura, title, pauseRequest, setPauseRequest, profundidadesPerPage, currentView }) => {
    
    const commonProps = {
        fullwidth:'11.8vw',
        fullHeight:'13.5vh',
        //Tamaño de icono
        heightIMG: '7.5vh',
        widthIMG: '3.75vw',
        sinText: true,
        // ajuste de la card de oxígeno
        width: '6vw',
        height: '12vh',
        // ajuste de la posición del valor de oxígeno
        fontSizeH1: '3.8vh',
        fontSizeTypo1: '2.8vh',
        //tamaño card del valor de oxigeno
        width1: '7vw',
        height1: '12vh',
        // separación de la card de oxígeno y su valor
        marginLeft1: '-1vw',
    };

    const graphProps = {
        width       : "68vw",
        height      : "30vh",
        data1       : profundidad.O2_val ? profundidad.O2_val : null,
        data2       : profundidad.DO2_val? profundidad.DO2_val : null,
        data3       : profundidad.sal_val? profundidad.sal_val : null,
        data4       : profundidad.temp_val? profundidad.temp_val : null,
        title       : title,
        legendFontSize   : "2.2vh",
        axisFontSize     : window.innerHeight*0.02 , //"20vh",
        showOxigeno      : showOxigeno,
        showSaturacion   : showSaturacion,
        showSalinidad    : showSalinidad,
        showTemperatura  : showTemperatura,
        toggleOxigeno    : toggleOxigeno,
        toggleSaturacion : toggleSaturacion,
        toggleSalinidad  : toggleSalinidad,
        toggleTemperatura: toggleTemperatura,
        // Activar Solicitudes de datos
        pauseRequest          : pauseRequest, 
        setPauseRequest       : setPauseRequest,
        profundidadesPerPage  : profundidadesPerPage,
        currentView           : currentView,
        translate              : translate

    }
    const initialAlerts = alerta?.alertasActivas || [];
    const [activeAlerts, setActiveAlerts] = useState(initialAlerts);
    return (
        <Grid container justifyContent="center" style={{marginTop:'2vh'}}>
            <Grid container item xs={12} alignContent="center">
                <h1 style={{ color: "white", fontSize:'3vh', marginLeft:'2.5vw',height:'2vh', marginTop:'-1vh' }}>
                    {profundidad.profundidad ? `${profundidad.profundidad}m` : ''} 
                </h1>
            </Grid>
            <Alertas activeAlerts={activeAlerts} setActiveAlerts={setActiveAlerts} translate={translate}/>
            {/* Grafico */}
            <Grid container justifyContent="center" item xs={9} height="36vh">
                <Grid item xs={12} style={{marginLeft:"-3vw", marginTop:'1vh'}}>
                    <AmbientalChart
                        {...graphProps}
                    />
                </Grid>
            </Grid>

            {/* Sensores */}
            <Grid container item xs={3} justifyContent="center" style={{ height: "27.3vh" }} >
                <Grid item xs={6}>
                    <Oxigeno
                        showOxigeno={showOxigeno}
                        toggleOxigeno={toggleOxigeno}
                        {...commonProps}
                        medicion={profundidad.O2_val && profundidad.O2_val[profundidad.O2_val.length - 1] && profundidad.O2_val[profundidad.O2_val.length - 1].value ? profundidad.O2_val[profundidad.O2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Saturacion
                        showSaturacion={showSaturacion}
                        toggleSaturacion={toggleSaturacion}
                        {...commonProps}
                        medicion={profundidad.DO2_val && profundidad.DO2_val[profundidad.DO2_val.length - 1] && profundidad.DO2_val[profundidad.DO2_val.length - 1].value ? profundidad.DO2_val[profundidad.DO2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Salinidad
                        showSalinidad={showSalinidad}
                        toggleSalinidad={toggleSalinidad}
                        {...commonProps}
                        medicion={profundidad.sal_val && profundidad.sal_val[profundidad.sal_val.length - 1] && profundidad.sal_val[profundidad.sal_val.length - 1].value ? profundidad.sal_val[profundidad.sal_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                <Grid item xs={6} >
                    <Temperatura
                        showTemperatura={showTemperatura}
                        toggleTemperatura={toggleTemperatura}
                        {...commonProps}
                        medicion={profundidad.temp_val && profundidad.temp_val[profundidad.temp_val.length - 1] && profundidad.temp_val[profundidad.temp_val.length - 1].value ? profundidad.temp_val[profundidad.temp_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Prof_2_Normal