import React, {useState} from 'react'
import { Grid } from '@mui/material'
import Oxigeno from '../Sensores/Oxigeno'
import Salinidad from '../Sensores/Salinidad'
import Saturacion from '../Sensores/Saturacion'
import { Temperatura } from '../Sensores/Temperatura'
// import Chart from '../Grafico/grafico'
import AmbientalChart from '../../Grafico/AmbientalGraph'
import Alertas from '../alertas'

const Prof_1_x2 = ({ alerta,translate, profundidad, showOxigeno, showSaturacion, showSalinidad, showTemperatura, toggleOxigeno, toggleSaturacion, toggleSalinidad, toggleTemperatura, title, pauseRequest, setPauseRequest, profundidadesPerPage, currentView  }) => {

    const commonProps = {
        // Tamaños generales
        fullwidth: '11vw',
        fullHeight: '12vh',
        //Tamaño de icono
        heightIMG: '6vh',
        widthIMG: '3vw',
        sinText: false,
        // ajuste de la card de oxígeno
        width: '4.5vw',
        height: '9vh',
        // ajuste de la posición del valor de oxígeno
        fontSizeH1: '4vh',
        fontSizeTypo1: '2.2vh',
        //tamaño card del valor de oxigeno
        width1: '6vw',
        height1: '9vh',
        // separación de la card de oxígeno y su valor
        marginLeft1: '-1vw',
    };

    const graphProps = {
        width   : "48vw",
        height  : "50vh",
        data1   : profundidad.O2_val,
        data2   : profundidad.DO2_val,
        data3   : profundidad.sal_val,
        data4   : profundidad.temp_val,
        title   : title,
        legendFontSize   : "2vh",
        // axisFontSize     : "0vh",
        axisFontSize     : window.innerHeight*0.015, //"20vh",
        showOxigeno      : showOxigeno,
        showSaturacion   : showSaturacion,
        showSalinidad    : showSalinidad,
        showTemperatura  : showTemperatura,
        toggleOxigeno    : toggleOxigeno,
        toggleSaturacion : toggleSaturacion,
        toggleSalinidad  : toggleSalinidad,
        toggleTemperatura: toggleTemperatura,
        // Activar Solicitudes de datos
        pauseRequest          : pauseRequest, 
        setPauseRequest       : setPauseRequest,
        profundidadesPerPage  : profundidadesPerPage,
        currentView           : currentView,
        translate              : translate

    }
    const initialAlerts = alerta?.alertasActivas || [];
    const [activeAlerts, setActiveAlerts] = useState(initialAlerts);
    
    return (
        <Grid container style={{marginTop:'3vh'}} >
            <Grid container justifyContent="flex-start" item xs={12}>
                <h1 style={{height:'4vh',fontSize:"2vh", marginTop:'-0.5vh', marginLeft:'1.9vw'}}>
                    {profundidad.profundidad ? `${profundidad.profundidad}m` : ''} </h1>
            </Grid>
            <Alertas activeAlerts={activeAlerts} setActiveAlerts={setActiveAlerts} translate={translate}/>
            {/* Grafico */}
            <Grid container justifyContent="center" item xs={12} style={{ height: "60vh" }} >
                <AmbientalChart
                    {...graphProps}                   
                />
            </Grid>

            {/* Indicadores sensores */}
            <Grid container item xs={12} justifyContent="center" >
                <Grid container justifyContent="center" item xs={3} sm={3} md={3} style={{ height: "20vh" }}>
                    {/* <Box style={{marginLeft:"0vw"}}>  */}
                    <Oxigeno

                        marginTopIMG={"0vh"}
                        showOxigeno={showOxigeno}
                        toggleOxigeno={toggleOxigeno}
                        {...commonProps}
                        medicion={profundidad.O2_val[profundidad.O2_val.length - 1] && profundidad.O2_val[profundidad.O2_val.length - 1].value ? profundidad.O2_val[profundidad.O2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                    {/* </Box> */}
                </Grid>
                <Grid container justifyContent="center" item xs={3} sm={3} md={3} style={{ height: "20vh" }}>
                    <Saturacion

                        marginTopIMG={"0vh"}
                        showSaturacion={showSaturacion}
                        toggleSaturacion={toggleSaturacion}
                        {...commonProps}
                        medicion={profundidad.DO2_val[profundidad.DO2_val.length - 1] && profundidad.DO2_val[profundidad.DO2_val.length - 1].value ? profundidad.DO2_val[profundidad.DO2_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                    {/* </Box> */}
                </Grid>
                <Grid container justifyContent="center" item xs={3} sm={3} md={3} style={{ height: "20vh" }}>
                    <Salinidad

                        marginTopIMG={"-1vh"}
                        showSalinidad={showSalinidad}
                        toggleSalinidad={toggleSalinidad}
                        {...commonProps}
                        medicion={profundidad.sal_val[profundidad.sal_val.length - 1] && profundidad.sal_val[profundidad.sal_val.length - 1].value ? profundidad.sal_val[profundidad.sal_val.length - 1].value.toFixed(1) : 'N/D'}
                    />
                </Grid>
                <Grid container justifyContent="center" item xs={3} sm={3} md={3} style={{ height: "20vh" }}>
                    <Temperatura

                        marginTopIMG={"0vh"}
                        showTemperatura={showTemperatura}
                        toggleTemperatura={toggleTemperatura}
                        {...commonProps}
                        medicion={profundidad.temp_val[profundidad.temp_val.length - 1] && profundidad.temp_val[profundidad.temp_val.length - 1].value ? profundidad.temp_val[profundidad.temp_val.length - 1].value.toFixed(1) : 'N/D'}

                    />
                </Grid>
            </Grid>
        </Grid>

    )
}

export default Prof_1_x2