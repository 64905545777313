import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import RegionSelector from './SeleccionAcceso/Region';
import AreaSelector from './SeleccionAcceso/Area';
import CenterSelector from './SeleccionAcceso/Centro';
import Alert from '@mui/material/Alert';
import CardReggions from './CardRegions/CardReggions';
import { fetchConsolidadoCenters } from '../../utils/apiServer';

function capitalize(value) {
  if (typeof value !== 'string') {
    return '';
  }

  let words = value.toLowerCase().split(' ');
  let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  let capitalized = capitalizedWords.join(' ');

  if (capitalized.startsWith('Los')) {
    capitalized = capitalized.replace('Los', 'Los ');
  }

  if (capitalized.startsWith('Puerto')) {
    capitalized = capitalized.replace('Puerto', 'Puerto ');
  }

  return capitalized;
}

const Acceso = ({ user, setUser, translate }) => {
  const [region, setRegion] = useState('');
  const [area, setArea] = useState('');
  const [selectedCenters, setSelectedCenters] = useState({});
  const [showAreaSelector, setShowAreaSelector] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState('');
  const [consolidado_estados, setConsolidado_estados] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let PAGE = 'HOME';
      const result = await fetchConsolidadoCenters(PAGE);
      setConsolidado_estados(result);
    };

    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  function transformData(consolidado_estados) {
    const { areas, centros, regiones } = consolidado_estados;
    const transformedData = {};

    for (const regionName in regiones) {
      transformedData[regionName] = {};

      for (const areaName in areas) {
        const [regionPrefix, areaPrefix] = areaName.split('-');

        if (regionPrefix === regionName) {
          transformedData[regionName][areaPrefix] = {};

          for (const centerName in centros[areaName]) {
            const center = centros[areaName][centerName];
            const [centerPrefix] = centerName.split('-');
            transformedData[regionName][areaPrefix][centerPrefix] = {
              "enviro": { "status": false },
              "nanoox": { "status": false },
              "scapp": { "status": false },
              "pss": { "status": false }
            };
          }
        }
      }
    }

    return transformedData;
  }

  const handleRegionChange = (event) => {
    const selectedRegionName = event;
    setRegion(selectedRegionName);
    setArea('');
    setSelectedCenters({});
  };

  const handleAreaChange = (event) => {
    const selectedArea = event;
    setArea(selectedArea);
    setSelectedCenters({});
  };

  const handleCenterChange = (event) => {
    setSelectedCenters(event);
  };

  // const add = (event) => {
  //   event.preventDefault();
  //   const updatedUser = { ...user };

  //   if (!updatedUser.permisos) {
  //     updatedUser.permisos = {};
  //   }

  //   if (!updatedUser.permisos[region]) {
  //     updatedUser.permisos[region] = {};
  //   }

  //   if (!updatedUser.permisos[region][area]) {
  //     updatedUser.permisos[region][area] = {};
  //   }

  //   Object.keys(selectedCenters).forEach(centerName => {
  //     if (!updatedUser.permisos[region][area][centerName]) {
  //       updatedUser.permisos[region][area][centerName] = {};
  //     }

  //     updatedUser.permisos[region][area][centerName] = {
  //       enviro: { status: selectedCenters[centerName].enviro },
  //       nanoox: { status: selectedCenters[centerName].nanoox },
  //       scapp: { status: selectedCenters[centerName].scapp },
  //       pss: { status: selectedCenters[centerName].pss }
  //     };
  //   });

  //   setUser(updatedUser);
  //   setRegion('');
  //   setArea('');
  //   setSelectedCenters({});
  // };
  const add = (event) => {
    event.preventDefault();
    const updatedUser = { ...user };
  
    if (!updatedUser.permisos) {
      updatedUser.permisos = {};
    }
  
    if (!updatedUser.permisos[region]) {
      updatedUser.permisos[region] = {};
    }
  
    if (!updatedUser.permisos[region][area]) {
      updatedUser.permisos[region][area] = {};
    }
  
    Object.keys(selectedCenters).forEach(centerName => {
      // Crear el nombre del centro en el formato 'centro-area-region'
      const formattedCenterName = `${centerName}-${area}-${region}`;
  
      if (!updatedUser.permisos[region][area][formattedCenterName]) {
        updatedUser.permisos[region][area][formattedCenterName] = {};
      }
  
      updatedUser.permisos[region][area][formattedCenterName] = {
        enviro: { status: selectedCenters[centerName].enviro },
        nanoox: { status: selectedCenters[centerName].nanoox },
        scapp: { status: selectedCenters[centerName].scapp },
        pss: { status: selectedCenters[centerName].pss }
      };
    });
  
    setUser(updatedUser);
    setRegion('');
    setArea('');
    setSelectedCenters({});
  };
  // console.log('user', user)
  const handleCenterDelete = (regionName, areaName, centerToDelete) => () => {
    const updatedPermisos = { ...user.permisos };
    if (updatedPermisos[regionName] && updatedPermisos[regionName][areaName]) {
      delete updatedPermisos[regionName][areaName][centerToDelete];
      if (Object.keys(updatedPermisos[regionName][areaName]).length === 0) {
        delete updatedPermisos[regionName][areaName];
        if (Object.keys(updatedPermisos[regionName]).length === 0) {
          delete updatedPermisos[regionName];
        }
      }
    }
    setUser({ ...user, permisos: updatedPermisos });
  };

  const toggleAreaSelector = () => {
    setShowAreaSelector(!showAreaSelector);
  };

  const saveArea = (regionName) => {
    const updatedPermisos = { ...user.permisos };
    if (!updatedPermisos[regionName]) {
      updatedPermisos[regionName] = {};
    }

    if (!updatedPermisos[regionName][area]) {
      updatedPermisos[regionName][area] = {};
    }

    if (Object.keys(selectedCenters).length === 0) {
      setOpenAlert(true);
      setMensajeAlert('No se han seleccionado centros.');
      return;
    }

    Object.keys(selectedCenters).forEach(centerName => {
      if (!updatedPermisos[regionName][area][centerName]) {
        updatedPermisos[regionName][area][centerName] = {
          enviro: { status: selectedCenters[centerName].enviro },
          nanoox: { status: selectedCenters[centerName].nanoox },
          scapp: { status: selectedCenters[centerName].scapp },
          pss: { status: selectedCenters[centerName].pss }
        };
      }
    });

    setUser({ ...user, permisos: updatedPermisos });
    setArea('');
    setSelectedCenters({});
    toggleAreaSelector();
  };

  useEffect(() => {
    setIsAddButtonDisabled(!(region && area && Object.keys(selectedCenters).length > 0));
  }, [region, area, selectedCenters]);

  const remove = (regionName, areaName, centerToDelete) => {
    const updatedPermisos = { ...user.permisos };
  
    if (centerToDelete) {
      if (updatedPermisos[regionName] && updatedPermisos[regionName][areaName]) {
        delete updatedPermisos[regionName][areaName][centerToDelete];
  
        if (Object.keys(updatedPermisos[regionName][areaName]).length === 0) {
          delete updatedPermisos[regionName][areaName];
  
          if (Object.keys(updatedPermisos[regionName]).length === 0) {
            delete updatedPermisos[regionName];
          }
        }
      }
    } else if (areaName) {
      if (updatedPermisos[regionName] && updatedPermisos[regionName][areaName]) {
        delete updatedPermisos[regionName][areaName];
  
        if (Object.keys(updatedPermisos[regionName]).length === 0) {
          delete updatedPermisos[regionName];
        }
      }
    } else if (regionName) {
      delete updatedPermisos[regionName];
    }
  
    setUser({ ...user, permisos: updatedPermisos });
  };
  
  return (
    <Grid container>
      <Grid container justifyContent="center" item xs={12} style={{ zIndex: 1 }}>
        <Grid item xs={2} style={{ marginLeft: "10vw" }}>
          <RegionSelector capitalize={capitalize} translate={translate} region={region} datosArray={transformData(consolidado_estados)} handleRegionChange={handleRegionChange} />
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "2vw" }}>
          <AreaSelector capitalize={capitalize} translate={translate} region={region} area={area} datosArray={transformData(consolidado_estados)} handleAreaChange={handleAreaChange} />
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "2vw" }}>
          <CenterSelector capitalize={capitalize} translate={translate} selectedCenters={selectedCenters} region={region} area={area} datosArray={transformData(consolidado_estados)} handleCenterChange={handleCenterChange} />
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "2vw" }}>
          <Button onClick={add} disabled={isAddButtonDisabled} variant="outlined" color="primary" type="submit" style={{ fontSize: '1.8vh', marginTop: '0.5vh' }}>
            {translate('add_item')}
          </Button>
        </Grid>
      </Grid>
      {openAlert && (
        <Alert severity="warning" onClose={() => setOpenAlert(false)} style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
          {translate(mensajeAlert)}
        </Alert>
      )}
      <Grid container justifyContent='center' style={{ zIndex: 0 }}>
        <CardReggions
          user={user}
          remove={remove}
          translate={translate}
          handleCenterChange={handleCenterChange}
          handleAreaChange={handleAreaChange}
          area={area}
          showAreaSelector={showAreaSelector}
          saveArea={saveArea}
          selectedCenters={selectedCenters}
          toggleAreaSelector={toggleAreaSelector}
          handleCenterDelete={handleCenterDelete}
          capitalize={capitalize}
        />
      </Grid>
    </Grid>
  );
}

export default Acceso;
