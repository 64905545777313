import React, {useState} from 'react'
import { Button, Grid, Modal, Avatar, IconButton } from '@mui/material';
import { EliminarCentro } from '../utils/apiServer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel, EffectCoverflow, Pagination  } from 'swiper/modules';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import BusinessIcon from '@mui/icons-material/Business';
import SetMealIcon from '@mui/icons-material/SetMeal';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

function capitalize(value) {
  // Divide la cadena en palabras
  let words = value.toLowerCase().split(' ');

  // Capitaliza la primera letra de cada palabra
  let capitalizedWords = words.map(word => {
      // Capitaliza la primera letra de la palabra
      let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
      return capitalized;
  });

  // Une las palabras nuevamente en una cadena
  let capitalized = capitalizedWords.join(' ');

  // Si la cadena comienza con 'De', agrega un espacio después de 'De'
  if (capitalized.startsWith('Los')) {
      capitalized = capitalized.replace('Los', 'Los ');
  }

  // Si la cadena comienza con 'Puerto', agrega un espacio después de 'Puerto'
  if (capitalized.startsWith('Puerto')) {
      capitalized = capitalized.replace('Puerto', 'Puerto ');
  }

  return capitalized;
}


const Centro = ({ centros, actualizarListaCentros, centroSeleccionado , setCentroSeleccionado, handleEdit, editar, cancelEdit ,translate}) => {

  const [openModal, setOpenModal] = useState(false); // si se decea eliminar usuario
  const [fechasServicio, setFechasServicio] = useState({ inicio: '', fin: '' });
  const servicios = ['nanoox', 'enviro', 'pss', 'scapp'];

  // //console.log(fechasServicio,'fechasServicio')

  const handleCentroClick = (centro) => {
    setCentroSeleccionado(centro.centro_id);
    for (const servicio of servicios) {
      if (centro[servicio].status) {
        setFechasServicio({ inicio: centro[servicio].init, fin: centro[servicio].fin });
        return;
      }
    }
    setFechasServicio({ inicio: '', fin: '' });
  };

    // Función para abrir el modal de confirmación de eliminación
    const handleOpenModal = () => {
      setOpenModal(true);
    };
    const handleCloseModal = () => {
      setOpenModal(false);
    };

  // //console.log('centroselecionado', centroSeleccionado)
  const procesarCentros = (centros) => {
    const regiones = {};
  
    centros.forEach((centro) => {
      if (centro.centro_id && typeof centro.centro_id === 'string') {
        const [nombreCentro, area, region] = centro.centro_id.split('-');
        
        if (!regiones[region]) {
          regiones[region] = {};
        }
    
        if (!regiones[region][area]) {
          regiones[region][area] = [];
        }
    
        regiones[region][area].push({
          nombreCentro,
          ...centro
        });
      } else {
        console.warn(`Centro con ID inválido o indefinido encontrado:`, centro);
      }
    });
  
    return regiones;
  };

  const regiones = procesarCentros(centros);


  const handleDelete = () => {
    // Verificar si hay un centro seleccionado antes de eliminarlo
    if (centroSeleccionado) {
      //console.log("Usuario seleccionado:", centroSeleccionado);
      // Llamar a la función para eliminar el centro seleccionado
      EliminarCentro( centroSeleccionado)
        .then(async () => {
          // Opcionalmente, puedes actualizar la lista de centro aquí si es necesario
          // Actualiza la lista de centro después de eliminar el centro
          await actualizarListaCentros();
          setOpenModal(false);
        })
        .catch((error) => {
          // Manejar cualquier error que ocurra durante la eliminación del centro
          console.error("Error al eliminar usuario:", error);
          // Opcionalmente, puedes mostrar un mensaje de error al centro
        });
    } else {
      console.warn("No se ha seleccionado ningún usuario para eliminar.");
      // Opcionalmente, puedes mostrar un mensaje al centro indicando que no ha seleccionado ningún centro
      setOpenModal(false);
    }
  };
  
  
  const generarColorAvatar = (centro) => {
    // Generar un valor hash a partir del nombre de usuario
    let hash = 0;
    for (let i = 0; i < centro.length; i++) {
      hash = centro.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convertir el hash a un color hexadecimal
    const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    // Añadir ceros a la izquierda si es necesario
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const handleEditClick = (centro) => {
    handleEdit(centro);
  };

  return (

    <Grid container justifyContent='center' sx={{ height:'38vh', width:'90vw', }}>
      <Grid container justifyContent='center' alignContent='center' style={{height:'5vh', }}>
        <h1  style={{fontSize:'3vh', }}>
          {translate('list_centers')}
        </h1>
      </Grid>
      <Grid container justifyContent='center' alignContent='center' style={{ height:'33vh',}} >
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={3} 
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {Object.keys(regiones).map((region, id) => (
            <SwiperSlide key={id}>
              <Grid container justifyContent='center'  style={{borderRadius:'2vh', height:'30vh', width:'30vw', backgroundColor:'#282828'}} >
                <Grid container justifyContent='center' alignContent='center' style={{height:'6vh'}}>
                  <h2 style={{fontSize:'3vh', marginTop:'3vh'}}>{capitalize(String(region))}</h2>
                </Grid>
                <Swiper
                  spaceBetween={10}
                  direction={'vertical'}
                  scrollbar={{ hide: true }}
                  mousewheel={true}
                  slidesPerView={1} // cantidad de ususraios visibles
                  modules={[FreeMode, Scrollbar, Mousewheel]}
                  // className="mySwiper"
                  style={{ width:'90%', }}
                >
                  {Object.keys(regiones[region]).map((area, index) => (
                    <SwiperSlide key={index} >
                      <Grid container justifyContent='center' key={area}  style={{height:'20vh',  backgroundColor:'#121212',marginTop:'-9vh'}}  >
                        <Grid container justifyContent='center' alignContent='center' style={{ height:'4vh',}}>
                          <h3 style={{fontSize:'2.5vh', marginTop:'5vh' }}>{capitalize(String(area))}</h3>                  
                        </Grid>
                        <Grid container justifyContent='center' style={{ height:'18vh' }}>
                          <Swiper
                              spaceBetween={10}
                              slidesPerView={4} // cantidad de ususraios visibles
                              scrollbar={{ hide: true }}
                              mousewheel={true}
                              modules={[FreeMode, Scrollbar, Mousewheel]}
                              // className="mySwiper"
                              style={{ width:'95%',}}
                            >
                          {regiones[region][area].map((centro, index) => (
                            <SwiperSlide key={index} >
                              <Grid marginTop='-2vh' container justifyContent='center'  key={index} 
                                  onClick={() => handleCentroClick(centro)} 
                                  /* onDoubleClick={() => handleOpenData(usuario)}  style={{ backgroundColor: centroSeleccionado === usuario && '#5f6b76' ,*/ 
                                  style={{  marginLeft:'1vw', borderRadius:'1vh',height:'13vh',width:'18vw', backgroundColor: centroSeleccionado === centro.centro_id && '#5f6b76', overflow:'hidden' }}>
                                <Grid container justifyContent='center' alignItems='center' style={{ marginTop:'1vh', position: 'relative', height: centroSeleccionado === centro.centro_id ? '3vh' :'5vh' }}>
                                  <Avatar  variant="soft" sx={{ bgcolor: generarColorAvatar(centro.nombreCentro), fontSize:'2.5vh', width: centroSeleccionado === centro.centro_id ? '4vh' : '5vh', height: centroSeleccionado === centro.centro_id ? '4vh' : '5vh' }}>
                                    {/* {centro.nombreCentro.charAt(0)} */}
                                    {/* ShareLocationIcon BusinessIcon  SetMealIcon */}
                                      <BusinessIcon style={{fontSize:  '3vh', color:'white'}}/>
                                    </Avatar>
                                  {/* IconButton para el ícono de eliminación */}
                                  {centroSeleccionado === centro.centro_id && (
                                    <>
                                      <IconButton onClick={handleOpenModal} sx={{ position: 'absolute', top: -15, right: -7, zIndex:10 }} >
                                        <DeleteIcon style={{fontSize:'2.2vh'}} />
                                      </IconButton>
                                        <IconButton  onClick={() =>{ editar ? cancelEdit() : handleEditClick(centro)}} sx={{ position: 'absolute', top: -15, left: -6, }} >
                                          {editar ?  <EditOffIcon style={{fontSize:'2.2vh'}} /> : <EditIcon style={{fontSize:'2.2vh'}} /> }
                                        </IconButton>
                                    </>
                                  )}
                                </Grid>
                                <Grid container justifyContent='center' alignContent='center' style={{height: '5vh', marginTop:'-1vh'}}>
                                  {/* Manejar el clic en el usuario */}
                                  <p style={{fontSize: centroSeleccionado === centro.centro_id ? '1.3vh' : '2vh'}}>{capitalize(String(centro.nombreCentro))}</p>
                                </Grid>
                                {centroSeleccionado === centro.centro_id && fechasServicio.inicio && fechasServicio.fin && (
                                  <Grid container justifyContent='center' alignContent='center' alignItems='center' style={{ marginTop: '-1.5vh', height:'4.5vh' }}>
                                    <Grid style={{fontSize:'1.2vh'}}>
                                      {translate('ciclo')}
                                    </Grid>
                                    <Grid style={{fontSize:'1.1vh'}}>
                                      {translate('inicio')}:  {fechasServicio.inicio}
                                    </Grid>
                                    <Grid style={{fontSize:'1.1vh'}}>
                                      {translate('fin')}:  {fechasServicio.fin}
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </SwiperSlide>
                          ))}
                          </Swiper>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
            </SwiperSlide>  
          ))}
        </Swiper>
      </Grid>
             {/* Modal de confirmación de eliminación */}
      <Modal open={openModal} >
        <Grid  container justifyContent='center' style={{ height:'13vh', width:'22vw', borderRadius:'2vh', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#1e1e1e', padding: 20 }}>
          <h1 variant="h6" style={{fontSize:'2vh'}}>{translate('confirm_delete_center')}</h1>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item> 
              <Button style={{width:'5vw', height:'4vh', fontSize:'1.8vh'}} variant='outlined' color='inherit' onClick={() => {
                // Aquí puedes implementar la lógica para eliminar el usuario
                //console.log("centro eliminado:", centroSeleccionado);
                handleDelete();
              }}>{translate('si')}</Button>
            </Grid>
            <Grid item>
              <Button style={{width:'5vw', height:'4vh', fontSize:'1.8vh'}} variant='outlined' color='inherit' onClick={handleCloseModal}>{translate('no')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  )
}

export default Centro